import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { StringExt } from 'src/app/utils/string';
import { CustomersService, ICustomer } from './customers.service';
import { IProject, ProjectsService } from './projects.service';
import { IProposal, ProposalsService } from './proposals.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalAutocompleteOptionsService {

  private customers: ICustomer[] = [];
  private projects: IProject[] = [];
  private proposals: IProposal[] = [];

  private references$: ReplaySubject<IOption[]> = new ReplaySubject(1);
  public get references(): Observable<IOption[]> { return this.references$.asObservable(); }


  private phoneNotes$: ReplaySubject<IOption[]> = new ReplaySubject(1);
  public get phoneNotes(): Observable<IOption[]> { return this.phoneNotes$.asObservable(); }


  private city$: ReplaySubject<IOption[]> = new ReplaySubject(1);
  public get city(): Observable<IOption[]> { return this.city$.asObservable(); }

  private projectType$: ReplaySubject<IOption[]> = new ReplaySubject(1);
  public get projectType(): Observable<IOption[]> { return this.projectType$.asObservable(); }

  private statusOptions$: ReplaySubject<IOption[]> = new ReplaySubject(1);
  public get statusOptions(): Observable<IOption[]> { return this.statusOptions$.asObservable(); }


  private conditionOptions$: ReplaySubject<IOption[]> = new ReplaySubject(1);
  public get conditionOptions(): Observable<IOption[]> { return this.conditionOptions$.asObservable(); }

  constructor(private customersService: CustomersService, private projectsService: ProjectsService, private proposalService: ProposalsService) {
    setTimeout(() => { this.init(); }, 100);
  }

  private subscriptions: Subscription = new Subscription();

  init() {
    const projects$ = this.projectsService.projects;
    const customers$ = this.customersService.customers;
    this.subscriptions.add(combineLatest(projects$, customers$)
      .subscribe(([projects, customers]) => {
        this.customers = customers;
        this.projects = projects;
        this.updateOptions();
      }));

    this.subscriptions.add(this.proposalService.proposals.subscribe(data => { this.proposals = data; this.updatePaymentConditions(); }))
  }

  updateOptions() {
    const referenceSet: Set<string> = new Set();
    const phoneNotesSet: Set<string> = new Set();
    const citySet: Set<string> = new Set();
    const projectTypeStrings: string[] = [];
    const statusSet: Set<string> = new Set();

    for (const customer of this.customers) {
      if (!StringExt.isNullOrWhiteSpace(customer.Reference)) { referenceSet.add(customer.Reference.trim()); }
      if (!StringExt.isNullOrWhiteSpace(customer.Phone1Note)) { phoneNotesSet.add(customer.Phone1Note.trim()); }
      if (!StringExt.isNullOrWhiteSpace(customer.Phone2Note)) { phoneNotesSet.add(customer.Phone2Note.trim()); }
      if (!StringExt.isNullOrWhiteSpace(customer.Phone3Note)) { phoneNotesSet.add(customer.Phone3Note.trim()); }
      if (!StringExt.isNullOrWhiteSpace(customer.City)) { citySet.add(customer.City.trim()); }
    }

    for (const project of this.projects) {
      if (!StringExt.isNullOrWhiteSpace(project.ProjectType)) {
        projectTypeStrings.push(project.ProjectType.trim());
      }
      if (!StringExt.isNullOrWhiteSpace(project.City)) { citySet.add(project.City.trim()); }
      if (!StringExt.isNullOrWhiteSpace(project.Status)) { statusSet.add(project.Status.trim()); }
    }

    this.phoneNotes$.next(Array.from(phoneNotesSet).sort((a, b) => a.localeCompare(b)).map(m => { return { Text: m, Value: m, FilterValue: m.toLowerCase() } as IOption }) ?? []);
    this.references$.next(Array.from(referenceSet).sort((a, b) => a.localeCompare(b)).map(m => { return { Text: m, Value: m, FilterValue: m.toLowerCase() } as IOption }));
    this.city$.next(Array.from(citySet).sort((a, b) => a.localeCompare(b)).map(m => { return { Text: m, Value: m, FilterValue: m.toLowerCase() } as IOption }));

    const projectTypes: IOption[] = [];
    for (const projectType of projectTypeStrings) {
      const index = projectTypes.findIndex(f => f.FilterValue === projectType.toLowerCase());
      if (index === -1) {
        projectTypes.push({ FilterValue: projectType.toLowerCase(), Value: projectType, Text: projectType, Count: 1 });
      }
      else {
        projectTypes[index].Count = (projectTypes[index].Count ?? 1) + 1;
      }
    }
    this.projectType$.next(projectTypes.sort((a, b) => ((a.Count ?? 0) < (b.Count ?? 0)) ? 1 : -1));
    this.statusOptions$.next(Array.from(statusSet).sort((a, b) => a.localeCompare(b)).map(m => { return { Text: m, Value: m, FilterValue: m.toLowerCase() } as IOption }));
  }

  updatePaymentConditions() {
    const conditionsStrings: string[] = [];
    for (const proposal of this.proposals) {
      for (const payment of proposal.Payments) {
        if (!StringExt.isNullOrWhiteSpace(payment.Condition)) {
          conditionsStrings.push(payment.Condition.trim());
        }
      }
    }

    const conditions: IOption[] = [];
    for (const condition of conditionsStrings) {
      const index = conditions.findIndex(f => f.FilterValue === condition.toLowerCase());
      if (index === -1) {
        conditions.push({ FilterValue: condition.toLowerCase(), Value: condition, Text: condition, Count: 1 });
      }
      else {
        conditions[index].Count = (conditions[index].Count ?? 1) + 1;
      }
    }
    this.conditionOptions$.next(conditions.sort((a, b) => ((a.Count ?? 0) < (b.Count ?? 0)) ? 1 : -1));
  }
}

export interface IOption {
  Text: string;
  Value: string;
  FilterValue: string;
  Count?: number
}
