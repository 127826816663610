import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService, User } from '../api-authorization/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  readonly agGridKey = 'agGridSettings';
  // readonly defaultsKey = 'defaults';

  private _agGridSettings: AgGridSettings = {
    rowHeight: 22,
    headerHeight: 22,
    floatingFiltersHeight: 22,
    theme: 'ag-theme-balham'
  }

  private isAgGridSettingsLoaded = false;

  private agGridSettings$: ReplaySubject<AgGridSettings> = new ReplaySubject<AgGridSettings>();
  get agGridSettings(): Observable<AgGridSettings> {
    return this.agGridSettings$.asObservable();
  }

  public get user(): Observable<User | null> { return this.authService.user; }
  public get userValue(): User | null {
    return this.authService.userValue;
  }

  constructor(private storage: StorageMap, private authService: AuthenticationService) {
    this.storage.get(this.agGridKey).subscribe(data => {
      const settings = data ? data as AgGridSettings : this._agGridSettings;
      this.agGridSettings$.next(settings);
    });
  }

  loadAgGridSettings() {
    var m =  this.storage.get(this.agGridKey).pipe(map(data => {
      const settings = data ? data as AgGridSettings : this._agGridSettings;
       return new BehaviorSubject(settings);
      return this.agGridSettings$
    }));
  }

  saveAgGridSettings(agGridSettings: AgGridSettings) {
    this.agGridSettings$?.next(agGridSettings);
    this.storage.set(this.agGridKey, agGridSettings).subscribe();
  }
}
export interface AgGridSettings extends GridOptions {
  theme: string;
}
