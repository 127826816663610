import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellValueChangedEvent, GridApi, GridOptions, RowClickedEvent, RowNode } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { TextareaEditorComponent } from 'src/app/ag-grid-components/textarea-editor/textarea-editor.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { Grids, IProposalGridState, ProposalLayoutService } from '../proposal-layout.service';
import { CustomersService, ICustomer } from '../services/customers.service';
import { ProjectAttachmentsService } from '../services/project-attachments.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { ProposalsService } from '../services/proposals.service';

@Component({
  selector: 'app-project-customer',
  templateUrl: './project-customer.component.html',
  styleUrls: ['./project-customer.component.scss']
})
export class ProjectCustomerComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;
  gridState: IProposalGridState | null = null;
  frameworkComponents = { textareaEditor: TextareaEditorComponent, };
  projects: IProject[] = [];
  selectedCustomer: ICustomer | null = null;
  selectedProject: IProject | null = null;

  pinnedTopRowData: object[] = [{ Value: "All", fullWidth: true }];
  fullWidthCellRenderer = (params: any) => {
    return `<div class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height ag-cell-value">All</div>`
  };



  constructor(private projectsService: ProjectsService, private customerService: CustomersService, private layoutService: ProposalLayoutService,
    private userSettings: UserSettingsService, private proposalsService: ProposalsService, private projectAttachmentService: ProjectAttachmentsService, private datePipe: DatePipe) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => this.initGrid(data)));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.layoutService.proposalViewLayout.subscribe(data => { this.gridState = data.Grids.ProjectCustomer; this.setSavedGridSate(); }));
    this.subscriptions.add(this.projectsService.projects.subscribe(data => this.projects = data));
    this.subscriptions.add(this.customerService.selectedCustomer.subscribe(data => {
      this.selectedCustomer = data;
      setTimeout(() => { this.gridApi?.onFilterChanged(); }, 1);
    }));
    this.subscriptions.add(this.projectsService.selectedProject.subscribe(data => {
      const oldSelectedProjectId: number | null = this.selectedProject?.Id ?? null;
      this.selectedProject = data;

      if (data !== null) {
        if ((oldSelectedProjectId !== data.Id)) {
          setTimeout(() => {
            this.gridApi?.forEachNodeAfterFilter(node => {
              if (node.data.Id === data?.Id) {
                node.setSelected(true);
                this.gridApi?.ensureIndexVisible(node.rowIndex);
              }
            });
            setTimeout(() => { this.gridApi?.redrawRows(); }, 2);
          }, 2);
        }
      }
      else {
        this.gridApi?.deselectAll();
        if (oldSelectedProjectId !== null) {
          this.proposalsService.selectProposal(null);
        }
        setTimeout(() => { this.gridApi?.redrawRows(); }, 2);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setSavedGridSate();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedProject = selectedRows[0] as IProject;
      if (this.selectedProject?.Id !== selectedProject.Id) {
        this.selectedProject = selectedProject;
        this.projectsService.selectProject(selectedProject);
        this.proposalsService.selectProposal(null);
        setTimeout(() => { this.gridApi?.redrawRows(); }, 2);
      }
    }
    else {
      if (this.selectedProject !== null) {
        this.projectsService.selectProject(null);
      }
    }
  }

  onCellValueChanged(params: CellValueChangedEvent) {
    const colId = params.column.getId();
    const project: IProject = params.data;
    this.projectsService.update(project).subscribe();
    this.gridApi?.redrawRows();
  }

  doesExternalFilterPass(node: RowNode): boolean {
    if (this.selectedCustomer == null) return false;
    const data = node.data as IProject;
    return this.selectedCustomer.Id === data.CustomerId;
  }

  saveGridState() {
    const gridState: IProposalGridState = {
      ColumnState: this.gridColumnApi.getColumnState(),
      ColumnFilterState: {}
    }
    this.layoutService.gridUpdate(Grids.ProjectCustomer, gridState);
  }

  setSavedGridSate() {
    if (this.gridApi && this.gridState) {
      this.gridOptions?.columnApi?.applyColumnState({ state: this.gridState.ColumnState, applyOrder: true });
    }
    else if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.isExternalFilterPresent = () => true;
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: IProject) => data.Id.toString();
    this.gridOptions.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    this.gridOptions.onDragStopped = () => this.saveGridState();
    this.gridOptions.onSortChanged = () => this.saveGridState();
    this.gridOptions.onColumnPinned = () => this.saveGridState();
    this.gridOptions.onColumnMoved = () => this.saveGridState();
    this.gridOptions.onColumnResized = () => this.saveGridState();
    this.gridOptions.onColumnValueChanged = () => this.saveGridState();

    this.gridOptions.isFullWidthCell = (node: RowNode) => { return node.data.fullWidth };
    this.gridOptions.fullWidthCellRenderer = this.fullWidthCellRenderer as any;
    this.gridOptions.pinnedTopRowData = this.pinnedTopRowData;
    this.gridOptions.onRowClicked = (event: RowClickedEvent) => {
      if (event.node.rowPinned) {
        this.selectedProject = null;
        this.projectsService.selectProject(null);
      }
    };
    this.gridOptions.getRowClass = (params) => {
      return (params.node.rowPinned && this.selectedProject == null) ? 'ag-row-selected' : "";
    };

    this.defaultColDef = {
      filter: null,
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: false,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'ProjectType', headerName: 'Project Type', width: 90 },
      { field: 'Description', headerName: 'Description', width: 120, editable: (params: any) => true, cellEditor: 'textareaEditor' },
      {
        field: 'Notes', headerName: 'Notes', width: 120, cellStyle: (params: any) => {
          const sch = params.value?.toLowerCase().trim().startsWith('sch')
          const cellStyle = sch ? { color: 'Black', backgroundColor: 'Yellow' } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }, editable: (params: any) => true, cellEditor: 'textareaEditor',
        cellEditorParams: {
          minimumRows: 1,
          maximumRows: 6,
          suppressEnterKey: true
        }
      },
      { field: 'City', headerName: 'City', width: 100 },
      {
        field: 'DateCalled', headerName: 'Date', width: 90, sort: 'desc',
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
      },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }

  dragover(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (rowId) {
      this.addHoverClass(rowId);
    }
  }

  dragEnd(event: any) {
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
  }

  drop(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (!rowId) { return }
    this.saveFiles(rowId, e.dataTransfer.files);
  }

  saveFiles(rowId: string, files: any) {
    const projectId = parseInt(rowId);
    const project = this.projects.find(c => c.Id === projectId);
    if (!project) {
      console.error('Could not find project with rowId ' + rowId);
      return
    }

    this.projectAttachmentService.uploadFiles(project.Id, files)?.subscribe();
  }

  getRowId(element: HTMLElement | null) {
    if (!element) { return null }
    let rowId = element.getAttribute('row-id');
    if (rowId) { return rowId; }

    while (!rowId) {
      element = element.parentElement;
      if (element === null) { return null; }

      rowId = element.getAttribute('row-id');
    }
    return rowId;
  }

  addHoverClass(rowId: string) {
    if (rowId === 'b-0') { return; }
    document.querySelectorAll(`div[row-id="${rowId}"`).forEach(element => element.classList.add('drag-drop-attachment-hover'));
  }
}


