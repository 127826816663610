import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalTermsService {

  private terms$: BehaviorSubject<IProposalTerms[]> = new BehaviorSubject<IProposalTerms[]>([]);
  public get terms(): Observable<IProposalTerms[]> { if (!this.isLoaded) { this.loadTerms(); } return this.terms$.asObservable(); }

  private selectedTerms$: BehaviorSubject<IProposalTerms | null> = new BehaviorSubject<IProposalTerms | null>(null);
  public get selectedTerms(): Observable<IProposalTerms | null> { return this.selectedTerms$.asObservable(); }


  baseUrl = 'ProposalTerms';
  isLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadTerms(): void {
    const url = `${this.baseUrl}`;
    this.isLoaded = true;
    this.httpClient.get<IProposalTerms[]>(url).subscribe(data => { this.terms$.next(data); });
  }

  selectTerms(terms: IProposalTerms | null | number) {
    setTimeout(() => {
      if (typeof (terms) === 'number') {
        terms = this.terms$.value.find(p => p.Id === terms) ?? null;
      }
      terms && this.refreshSelectedTerms(terms);
      this.selectedTerms$.next(terms);
    }, 1);
  }

  private refreshSelectedTerms(terms: IProposalTerms) {
    if (this.selectedTerms$.value?.Id !== terms.Id) {
      this.getTerms(terms.Id);
    }
  }

  getTerms(id: number): void {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<IProposalTerms>(url).subscribe(data => { this.updateTerms(1, [data]); this.selectedTerms$.next(data) });
  }

  add(terms: IProposalTerms) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<IProposalTerms>(url, terms).pipe(catchError(err => this.handleError(err)), tap((data => this.updateTerms(0, [data]))));
  }

  update(terms: IProposalTerms) {
    const url = `${this.baseUrl}/${terms.Id}`;
    return this.httpClient.put<IProposalTerms>(url, terms).pipe(catchError(err => this.handleError(err)), tap((data => this.updateTerms(1, [data]))));
  }

  delete(terms: IProposalTerms) {
    const url = `${this.baseUrl}/${terms.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete Proposal Terms. ")), tap((data => this.updateTerms(2, [terms]))));
  }

  private handleError(err: any, message: string = "Failed to save Proposal Terms. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }


  updateTerms(crudType: number, terms: IProposalTerms[]) {
    this.terms.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const term of terms) {
          const index = data.findIndex(c => c.Id === term.Id);
          if (index === -1) {
            data.push(term);
          }
          else {
            data[index] = term;
          }
        }
      }
      else if (crudType === 2) {
        for (const term of terms) {
          const index = data.findIndex(c => c.Id === term.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.terms$.next([...data]);
    });
  }
}

export interface IProposalTerms {
  Id: number;
  Name: string;
  Terms: string;
}
