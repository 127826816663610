<div style="display: flex; flex-direction: row; align-items: center;">
    <div style="font-weight: 500; display: flex; align-items: center; width: 110px;">
        Contracts (<span class="contracts-count"> {{ contractsCount }}</span>)
    </div>
    <button mat-mini-fab color="primary" class="mat-icon-btn-sm selectColumnsButton" (click)="selectColumns.open()">
        <mat-icon>menu</mat-icon>
        <mat-form-field #mySelect class="mat-select-sm" style="display: none;" appearance="fill">
            <mat-label>Columns</mat-label>
            <mat-select #selectColumns [formControl]="selectedColumns" multiple
                (ngModelChange)="selectedColumnsUpdated($event)" panelClass="mat-option-sm">
                <mat-option *ngFor="let column of selectableColumns" [value]="column.Field">
                    {{column.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </button>

    <button (click)="clearFilters()" mat-raised-button color="primary" class="mat-raised-button-sm" style="margin-left: 15px !important;">Clear
        Filters</button>

    <div style="margin-left: 10px;  display: flex; width: 150px; ">
        <mat-label style="margin-top: 4px;">Status:</mat-label>
        <div style="border: 1px darkgray solid; border-radius: 4px; width: 100%; margin-left: 3px;">
            <mat-form-field class="mat-select-sm" appearance="legacy">
                <mat-select [(ngModel)]="statusFilter " (ngModelChange)="statusFilterUpdated($event) ">
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Open">Open</mat-option>
                    <mat-option value="Closed">Closed</mat-option>
                    <mat-option value="Incomplete">Incomplete</mat-option>
                    <mat-option value="Completed">Completed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <button (click)="gridApi?.sizeColumnsToFit()" mat-raised-button color="primary" class="mat-raised-button-sm" style=" margin-left: 15px !important;">Size Columns to Fit
    </button>
    <app-saved-layouts [viewType]="viewType" style="width: 300px;"></app-saved-layouts>
</div>
<ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 35px); margin-top: 4px;" (dragover)="dragover($event)" (dragleave)="dragEnd($event)" (drop)="drop($event)" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [rowClassRules]="rowClassRules"
    [rowData]="contracts" [columnDefs]="cols" [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()" [pinnedBottomRowData]="pinnedBottomRowData" (cellValueChanged)="onCellValueChanged($event)">
</ag-grid-angular>