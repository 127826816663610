import { BreakpointObserver } from '@angular/cdk/layout';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnState, GridApi, GridOptions, RowClickedEvent, RowDoubleClickedEvent, RowNode } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ActionButtonComponent } from 'src/app/ag-grid-components/action-button/action-button.component';
import { TextareaEditorComponent } from 'src/app/ag-grid-components/textarea-editor/textarea-editor.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { Grids, IProposalGridState, ProposalLayoutService } from '../proposal-layout.service';
import { CustomersService, ICustomer } from '../services/customers.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { IProposal, ProposalsService } from '../services/proposals.service';

@Component({
  selector: 'app-proposal-customer',
  templateUrl: './proposal-customer.component.html',
  styleUrls: ['./proposal-customer.component.scss']
})
export class ProposalCustomerComponent implements OnInit {

  subscriptions = new Subscription();
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;
  frameworkComponents = { textareaEditor: TextareaEditorComponent, 'btnCellRenderer': ActionButtonComponent };
  gridState: ColumnState[] | null = null;

  proposals: IProposal[] = [];
  selectedCustomer: ICustomer | null = null;
  selectedProject: IProject | null = null;
  selectedProposal: IProposal | null = null;

  pinnedTopRowData: object[] = [{ Value: "New", fullWidth: true }];
  fullWidthCellRenderer = (params: any) => {
    return `<div class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height ag-cell-value">New</div>`
  };


  constructor(private proposalsService: ProposalsService, private projectsService: ProjectsService, private customerService: CustomersService, private userSettings: UserSettingsService,
    private layoutService: ProposalLayoutService, private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private router: Router, private breakpointObserver: BreakpointObserver) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => this.initGrid(data)));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.proposalsService.proposals.subscribe(data => this.proposals = data));
    this.subscriptions.add(this.layoutService.proposalViewLayout.subscribe(data => { this.gridState = data.Grids.ProposalCustomer?.ColumnState ?? null; this.setSavedGridSate(); }));

    this.subscriptions.add(this.proposalsService.selectedProposal.subscribe(data => {
      const oldSelectedProposalId: number | null = this.selectedProposal?.Id ?? null;
      this.selectedProposal = data;
      if (data !== null) {
        if (oldSelectedProposalId !== data.Id) {
          setTimeout(() => {
            this.gridApi?.forEachNodeAfterFilter(node => {
              if (node.data.Id === data?.Id) {
                node.setSelected(true);
                this.gridApi?.ensureIndexVisible(node.rowIndex)
              }
            });
            setTimeout(() => { this.gridApi?.redrawRows(); }, 2);
          }, 2);
        }
      }
      else {
        this.gridApi?.deselectAll();
        setTimeout(() => { this.gridApi?.redrawRows(); }, 2);
      }
    }));

    this.subscriptions.add(this.customerService.selectedCustomer.subscribe(data => {
      this.selectedCustomer = data;
      setTimeout(() => { this.gridApi?.onFilterChanged(); }, 0);
    }));

    this.subscriptions.add(this.projectsService.selectedProject.subscribe(data => {
      this.selectedProject = data;
      setTimeout(() => { this.gridApi?.onFilterChanged(); }, 1);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setSavedGridSate();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedProposal = selectedRows[0] as IProposal;
      this.selectedProposal = selectedProposal;
      this.proposalsService.selectProposal(selectedProposal);
      if (this.selectedProject?.Id !== selectedProposal.ProjectId) {
        this.projectsService.selectProject(selectedProposal.ProjectId);
      }
      setTimeout(() => { this.gridApi?.redrawRows(); }, 2);
    }
    else {
      if (this.selectedProposal !== null) {
        this.proposalsService.selectProposal(null);
      }
    }
  }

  doesExternalFilterPass(node: RowNode): boolean {
    if (this.selectedCustomer === null && this.selectedProject === null) return false;
    const data = node.data as IProposal;

    if (this.selectedProject) {
      return this.selectedProject.Id === data.ProjectId;
    }

    if (this.selectedCustomer) {
      return this.selectedCustomer.Id === data.CustomerId;
    }
    return false;
  }


  saveGridState() {
    const gridState: IProposalGridState = {
      ColumnState: this.gridColumnApi.getColumnState(),
      ColumnFilterState: {}
    }
    this.layoutService.gridUpdate(Grids.ProposalCustomer, gridState);
  }

  setSavedGridSate() {
    if (this.gridApi && this.gridState) {
      this.gridOptions?.columnApi?.applyColumnState({ state: this.gridState, applyOrder: true });
    }
    else if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }



  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.isExternalFilterPresent = () => true;
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: IProject) => data.Id.toString();
    this.gridOptions.isFullWidthCell = (node: RowNode) => { return node.data.fullWidth };
    this.gridOptions.fullWidthCellRenderer = this.fullWidthCellRenderer as any;
    this.gridOptions.pinnedTopRowData = this.pinnedTopRowData;
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    this.gridOptions.onDragStopped = () => this.saveGridState();
    this.gridOptions.onSortChanged = () => this.saveGridState();
    this.gridOptions.onColumnPinned = () => this.saveGridState();
    this.gridOptions.onColumnMoved = () => this.saveGridState();
    this.gridOptions.onColumnResized = () => this.saveGridState();
    this.gridOptions.onColumnValueChanged = () => this.saveGridState();


    this.gridOptions.getRowClass = (params) => {
      return (params.node.rowPinned && this.selectedProposal == null) ? 'ag-row-selected' : "";
    };
    // this.gridOptions.getRowHeight = (params: any) => { };
    this.gridOptions.onRowClicked = (event: RowClickedEvent) => {
      if (event.node.rowPinned) {
        this.selectedProposal = null;
        this.proposalsService.selectProposal(null);
        event.data.Id = 'New';
      }
      if (this.breakpointObserver.isMatched('(max-width: 1280px)')) {
        //If on small screen navigate to edit page.
        const data = event.data as IProject;
        this.router.navigate([`/proposals/${data.Id}`]);
      }
    };
    this.gridOptions.onRowDoubleClicked = (event: RowDoubleClickedEvent) => this.router.navigate([`/proposals/${event.data.Id}`]);

    this.defaultColDef = {
      filter: null,
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: false,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'ProposalNumber', headerName: 'Proposal Number', width: 70 },
      {
        field: 'BidDate', headerName: 'Bid Date', width: 80, sort: 'desc',
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
      },
      { field: 'Amount', headerName: 'Amount', width: 100, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }
}
