import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RowDataTransaction } from 'ag-grid-community';
import { EMPTY, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { SignalrService } from '../services/signalr.service';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  baseUrl = 'contracts';
  isLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) { }

  private contracts$: ReplaySubject<Contract[]> = new ReplaySubject(1);
  public get contracts(): Observable<Contract[]> { return this.contracts$.asObservable() }

  private updatedContracts$: Subject<RowDataTransaction> = new Subject();
  public get updatedContracts(): Observable<RowDataTransaction> { return this.updatedContracts$.asObservable() }


  private selectedContract$: ReplaySubject<Contract | null> = new ReplaySubject(1);
  public get selectedContract(): Observable<Contract | null> { return this.selectedContract$.asObservable() }

  public filteredContracts$: ReplaySubject<Contract[]> = new ReplaySubject(1);

  loadContracts(): Observable<Contract[]> {
    if (!this.isLoaded) {
      this.isLoaded = true;
      const appsURL = `${this.baseUrl}`;
      this.httpClient.get<Contract[]>(appsURL).pipe(map(response => {
        return response.map(value => Object.assign(new Contract(), value));
      })).subscribe(data => { this.contracts$.next(data); });
    }
    return this.contracts$.asObservable();
  }

  selectContract(contract: Contract | null) {
    if (contract) {
      this.getContract(contract);
    }
    else {
      this.selectedContract$.next(null);
    }
  }

  getContract(contract: Contract) {
    const appsURL = `${this.baseUrl}/${contract.DocId}`;
    this.httpClient.get<IContract>(appsURL).subscribe(data => {
      const contract = Object.assign(new Contract(), data)
      this.selectedContract$.next(contract);
      this.updatedContracts$.next({ update: [contract] });
    });
  }

  updateSpruceNotes(contract: IContract) {
    const url = `${this.baseUrl}/SpruceNotes/${contract.DocId}`;
    const spruceNoteDto = { DocId: contract.DocId, Notes: contract.SpruceNotes };
    return this.httpClient.put(url, spruceNoteDto).pipe(catchError(err => this.handleError(err, "Failed to update Spruce Notes.")),);
  }

  updateContract(contract: IContract) {
    const url = `${this.baseUrl}/${contract.DocId}`;
    return this.httpClient.put(url, contract).pipe(catchError(err => this.handleError(err)));
  }


  public updateContractsFromSignalR(contracts: IContract[]) {
    this.contracts$.asObservable().pipe(take(1)).subscribe(data => {
      const addContracts: Contract[] = [];
      const updateContracts: Contract[] = [];

      const updatedContracts = contracts.map(c => Object.assign(new Contract(), c));
      for (const contract of updatedContracts) {
        const index = data.findIndex(m => m.DocId == contract.DocId);
        if (index === -1) {
          data.push(contract);
          addContracts.push(contract);
        }
        else {
          data[index] = contract;
          updateContracts.push(contract);
        }
      }
      this.updatedContracts$.next({ add: addContracts, update: updateContracts });
      this.contracts$.next(data);

      this.selectedContract.pipe(take(1)).subscribe(data => {
        const updated = updateContracts.find(c => c.DocId === data?.DocId);
        if (updated) {
          this.selectedContract$.next(updated);
        }
      });
    });
  }

  private handleError(err: any, message: string = "Failed to update contract. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

}

export class Contract implements IContract {

  constructor() { }

  JobName: string = '';
  Reference: string = '';
  ContractID: string = '';
  Total: number = 0;
  Paid: number = 0;
  Remaining: number = 0;
  Rep: string = '';
  EstLabor: number = 0;
  ActLabor: number = 0;
  Hours: number = 0;
  Est3rdParty: number = 0;
  Act3rdParty: number = 0;
  EstProfit: number = 0;
  ActProfit: number = 0;
  EstMargin: number = 0;
  ActMargin: number = 0;
  Month: string = '';
  Year: string = '';
  IsClosed: boolean = false;
  DocId: number = 0;
  BilledAmount: number = 0;
  Orders: number = 0;
  JobNum: number | null = null;
  Size: string = '';
  Notes: string = '';
  Status: string = '';
  PermitStatus: string = '';
  WorkDays: number = 0;
  ScheduleNotes: string = '';
  Crew: string = '';
  City: string = '';
  AttachmentCount: number = 0;
  SpruceNotes: string = '';

  get EstLaborMargin() { return this.EstLabor === 0 ? 0 : this.EstProfit === 0 ? 0 : (this.EstProfit / this.EstLabor) * 100 }
  get ActLaborMargin() { return this.ActLabor === 0 ? 0 : this.ActProfit === 0 ? 0 : (this.ActProfit / this.ActLabor) * 100 }
  get IsDue() { return (this.Total - this.BilledAmount) < this.Remaining && this.Remaining > 0; }
  get IsInvoicedInFull() { return this.Total === this.BilledAmount && this.Remaining > 0 }
  get SpruceHours() { return this.ActLabor == 0 ? 0 : this.ActLabor / 25; }
  get MonthInt() {

    switch (this.Month) {
      case "Jan": return 1;
      case "Feb": return 2;
      case "Mar": return 3;
      case "Apr": return 4;
      case "May": return 5;
      case "Jun": return 6;
      case "Jul": return 7;
      case "Aug": return 8;
      case "Sep": return 9;
      case "Oct": return 10;
      case "Nov": return 11;
      case "Dec": return 12;
      default: return 0;
    }
  }

}
export interface IContract {
  JobName: string;
  Reference: string;
  ContractID: string;
  Total: number;
  Paid: number;
  Remaining: number;
  Rep: string;
  EstLabor: number;
  ActLabor: number;
  Hours: number;
  Est3rdParty: number;
  Act3rdParty: number;
  EstProfit: number;
  ActProfit: number;
  EstMargin: number;
  ActMargin: number;
  Month: string;
  Year: string;
  IsClosed: boolean;
  DocId: number;
  BilledAmount: number;
  Orders: number;
  JobNum: number | null;
  Size: string;
  Notes: string;
  Status: string;
  PermitStatus: string;
  WorkDays: number;
  ScheduleNotes: string;
  Crew: string;
  City: string;
  AttachmentCount: number;
  SpruceNotes: string;
}




