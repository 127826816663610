import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ColumnState } from 'ag-grid-community';
import { IOutputAreaSizes } from 'angular-split';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ILayoutService } from '../services/LayoutService';

@Injectable({
  providedIn: 'root'
})
export class ProposalLayoutService implements ILayoutService {


  private webStorageKey = "Proposal.LayoutState";

  private proposalViewLayout$: BehaviorSubject<ProposalState> = new BehaviorSubject<ProposalState>(new ProposalState());
  public get proposalViewLayout() {
    return this.proposalViewLayout$.asObservable();
  }

  private currentState: ProposalState = new ProposalState();
  private stateChanged$: Subject<ProposalState> = new Subject();
  get stateChanged() { return this.stateChanged$.asObservable(); }
  private isSettingPreset = false;


  constructor(private storage: StorageMap) {
    this.isSettingPreset = true;
  }

  loadAndSetStateFromBrowser(): void {
    this.isSettingPreset = true;
    this.storage.get(this.webStorageKey).subscribe(data => {
      if (data) {
        const proposalState = Object.assign(new ProposalState(), data);
        this.currentState = proposalState;
        this.proposalViewLayout$.next(proposalState);
      }
      this.isSettingPreset = false;
    });
  }

  gridUpdate(grid: Grids, gridState: IProposalGridState) {
    this.currentState.Grids[grid] = gridState;
    this.saveStateToClient();
  }

  splitterUpdated(splitter: Splitters, size: IOutputAreaSizes) {
    this.currentState.Splitters[splitter] = size;
    this.saveStateToClient();
  }

  selectedGridUpdated(grid: string) {
    this.currentState.SelectedGrid = grid;
    this.saveStateToClient();
  }

  expansionPanelStateChanged(isOpen: boolean) {
    this.currentState.EditExpansionPanelExpanded = isOpen;
    this.saveStateToClient();
  }

  private saveStateToClient() {
    if (this.isSettingPreset) { return; }
    this.storage.set(this.webStorageKey, this.currentState).subscribe();
    this.layoutChanged();
  }

  setSavedView(savedView: any) {
    this.isSettingPreset = true;
    const proposalState = Object.assign(new ProposalState(), JSON.parse(JSON.stringify(savedView)));
    this.currentState = proposalState;
    this.proposalViewLayout$.next(proposalState);
    this.storage.set(this.webStorageKey, this.currentState).subscribe();
    setTimeout(() => {
      this.isSettingPreset = false;
    }, 500);
  }

  private layoutChanged() {
    if (this.isSettingPreset) { return }
    if (this.currentState) {
      this.stateChanged$.next(this.currentState);
    }
  }
}

export const enum Splitters {
  ProposalsListEditProposal = "ProposalListEditProposal",
  ProposalsList = "ProposalsList",
  CustomerEditProjectEdit = "CustomerEditProjectEdit",
  ProjectCustomerProposalCustomer = "ProjectCustomerProposalCustomer",
};

export const enum Grids {
  ProjectCustomer = "ProjectCustomer",
  ProposalCustomer = "ProposalCustomer",
  ProjectList = "ProjectList",
  ProposalList = "ProposalList",
  CustomerList = "CustomerList",
}

export class ProposalState implements IProposalState {
  Splitters: Record<Splitters, IOutputAreaSizes | null> = {
    ProposalListEditProposal: null,
    ProposalsList: null,
    CustomerEditProjectEdit: null,
    ProjectCustomerProposalCustomer: null
  };

  SelectedGrid: string | null = null;
  EditExpansionPanelExpanded: boolean | null = null;
  Grids: Record<Grids, IProposalGridState | null> = {
    ProjectCustomer: null,
    ProposalCustomer: null,
    ProjectList: null,
    ProposalList: null,
    CustomerList: null
  };
}

interface IProposalState {
  Splitters: Record<Splitters, IOutputAreaSizes | null>;
  SelectedGrid: string | null;
  EditExpansionPanelExpanded: boolean | null;
  Grids: Record<Grids, IProposalGridState | null>;
}

export interface IProposalGridState {
  ColumnState: ColumnState[];
  ColumnFilterState: { [key: string]: any }
}
