<div style="margin-top: 2px;">
    <button mat-raised-button color="primary" class="mat-raised-button-sm" (click)="clearFilters()">Clear Filters
    </button>
    <button mat-raised-button color="primary" class="mat-raised-button-sm" (click)="addScheduleItem()" [disabled]="selectedContract === null">
        Add Item</button>
    <button mat-raised-button color="primary" class="mat-raised-button-sm" [disabled]="selectedScheduleItem === null" (click)="deleteScheduledItem()">Delete Item</button>
    <a mat-raised-button color="primary" class="mat-raised-button-sm" routerLink="/scheduler">Scheduler</a>
</div>

<ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 30px); margin-top: 2px;" (dragover)="dragover($event)" (dragleave)="dragEnd($event)" (drop)="drop($event)" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [columnDefs]="cols"
    [defaultColDef]="defaultColDef" [rowData]="filteredScheduleItems" rowSelection="single" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()" [isFullWidthCell]="isFullWidthCell" [fullWidthCellRenderer]="fullWidthCellRenderer" [frameworkComponents]="frameworkComponents"
    (rowClicked)="rowClicked($event)" [getRowHeight]="getRowHeight">>
</ag-grid-angular>