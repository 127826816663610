import { Component } from '@angular/core';
import { FilterChangedEvent, GridOptions, IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-month-floating-filter',
  templateUrl: './month-floating-filter.component.html',
  styleUrls: ['./month-floating-filter.component.scss']
})
export class MonthFloatingFilterComponent implements IFloatingFilter {

  gridOptions: GridOptions = {};
  params: IFloatingFilterParams | undefined;
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  month: string = 'All';

  constructor() {
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.month = 'All';
    }
    else {
      this.month = parentModel;
    }
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged() {
    this.params?.parentFilterInstance((instance) => {
      instance.setModel(this.month);
    });
  }
}
