<div style="width: 100%; height: 100%" (dragover)="dragover($event)" (drop)="drop($event)">
    <as-split #expensesSplitter direction="vertical">
        <as-split-area [size]="25">
            <div style="display: grid; margin: 2px; width: auto; grid-template-columns: 1fr 50px;">
                <div style="grid-column: 1;">
                    <div style="display: inline-block; font-size: larger;">Customer Attachments</div>
                    <div style="margin-left: 5px; display: inline-block;">
                        <input #addFile hidden="true" type="file" multiple onclick="this.value=null" type="file"
                            (change)="addAttachment($event)" />
                        <button mat-icon-button color="accent" [disabled]="this.selectedCustomer == null"
                            (click)="addFile.click()">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                    </div>

                    <button mat-icon-button color="warn" [disabled]="this.selectedAttachment == null" class=""
                        style="margin-left: 10px;" (click)="deleteAttachment()">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <button mat-button color="accent" [disabled]="this.selectedAttachment == null" class=""
                        style="margin-left: 10px;" (click)="openAttachment()">
                        Open Attachment
                    </button>

                    <a mat-button [href]="url$ | async" download="true"
                        [disabled]="this.selectedAttachment == null">Download</a>
                </div>

                <div style="grid-column: 2;">
                    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
                        <mat-icon class="close-icon" color="warn">close</mat-icon>
                    </button>
                </div>
            </div>
            <div style="height: 7px;">
                <mat-progress-bar *ngIf="isUploading" mode="determinate" color="accent" [value]="progress">
                </mat-progress-bar>
            </div>
            <ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 55px); margin-top: 4px;"
                [gridOptions]="gridOptions" [ngClass]="agGridTheme" [rowData]="attachments" [columnDefs]="cols"
                [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)"
                (selectionChanged)="onSelectionChanged()">
            </ag-grid-angular>
        </as-split-area>
        <as-split-area [size]="75">
            <app-secured-attachment [src]="(url$ | async) ?? ''">
            </app-secured-attachment>
        </as-split-area>
    </as-split>
</div>