import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/api-authorization/authentication.service';
import { MatDialog, } from '@angular/material/dialog';
import { UserSettingsComponent } from 'src/app/user-settings/user-settings/user-settings.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
  public isAuthenticated: Observable<boolean> = new Observable();
  public userName: Observable<string | null> = new Observable();

  constructor(private authorizeService: AuthenticationService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.isAuthenticated = this.authorizeService.isAuthenticated;
    this.userName = this.authorizeService.user.pipe(map(u => u && u.UserName));
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  openSettings() {
    const dialogRef = this.dialog.open(UserSettingsComponent, {
      //  width: '550px',
    });
  }

}
