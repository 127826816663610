<form class="edit-form" [formGroup]="emailSettingsForm">
    <div style="width: 100%;">
        <div style=" grid-row: 1; grid-column: 1 / -1; width: 100%;">

        </div>
        <div style=" width: 100%; margin-top: 10px;">
            <div class="left-label-inputs" style="padding: 3px; margin: 3px; max-width: 700px;">
                <label>Server</label>
                <div>
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Server">
                        <mat-error>Server is required.</mat-error>
                    </mat-form-field>
                </div>
                <label>Port</label>
                <div>
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Port">
                        <mat-error>Port is required.</mat-error>
                    </mat-form-field>
                </div>
                <label>UserName</label>
                <div>
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="UserName">
                        <mat-error>UserName is required.</mat-error>
                    </mat-form-field>
                </div>
                <label>Password</label>
                <mat-form-field appearance="fill" color="accent" style="max-width: 201px;">
                    <input matInput formControlName="Password" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error>Password is required.</mat-error>
                </mat-form-field>

                <label></label>
                <div>
                    <button mat-raised-button type="button" (click)="testSettings()" color="primary"
                        class="mat-raised-button-sm" style="max-width: 100px; margin-left: 10px;"> Test
                        Settings</button>

                    <button type="button" matTooltip="Save Changes" (click)="save()" mat-raised-button color="primary"
                        style="width: 100px;">
                        Save
                    </button>
                </div>
            </div>
            <p style="margin: 10px;">
                {{ message }}
            </p>
        </div>
    </div>
</form>