import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/api-authorization/authentication.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { CustomersService, ICustomer } from '../services/customers.service';

@Component({
  selector: 'app-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss'],
})
export class ProjectMapComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMap) googleMaps: GoogleMap | undefined;

  infoWindow!: google.maps.InfoWindow;
  options!: google.maps.MapOptions;
  markers: google.maps.Marker[] = [];


  apiLoaded: boolean = false;
  subscription: Subscription = new Subscription();
  customers: ICustomer[] = [];
  projects: IProject[] = [];

  constructor(private projectService: ProjectsService, private customerService: CustomersService, private authenticationService: AuthenticationService, httpClient: HttpClient) {
    this.subscription.add(this.authenticationService.user.pipe(switchMap((user) => httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${user?.GoogleMapsKey}`, 'callback'))).subscribe(() => {
      this.infoWindow = new google.maps.InfoWindow({ content: 'Hello from infoWindow', });
      this.options = { center: { lat: 40.95574190084939, lng: -82.36497672016169 }, zoom: 10 };
      this.apiLoaded = true;
      this.addMarkers();
    }, (err) => console.error(err)));
  }

  ngAfterViewInit(): void {
    this.subscription.add(this.projectService.filteredProjects$.subscribe(data => { this.projects = data; this.addMarkers(); }));
    this.subscription.add(this.customerService.customers.subscribe(data => { this.customers = data; this.addMarkers(); }));
  }


  ngOnInit(): void {

  }

  addMarkers(): void {
    if ((!this.projects.length) && (!this.customers.length)) {
      return;
    }
    if (this.googleMaps?.googleMap == undefined) {
      return;
    }
    this.clearMarkers();
    
    const bounds = new google.maps.LatLngBounds();
    for (const project of this.projects) {
      const lat = project.Latitude;
      const lng = project.Longitude;

      if (lat == null || lng == null) {
        continue;
      }

      const customer = this.customers.find(x => x.Id == project.CustomerId);
      const marker = new google.maps.Marker();
      this.markers.push(marker);
      const position = new google.maps.LatLng(lat, lng);
      marker.setPosition(position);
      bounds.extend(position);
      marker.setTitle(`${customer?.Name}, ${project.Description}`);

      marker.setMap(this.googleMaps.googleMap);
      marker.addListener('click', (event: any) => {
        const baseAddress = 'https://www.google.com/maps/dir/?api=1&destination=';
        const address = `${baseAddress}${lat},${lng}`;

        this.infoWindow.setContent(`<h4 id="${project?.CustomerId}:${project.Id}" style="margin-bottom: 2px; color: black">
                                    ${customer?.Name}  ${project.Description}</h4>
                                    <div style="margin-bottom: 3px; color: black" >${project.Address} ${project.City} </div>
                                    <div class="m-2" style="margin-bottom: 3px; color: black"> Description: ${project.Description}</div>
                                    <div class="m-2" style="margin-bottom: 3px; color: black"> Project Type: ${project.ProjectType}</div>
                                    <a target="_blank" href="${address}">Get Directions</a>`);

        this.infoWindow.open(this.googleMaps!.googleMap, marker);
      });
    }
    this.googleMaps.fitBounds(bounds);
  }

  clearMarkers() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers.length = 0;
  }

}
