import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractLaborService {

  baseUrl = 'ContractLabor';

  constructor(private httpClient: HttpClient) { }

  private contractLabor$: ReplaySubject<IContractLabor[]> = new ReplaySubject(1);
  public get contractLabor(): Observable<IContractLabor[]> { return this.contractLabor$.asObservable() }

  loadContractLabor(contractId: string): Observable<IContractLabor[]> {
    const url = `${this.baseUrl}/${contractId}`;
    this.httpClient.get<IContractLabor[]>(url).subscribe(data => { this.contractLabor$.next(data); });
    return this.contractLabor$.asObservable();
  }
}


export interface IContractLabor {
  Name: string;
  DatePaid: Date;
  TotalPay: number;
  RegHours: number;
  OTHours: number;
  TotalHours: number;
}
