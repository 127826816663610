<h2>Change Password</h2>
<div>Change the password for: </div>
<div>{{ selectedRep?.Name }}, {{ selectedRep?.Email}}</div>
<form [formGroup]="changePasswordForm" class="edit-form" (ngSubmit)="submit()">
    <div style="margin-top: 15px;">
        <div>
            <mat-form-field appearance="fill" color="accent">
                <mat-label>Password</mat-label>
                <input matInput formControlName="Password" [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error>Password is required.</mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill" color="accent">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="ConfirmPassword" [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error>Confirm Password is required.</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="changePasswordForm.hasError('notSame')" class="mat-error">
        Your new passwords are not match.
    </div>
    <button mat-button color="accent" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" *ngIf="selectedRep !== null">Submit</button>
</form>