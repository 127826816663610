import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap, take } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class TradesService {

   private trades$: ReplaySubject<ITrade[]> = new ReplaySubject(1);
  public get trades(): Observable<ITrade[]> { if (!this.isTradesLoaded) { this.loadTrades(); } return this.trades$.asObservable() }

  baseUrl = 'trades';
  isTradesLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadTrades() {
    const url = `${this.baseUrl}`;
    this.httpClient.get<ITrade[]>(url).subscribe(data => { this.trades$.next(data); this.isTradesLoaded = true; });
  }

  save(trade: ITrade) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<ITrade>(url, trade).pipe(catchError(err => this.handleError(err)), tap((data => this.update(0, [data]))));
  }

  delete(trade: ITrade) {
    const url = `${this.baseUrl}/${trade.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete subcontractor.")), tap(data => this.update(2, [trade])));
  }

  private handleError(err: any, message: string = "Failed to save subcontractor. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }


  update(crudType: number, trades: ITrade[]) {
    this.trades.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const trade of trades) {
          const index = data.findIndex(c => c.Id === trade.Id);
          if (index === -1) {
            data.push(trade);
          }
          else {
            data[index] = trade;
          }
        }
      }
      else if (crudType === 2) {
        for (const trade of trades) {
          const index = data.findIndex(c => c.Id === trade.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.trades$.next([...data]);
    });
  }
}



export interface ITrade {
  Id: number;
  TradeName: string
}
