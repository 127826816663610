<h3 mat-dialog-title>Save Layout</h3>
<div mat-dialog-content style="padding: 5px;">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <p>
            <mat-form-field>
                <mat-label>Layout Name</mat-label>
                <input type="text" matInput [matAutocomplete]="auto" formControlName="newLayoutName">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.controls['newLayoutName'].hasError('required')">
                    A layout name is required.
                </mat-error>
            </mat-form-field>
        </p>
        <div class="button">
            <button mat-raised-button (click)="cancel()" type="button">Cancel</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </form>
</div>