import { Component, OnInit } from '@angular/core';

import { FilterChangedEvent, GridOptions, IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-year-floating-filter',
  templateUrl: './year-floating-filter.component.html',
  styleUrls: ['./year-floating-filter.component.scss']
})
export class YearFloatingFilterComponent implements IFloatingFilter {

  params: IFloatingFilterParams | undefined;
  gridOptions: GridOptions = {};


  year: string = 'All';
  options: string[] = []

  constructor() {
    this.options.push("Last Year");
    this.options.push("This Year");

    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const count = (endYear - startYear) + 1;

    for (let i = count; i > 0; i--) {
      this.options.push(`${startYear + i}`)
    }
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.year = 'All';
    }
    else {
      this.year = parentModel;
    }
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged() {
    this.params?.parentFilterInstance((instance) => {
      instance.setModel(this.year);
    });
  }
}
