import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { CustomersService, ICustomer } from '../services/customers.service';
import { StringExt } from 'src/app/utils/string';
import { CustomerMatchService } from '../customer-match.service';
import { UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { IOption, ProposalAutocompleteOptionsService } from '../services/proposal-autocomplete-options.service';
import { CustomerProjectEditService } from '../services/customer-project-edit.service';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomerAttachmentsComponent } from '../customer-attachments/customer-attachments.component';
import { CustomerAttachmentsService } from '../services/customer-attachments.service';
import { User } from 'src/app/api-authorization/authentication.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {

  subscriptions = new Subscription();
  selectedCustomer: ICustomer | null = null;
  customers: ICustomer[] = [];
  reps: IRep[] = [];
  attachmentCount: number = 0;

  cityOptions: IOption[] = [];
  filteredCityOptions: IOption[] = [];
  filteredReferenceOptions: IOption[] = [];
  referenceOptions: IOption[] = [];
  phoneNotesOptions: IOption[] = [];
  filteredPhoneNotesOptions: IOption[] = [];

  nameMessage: string | null = null;
  phone1Message: string | null = null;
  phone2Message: string | null = null;
  phone3Message: string | null = null;

  user: User | null = null;
  isInitializing = true;

  editCustomerForm = this.fb.group({
    Id: [0],
    Name: ['', Validators.required],
    Address: [''],
    City: [''],
    Email: [''],
    Reference: [''],
    Phone1: [''],
    Phone2: [''],
    Phone3: [''],
    Phone1Note: [''],
    Phone2Note: [''],
    Phone3Note: [''],
    Fax: [''],
    RepId: [0],
    Note: [''],
  });

  constructor(private customerService: CustomersService, private customerMatchService: CustomerMatchService, private userSettingsService: UserSettingsService, private repsService: RepsService,
    private fb: FormBuilder, private customerProjectEditService: CustomerProjectEditService, private autoCompleteOptionsService: ProposalAutocompleteOptionsService, private dialog: MatDialog,
    private attachmentsService: CustomerAttachmentsService) {

  }

  ngOnInit(): void {
    this.autoCompleteOptionsService.init();
    this.subscriptions.add(this.customerService.customers.subscribe(data => {
      this.customers = data;
      if (this.selectedCustomer !== null) {
        const id = this.selectedCustomer.Id;
        this.selectedCustomer = this.customers.find(c => c.Id === id) ?? null;
        if (this.selectedCustomer) {
          this.editCustomerForm.patchValue(this.selectedCustomer);
        }
      }
    }));

    this.subscriptions.add(this.customerService.selectedCustomer.subscribe(data => {
      if (this.selectedCustomer === null && data !== null) {
        this.customerMatchService.filterCustomerIfMatch(null);
      }

      this.clearMessages();
      this.selectedCustomer = data;
      if (data) {
        this.editCustomerForm.patchValue(data);
        this.editCustomerForm.markAsUntouched();
      }
      else {
        if ((this.editCustomerForm.value.Id ?? 0) > 0) {
          this.newCustomer();
        }
      }
    }));
    this.subscriptions.add(this.attachmentsService.attachments.subscribe(data => this.attachmentCount = data.length));
    this.subscriptions.add(this.autoCompleteOptionsService.city.subscribe(data => { this.cityOptions = data; this.filteredCityOptions = [...data] }));
    this.subscriptions.add(this.autoCompleteOptionsService.references.subscribe(data => { this.referenceOptions = data; this.filteredReferenceOptions = [...data] }));
    this.subscriptions.add(this.autoCompleteOptionsService.phoneNotes.subscribe(data => { this.phoneNotesOptions = data; this.filteredPhoneNotesOptions = [...data] }));
    // this.subscriptions.add(this.autoCompleteOptionsService.repOptions.subscribe(data => { this.repOptions = data; this.filteredRepOptions = [...data] }));
    this.subscriptions.add(this.repsService.reps.subscribe(data => this.reps = data));

    this.subscriptions.add(this.editCustomerForm.valueChanges.pipe(filter(d => this.selectedCustomer === null)).subscribe(data => {
      this.customerMatchService.filterCustomerIfMatch(data);
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Name'].valueChanges.pipe(filter(d => (this.selectedCustomer === null))).subscribe(data => {
      const matchingCustomers = this.customerMatchService.checkIfCustomerNameExist(data).filter(c => c.Id !== this.selectedCustomer?.Id);
      if (matchingCustomers.length > 0) {
        this.nameMessage = "Customer already in the database!"
      }
      else {
        this.nameMessage = null;
      }
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Phone1'].valueChanges.pipe(filter(d => (this.selectedCustomer === null))).subscribe(data => {
      const matchingCustomers = this.customerMatchService.checkIfPhoneNumberExists(data).filter(c => c.Id !== this.selectedCustomer?.Id);
      if (matchingCustomers.length > 0) {
        const mc = matchingCustomers.map(m => m.Name).join();
        this.phone1Message = `This number is listed under customer: ${mc}!`;
      }
      else {
        this.phone1Message = null;
      }
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Phone2'].valueChanges.pipe(filter(d => (this.selectedCustomer === null))).subscribe(data => {
      const matchingCustomers = this.customerMatchService.checkIfPhoneNumberExists(data).filter(c => c.Id !== this.selectedCustomer?.Id);
      if (matchingCustomers.length > 0) {
        const mc = matchingCustomers.map(m => m.Name).join();
        this.phone2Message = `This number is listed under customer: ${mc}!`;
      }
      else {
        this.phone2Message = null;
      }
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Phone3'].valueChanges.pipe(filter(d => (this.selectedCustomer === null))).subscribe(data => {
      const matchingCustomers = this.customerMatchService.checkIfPhoneNumberExists(data).filter(c => c.Id !== this.selectedCustomer?.Id);
      if (matchingCustomers.length > 0) {
        const mc = matchingCustomers.map(m => m.Name).join();
        this.phone3Message = `This number is listed under customer: ${mc}!`;
      }
      else {
        this.phone3Message = null;
      }
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Reference'].valueChanges.pipe(startWith(''),).subscribe(data => {
      const filter = data.toLowerCase();
      if (StringExt.isNullOrWhiteSpace(filter)) { this.filteredReferenceOptions = this.referenceOptions; return; }
      this.filteredReferenceOptions = this.referenceOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Phone1Note'].valueChanges.pipe(startWith(''),).subscribe(data => this.phoneNotesFilter(data)));
    this.subscriptions.add(this.editCustomerForm.controls['Phone2Note'].valueChanges.pipe(startWith(''),).subscribe(data => this.phoneNotesFilter(data)));
    this.subscriptions.add(this.editCustomerForm.controls['Phone3Note'].valueChanges.pipe(startWith(''),).subscribe(data => this.phoneNotesFilter(data)));
    // this.subscriptions.add(this.editCustomerForm.controls['Rep'].valueChanges.pipe(startWith(''),).subscribe(data => {
    //   if (StringExt.isNullOrWhiteSpace(data)) {
    //     if (this.repOptions !== undefined) {
    //       this.filteredRepOptions = this.repOptions.map(m => m); return;
    //     }
    //   }
    //   const filter = data.toLowerCase();
    //   this.filteredRepOptions = this.repOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
    // }));

    this.subscriptions.add(this.editCustomerForm.controls['City'].valueChanges.pipe(startWith(''),).subscribe(data => {
      if (StringExt.isNullOrWhiteSpace(data)) {
        if (this.cityOptions !== undefined) {
          this.filteredCityOptions = [...this.cityOptions];
          return;
        }
      }
      const filter = data.toLowerCase();
      this.filteredCityOptions = this.cityOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
    }));

    this.subscriptions.add(this.editCustomerForm.controls['City'].valueChanges.subscribe(data => {
      if (this.selectedCustomer === null) { this.customerProjectEditService.city$.next(data); }
    }));

    this.subscriptions.add(this.editCustomerForm.controls['Address'].valueChanges.subscribe(data => {
      if (this.selectedCustomer === null) { this.customerProjectEditService.address$.next(data); }
    }));

    this.subscriptions.add(this.editCustomerForm.controls['RepId'].valueChanges.subscribe(data => {
      if (this.selectedCustomer === null) { this.customerProjectEditService.rep$.next(data); }
    }));
    this.subscriptions.add(this.customerProjectEditService.saveEvent$.subscribe(data => this.saveCustomer()));

    this.subscriptions.add(this.userSettingsService.user.subscribe(data => {
      this.user = data;
      if (this.isInitializing) {
        this.isInitializing = false;
        this.newCustomer();
      }
    }));
  }

  clearMessages() {
    this.nameMessage = null;
    this.phone1Message = null;
    this.phone2Message = null;
    this.phone3Message = null;
  }

  phoneNotesFilter(data: string) {
    if (StringExt.isNullOrWhiteSpace(data)) {
      if (this.phoneNotesOptions !== undefined) {
        this.filteredPhoneNotesOptions = this.phoneNotesOptions.map(m => m); return;
      }
    }
    const filter = data.toLowerCase();
    this.filteredPhoneNotesOptions = this.phoneNotesOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
  }

  phoneNoteAutoCompleteOpen() {
    if (this.phoneNotesOptions !== undefined) {
      this.filteredPhoneNotesOptions = this.phoneNotesOptions.map(m => m); return;
    }
  }

  newCustomer(): void {
    const newCustomer: ICustomer = {
      Id: 0, Name: '', Address: '', City: '',
      Email: '', Reference: '',
      Phone1: '', Phone2: '', Phone3: '',
      Phone1Note: '',
      Phone2Note: '',
      Phone3Note: '',
      Fax: '',
      RepId: this.user?.RepId ?? null,
      Note: ''
    };
    this.clearMessages();
    this.selectedCustomer = null;
    this.editCustomerForm.markAsUntouched();
    this.editCustomerForm.patchValue(newCustomer);
    this.customerService.selectCustomer(null);
  }

  saveCustomer() {
    if (this.editCustomerForm.valid) {
      this.clearMessages();
      const customer = this.editCustomerForm.value as ICustomer;
      if (customer.Id > 0) {
        this.customerService.update(customer).subscribe();
      }
      else {
        this.customerService.add(customer).subscribe(data => {
          this.selectedCustomer = data;
          this.editCustomerForm.patchValue(data);
          this.customerProjectEditService.customerSuccessfullySaved$.next(data);
          setTimeout(() => { this.customerService.selectCustomer(data); }, 1);
        });
      }
    }
    else {
      this.editCustomerForm.markAllAsTouched();
    }
  }

  deleteCustomer() {
    if (this.selectedCustomer) {
      this.customerService.delete(this.selectedCustomer).subscribe();
    }
  }

  addressClicked() {
    if (this.selectedCustomer) {
      const baseUrl = `https://www.google.com/maps/search/?api=1&query=`;
      let address = `${this.selectedCustomer.Address},${this.selectedCustomer.City}`;
      address = address.replace(/\r|\n/g, ' ');
      let url = encodeURI(baseUrl + address);
      window.open(url, "_blank")
    }
  }

  openAttachmentDialog() {
    const dialogRef = this.dialog.open(CustomerAttachmentsComponent, {
      data: { customerId: this.selectedCustomer?.Id ?? null },
      maxWidth: '1000px',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: ['full-screen-modal']
    });
  }

  drop(e: any): void {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.uploadFiles(files);
  }

  dragover(e: any): void {
    e.preventDefault();
  }

  uploadFiles(files: any) {
    if (this.selectedCustomer) {
      this.attachmentsService.uploadFiles(this.selectedCustomer.Id, files)?.subscribe();
    }
  }
}

