<div style="display: flex; height: 100%;" ondrop="return false" ondragover="return false">
    <div style="display: flex; flex-direction: column;  width: 40px;">
        <div style="margin: 2px">
            <input #addFile hidden="true" type="file" multiple onclick="this.value=null;" type="file" (change)="addAttachment($event)" />
            <button mat-icon-button color="accent" (click)="addFile.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>

        <button mat-icon-button color="warn" [disabled]="this.selectedAttachment == null" class="" style="margin: 2px;" (click)="deleteAttachment()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>


    <div class="grid-wrapper">
        <ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: 100%; " [ngClass]="agGridTheme" [gridOptions]="gridOptions" [rowData]="attachments" [columnDefs]="cols" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)" rowSelection="single"
            (selectionChanged)="onSelectionChanged()">
        </ag-grid-angular>
    </div>
</div>