<mat-card>
    <mat-card-title>Login</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput placeholder="Email" formControlName="Username">
                    <mat-error *ngIf="form.controls['Username'].hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="form.controls['Username'].hasError('required')">
                        Email is required
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Enter your password</mat-label>
                    <input matInput formControlName="Password" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <!-- <input type="password" matInput placeholder="Password" formControlName="Password"> -->
                    <mat-error *ngIf="form.controls['Password'].hasError('required')">
                        Password is required
                    </mat-error>
                </mat-form-field>
            </p>

            <p *ngIf="error" class="error">
                {{ error }}
            </p>

            <div class="button">
                <button type="submit" mat-raised-button color="primary" [class.spinner]="saving" [disabled]="saving">Login</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>