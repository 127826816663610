import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { StringExt } from 'src/app/utils/string';
import { NumberExt } from 'src/app/utils/number-ext';
import { IProject, ProjectsService } from '../services/projects.service';
import { IOption, ProposalAutocompleteOptionsService } from '../services/proposal-autocomplete-options.service';
import { ILine, IPayment, IProposal, ProposalsService } from '../services/proposals.service';
import * as math from 'mathjs';
import { Snippet, SnippetsService } from 'src/app/snippets/snippets.service';
import { IProposalTemplate, ProposalTemplatesService } from '../services/proposal-templates.service';
import { IProposalTerms, ProposalTermsService } from '../services/proposal-terms.service';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { PrintProposalDialogComponent } from '../print-proposal-dialog/print-proposal-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectAttachmentsService } from '../services/project-attachments.service';
import { User } from 'src/app/api-authorization/authentication.service';
import { ProposalEmailDialogComponent } from '../proposal-email-dialog/proposal-email-dialog.component';

@Component({
  selector: 'app-proposal-edit',
  templateUrl: './proposal-edit.component.html',
  styleUrls: ['./proposal-edit.component.scss']
})
export class ProposalEditComponent implements OnInit, OnDestroy {
  @ViewChildren('textInput') textInputs: QueryList<ElementRef<HTMLInputElement>> | undefined;

  subscriptions = new Subscription();
  proposals: IProposal[] = [];
  selectedProposal: IProposal | null = null;
  selectedProject: IProject | null = null;
  showTemplateSelector = false;
  terms: IProposalTerms[] = [];
  reps: IRep[] = [];

  conditionOptions: IOption[] = [];
  filteredConditionOptions: IOption[] = [];

  proposalSnippets = new Map<string, Snippet>();
  templates: IProposalTemplate[] = [];
  selectedTemplate: IProposalTemplate | null = null;
  //"ps ${width}x${length}x${height}": "Build a ${width} x ${length} x ${height} per the attached specs." };

  user: User | null = null;
  isInitializing = true;
  printAfterSave = false;
  emailAfterSave = false;
  isCloning = false;

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['Amount', 'Condition'];
  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'Payments': this.rows });

  proposalForm = this.fb.group({
    Id: [0],
    ProjectId: [0, Validators.required],
    CustomerId: [0, Validators.required],
    ProposalNumber: [{ value: '', disabled: true }],
    BidDate: [new Date(), Validators.required],
    NumberOfPayments: [0],
    DaysValid: [0],
    Status: [''],
    Amount: [0, Validators.pattern("^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$")],
    RepId: [0],
    Terms: [''],
    Lines: this.fb.array([]),
    Payments: this.fb.array([])
  });

  get Lines() { return this.proposalForm.get('Lines') as FormArray };
  get Payments() { return this.proposalForm.get('Payments') as FormArray };
  get Amount() { return this.proposalForm.get('Amount')?.value };
  get NumberOfPayments() { return this.proposalForm.get('NumberOfPayments')?.value };
  get Terms() { return this.proposalForm.get('Terms')?.value };

  getLine(index: number) {
    return this.Lines.get(`${index}`) as FormGroup ?? new FormControl();
  };

  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key.toLowerCase() === "p") {
      event.preventDefault();
      this.print();
    }
  }

  constructor(private proposalService: ProposalsService, private projectsService: ProjectsService, private fb: FormBuilder, private userSettingsService: UserSettingsService,
    private route: ActivatedRoute, private autoCompleteOptionsService: ProposalAutocompleteOptionsService, private snippetsService: SnippetsService,
    private templatesService: ProposalTemplatesService, private termsService: ProposalTermsService, private repsService: RepsService, public dialog: MatDialog,
    private projectAttachmentService: ProjectAttachmentsService) {
    this.createLines();


    this.subscriptions.add(this.snippetsService.snippets.subscribe(data => {
      this.proposalSnippets.clear();
      for (const snippet of data) {
        this.proposalSnippets.set(snippet.Keyword, snippet);
      }
    }));

  }

  ngOnInit(): void {
    this.subscriptions.add(this.autoCompleteOptionsService.conditionOptions.subscribe(data => { this.conditionOptions = data; this.filteredConditionOptions = data; }));
    this.subscriptions.add(this.templatesService.templates.subscribe(data => this.templates = data));
    this.subscriptions.add(this.termsService.terms.subscribe(data => this.terms = data));
    this.subscriptions.add(this.repsService.reps.subscribe(data => this.reps = data));
    this.subscriptions.add(this.proposalForm.valueChanges.subscribe(data => { this.showTemplateSelector = false; this.selectedTemplate = null; }));


    this.subscriptions.add(this.proposalService.selectedProposal.subscribe(data => {
      this.selectedProposal = data;
      if (data !== null) {
        data.Payments.length = data.NumberOfPayments;
        this.addPaymentsControls(data.NumberOfPayments, true);
        this.proposalForm.patchValue(data);
        this.proposalForm.markAsUntouched();
      } else {
        if (this.isCloning) {
          this.isCloning = false;
          return;
        }
        this.newProposal();
      }
    }));

    this.subscriptions.add(this.projectsService.selectedProject.subscribe(data => {
      this.selectedProject = data;
    }));

    this.subscriptions.add(this.proposalService.proposals.subscribe(data => {
      this.proposals = data;
      if (this.selectedProposal !== null) {
        const id = this.selectedProposal.Id;
        this.selectedProposal = this.proposals.find(p => p.Id === id) ?? null;
        if (this.selectedProposal) {
          this.proposalForm.patchValue(this.selectedProposal);
        }
      }
    }));

    const proposalId = parseInt(this.route.snapshot.paramMap.get('id') ?? '0');
    this.subscriptions.add(this.userSettingsService.user.subscribe(data => {
      this.user = data;
      if (this.isInitializing) {
        this.isInitializing = false;
        proposalId || this.newProposal();
      }
    }));

    if (proposalId === 0) { return; }
    this.proposalService.proposals.pipe(take(1)).subscribe(data => {
      const proposal = data.find(p => p.Id === proposalId);
      if (proposal) {
        this.projectsService.selectProject(proposal.ProjectId);
        this.proposalService.selectProposal(proposal);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  filterPaymentConditionOptions(event: any) {
    const value = event.target.value;
    if (StringExt.isNullOrWhiteSpace(value)) {
      this.filteredConditionOptions = [...this.conditionOptions];
      return;
    }
    const filter = value.toLowerCase();
    this.filteredConditionOptions = this.conditionOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
  }

  onKeyDownEvent(i: number, event: KeyboardEvent) {
    switch (event.key) {
      case "Enter":
        this.checkSnippets(i, event);
        this.setFocusToNextRow(i);
        this.preventDefaultKeyEvent(event);
        break;
      case "ArrowUp":
        {
          if (event.ctrlKey) {
            this.setFocusToPreviousRow(i);
            this.preventDefaultKeyEvent(event);
          }
          // const el = event.target as HTMLInputElement;
          // const pos = el.selectionStart;
          // if (pos === 0) {
          this.setFocusToPreviousRow(i);
          this.preventDefaultKeyEvent(event);
          // }
        }
        break;
      case "ArrowDown":
        {
          if (event.ctrlKey) {
            this.setFocusToNextRow(i);
            this.preventDefaultKeyEvent(event);
          }
          else {
            const el = event.target as HTMLInputElement;
            // const pos = el.selectionStart;
            // if (pos === el.value.length) {
            this.setFocusToNextRow(i);
            this.preventDefaultKeyEvent(event);
            // }
          }
        }
        break;
      default:
        break;
    }
  }

  onLineFocus(i: number, event: any) {
    if (this.textInputs) {
      const el = this.textInputs.get(i);
      el?.nativeElement.select();
    }
  }

  checkSnippets(lineNumber: number, event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (StringExt.isNullOrWhiteSpace(input.value)) { return; }
    const value = input.value.toLocaleLowerCase();

    let snippet: Snippet | undefined = this.proposalSnippets.get(value.trim());
    if (snippet === undefined) {
      const snippetId = value.substring(0, value.search(/\d/)).trim(); // ?? value.split(' ')[0];
      snippet = this.proposalSnippets.get(snippetId);
    }

    if (snippet === undefined) {
      const snippetId = value.split(' ')[0];
      snippet = this.proposalSnippets.get(snippetId);
    }

    if (snippet === undefined) { return; }
    if (snippet.Parameter.includes('${') && value === snippet.Keyword) {
      return;
    }
    const parameterSplit = this.splitString(snippet.Parameter);
    const parameterDelimiters = parameterSplit.filter(x => !x.includes('${'));
    const parameters = parameterSplit.filter(f => f.includes('${'));
    const regex = new RegExp(`[${parameterDelimiters.join('')}]+`, 'g');  // /[psxx]+/g
    const valueSplits = input.value.split(regex).map(m => m.trim()).filter(f => !StringExt.isNullOrWhiteSpace(f));

    let textParameters = this.splitString(snippet.Text).filter(f => f.includes('${'));
    const snippetTextPlaceHoldersCleaned = textParameters.map(m => m.replace(new RegExp('[${}]+', 'g'), ''));

    const parameterMap = new Map<string, string>();
    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i].replace(new RegExp('[${}]+', 'g'), '');
      let newValue = valueSplits[i] ?? '';

      if (!isNaN(Number(valueSplits[i]))) {
        newValue = NumberExt.numberWithCommas(Number(valueSplits[i]), 0);
      }
      parameterMap.set(parameter, newValue);
    }

    let newString = snippet.Text;
    for (let i = 0; i < textParameters.length; i++) {
      const textParam = textParameters[i];
      const cleanedTextParam = textParam.replace(new RegExp('[${}]+', 'g'), '');
      const cleanedTextParamSplit = cleanedTextParam.split(' ').map(m => m.trim()).filter(f => !StringExt.isNullOrWhiteSpace(f));

      const parser = math.parser();
      for (const textParam of cleanedTextParamSplit) {
        const parameter = parameterMap.get(textParam);
        if (parameter !== undefined) {
          const n = NumberExt.stringToNumber(parameter);
          if (n === 0) {
            parser.set(textParam, parameter);
          }
          else {
            parser.set(textParam, n);
          }
        }
      }
      const newValue = parser.evaluate(cleanedTextParam);
      newString = newString.replace(textParam, newValue);
    }

    const newStringSplit = newString.split(/\r?\n/);
    for (let i = 0; i < newStringSplit.length; i++) {
      const lineText = newStringSplit[i];
      const currentLine = Math.min((lineNumber + i), ((this.textInputs?.length ?? 0) - 1));
      const text: ILine = { Text: lineText };
      this.getLine(currentLine).patchValue(text);
    }
  }

  splitString(value: string) {
    // "ps${width}x${length}x${height}": "Build a ${width} x ${length} x ${height} per the attached specs." };
    const v = value.split(/(\${.*?})/g);
    return v;
  }

  preventDefaultKeyEvent(event: KeyboardEvent) {
    event.cancelBubble = true;
    event.preventDefault();
  }

  setFocusToNextRow(i: number) {
    if (this.textInputs) {
      if (this.textInputs.length > (i + 1)) {
        const el = this.textInputs.get(i + 1);
        el?.nativeElement.focus();
        el?.nativeElement.select();
      }
    }
  }

  setFocusToPreviousRow(i: number) {
    if (this.textInputs) {
      if (i > 0) {
        const el = this.textInputs.get(i - 1);
        el?.nativeElement.focus();
        el?.nativeElement.select();
      }
    }
  }

  saveProposal() {
    if (this.proposalForm.valid) {
      const proposal = this.proposalForm.value as IProposal;
      if (proposal.Id > 0) {
        this.proposalService.update(proposal).subscribe(data => {
          this.proposalForm.markAsPristine();
          if (this.printAfterSave) {
            this.printAfterSave = false;
            this.print();
          }
        });
      }
      else {
        proposal.CustomerId = this.selectedProject?.CustomerId ?? 0;
        proposal.ProjectId = this.selectedProject?.Id ?? 0;

        this.proposalService.add(proposal).subscribe(data => {
          this.selectedProposal = data;
          this.proposalForm.patchValue(data);
          this.proposalForm.markAsPristine();

          if (this.printAfterSave) {
            this.printAfterSave = false;
            this.print();
          }
          setTimeout(() => { this.proposalService.selectProposal(data); }, 10);
        });
      }
    }
    else {
      this.proposalForm.markAllAsTouched();
    }
  }

  createLines(): void {
    this.Lines.clear();
    for (let i = 0; i < 19; i++) {
      this.Lines.push(this.fb.group({ Text: '' }));
    }
    this.addPaymentsControls(1, true);
  }

  addPaymentsControls(numberOfPayments: number, clearExisting: boolean) {
    if (clearExisting) {
      this.Payments.clear();
      for (let i = 0; i < numberOfPayments; i++) {
        // this.Payments.push(this.fb.group({ Condition: '', Amount: '' }));
        this.Payments.push(this.fb.group({ Condition: [0, Validators.required], Amount: [0, Validators.pattern("^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$")], }));
      }
    }
    else {
      if (this.Payments.length < numberOfPayments) {
        const count = this.Payments.length;
        for (let i = count; i < numberOfPayments; i++) {
          // this.Payments.push(this.fb.group({ Condition: '', Amount: '' }));
          this.Payments.push(this.fb.group({ Condition: [0, Validators.required], Amount: [0, Validators.pattern("^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$")], }));
        }
      }
      else {
        for (let i = this.Payments.length; i > numberOfPayments; i--) {
          this.Payments.removeAt(i - 1);
        }
      }
    }
  }

  recalculatePaymentAmounts() {
    let amount = Number(this.Amount);
    amount = isNaN(amount) ? 0 : amount;

    let numberOfPayments = Number(this.NumberOfPayments);
    numberOfPayments = isNaN(numberOfPayments) ? 0 : numberOfPayments;

    let newPaymentAmount: number[] = [];

    if (numberOfPayments <= 0 || amount <= 0) {
      newPaymentAmount = new Array<number>(numberOfPayments).fill(0);
    }
    else {
      for (let i = 0; i < numberOfPayments; i++) {
        if (i < (numberOfPayments - 1)) {
          const np = Math.round(amount / numberOfPayments);
          newPaymentAmount.push(np);
        } else {
          const sum = newPaymentAmount.reduce((a, b) => a + b, 0);
          const np = amount - sum;
          newPaymentAmount.push(np);
        }
      }
    }

    for (let i = 0; i < this.Payments.length; i++) {
      const control = this.Payments.at(i);
      const c = control.get('Amount');
      c?.setValue(newPaymentAmount[i]);
    }
  }

  numberOfPaymentsSelected(event: MatSelectChange) {
    this.addPaymentsControls(event.value, false);
    this.recalculatePaymentAmounts();
  }

  amountChanged(event: any) {
    this.recalculatePaymentAmounts();
  }

  paymentAmountChanged(index: number) {
    const proposal = this.proposalForm.value as IProposal;
    if (index === this.Payments.length - 1) {
      const sum = proposal.Payments.map(m => this.getNumber(m.Amount)).reduce((a, b) => a + b, 0);
      this.proposalForm.get('Amount')?.setValue(sum);
      return;
    }

    const amounts = proposal.Payments.map(m => Number(m.Amount));
    let sumOfAmountsToChangedPayment: number = 0;
    for (let i = index; i >= 0; i--) {
      sumOfAmountsToChangedPayment += amounts[i];
    }

    let paymentsLeft = proposal.NumberOfPayments - (index + 1);
    let amountLeft = proposal.Amount - sumOfAmountsToChangedPayment;
    amountLeft = isNaN(amountLeft) ? 0 : amountLeft < 0 ? 0 : amountLeft;
    paymentsLeft = isNaN(paymentsLeft) ? 0 : paymentsLeft < 0 ? 0 : paymentsLeft;
    let newPaymentAmount: number[] = new Array<number>(proposal.Payments.length).fill(0);

    if (paymentsLeft <= 0 || amountLeft <= 0) {
      newPaymentAmount = new Array<number>(paymentsLeft).fill(0);
    }
    else {
      for (let i = (index + 1); i < proposal.Payments.length; i++) {
        if (i < (proposal.Payments.length - 1)) {
          const np = Math.round(amountLeft / paymentsLeft);
          newPaymentAmount[i] = np;
        } else {
          const sum = newPaymentAmount.reduce((a, b) => a + b, 0);
          const np = amountLeft - sum;
          newPaymentAmount[i] = np;
        }
      }
    }

    for (let i = (index + 1); i < proposal.Payments.length; i++) {
      const control = this.Payments.at(i);
      const c = control.get('Amount');
      c?.setValue(newPaymentAmount[i]);
    }
  }

  newProposal() {
    const newP: IProposal = {
      Id: 0,
      ProjectId: this.selectedProject?.Id ?? 0,
      CustomerId: this.selectedProject?.CustomerId ?? 0,
      RepId: this.user?.RepId ?? this.selectedProject?.RepId ?? null,
      ProposalNumber: '',
      BidDate: new Date(),
      NumberOfPayments: 1,
      DaysValid: 21,
      Status: 'Quoted',
      Amount: 0,
      Lines: new Array<ILine>(),
      Payments: new Array<IPayment>(),
      Terms: '',
    }

    this.createLines();

    for (let i = 0; i < 19; i++) {
      const newLine: ILine = { Text: '' };
      newP.Lines.push(newLine);
    }
    const payment: IPayment = { Amount: 0, Condition: '' };
    newP.Payments.push(payment);

    this.proposalForm.setValue(newP);
    this.proposalForm.markAsUntouched();
    if (this.selectedProposal !== null) {
      this.selectedProposal = null;
      this.proposalService.selectProposal(null);
    }

    if (this.user !== null && this.user.DefaultProposalTemplateId) {
      const template = this.templates.find(x => x.Id == this.user!.DefaultProposalTemplateId) as IProposalTemplateWithTerms
      this.applyTemplateToForm(template);
      this.selectedTemplate = template;
    }
    this.showTemplateSelector = true;
  }

  clone() {
    if (this.selectedProposal === null) { return; }
    const clonedProposal: IProposal = JSON.parse(JSON.stringify(this.selectedProposal));
    clonedProposal.Id = 0;
    clonedProposal.ProposalNumber = "";
    clonedProposal.BidDate = new Date();
    clonedProposal.Status = 'Quoted';
    clonedProposal.RepId = this.user?.RepId ?? this.selectedProject?.RepId ?? null;

    this.selectedProposal = null;
    this.isCloning = true;
    this.proposalService.selectProposal(null);
    this.proposalForm.setValue(clonedProposal);
  }

  selectedTemplateChanged(event: MatSelectChange) {
    const template = event.value as IProposalTemplateWithTerms;
    this.applyTemplateToForm(template);
  }

  applyTemplateToForm(template: IProposalTemplateWithTerms) {

    if (!template) {
      return;
    }

    let { Id, Description, Terms, TermsId, RepId, ...rest } = template;
    this.addPaymentsControls(template.NumberOfPayments, true);

    if (this.user?.RepId) {
      RepId = this.user.RepId;
    }

    const newProposal = { ...rest, RepId, Terms: Terms.Terms };
    this.proposalForm.patchValue(newProposal);
  }

  selectedTermsChanged(event: MatSelectChange) {
    const pTerms = event.value as IProposalTerms;
    const terms: string = pTerms.Terms;
    this.proposalForm.patchValue({ Terms: terms });
  }

  deleteProposal() {
    if (this.selectedProposal === null) { return; }
    this.proposalService.delete(this.selectedProposal).subscribe();
  }



  print() {
    if (this.proposalForm.dirty) {
      this.printAfterSave = true;
      this.saveProposal();
      return;
    }

    if (this.selectedProposal === null) {
      return;
    }

    if (this.emailAfterSave) {
      this.email();
      return;
    }

    const url = this.proposalService.getProposalPdfURL(this.selectedProposal);
    if (this.isMobileOS()) {
      const printPdfWindow = window.open(url, "_blank");
      if (printPdfWindow !== null) {
        printPdfWindow.focus();
        printPdfWindow.document.title = `Print Proposal ${this.selectedProposal.ProposalNumber}`
        printPdfWindow.onload = () => {
          printPdfWindow.print();
        };
      }
    }
    else {
      const dialogRef = this.dialog.open(PrintProposalDialogComponent, {
        // width: '250px',
        data: { url: url },
        maxWidth: '1000px',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: ['full-screen-modal']
      });
    }
  }

  email() {
    if (this.proposalForm.dirty) {
      this.emailAfterSave = true;
      this.saveProposal();
      return;
    }
    this.emailAfterSave = false;
    if (this.selectedProposal === null) { return; }

    const dialogRef = this.dialog.open(ProposalEmailDialogComponent, {
      width: '750px',
      height: '450px',
      data: { proposal: this.selectedProposal, project: this.selectedProject, }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.selectedProposal?.Lines ?? [], event.previousIndex, event.currentIndex);
    this.moveItemInFormArray(this.Lines, event.previousIndex, event.currentIndex);
  }

  moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }

  getNumber(value: string | number | null | undefined) {
    const v = Number(value);
    return isNaN(v) ? 0 : v;
  }


  isMobileOS() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      // return "Android"
      return true;
    }
    else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      // return "iOS"
      return true;
    }
    // return "Other"
    return false;
  }

  fileDrop(e: any): void {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.uploadFiles(files);
  }

  dragover(e: any): void {
    e.preventDefault();
  }

  uploadFiles(files: any) {
    if (this.selectedProject) {
      this.projectAttachmentService.uploadFiles(this.selectedProject.Id, files)?.subscribe();
    }
  }

}

interface IProposalTemplateWithTerms extends IProposalTemplate {
  Terms: IProposalTerms
}

