import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ReplaySubject, Subscription } from 'rxjs';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { IRep, IRepCreateDto, RepsService } from '../reps.service';

@Component({
  selector: 'app-reps',
  templateUrl: './reps.component.html',
  styleUrls: ['./reps.component.scss']
})
export class RepsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  reps: IRep[] = [];
  selectedRep: IRep | null = null;
  changePassword = false;
  signatureFile: File | null = null;

  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private repsService: RepsService, private fb: FormBuilder, private userSettings: UserSettingsService,) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.repsService.reps.subscribe(data => this.reps = data);

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedRep = selectedRows[0] as IRep;
      this.repsService.selectRep(selectedRep);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: IRep) => data.Id.toString();

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'Name', headerName: 'Name', flex: 1 },
      { field: 'Email', headerName: 'Email', flex: 2 },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }



}
