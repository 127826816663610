import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SubcontractorsService {


  private subcontractors$: ReplaySubject<ISubcontractor[]> = new ReplaySubject(1);
  public get subcontractors(): Observable<ISubcontractor[]> { if (!this.isLoaded) { this.loadSubcontractors(); } return this.subcontractors$.asObservable() }

  baseUrl = 'Subcontractors';
  isLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadSubcontractors(): void {
    const url = `${this.baseUrl}`;
    this.httpClient.get<ISubcontractor[]>(url).subscribe(data => { this.subcontractors$.next(data); this.isLoaded = true; });
  }

  saveSubcontractor(subcontractor: ISubcontractor) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<ISubcontractor>(url, subcontractor).pipe(catchError(err => this.handleError(err)), tap((data => this.updateSubcontractor(0, [data]))));
  }

  deleteSubcontractor(subcontractor: ISubcontractor) {
    const url = `${this.baseUrl}/${subcontractor.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete subcontractor.")), tap(data => this.updateSubcontractor(2, [subcontractor])));
  }

  private handleError(err: any, message: string = "Failed to save subcontractor. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateSubcontractor(crudType: number, subcontractors: ISubcontractor[]) {
    this.subcontractors.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const subcontractor of subcontractors) {
          const index = data.findIndex(c => c.Id === subcontractor.Id);
          if (index === -1) {
            data.push(subcontractor);
          }
          else {
            data[index] = subcontractor;
          }
        }
      }
      else if (crudType === 2) {
        for (const subcontractor of subcontractors) {
          const index = data.findIndex(c => c.Id === subcontractor.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.subcontractors$.next([...data]);
    });
  }
}


export interface ISubcontractor {
  Id: number;
  ContractorName: string;
  Trade: string;
}
