import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, ReplaySubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ThemeStorage {
  readonly appThemeKey = 'appTheme';

  private appThemeSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  get appTheme() { return this.appThemeSubject.asObservable() }


  constructor(private storage: StorageMap) {
    this.storage.get(this.appThemeKey).subscribe((data: any) => this.appThemeSubject.next(data ?? null));
  }

  saveAppTheme(isDark: boolean) {
    this.appThemeSubject.next(isDark);
    this.storage.set(this.appThemeKey, isDark).subscribe();
  }

}
