import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IProposalTerms, ProposalTermsService } from '../services/proposal-terms.service';

@Component({
  selector: 'app-proposal-terms',
  templateUrl: './proposal-terms.component.html',
  styleUrls: ['./proposal-terms.component.scss']
})
export class ProposalTermsComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;

  subscriptions: Subscription = new Subscription();
  terms: IProposalTerms[] = [];
  selectedTerms: IProposalTerms | null = null;

  termsForm = this.fb.group({
    Id: [0],
    Name: ['', Validators.required],
    Terms: ['', Validators.required],
  });

  get Terms() { return this._sanitizer.bypassSecurityTrustHtml(this.termsForm.get('Terms')?.value); };

  constructor(private termsService: ProposalTermsService, private fb: FormBuilder, private _ngZone: NgZone, protected _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.subscriptions.add(this.termsService.terms.subscribe(data => this.terms = data));
    this.subscriptions.add(this.termsService.selectedTerms.subscribe(data => {
      this.selectedTerms = data;
      if (data === null) {
        this.newProposalTerms();
      }
      else {
        this.termsForm.setValue(data);
      }
    }))
  }

  selectedTermChanged(event: MatSelectionListChange) {
    const options = event.options;
    const terms = options[0].value as IProposalTerms;
    this.termsService.selectTerms(terms);
  }

  newProposalTerms() {
    const newTerms: IProposalTerms = {
      Id: 0,
      Name: '',
      Terms: ''
    }
    this.termsForm.setValue(newTerms);
    this.termsForm.markAsUntouched();

    if (this.selectedTerms !== null) {
      this.termsService.selectTerms(null);
    }
  }

  saveProposalTerms() {
    if (!this.termsForm.valid) {
      this.termsForm.markAllAsTouched();
      return;
    }

    const terms = this.termsForm.value as IProposalTerms;
    if (terms.Id > 0) {
      this.termsService.update(terms).subscribe();
    }
    else {
      this.termsService.add(terms).subscribe(data => {
        this.selectedTerms = data;
        this.termsForm.setValue(data);
        setTimeout(() => { this.termsService.selectTerms(data); }, 10);
      });
    }
  }

  deleteProposalTerms() {
    if (this.selectedTerms === null) { return; }
    this.termsService.delete(this.selectedTerms).subscribe(data => this.newProposalTerms());
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize?.resizeToFitContent(true));
  }
}
