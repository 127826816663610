import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ILine, IPayment } from './proposals.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalTemplatesService {

  private templates$: BehaviorSubject<IProposalTemplate[]> = new BehaviorSubject<IProposalTemplate[]>([]);
  public get templates(): Observable<IProposalTemplate[]> { if (!this.isProposalLoaded) { this.loadTemplates(); } return this.templates$.asObservable(); }

  private selectedTemplate$: BehaviorSubject<IProposalTemplate | null> = new BehaviorSubject<IProposalTemplate | null>(null);
  public get selectedTemplate(): Observable<IProposalTemplate | null> { return this.selectedTemplate$.asObservable(); }


  baseUrl = 'ProposalTemplates';
  isProposalLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadTemplates(): void {
    const url = `${this.baseUrl}`;
    this.isProposalLoaded = true;
    this.httpClient.get<IProposalTemplate[]>(url).subscribe(data => { this.templates$.next(data); });
  }

  selectTemplate(template: IProposalTemplate | null | number) {
    setTimeout(() => {
      if (typeof (template) === 'number') {
        template = this.templates$.value.find(p => p.Id === template) ?? null;
      }
      template && this.updateSelectedProposalTemplate(template);
      this.selectedTemplate$.next(template);
    }, 1);
  }

  private updateSelectedProposalTemplate(template: IProposalTemplate) {
    if (this.selectedTemplate$.value?.Id !== template.Id) {
      this.getTemplate(template.Id);
    }
  }

  getTemplate(id: number): void {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<IProposalTemplate>(url).subscribe(data => { this.updateTemplates(1, [data]); this.selectedTemplate$.next(data) });
  }

  add(template: IProposalTemplate) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<IProposalTemplate>(url, template).pipe(catchError(err => this.handleError(err)), tap((data => this.updateTemplates(0, [data]))));
  }

  update(template: IProposalTemplate) {
    const url = `${this.baseUrl}/${template.Id}`;
    return this.httpClient.put<IProposalTemplate>(url, template).pipe(catchError(err => this.handleError(err)), tap((data => this.updateTemplates(1, [data]))));
  }

  delete(template: IProposalTemplate) {
    const url = `${this.baseUrl}/${template.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete template. ")), tap((data => this.updateTemplates(2, [template]))));
  }

  private handleError(err: any, message: string = "Failed to save template. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateTemplates(crudType: number, templates: IProposalTemplate[]) {
    this.templates.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const template of templates) {
          const index = data.findIndex(c => c.Id === template.Id);
          if (index === -1) {
            data.push(template);
          }
          else {
            data[index] = template;
          }
        }
      }
      else if (crudType === 2) {
        for (const template of templates) {
          const index = data.findIndex(c => c.Id === template.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.templates$.next([...data]);
    });
  }
}


export interface IProposalTemplate {
  Id: number;
  Description: string;
  RepId: number | null;
  DaysValid: number;
  NumberOfPayments: number;
  TermsId: number;
  Lines: ILine[];
  Payments: IPayment[];
}

