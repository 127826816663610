import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public user: Observable<User | null> = this.userSubject.asObservable();


  constructor(private router: Router, private http: HttpClient) {
  }

  public get userValue(): User | null {
    return this.userSubject.value;
  }

  public get isAuthenticated(): Observable<boolean> {
    return this.user.pipe(map(u => u !== null));
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  login(email: string, password: string): Observable<User> {
    return this.http.post<User>(`accounts/login`, { email, password }, { withCredentials: true })
      .pipe(map(user => {
        this.userSubject.next(user);
        // this.startRefreshTokenTimer();
        return user;
      }));
  }

  logout(): void {
    // this.stopRefreshTokenTimer();
    // await this.http.post<any>(`accounts/revoke-token`, {}, { withCredentials: true }).toPromise();
    // this.userSubject.next(null);
    this.http.post<any>(`accounts/logout`, {}, { withCredentials: true }).subscribe(data => {
      this.userSubject.next(null);
      this.router.navigate(['/login'])
    });
    // this.deleteRefreshTokenCookie();
    // this.router.navigate(['/login']);
    //document.location.href = "/"
  }

  refreshToken(): Observable<User> {
    return this.http.post<User>(`accounts/check-auth`, {}, { withCredentials: true })
      .pipe(map((user) => {
        this.userSubject.next(user);
        // this.startRefreshTokenTimer();
        return user;
      }));
  }

  // helper methods
  // private startRefreshTokenTimer(): void {
  //   if (this.userValue === null) { return; }
  //   if (!this.userValue.JwtToken) { return; }

  //   // parse json object from base64 encoded jwt token
  //   const jwtToken = JSON.parse(atob(this.userValue.JwtToken.split('.')[1]));
  //   // set a timeout to refresh the token a minute before it expires
  //   const expires = new Date(jwtToken.exp * 1000);
  //   const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //   this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  //}

  //   private stopRefreshTokenTimer(): void {
  //   clearTimeout(this.refreshTokenTimeout);
  // }

  // deleteRefreshTokenCookie() {
  //   const name = 'pmRefreshToken'
  //   document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  // }
}

export interface User {
  Id: string;
  UserName: string;
  Email: string;
  RepId: number | null;
  RepName: string
  DefaultProposalTemplateId: number | null;
  GoogleMapsKey: string;
}
