

export class StringExt {
    public static isNullOrWhiteSpace(str: string) {
        return (!str || str.length === 0 || /^\s*$/.test(str))
    }

    public static wordContainsFilterString(str: string, filter: string) {
        const f = filter.split(' ');
        for (const v of f) {
            if (!str.includes(v)) { return false; }
        }
        return true;
    }
}


