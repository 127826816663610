<div style="display: flex; flex-direction: row; margin-top: 3px; margin-left: 3px;">
    <div style="font-weight: 500; display: flex; align-items: center; justify-content: center;">
        3rd Party Expenses
    </div>
    <mat-checkbox color="primary" [(ngModel)]="totalByVendor" (ngModelChange)="setGridColumns()" style="margin-left: 10px;">Total By Vendor
    </mat-checkbox>

    <div style="display: flex; align-items: center; justify-content: center; margin-left: 15px;">
        Difference
        <span style="margin-left: 5px;" [ngClass]="{'color-green': SumDiff < 0, 'color-red': SumDiff > 0}">
            {{ SumDiff | currency}}
        </span>
        <span style="font-weight: 500; margin-left: 5px;">{{ SumDiffPercentage | percent : '1.1-1'}}</span>
    </div>
</div>
<ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 30px); margin-top: 2px;" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [rowData]="thirdPartyExpenses" [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()">
</ag-grid-angular>