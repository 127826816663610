import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  private projects$: BehaviorSubject<IProject[]> = new BehaviorSubject<IProject[]>([]);
  public get projects(): Observable<IProject[]> { if (!this.isProjectsLoaded) { this.loadProjects(); } return this.projects$.asObservable(); }

  public filteredProjects$: BehaviorSubject<IProject[]> = new BehaviorSubject<IProject[]>([]);

  private selectedProject$: BehaviorSubject<IProject | null> = new BehaviorSubject<IProject | null>(null);
  public get selectedProject(): Observable<IProject | null> { return this.selectedProject$.asObservable(); }


  baseUrl = 'projects';
  isProjectsLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadProjects(): void {
    const url = `${this.baseUrl}`;
    this.isProjectsLoaded = true;
    this.httpClient.get<IProject[]>(url).subscribe(data => { this.projects$.next(data); });
  }

  selectProject(project: IProject | number | null) {
    setTimeout(() => {
      if (typeof (project) === 'number') {
        project = this.projects$.value.find(p => p.Id === project) ?? null;
      }
      project && this.refreshSelectedProject(project);
      this.selectedProject$.next(project);
    }, 1);
  }

  private refreshSelectedProject(project: IProject) {
    if (this.selectedProject$.value?.Id !== project.Id) {
      this.getProject(project.Id);
    }
  }

  getProject(id: number): void {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<IProject>(url).subscribe(data => { this.updateProjects(1, [data]); this.selectedProject$.next(data); });
  }

  add(project: IProject) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<IProject>(url, project).pipe(catchError(err => this.handleError(err)), tap(data => this.updateProjects(0, [data])));
  }

  update(project: IProject) {
    const url = `${this.baseUrl}/${project.Id}`;
    return this.httpClient.put<IProject>(url, project).pipe(catchError(err => this.handleError(err)), tap(data => this.updateProjects(1, [data])));
  }

  delete(project: IProject) {
    const url = `${this.baseUrl}/${project.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete project. ")), tap(data => this.updateProjects(2, [project])));
  }

  private handleError(err: any, message: string = "Failed to save project. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateProjects(crudType: number, projects: IProject[]) {
    this.projects.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const project of projects) {
          const index = data.findIndex(c => c.Id === project.Id);
          if (index === -1) {
            data.push(project);
          }
          else {
            data[index] = project;
          }
        }
      }
      else if (crudType === 2) {
        for (const project of projects) {
          const index = data.findIndex(c => c.Id === project.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.projects$.next([...data]);
    });
  }
}


export interface IProject {
  Id: number;
  CustomerId: number;
  Description: string;
  Address: string;
  City: string;
  Notes: string;
  ProjectType: string;
  Status: string;
  DateCalled: Date;
  DateCompleted: Date | null;
  // DateModified: Date;
  RepId: number | null;
  Latitude: number | undefined | null;
  Longitude: number | null | undefined;
  GeocodeMessage: string | undefined;
}
