import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ColDef, ColumnApi, GetRowIdParams, GridApi, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ITrade, TradesService } from 'src/app/contracts/trades.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-components/confirm-dialog/confirm-dialog.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  trades: ITrade[] = [];
  selectedTrade: ITrade | null = null;
  changePassword = false;
  signatureFile: File | null = null;

  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: ColDef[] = [];

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };


  form = this.fb.group({
    Id: [0],
    TradeName: ['', Validators.required],
  });


  constructor(private tradesService: TradesService, private fb: FormBuilder, private userSettings: UserSettingsService,private dialog: MatDialog) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.tradesService.trades.subscribe(data => this.trades = data);

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedTrade = selectedRows[0] as ITrade;
      this.resetForm();
      this.selectedTrade = { ...selectedTrade };
      this.form.setValue(this.selectedTrade);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.getRowId = (params: GetRowIdParams) => params.data.Id;

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'TradeName', headerName: 'Name', flex: 1 },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }

  resetForm() {
    this.selectedTrade = null;
    this.form = this.fb.group({
      Id: [0],
      TradeName: ['', Validators.required],
    });
  }


  new() {
    this.selectedTrade = null;
    this.resetForm();
    this.gridApi?.deselectAll();
  }

  save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const trade = this.form.value as ITrade;
    this.tradesService.save(trade).subscribe(data => {
      setTimeout(() => {
        this.gridApi?.forEachNode(node => node.data.Id === data.Id ? node.setSelected(true, true) : null);
      }, 50);
    });

    this.resetForm();
  }

  deleteTrade() {
    if (this.selectedTrade === null) { return; }

    const message = `Are you sure you want to delete crew: '${this.selectedTrade.TradeName}' ?`;
    const dialogData = new ConfirmDialogModel("Confirm Delete", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (this.selectedTrade !== null) {
          this.tradesService.delete(this.selectedTrade).subscribe(data => this.resetForm());
        }
      }
    });
  }
}
