<div>
    <form [formGroup]="templateForm">
        <div>
            <button type="button" mat-icon-button matTooltip="Add New Proposal" color="accent"
                class="mat-raised-button-sm" (click)="newTemplate()">
                <mat-icon>add</mat-icon>
            </button>
            <button type="button" matTooltip="Save Changes" (click)="saveTemplate()" mat-icon-button color="primary"
                class="mat-raised-button-sm">
                <mat-icon>save</mat-icon>
            </button>
            <button type="button" matTooltip="Delete" mat-icon-button color="warn"
                [disabled]="selectedTemplate === null" class="mat-raised-button-sm" (click)="deleteTemplate()">
                <mat-icon class="md-18">delete</mat-icon>
            </button>
            <button type="button" mat-icon-button matTooltip="Clone" color="accent" class="mat-raised-button-sm"
                (click)="clone()">
                <mat-icon>copy_all</mat-icon>
            </button>

        </div>

        <div class="edit-proposal-form" style="padding: 3px; margin: 3px; max-width: 672px;">
            <div style="display: flex;">

                <div style="margin-left: 5px;">
                    <mat-form-field appearance="outline" floatLabel="always" color="accent">
                        <mat-label>Description</mat-label>
                        <input matInput formControlName="Description">
                    </mat-form-field>
                </div>

                <div style="margin-left: 5px;">
                    <mat-form-field appearance="outline" floatLabel="always" color="accent">
                        <mat-label>Days Valid</mat-label>
                        <input matInput formControlName="DaysValid">
                    </mat-form-field>
                </div>

                <div style="margin-left: 5px; min-width: 100px;">
                    <mat-form-field appearance="outline" floatLabel="always" color="accent">
                        <mat-label>Rep</mat-label>
                        <mat-select formControlName="RepId">
                            <mat-option>-- None --</mat-option>
                            <mat-option *ngFor="let rep of reps" [value]="rep.Id">
                                {{ rep.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="margin-left: 5px; min-width: 150px">
                    <mat-form-field appearance="outline" floatLabel="always" color="accent" w>
                        <mat-label>Terms</mat-label>
                        <mat-select formControlName="TermsId" required>
                            <mat-option *ngFor="let terms of proposalTerms" [value]="terms.Id">
                                {{ terms.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="border" style="display: flex; width: 100%; margin-top: 5px;">
                <div style=" margin-top: 3px; width: 130px;">
                    <div style="margin: 2px; margin-left: 10px; margin-bottom: 8px; font-size: large;">Payments</div>
                    <mat-form-field appearance="outline" floatLabel="always" color="accent">
                        <mat-label>Number of Payments</mat-label>
                        <mat-select formControlName="NumberOfPayments"
                            (selectionChange)="numberOfPaymentsSelected($event)" required>
                            <mat-option [value]="1">1</mat-option>
                            <mat-option [value]="2">2</mat-option>
                            <mat-option [value]="3">3</mat-option>
                            <mat-option [value]="4">4</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" floatLabel="always" color="accent">
                        <mat-label>Amount</mat-label>
                        <input matInput matInputCurrency formControlName="Amount" (change)="amountChanged($event)">
                    </mat-form-field> -->
                </div>
                <div style="margin: 4px; width: 100%;">
                    <!-- <div>Payments</div> -->
                    <table class="payment-table">
                        <thead>
                            <tr>
                                <th style="visibility: hidden;">''</th>
                                <!-- <th> Amount</th> -->
                                <th>Condition</th>
                            </tr>
                        </thead>
                        <tbody cdkDropList>
                            <ng-container formArrayName="Payments">
                                <tr *ngFor="let lineForm of Payments.controls; let i = index" cdkDragLockAxis="y"
                                    cdkDrag [formGroupName]="i">
                                    <td style="width: 25px;">
                                        <mat-icon class="drag-handle" cdkDragHandle>drag_handle</mat-icon>
                                    </td>
                                    <td style="width: 100%; min-width: 210px;">
                                        <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                                            <input matInput tab-directive formControlName="Condition"
                                                [matAutocomplete]="paymentConditionAutoComplete"
                                                (keyup)="filterPaymentConditionOptions($event)">
                                            <mat-autocomplete #paymentConditionAutoComplete="matAutocomplete"
                                                class="example-viewport">
                                                <mat-option *ngFor="let option of filteredConditionOptions"
                                                    [value]="option.Value" class="example-item-detail">
                                                    {{option.Text}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div style="margin-top: 10px;">Lines</div>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <ng-container formArrayName="Lines">
                    <div *ngFor="let lineForm of Lines.controls; let i = index">
                        <div cdkDragLockAxis="y" cdkDrag style="display: flex;" [formGroupName]="i">
                            <mat-icon class="drag-handle" cdkDragHandle>drag_handle</mat-icon>
                            <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                                <input #textInput matInput formControlName="Text" (keydown)="onKeyDownEvent(i,$event)">
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>