import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-textarea-editor',
  templateUrl: './textarea-editor.component.html',
  styleUrls: ['./textarea-editor.component.scss']
})
export class TextareaEditorComponent implements AfterViewInit, OnDestroy, ICellEditorAngularComp {

  @ViewChild('input', { read: ViewContainerRef }) public input: ViewContainerRef | undefined;
  private params: ICellEditorParams | undefined;
  public value: FormControl = new FormControl('');

  minimumRows = 1;
  maximumRows = 1;
  suppressEnterKey = false;
  subscriptions = new Subscription();
  textareaWidth = 5;
  minimumWidth = 30;
  cellWidth = '';   // [style.width] = "cellWidth"
  rowHeight = '20px';
  lineHeight = '22px';

  constructor() { }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    //this.cellWidth = (params.column as any).actualWidth + 'px';

    this.value.setValue(params.value ?? '');
    this.minimumRows = (params as any).minimumRows ?? 1;
    this.maximumRows = (params as any).maximumRows ?? 1;
    this.suppressEnterKey = (params as any).suppressEnterKey ?? false;

    // this.minimumWidth = ((params.column.getActualWidth()) * .145);
    this.minimumWidth = ((params.column.getActualWidth()) - 12);
    this.setTextareaWidth(params.value ?? '');
    this.rowHeight = params.node.rowHeight ? (params.node.rowHeight - 2) + 'px !Important' : '20px'
    const lineH = params.eGridCell.style.lineHeight;
    const ln = parseFloat(lineH);

    if (this.maximumRows > 1) {
      this.lineHeight = (ln) + 'px';
    }
    else {
      this.lineHeight = (ln - 2) + 'px';
    }
    this.subscriptions.add(this.value.valueChanges.subscribe(value => this.setTextareaWidth(value)));
  }

  getValue() {
    return this.value.value ?? '';
  }

  isPopup(): boolean {
    return true;
  }

  ngAfterViewInit(): void {
    // focus on the input 
    setTimeout(() => {
      this.setTextareaWidth(this.value.value);
      this.input?.element.nativeElement.focus();
      this.input?.element.nativeElement.select();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setTextareaWidth(value: string) {
    const lengthOfEachLine = (value.split(/\n/g) || []).map(line => {
      const whiteSpaceCont = Math.max(line.split(' ').length, 3);
      return line.length - (Math.round(whiteSpaceCont / 2.5));
    });
    this.textareaWidth = Math.max(...lengthOfEachLine, 5,);
  }

  onKeypressEvent(event: any) {
    if (event.keyCode === 13 && (!event.ctrlKey) && this.suppressEnterKey) {
      event.stopPropagation();
    }
  }

  onFocusOut() {
    this.params?.api?.stopEditing();
  }
}
