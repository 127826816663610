<div>
  <form class="edit-project-form" [formGroup]="projectForm" (ngSubmit)="saveProject()" autocomplete="new-password"
    (dragover)="dragover($event)" (drop)="drop($event)">
    <div style="display: grid; grid-gap:1px 3px; height: 100%; grid-template-columns: 40% 60%; margin-right: 3px;">
      <div class="left-label-inputs" style="grid-column: 1; ">
        <label>Description</label>
        <div>
          <mat-form-field class="" appearance="fill" color="accent">
            <input matInput formControlName="Description">
          </mat-form-field>
        </div>
        <label>Project Type</label>
        <div class="tooltip-container">
          <mat-form-field appearance="fill" color="accent">
            <input matInput tab-directive formControlName="ProjectType" [matAutocomplete]="projectTypeAutoComplete" (focus)="selectInputText($event)">
            <mat-autocomplete #projectTypeAutoComplete="matAutocomplete" class="example-viewport" panelWidth="300px">
              <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" [ngStyle]="{'height.px': projectTypeAutoCompleteHeight}"  [itemSize]="25">
                <mat-option *cdkVirtualFor="let option of filteredProjectTypeOptions" [value]="option.Value"
                  class="example-item-detail">
                  {{option.Text}}
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </mat-autocomplete>
          </mat-form-field>
          <div class="mat-drawer-container tooltip-content" *ngIf="projectTypeError">
            <mat-error>
              Project Type is required.
            </mat-error>
          </div>
        </div>
        <label (click)="addressClicked()">Address</label>
        <div>
          <mat-form-field class="" appearance="fill" color="accent">
            <input matInput formControlName="Address">
          </mat-form-field>        
        </div>
        <label>City</label>
        <div>
          <mat-form-field appearance="fill" color="accent">
            <input matInput tab-directive formControlName="City" [matAutocomplete]="cityAutoComplete"
              (focus)="selectInputText($event)">
            <mat-autocomplete #cityAutoComplete="matAutocomplete" class="example-viewport" panelWidth="300px">
              <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" style="height: 256px;" [itemSize]="25">
                <mat-option *cdkVirtualFor="let option of filteredCityOptions" [value]="option.Value"
                  class="example-item-detail">
                  {{option.Text}}
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <label>Status</label>
        <div>
          <mat-form-field appearance="fill" color="accent">
            <input matInput tab-directive formControlName="Status" [matAutocomplete]="statusAutoComplete"
              (focus)="selectInputText($event)">
            <mat-autocomplete #statusAutoComplete="matAutocomplete" class="example-viewport">
              <mat-option *ngFor="let option of filteredStatusOptions" [value]="option.Value"
                class="example-item-detail">
                {{option.Text}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div style="grid-column: 1 / span 2; justify-content: end; margin-top: 4px;">
          <button type="button" mat-icon-button matTooltip="Add New Project" color="accent" class="mat-raised-button-sm"
            (click)="newProject()">
            <mat-icon>add</mat-icon>
          </button>
          <button type="submit" matTooltip="Save Changes" mat-icon-button color="primary" class="mat-raised-button-sm">
            <mat-icon>save</mat-icon>
          </button>
          <button type="button" matTooltip="Delete" mat-icon-button color="warn" [disabled]="selectedProject === null"
            class="mat-raised-button-sm" (click)="deleteProject()">
            <mat-icon class="md-18">delete</mat-icon>
          </button>
          <button type="button" matTooltip="Attachments" mat-icon-button color="accent"
            [disabled]="selectedCustomer === null" class="mat-raised-button-sm attachment-count-badge"
            (click)="openAttachmentDialog()">
            <mat-icon [matBadge]="attachmentCount" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small"
              [matBadgeHidden]="attachmentCount  == 0">
              attach_file</mat-icon>
          </button>
        </div>
      </div>
      <div class="left-label-inputs " style="grid-column: 2;">
        <label>Date Called</label>
        <div>
          <mat-form-field class="mat-datepicker-sm" appearance="fill" color="accent">
            <input matInput formControlName="DateCalled" [matDatepicker]="dateCalledPicker">
            <mat-datepicker-toggle matSuffix [for]="dateCalledPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateCalledPicker></mat-datepicker>

          </mat-form-field>
        </div>
        <label>Rep</label>
        <div>
          <mat-form-field appearance="fill" style="margin-left: 3px;" color="accent">
            <mat-select formControlName="RepId" required>
              <mat-option *ngFor="let rep of reps" [value]="rep.Id">
                {{ rep.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="grid-column: 1 / span 2;">
          <mat-form-field class="" appearance="fill" color="accent">
            <textarea matInput #input formControlName="Notes" style="min-height: 88px;"
              [style.width]="'100%'"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
