<mat-form-field appearance="fill">
    <mat-select #select [(ngModel)]="value" (openedChange)="selectionChanged($event)">

        <!-- <mat-option *ngFor="let option of options" [value]="option.Value">{{option.Text}}</mat-option> -->


        <mat-select-trigger>
            {{ value?.JobNumber}}
        </mat-select-trigger>
        <mat-option *ngIf="clearOption">-- None --</mat-option>
        <mat-option *ngFor="let option of options" [value]="option">
            <div style="display: flex;">
                <div style="width: 8em;">{{option.JobNumber}}</div>
                <div style="width: 10em;">{{option.Name }}</div>
            </div>
        </mat-option>
    </mat-select>

</mat-form-field>