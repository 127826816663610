import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SavedLayoutsComponent } from '../saved-layouts/saved-layouts.component';

@Component({
  selector: 'app-save-layout-dialog',
  templateUrl: './save-layout-dialog.component.html',
  styleUrls: ['./save-layout-dialog.component.scss']
})
export class SaveLayoutDialogComponent implements OnInit {

  options: string[] = []
  filteredOptions: Observable<string[]> | undefined;

  form = new FormGroup({
    newLayoutName: new FormControl('', [Validators.required])
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { savedLayouts: string[] }, private dialogRef: MatDialogRef<SavedLayoutsComponent>) {
    this.options = data.savedLayouts
  }

  ngOnInit(): void {
    this.filteredOptions = this.form.controls['newLayoutName'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  submit() {
    if (this.form.valid) {
      const name = this.form.controls['newLayoutName'].value as string;
      this.dialogRef.close(name);
      return;
    }
    this.form.markAllAsTouched();
  }

  cancel() {
    this.dialogRef.close();
  }
}
