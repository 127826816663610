import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ICustomer } from './customers.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerProjectEditService {

  saveEvent$ = new Subject();
  customerSuccessfullySaved$ = new Subject<ICustomer>();
  address$ = new Subject<string>();
  city$ = new Subject<string>();
  rep$ = new Subject<number>();

  constructor() { }

}
