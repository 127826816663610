import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterChangedEvent, GridApi, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { Snippet, SnippetsService } from '../snippets.service';

@Component({
  selector: 'app-snippets',
  templateUrl: './snippets.component.html',
  styleUrls: ['./snippets.component.scss']
})
export class SnippetsComponent implements OnInit, OnDestroy {


  subscriptions = new Subscription();
  snippets: Snippet[] = [];
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private snippetsService: SnippetsService, private userSettings: UserSettingsService,) {
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {

    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));

    this.subscriptions.add(this.snippetsService.snippets.subscribe(data => {
      this.snippets = data;
    }));
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedSnippet = selectedRows[0] as Snippet;
      this.snippetsService.selectSnippet(selectedSnippet);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: Snippet) => data.Id.toString();

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'Keyword', headerName: 'Keyword', flex: 1 },
      { field: 'Text', headerName: 'Text', flex: 2 },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }
}
