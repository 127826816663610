import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './api-authorization/auth.guard';
import { LoginComponent } from './api-authorization/login/login.component';
import { LogoutComponent } from './api-authorization/logout/logout.component';
import { AppComponent } from './app.component';
import { ContractsComponent } from './contracts/contracts/contracts.component';
import { HomeComponent } from './home/home.component';
import { ProjectNavigationComponent } from './projects/project-navigation/project-navigation.component';
import { ProposalEditComponent } from './projects/proposal-edit/proposal-edit.component';
import { SettingsNavigationComponent } from './settings/settings-navigation/settings-navigation.component';
import { SnippetsComponent } from './snippets/snippets/snippets.component';
// import { SchedulerComponent } from './scheduler/scheduler.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  // { path: '', redirectTo: 'contracts', pathMatch: 'full' },
  { path: 'contracts', component: ContractsComponent, canActivate: [AuthGuard], data: { reuse: true } },
  { path: 'contracts/:id', component: ContractsComponent, canActivate: [AuthGuard], data: { reuse: true } },
  // { path: 'customers', component: ContractsComponent, canActivate: [AuthGuard] },
  { path: 'proposals', component: ProjectNavigationComponent, canActivate: [AuthGuard], data: { reuse: true } },
  { path: 'proposals/:id', component: ProposalEditComponent, canActivate: [AuthGuard], data: { reuse: false } },
  { path: 'settings', component: SettingsNavigationComponent, canActivate: [AuthGuard], data: { reuse: true } },

  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  { path: 'scheduler', canActivate: [AuthGuard], data: { reuse: false }, loadChildren: () => import('./scheduler/scheduler.module').then(m => m.SchedulerModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
