import { Component, OnInit } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { FilterChangedEvent, GridOptions, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { Contract } from 'src/app/contracts/contracts.service';

@Component({
  selector: 'app-remaining-filter',
  templateUrl: './remaining-filter.component.html',
  styleUrls: ['./remaining-filter.component.scss']
})
export class RemainingFilterComponent implements AgFilterComponent {

  gridOptions: GridOptions = {};
  params: IFilterParams | undefined;
  selectedRemaining = "All"

  constructor() {
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.selectedRemaining = 'All';
    }
  }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.selectedRemaining != 'All';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const contract = <Contract>Object.assign(new Contract(), params.data)

    switch (this.selectedRemaining) {
      case "0.00":
        if (contract.Remaining != 0) return false;
        break;
      case ">0.00":
        if (contract.Remaining <= 0) return false;
        break;
      case "Red":
        if (!contract.IsDue || contract.IsInvoicedInFull) return false;
        break;
      case "Green":
        if (!contract.IsInvoicedInFull) return false;
        break;
    }
    return true;
  }


  updateFilter() {
    (<any>this.params).onFloatingFilterChange();
  }

  getModel() {
    return this.selectedRemaining;
  }

  setModel(model: any) {
    this.selectedRemaining = model;
    this.params?.filterChangedCallback();
  }


}

