<mat-form-field class="mat-select-sm" appearance="fill">
    <mat-select [(ngModel)]="selectedValue" (ngModelChange)="valueChanged()" #singleSelect style="max-height: 300px;">
        <mat-option>
            <ngx-mat-select-search [formControl]="filterControl" placeholderLabel="search"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="allOption" value="All">All</mat-option>
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.Value">
            {{option.Text}}
        </mat-option>
    </mat-select>
</mat-form-field>