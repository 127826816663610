import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ProjectsService } from './projects.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectAttachmentsService {

  private attachments$: BehaviorSubject<ProjectAttachment[]> = new BehaviorSubject<ProjectAttachment[]>([]);
  public get attachments(): Observable<ProjectAttachment[]> { return this.attachments$.asObservable(); }

  private selectedAttachment$: BehaviorSubject<ProjectAttachment | null> = new BehaviorSubject<ProjectAttachment | null>(null);
  public get selectedAttachment(): Observable<ProjectAttachment | null> { return this.selectedAttachment$.asObservable(); }

  private projectId: number | null = null;

  baseUrl = 'ProjectAttachments';
  isAttachmentsLoaded = false;

  constructor(private httpClient: HttpClient, projectsService: ProjectsService, private notificationService: NotificationService) {
    projectsService.selectedProject.subscribe(data => {
      if (data == null) {
        this.attachments$.next([]);
        this.projectId = null;
        return;
      }
      if (this.projectId !== data.Id) {
        this.getAttachments(data.Id);
      }
      this.projectId = data.Id;
    });
  }

  private getAttachments(projectId: number): Observable<ProjectAttachment[]> {
    const url = `${this.baseUrl}/${projectId}`;
    this.httpClient.get<ProjectAttachment[]>(url).subscribe(data => { this.attachments$.next(data); });
    return this.attachments$.asObservable();
  }


  selectAttachment(attachment: ProjectAttachment | null) {
    this.selectedAttachment$.next(attachment);
  }

  uploadFiles(projectId: number, files: any) {
    const formData: FormData = new FormData();

    if (files) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < files.length; i++) {
        // If dropped items aren't files, reject them
        const file = files[i];
        if (!file.type && file.size % 4096 == 0) {
          // its a folder.. skip it.
          console.error('folder');
          return;
        } else {
          formData.append('files', file, file.name);
        }
      }
    }

    const url = `${this.baseUrl}/${projectId}`
    return this.httpClient.post(url, formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders({ 'ngsw-bypass': 'true' }) })
      .pipe(catchError(err => this.handleError(err, "Failed to upload attachment.")),
        tap((event) => {
          if (event.type === HttpEventType.Response) {
            if (this.projectId) {
              this.getAttachments(this.projectId)
            }
          }
          return event;
        }));
  }

  deleteAttachment(attachmentId: number) {
    const url = `${this.baseUrl}/${attachmentId}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete attachment.")), tap(data => this.projectId && this.getAttachments(this.projectId)));
  }

  private handleError(err: any, message: string) {
    this.notificationService.showError(message, err);
    return throwError(err);
  }
}


export interface ProjectAttachment {
  Id: number,
  ProjectId: number,
  FileName: string,
  ContentType: string,
  DateCreated: Date,
}