import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-components/confirm-dialog/confirm-dialog.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { CustomerAttachment, CustomerAttachmentsService } from '../services/customer-attachments.service';
import { CustomersService, ICustomer } from '../services/customers.service';

@Component({
  selector: 'app-customer-attachments',
  templateUrl: './customer-attachments.component.html',
  styleUrls: ['./customer-attachments.component.scss']
})
export class CustomerAttachmentsComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;

  attachments: CustomerAttachment[] = [];
  selectedAttachment: CustomerAttachment | null = null;
  selectedCustomer: ICustomer | null = null;
  url$: Subject<string> = new Subject();
  isUploading = false;
  progress = 0;


  constructor(private attachmentsService: CustomerAttachmentsService, private customerService: CustomersService, private userSettings: UserSettingsService, private datePipe: DatePipe,
    public dialog: MatDialog, private dialogRef: MatDialogRef<CustomerAttachmentsComponent>) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => this.initGrid(data)));
    this.customerService.selectedCustomer.subscribe(data => {
      this.selectedCustomer = data;
      if (data !== null) {
        this.attachmentsService.attachments.subscribe(data => this.attachments = data);
      }
      else {
        this.attachments = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addAttachment(event: any) {
    this.uploadFiles(event.target.files);
  }

  drop(e: any): void {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.uploadFiles(files);
  }

  dragover(e: any): void {
    e.preventDefault();
  }

  uploadFiles(files: any) {
    if (this.selectedCustomer) {
      this.isUploading = true;
      this.progress = 0;
      this.attachmentsService.uploadFiles(this.selectedCustomer.Id, files)?.subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / Number(event.total));
        }
        else if (event.type === HttpEventType.Response) {
          this.progress = 0;
          this.isUploading = false;
        }
      });
    }
  }

  deleteAttachment() {
    if (!this.selectedAttachment) { return }
    const message = `Are you sure you want to delete ${this.selectedAttachment?.FileName} attachment?`;
    const dialogData = new ConfirmDialogModel("Confirm Delete", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.attachmentsService.deleteAttachment(this.selectedAttachment!.Id).subscribe();
        this.attachments = this.attachments.filter(a => a.Id !== this.selectedAttachment?.Id);
        this.selectedAttachment = null;
      }
    });
  }

  openAttachment() {
    window.open(`api/CustomerAttachments/${this.selectedAttachment?.Id}/${this.selectedAttachment?.FileName}`, "_blank")
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedAttachment = selectedRows[0] as CustomerAttachment;
      this.selectedAttachment = selectedAttachment;
      this.url$.next(`api/CustomerAttachments/${selectedAttachment?.Id}/${selectedAttachment?.FileName}`);

      this.attachmentsService.selectAttachment(selectedAttachment);
    }
    else {
      this.selectedAttachment = null;
      this.url$.next('');
      this.attachmentsService.selectAttachment(null);
    }
  }


  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: CustomerAttachment) => data.Id.toString();

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'FileName', headerName: 'File Name', flex: 1 },
      {
        field: 'DateSaved', headerName: 'Date Saved', width: 160, sort: 'desc',
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        filter: 'datesFilter', floatingFilterComponent: 'datesFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true },
      },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }
}
