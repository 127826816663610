<div style="width: 100%; height: 100%">

  <as-split #expensesSplitter direction="horizontal" (dragEnd)="layoutUpdated()">
    <as-split-area [size]="50">

      <div style="display: flex; margin: 2px;">
        <div style="margin-left: 5px">
          <input #addFile hidden type="file" multiple onclick="this.value=null" (change)="addAttachment($event)" />
          <button mat-icon-button color="accent" [disabled]="this.selectedContract == null"
                  (click)="addFile.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>

        <button mat-icon-button color="warn" [disabled]="this.selectedAttachment == null" class=""
                style="margin-left: 10px;" (click)="deleteAttachment()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 45px); margin-top: 2px;"
                       [gridOptions]="gridOptions" [ngClass]="agGridTheme" [rowData]="attachments"
                       [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)"
                       (selectionChanged)="onSelectionChanged()">
      </ag-grid-angular>
    </as-split-area>
    <as-split-area [size]="50">
      <app-secured-attachment *ngIf="selectedAttachment" [src]="selectedAttachmentUrl">
      </app-secured-attachment>
    </as-split-area>
  </as-split>
</div>
