<div style="display: grid; grid-template-columns: 150px 1fr; height: 100%">
    <mat-selection-list [multiple]="false" (selectionChange)="selectedTermChanged($event)" class="border"
        style="grid-column: 1;">
        <mat-list-option *ngFor="let term of terms, let i = index" [value]="term" [selected]="term == selectedTerms">
            {{term?.Name ?? 'Null Value'}}
        </mat-list-option>
    </mat-selection-list>
    <div style="grid-column: 2;">
        <form class="" [formGroup]="termsForm">
            <div>
                <button type="button" mat-icon-button matTooltip="Add New Proposal" color="accent"
                    class="mat-raised-button-sm" (click)="newProposalTerms()">
                    <mat-icon>add</mat-icon>
                </button>
                <button type="button" matTooltip="Save Changes" (click)="saveProposalTerms()" mat-icon-button
                    color="primary" class="mat-raised-button-sm">
                    <mat-icon>save</mat-icon>
                </button>
                <button type="button" matTooltip="Delete" mat-icon-button color="warn"
                    [disabled]="selectedTerms === null" class="mat-raised-button-sm" (click)="deleteProposalTerms()">
                    <mat-icon class="md-18">delete</mat-icon>
                </button>
            </div>

            <div class="edit-proposal-form" style="padding: 3px; margin: 3px; max-width: 672px;">
                <label>Name</label>
                <div>
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Name">
                        <mat-error>Name is required.</mat-error>
                    </mat-form-field>
                </div>

                <label>Terms</label>
                <div style="width: 100%;">
                    <mat-form-field appearance="fill" color="accent" style="width: 100%;">
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="30" formControlName="Terms" style="min-height: 88px;"
                            [style.width]="'100%'"></textarea>
                    </mat-form-field>
                </div>

                <label>Preview</label>
                <div [innerHtml]="Terms" class="border"
                    style="columns: 2; font-size: x-small; line-height: 1em; padding: 2px; min-height: 20px;">
                </div>
            </div>
        </form>
    </div>
</div>