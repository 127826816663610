import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  private attachmentsSubject: ReplaySubject<IAttachmentDto[]> = new ReplaySubject(1);
  public get attachments$(): Observable<IAttachmentDto[]> { return this.attachmentsSubject.asObservable() }

  baseUrl = 'Attachments';
  isLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  getAttachments(contractDocId: number): Observable<IAttachmentDto[]> {
    const url = `contracts/${contractDocId}/attachments`;
    this.httpClient.get<IAttachmentDto[]>(url).subscribe(data => { this.attachmentsSubject.next(data); });
    return this.attachmentsSubject.asObservable();
  }

  uploadFiles(docId: number, files: any) {
    const formData: FormData = new FormData();

    if (files) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < files.length; i++) {
        // If dropped items aren't files, reject them
        const file = files[i];
        if (!file.type && file.size % 4096 == 0) {
          // its a folder.. skip it.
          console.error('folder');
          return;
        } else {
          formData.append('files', file, file.name);
        }
      }
    }

    const url = `${this.baseUrl}/${docId}`
    return this.httpClient.post(url, formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders({ 'ngsw-bypass': 'true' }) })
      .pipe(catchError(err => this.handleError(err, "Failed to save attachment. ")),)
  }

  deleteAttachment(attachmentId: number) {
    const url = `${this.baseUrl}/${attachmentId}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete attachment. ")),);
  }

  private handleError(err: any, message: string) {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

}


export interface IAttachmentDto {
  Id: number;
  FileName: string;
}
