import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EmailSettings, IRep, RepsService } from '../reps.service';

@Component({
  selector: 'app-rep-email-settings',
  templateUrl: './rep-email-settings.component.html',
  styleUrls: ['./rep-email-settings.component.scss']
})
export class RepEmailSettingsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  selectedRep: IRep | null = null;
  hide = true;
  isTestingSettings = false;
  message = "";

  emailSettingsForm = this.fb.group({
    Server: ['', Validators.required],
    Port: ['', Validators.required],
    UserName: ['', Validators.required],
    Password: [null],
  });

  constructor(private repsService: RepsService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.subscriptions.add(this.repsService.selectedRep.subscribe(data => {
      this.selectedRep = data;
      if (data) {
        this.repsService.getEmailSettings(data.Id).subscribe(d => {
          d = d ?? { Server: '', Port: '', UserName: '', Password: null };
          this.emailSettingsForm.setValue(d);
          this.emailSettingsForm.markAsUntouched();
          this.message = "";
        });
      }
      else {
        this.resetForm();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resetForm() {
    this.emailSettingsForm = this.fb.group({
      Server: ['', Validators.required],
      Port: ['', Validators.required],
      UserName: ['', Validators.required],
      Password: [null],
    });
  }

  save() {
    if (!this.emailSettingsForm.valid) {
      this.emailSettingsForm.markAllAsTouched();
      return;
    }

    if (this.selectedRep == null) {
      return;
    }
    this.emailSettingsForm.markAsUntouched();
    this.emailSettingsForm.markAsPristine();
    const emailSettings = this.emailSettingsForm.value as EmailSettings;
    this.repsService.postEmailSettings(this.selectedRep.Id, emailSettings).subscribe(data => this.isTestingSettings && this.testSettings());
  }

  testSettings() {
    this.message = "Testing...."
    if (this.emailSettingsForm.dirty) {
      if (!this.emailSettingsForm.valid) {
        this.emailSettingsForm.markAllAsTouched();
        return;
      }
    }

    if (!this.selectedRep) { return; }

    if (this.emailSettingsForm.dirty) {
      this.isTestingSettings = true;
      this.save();
      return;
    }

    this.repsService.testEmailSettings(this.selectedRep.Id).subscribe(data => {
      this.message = "Success!"
    }, err => {
      console.error(err);
      this.message = "Error " + err.error;
    });
  }
}
