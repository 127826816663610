import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { isObservable, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-select-editor',
  templateUrl: './select-editor.component.html',
  styleUrls: ['./select-editor.component.scss']
})
export class SelectEditorComponent implements OnInit, AgEditorComponent, AfterViewInit, OnDestroy {

  params: ICellEditorParams | undefined;
  value: string | number | null = null;
  @ViewChild('select') matSelect: MatSelect | undefined;
  options: ISelectEditorOption[] = [];
  clearOption = true;
  subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getValue() {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }


  agInit(params: ISelectCellEditorParams): void {
    this.params = params;

    if (isNaN(params.value)) {
      this.value = params.value;
    }
    else {
      this.value = parseFloat(params.value);
    }

    this.clearOption = params.clear ?? true;

    let paramOptions = (params as any).options;
    if (!paramOptions) { console.error("Options is required."); return; }

    if (isObservable(paramOptions)) {
      this.subscriptions.add(paramOptions.subscribe((data: any) => this.options = data));
      return;
    }

    if (typeof paramOptions === 'function') {
      paramOptions = paramOptions();
    }

    if (!Array.isArray(paramOptions)) {
      console.error("Supplied options is not an array.");
      return;
    }

    const optionType = paramOptions[0];
    if (typeof optionType === 'string' || optionType instanceof String || typeof optionType === 'number') {
      this.options = paramOptions.map(o => ({ Value: o, Text: o } as ISelectEditorOption));
    }
    else {
      this.options = paramOptions;
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.matSelect?.open();
      this.matSelect?.focus();
    });
  }

  selectionChanged(state: boolean) {
    if (!state) {
      this.params?.api?.stopEditing();
    }
  }
}

export interface ISelectEditorOption {
  Value: string | number;
  Text: string;
}

export interface ISelectCellEditorParams extends ICellEditorParams {
  options: ISelectEditorOption[] | number[] | string[] | Observable<any>;
  clear: boolean | undefined;
}