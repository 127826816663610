<div *ngIf="selectedProject === null" style="margin: 5px; padding: 5px;">
  <h3> Select a project.</h3>
</div>
<div (dragover)="dragover($event)" (drop)="fileDrop($event)">
  <form class="" [formGroup]="proposalForm" [hidden]="selectedProject === null">
    <div *ngIf="showTemplateSelector">
      <label>Template</label>
      <mat-form-field appearance="fill" class="mat-select-sm" style="width: 120px; margin-left: 5px;">
        <mat-select [(value)]="selectedTemplate" (selectionChange)="selectedTemplateChanged($event)">
          <mat-option *ngFor="let template of templates" [value]="template">
            {{template.Description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!showTemplateSelector">
      <button type="button" mat-icon-button matTooltip="Add New Proposal" [disabled]="selectedProject === null"
        color="accent" class="mat-raised-button-sm" (click)="newProposal()">
        <mat-icon>add</mat-icon>
      </button>

      <button type="button" matTooltip="Save Changes" (click)="saveProposal()" mat-icon-button color="primary"
        class="mat-raised-button-sm">
        <mat-icon>save</mat-icon>
      </button>
      <button type="button" matTooltip="Delete" mat-icon-button color="warn" [disabled]="selectedProposal === null"
        class="mat-raised-button-sm" (click)="deleteProposal()">
        <mat-icon class="md-18">delete</mat-icon>
      </button>
      <button type="button" matTooltip="Print" mat-icon-button class="mat-raised-button-sm" (click)="print()">
        <mat-icon class="md-18">print</mat-icon>
      </button>
      <button type="button" matTooltip="Email" mat-icon-button class="mat-raised-button-sm" (click)="email()">
        <mat-icon class="md-18">send</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Clone" [disabled]="selectedProposal === null" color="accent"
        class="mat-raised-button-sm" (click)="clone()">
        <mat-icon>copy_all</mat-icon>
      </button>
    </div>

    <div class="edit-proposal-form" style="padding: 3px; margin: 3px; max-width: 672px;">
      <div style="display: flex;">
        <div>
          <mat-form-field appearance="outline" floatLabel="always" color="accent">
            <mat-label>Proposal Number</mat-label>
            <input matInput formControlName="ProposalNumber">
          </mat-form-field>
        </div>

        <div style="margin-left: 5px;">
          <mat-form-field class="mat-datepicker-sm" appearance="outline" floatLabel="always" color="accent">
            <mat-label>Bid Date</mat-label>
            <input matInput formControlName="BidDate" [matDatepicker]="bidDatePicker">
            <mat-datepicker-toggle matSuffix [for]="bidDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #bidDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div style="margin-left: 5px;">
          <mat-form-field appearance="outline" floatLabel="always" color="accent">
            <mat-label>Days Valid</mat-label>
            <input matInput formControlName="DaysValid">
          </mat-form-field>
        </div>

        <div style="margin-left: 5px; min-width: 140px;">
          <mat-form-field appearance="outline" floatLabel="always" color="accent">
            <mat-label>Rep</mat-label>
            <mat-select formControlName="RepId" required>
              <mat-option *ngFor="let rep of reps" [value]="rep.Id">
                {{ rep.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="border" style="display: flex; width: 100%; margin-top: 5px;">
        <div style=" margin-top: 3px; width: 130px;">
          <div style="margin: 2px; margin-left: 10px; margin-bottom: 8px; font-size: large;">Payments</div>
          <mat-form-field appearance="outline" floatLabel="always" color="accent" w>
            <mat-label>Number of Payments</mat-label>
            <mat-select formControlName="NumberOfPayments" (selectionChange)="numberOfPaymentsSelected($event)"
              required>
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" color="accent">
            <mat-label>Amount</mat-label>
            <input matInput matInputCurrency formControlName="Amount" (change)="amountChanged($event)">
          </mat-form-field>
        </div>
        <div style="margin: 4px; width: 100%;">
          <!-- <div>Payments</div> -->
          <table class="payment-table">
            <thead>
              <tr>
                <th style="visibility: hidden;">''</th>
                <th> Amount</th>
                <th>Condition</th>
              </tr>
            </thead>
            <tbody cdkDropList>
              <ng-container formArrayName="Payments">
                <tr *ngFor="let lineForm of Payments.controls; let i = index" cdkDragLockAxis="y" cdkDrag
                  [formGroupName]="i">
                  <td style="width: 25px;">
                    <mat-icon class="drag-handle" cdkDragHandle>drag_handle</mat-icon>
                  </td>
                  <td style=" width:20%">
                    <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                      <input matInput matInputCurrency formControlName="Amount" (change)="paymentAmountChanged(i)">
                    </mat-form-field>
                  </td>
                  <td style="width: 80%; min-width: 210px;">
                    <!-- <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                        <input matInput formControlName="Condition">
                    </mat-form-field> -->
                    <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                      <input matInput tab-directive formControlName="Condition"
                        [matAutocomplete]="paymentConditionAutoComplete"
                        (keyup)="filterPaymentConditionOptions($event)">
                      <mat-autocomplete #paymentConditionAutoComplete="matAutocomplete" class="example-viewport">
                        <mat-option *ngFor="let option of filteredConditionOptions" [value]="option.Value"
                          class="example-item-detail">
                          {{option.Text}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div style="margin-top: 10px;">Lines</div>
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <ng-container formArrayName="Lines">
          <div *ngFor="let lineForm of Lines.controls; let i = index">
            <div cdkDragLockAxis="y" cdkDrag style="display: flex;" [formGroupName]="i">
              <mat-icon class="drag-handle" cdkDragHandle>drag_handle</mat-icon>
              <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                <input #textInput matInput formControlName="Text" (keydown)="onKeyDownEvent(i,$event)"
                  (focus)="onLineFocus(i,$event)">
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div>
        <div style="margin-top: 10px; display: flex; flex-direction: row;">
          <label (click)="selectTerms.open()">Terms</label>
          <mat-form-field class="mat-select-sm"
            style="width: 0px; min-width: 0px !important; height: 0px !important; margin-left: 3px;;">
            <!-- <mat-label>Select Terms</mat-label> -->
            <mat-select #selectTerms (selectionChange)="selectedTermsChanged($event)">
              <mat-option *ngFor="let item of terms" [value]="item">
                {{ item.Name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [innerHtml]="Terms" class="border" style="columns: 2; font-size: x-small; line-height: 1em; padding: 2px;">
        </div>
      </div>
    </div>
  </form>
</div>
