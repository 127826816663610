import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/api-authorization/authentication.service';
import { EmailTemplate, IRep, RepsService } from 'src/app/reps/reps.service';
import { StringExt } from 'src/app/utils/string';
import { CustomersService, ICustomer } from '../services/customers.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { IProposalTemplate } from '../services/proposal-templates.service';
import { IProposal, ProposalsService } from '../services/proposals.service';

@Component({
  selector: 'app-proposal-email-dialog',
  templateUrl: './proposal-email-dialog.component.html',
  styleUrls: ['./proposal-email-dialog.component.scss']
})
export class ProposalEmailDialogComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  template: EmailTemplate | null = null;
  proposal: IProposal | null = null;
  project: IProject | null = null;
  customer: ICustomer | null = null;
  message: string = "";
  sending = false;

  pdfLink: string = "";

  emailTemplateForm = this.fb.group({
    To: ['', [Validators.required]],
    CC: ['',],
    Subject: ['', Validators.required],
    Body: ['', Validators.required],
  });



  constructor(@Inject(MAT_DIALOG_DATA) public data: { proposal: IProposal, project: IProject }, private dialogRef: MatDialogRef<ProposalEmailDialogComponent>, private authenticationService: AuthenticationService,
    private repsService: RepsService, private proposalService: ProposalsService, private customerService: CustomersService, private projectService: ProjectsService, private fb: FormBuilder) {
    this.proposal = data.proposal;
    this.project = data.project;
  }

  ngOnInit(): void {
    if (this.proposal === null) { return; }

    this.pdfLink = this.proposalService.getProposalPdfURL(this.proposal);

    const template$ = this.repsService.getEmailTemplate(this.authenticationService.userValue?.RepId ?? 0)
    const customer$ = this.customerService.selectedCustomer;

    this.subscriptions.add(combineLatest(template$, customer$).subscribe(([template, customer]) => {
      this.template = template;
      this.customer = customer;

      const subject = this.replacePlaceHolders(this.template.Subject);
      const body = this.replacePlaceHolders(this.template.Body);
      let customerEmail = customer?.Email ?? '';
      if (!StringExt.isNullOrWhiteSpace(customer?.Email ?? '')) {
        customerEmail = `${customer?.Name}<${customer?.Email}>`;
      }

      const email = {
        To: customerEmail,
        CC: '',
        Subject: subject,
        Body: body
      }
      this.emailTemplateForm.setValue(email);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  replacePlaceHolders(text: string) {
    let pattern = new RegExp("<customer>", 'gi');
    text = text.replace(pattern, this.customer?.Name ?? "Customer");

    pattern = new RegExp("<proposalNumber>", 'gi');
    text = text.replace(pattern, this.proposal?.ProposalNumber ?? "Proposal Number");

    pattern = new RegExp("<amount>", 'gi');
    text = text.replace(pattern, (this.proposal?.Amount ?? 0).toString());


    pattern = new RegExp("<daysValid>", 'gi');
    text = text.replace(pattern, (this.proposal?.DaysValid ?? 0).toString());

    pattern = new RegExp("<projectDescription>", 'gi');
    text = text.replace(pattern, (this.project?.Description ?? ""));

    return text;
  }

  resetForm() {
    this.emailTemplateForm = this.fb.group({
      Subject: [''],
      Body: [''],
    });
  }

  send() {
    if (this.emailTemplateForm.valid) {
      if (this.proposal == null) { return; }
      this.sending = true;
      this.proposalService.emailProposal(this.proposal?.Id, this.emailTemplateForm.value)
        .subscribe(data => { this.dialogRef.close(); this.sending = false; }, err => {
          console.error(err);
          this.message = "Error " + err.error;
          this.sending = false;
        });
    }
    else {
      this.emailTemplateForm.markAllAsTouched();
    }
  }
}
