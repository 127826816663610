<div mat-dialog-content style="width: 100%; height: 100%; position: relative;">
    <div style="display: flex; flex-direction: row; justify-content: end; width: 100%;">
        <button type="button" matTooltip="Print" mat-icon-button (click)="printDocument()" style="margin-right: 15px;">
            <mat-icon class="md-18">print</mat-icon>
        </button>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>

    <mat-spinner *ngIf="loading" [diameter]="50" color="accent" class="center">Loading...</mat-spinner>


    <!-- <embed type="application/pdf" [src]="url" id="pdfProposalDocument" width="100%" height="100%" /> -->
    <iframe id="pdfProposalDocument" [src]="dataUrl$ | async" (load)="pdfLoaded()">
        This browser does not support PDFs. Please download the PDF to view it:
        <a [href]="dataUrl$ | async">Download PDF</a></iframe>
</div>