import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { StringExt } from '../utils/string'

@Injectable({
  providedIn: 'root'
})
export class ScheduleItemsService {
  private scheduleItems$: ReplaySubject<IScheduleItem[]> = new ReplaySubject(1);
  public get scheduleItems(): Observable<IScheduleItem[]> {
    if (!this.isScheduleItemsLoaded) {
      this.loadScheduleItems();
    }
    return this.scheduleItems$.asObservable()
  }

  private filteredScheduleItems$: ReplaySubject<IScheduleItem[]> = new ReplaySubject(1);
  public get filteredScheduleItems(): Observable<IScheduleItem[]> {
    if (!this.isScheduleItemsLoaded) {
      this.loadScheduleItems();
    }
    return this.filteredScheduleItems$.asObservable()
  }

  baseUrl = 'SubcontractorSchedules';
  isScheduleItemsLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) { }

  loadScheduleItems(): void {
    const url = `${this.baseUrl}`;
    this.httpClient.get<IScheduleItem[]>(url).subscribe(data => {
      this.filteredScheduleItems$.next(data);
      this.scheduleItems$.next(data);
      this.isScheduleItemsLoaded = true;
    });
  }

  saveScheduleItem(scheduleItem: IScheduleItem) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<IScheduleItem>(url, scheduleItem).pipe(catchError(err => this.handleError(err, "Failed to save schedule.")),);
  }

  deleteScheduleItem(scheduleItem: IScheduleItem) {
    const url = `${this.baseUrl}/${scheduleItem.ID}`;
    return this.httpClient.delete<IScheduleItem>(url).pipe(catchError(err => this.handleError(err, "Failed to delete schedule.")),);
  }

  updateScheduleItems(updatedScheduleItems: IScheduleItem[]) {
    this.scheduleItems.pipe(take(1)).subscribe(data => {
      for (const item of updatedScheduleItems) {
        const index = data.findIndex(m => m.ID === item.ID);
        if (index === -1) {
          data.push(item);
        }
        else {
          if (StringExt.isNullOrWhiteSpace(item.ContractID)) {
            data.splice(index, 1);
          } else {
            data[index] = item;
          }
        }
      }
      this.scheduleItems$.next(data);
    });


    this.filteredScheduleItems$.pipe(take(1)).subscribe(data => {
      for (const item of updatedScheduleItems) {
        const index = data.findIndex(m => m.ID === item.ID);
        if (index === -1) {
          if (item.StartTime != null && item.EndTime !== null) {
            data.push(item);
          }
        }
        else {
          if (StringExt.isNullOrWhiteSpace(item.ContractID) || item.StartTime == null || item.EndTime == null) {
            data.splice(index, 1);
          } else {
            data[index] = item;
          }
        }
      }
      this.filteredScheduleItems$.next(data);
    });
  }


  getAttachments(scheduleItemId: number) {
    const url = `${this.baseUrl}/${scheduleItemId}/attachments`;
    return this.httpClient.get<IScheduleItemAttachment[]>(url);
  }

  saveAttachments(scheduleItemId: number, files: any) {
    const url = `${this.baseUrl}/${scheduleItemId}/attachments`;
    const formData: FormData = new FormData();
    if (files) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < files.length; i++) {
        // If dropped items aren't files, reject them
        const file = files[i];
        if (!file.type && file.size % 4096 == 0) {
          // its a folder.. skip it.
          console.error('folder');
          return;
        } else {
          formData.append('files', file, file.name);
        }
      }
    }
    return this.httpClient.post(url, formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders({ 'ngsw-bypass': 'true' }) })
      .pipe(catchError(err => this.handleError(err, "Failed to save attachment.")),)
  }

  deleteAttachment(scheduleItemId: number, attachmentId: number) {
    const url = `${this.baseUrl}/${scheduleItemId}/attachments/${attachmentId}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete attachment.")),);
  }

  private handleError(err: any, message: string) {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

}

export interface IScheduleItem {
  ID: number;
  ContractID: string;
  JobName: string;
  Trade: string;
  Contractor: string;
  Status: string;
  StartTime: Date | null
  EndTime: Date | null
  Notes: string;
  Attachments: IScheduleItemAttachment[];
  expanded: undefined | boolean;
}

export interface IScheduleItemAttachment {
  Id: number;
  SubSchedulerId: number,
  FileName: string;
  DateSaved: Date;
  ContentType: string;
}
