export class NumberExt {
    public static numberWithCommas(x: number, decimalPlaces = 2) {
        return x.toLocaleString('en-US', { minimumFractionDigits: decimalPlaces });
    }

    public static stringToNumber(value: string): number {
        return Number(value.replace(/[^\d.-]/g, ''));
        // return isNaN(v) ? 0 : v;
    }
}
