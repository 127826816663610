import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, first, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { CustomersService } from './customers.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerAttachmentsService {

  private attachments$: BehaviorSubject<CustomerAttachment[]> = new BehaviorSubject<CustomerAttachment[]>([]);
  public get attachments(): Observable<CustomerAttachment[]> { return this.attachments$.asObservable(); }

  private selectedAttachment$: BehaviorSubject<CustomerAttachment | null> = new BehaviorSubject<CustomerAttachment | null>(null);
  public get selectedAttachment(): Observable<CustomerAttachment | null> { return this.selectedAttachment$.asObservable(); }

  private customerId: number | null = null;

  baseUrl = 'CustomerAttachments';
  isAttachmentsLoaded = false;

  constructor(private httpClient: HttpClient, customerService: CustomersService, private notificationService: NotificationService) {
    customerService.selectedCustomer.subscribe(data => {
      if (data == null) {
        this.attachments$.next([]);
        this.customerId = null;
        return;
      }
      if (this.customerId !== data.Id) {
        this.getAttachments(data.Id);
      }
      this.customerId = data.Id;
    });
  }

  private getAttachments(customerId: number): Observable<CustomerAttachment[]> {
    const url = `${this.baseUrl}/${customerId}`;
    this.httpClient.get<CustomerAttachment[]>(url).subscribe(data => { this.attachments$.next(data); });
    return this.attachments$.asObservable();
  }


  selectAttachment(attachment: CustomerAttachment | null) {
    this.selectedAttachment$.next(attachment);
  }

  uploadFiles(customerId: number, files: any) {
    const formData: FormData = new FormData();

    if (files) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < files.length; i++) {
        // If dropped items aren't files, reject them
        const file = files[i];
        if (!file.type && file.size % 4096 == 0) {
          // its a folder.. skip it.
          console.error('folder');
          return;
        } else {
          formData.append('files', file, file.name);
        }
      }
    }

    const url = `${this.baseUrl}/${customerId}`
    return this.httpClient.post(url, formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders({ 'ngsw-bypass': 'true' }) })
      .pipe(catchError(err => this.handleError(err, "Failed to upload attachment.")), tap((event) => {
        if (event.type === HttpEventType.Response) {
          this.customerId && this.getAttachments(this.customerId);
        }
        return event;
      }));
  }

  deleteAttachment(attachmentId: number) {
    const url = `${this.baseUrl}/${attachmentId}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete attachment.")), tap(data => this.customerId && this.getAttachments(this.customerId)));
  }

  private handleError(err: any, message: string) {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

}


export interface CustomerAttachment {
  Id: number,
  CustomerId: number,
  FileName: string,
  ContentType: string,
  DateCreated: Date,
}
