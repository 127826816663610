import { Icu } from '@angular/compiler/src/i18n/i18n_ast';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { StringExt } from '../utils/string';
import { CustomersService, ICustomer } from './services/customers.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerMatchService {

  private customers: ICustomer[] = [];
  private filterCustomers: ICustomerFilter[] = [];

  constructor(customersService: CustomersService) {
    customersService.customers.subscribe(data => {
      this.customers = data;
      const filterCustomers = JSON.parse(JSON.stringify(data)) as ICustomerFilter[];

      for (const customer of filterCustomers) {
        customer.FilterName = customer.Name.toLowerCase();
        customer.FilterPhone1 = this.getNumbersOnlyFromPhoneNumber(customer.Phone1);
        customer.FilterPhone2 = this.getNumbersOnlyFromPhoneNumber(customer.Phone2);
        customer.FilterPhone3 = this.getNumbersOnlyFromPhoneNumber(customer.Phone3);
      }
      this.filterCustomers = filterCustomers;
    });
  }

  private customerMatch$: BehaviorSubject<ICustomer[]> = new BehaviorSubject<ICustomer[]>([]);
  public get customerMatch(): Observable<ICustomer[]> { return this.customerMatch$.asObservable(); }

  filterCustomerIfMatch(customer: ICustomer | null) {
    if (customer === null) {
      this.customerMatch$.next([]);
      return;
    }

    const lstPhone: string[] = [];
    const matchNames = this.checkIfCustomerNameExist(customer.Name).map(c => c.Id);

    const phone1 = this.getNumbersOnlyFromPhoneNumber(customer.Phone1);
    if (phone1.length > 0) { lstPhone.push(phone1); }

    const phone2 = this.getNumbersOnlyFromPhoneNumber(customer.Phone2);
    if (phone2.length > 0) { lstPhone.push(phone2); }

    const phone3 = this.getNumbersOnlyFromPhoneNumber(customer.Phone3);
    if (phone3.length > 0) { lstPhone.push(phone3); }

    let customersWithMatchNumbers: number[] = []
    if (lstPhone.length > 0) {
      customersWithMatchNumbers = this.filterCustomers.filter(c => lstPhone.includes(c.FilterPhone1) || lstPhone.includes(c.FilterPhone2) || lstPhone.includes(c.FilterPhone3)).map(c => c.Id);
    }

    const customerIds = [...matchNames, ...customersWithMatchNumbers];
    if (customerIds.length > 0) {
      const mc = this.customers.filter(c => customerIds.includes(c.Id));
      this.customerMatch$.next(mc);
    }
    else {
      this.customerMatch$.next([]);
    }
  }

  checkIfCustomerNameExist(customerName: string) {
    if (StringExt.isNullOrWhiteSpace(customerName)) { this.customerMatch$.next([]); return []; }
    customerName = customerName.toLowerCase();
    const matchingCustomers = this.filterCustomers.filter(c => c.FilterName === customerName).map(c => c.Id);
    const mc = this.customers.filter(c => matchingCustomers.includes(c.Id));
    return mc;
  }

  checkIfPhoneNumberExists(phone: string) {
    if (StringExt.isNullOrWhiteSpace(phone)) { this.customerMatch$.next([]); return [] }
    const phoneNumber = this.getNumbersOnlyFromPhoneNumber(phone);
    const customersWithMatchNumbers = this.filterCustomers.filter(c => c.FilterPhone1 === phoneNumber || c.FilterPhone2 === phoneNumber || c.FilterPhone3 === phoneNumber).map(c => c.Id);
    const mc = this.customers.filter(c => customersWithMatchNumbers.includes(c.Id));
    return mc;
  }

  getNumbersOnlyFromPhoneNumber(phone: string): string {
    if (StringExt.isNullOrWhiteSpace(phone)) { return phone; }
    //return phone.trim().replace("[()\\s-]+", "");
    //const p = phone.trim().replace("/\d/g", "");
    const p = phone.trim().replace(/[- )(]/g, '');
    return p
  }
}

export interface ICustomerFilter extends ICustomer {
  FilterName: string;
  FilterPhone1: string;
  FilterPhone2: string;
  FilterPhone3: string;
}

