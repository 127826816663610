import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { ColDef, ColumnApi, GetRowIdParams, GridApi, GridOptions, RowDragEndEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-components/confirm-dialog/confirm-dialog.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { AppSettingsService, ISetting, IStatus, Settings } from '../app-settings.service';

@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.scss']
})
export class StatusesComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  settings: ISetting[] = [];
  statusList: IStatus[] = [];
  selectedStatus: IStatus | null = null;
  selectedStatusType: Settings | null = null;
  isAddingNew = false;

  statusTypes: any[] = [
    { Value: Settings.ContractStatus, Text: "Contract Status" },
    { Value: Settings.ScheduleStatus, Text: "Schedule Status" },
    { Value: Settings.PermitStatus, Text: "Permit Status" }]

  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: ColDef[] = [];

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  form = this.fb.group({
    Name: ['', Validators.required],
    Idx: [0],
    FontColor: ['Initial'],
    BackgroundColor: ['Initial'],
    FontWeight: ['Initial'],
  });

  constructor(private settingsService: AppSettingsService, private fb: FormBuilder, private userSettings: UserSettingsService, private dialog: MatDialog) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.settingsService.settings.subscribe(data => {
      this.settings = data;
      this.loadStatusList();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadStatusList() {
    this.form.reset();
    if (this.selectedStatusType == null) { return; }
    this.statusList = this.settings.find(x => x.Key === this.selectedStatusType)?.Data as IStatus[] ?? [];
    this.gridApi?.setRowData(this.statusList);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedStatus = selectedRows[0] as IStatus;
      this.resetForm();
      this.selectedStatus = { ...selectedStatus };
      this.form.setValue(this.selectedStatus);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.animateRows = true;
    this.gridOptions.onRowDragEnd = (event: RowDragEndEvent) => {
      if (this.selectedStatusType === null) { return; }
      const rowData: any = [];
      this.gridApi?.forEachNode(node => rowData.push(node.data));

      for (let i = 0; i < rowData.length; i++) {
        rowData[i].Idx = i;
      }

      const setting = this.settings.find(x => x.Key === this.selectedStatusType);
      if (setting !== undefined) {
        setting.Data = rowData;
        this.settingsService.saveSettings(this.selectedStatusType, setting).subscribe();
      }
    }

    this.defaultColDef = {
      filter: undefined,
      resizable: true,
      sortable: false,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: false,
    };

    this.cols = [
      {
        field: 'Name', headerName: 'Status', flex: 1, rowDrag: true,
        cellStyle: (params: any) => {
          const status = params.data;
          const cellStyle = status !== undefined ? { color: status.FontColor, backgroundColor: status.BackgroundColor, fontWeight: status.FontWeight } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }

  resetForm() {
    this.selectedStatus = null;
    this.form = this.fb.group({
      Name: ['', Validators.required],
      Idx: [0],
      FontColor: ['Initial'],
      BackgroundColor: ['Initial'],
      FontWeight: ['Initial'],
    });
  }

  newStatus() {
    this.selectedStatus = null;
    this.isAddingNew = true;
    this.resetForm();
    this.gridApi?.deselectAll();
  }

  save() {
    if (!this.form.valid || this.selectedStatusType === null) {
      this.form.markAllAsTouched();
      return;
    }

    let setting = this.settings.find(x => x.Key === this.selectedStatusType);
    if (setting == undefined) {
      setting = { Id: 0, Key: this.selectedStatusType, Data: [] }
    }

    if (!setting.Data) {
      setting.Data = [];
    }

    const status = this.form.value as IStatus;
    let statusIndex = (setting.Data as IStatus[]).findIndex(x => x.Name === this.selectedStatus?.Name);
    if (statusIndex === -1) {
      setting.Data.push(status);
    }
    else {
      setting.Data[statusIndex] = status;
    }

    this.settingsService.saveSettings(this.selectedStatusType, setting).subscribe(data => {
      setTimeout(() => {
        this.gridApi?.forEachNode(node => node.data.Name === status?.Name ? node.setSelected(true, true) : null);
      }, 10);
    });

    this.resetForm();
  }

  deleteStatus() {
    if (this.selectedStatus === null) { return; }

    const message = `Are you sure you want to delete status: '${this.selectedStatus.Name}' ?`;
    const dialogData = new ConfirmDialogModel("Confirm Delete", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (this.selectedStatus !== null) {
          if (this.selectedStatusType === null) { return; }
          let setting = this.settings.find(x => x.Key === this.selectedStatusType);
          if (setting === undefined) { return; }

          let statusIndex = (setting.Data as IStatus[]).findIndex(x => x.Name === this.selectedStatus?.Name);
          if (statusIndex === -1) {
            return;
          }
          else {
            setting.Data[statusIndex] = status;
            setting.Data.splice(statusIndex);
          }
          this.settingsService.saveSettings(this.selectedStatusType, setting).subscribe();
          this.resetForm();
        }
      }
    });
  }


  selectedStatusTypeChanged(event: MatSelectionListChange) {
    const options = event.options;
    const status = options[0].value as Settings;
    this.selectedStatusType = status;
    this.selectedStatus = null;
    this.loadStatusList();
  }

}
