import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { combineLatest, Subscription } from 'rxjs';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { IProposalTemplate, ProposalTemplatesService } from '../services/proposal-templates.service';

@Component({
  selector: 'app-proposal-templates',
  templateUrl: './proposal-templates.component.html',
  styleUrls: ['./proposal-templates.component.scss']
})
export class ProposalTemplatesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  templates: IProposalTemplate[] = [];
  reps: IRep[] = [];
  selectedTemplate: IProposalTemplate | null = null;
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private templatesService: ProposalTemplatesService, private userSettings: UserSettingsService, private repsService: RepsService) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.repsService.reps.subscribe(data => this.reps = data));

    const templates$ = this.templatesService.templates;
    const reps$ = this.repsService.reps;
    this.subscriptions.add(combineLatest(templates$, reps$).subscribe(([templates, reps]) => {
      this.templates = templates;
    }));

    this.subscriptions.add(this.templatesService.selectedTemplate.subscribe(data => {
      if (data === null && this.selectedTemplate !== null) {
        this.gridApi?.deselectAll();
      } else {
        if (this.selectedTemplate?.Id !== data?.Id) {
          this.gridApi?.forEachNodeAfterFilter(node => {
            if (node.data.Id === data?.Id) {
              node.setSelected(true);
              this.gridApi?.ensureIndexVisible(node.rowIndex, 'middle');
            }
          });
        }
      }
      this.selectedTemplate = data;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedSnippet = selectedRows[0] as IProposalTemplate;
      this.templatesService.selectTemplate(selectedSnippet);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: IProposalTemplate) => data.Id.toString();

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'Description', headerName: 'Description', flex: 3 },
      {
        field: 'RepId', headerName: 'Rep', flex: 1, valueFormatter: (params: any) => {
          return this.reps.find(x => x.Id == params.value)?.Name ?? '';
        },
      },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }
}
