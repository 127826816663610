import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { GoogleMapsModule } from '@angular/google-maps';

import { AgGridModule } from 'ag-grid-angular';
import { ContractsListComponent } from './contracts/contracts-list/contracts-list.component';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { AuthorizeInterceptor } from './api-authorization/authorize.interceptor';
import { AngularSplitModule } from 'angular-split';
import { YearFloatingFilterComponent } from './ag-grid-components/year-floating-filter/year-floating-filter.component';
import { MonthFloatingFilterComponent } from './ag-grid-components/month-floating-filter/month-floating-filter.component';
import { RemainingFilterComponent } from './ag-grid-components/remaining-filter/remaining-filter.component';
import { RemainingFloatingFilterComponent } from './ag-grid-components/remaining-floating-filter/remaining-floating-filter.component';
import { ContractsComponent } from './contracts/contracts/contracts.component';
import { ExpensesComponent } from './contracts/expenses/expenses.component';
import { LaborComponent } from './contracts/labor/labor.component';
import { OrdersComponent } from './contracts/orders/orders.component';
import { LoginComponent } from './api-authorization/login/login.component';
import { AuthenticationService } from './api-authorization/authentication.service';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenave-list/sidenav-list.component';
import { LogoutComponent } from './api-authorization/logout/logout.component';
import { UserSettingsComponent } from './user-settings/user-settings/user-settings.component';
import { YearFilterComponent } from './ag-grid-components/year-floating-filter/year-filter.component';
import { MonthFilterComponent } from './ag-grid-components/month-floating-filter/month-filter.component';
import { SavedLayoutsComponent } from './saved-layouts/saved-layouts/saved-layouts.component';
import { TotalPinnedRowRendererComponent } from './ag-grid-components/total-pinned-row-renderer/total-pinned-row-renderer.component';
import { SaveLayoutDialogComponent } from './saved-layouts/save-layout-dialog/save-layout-dialog.component';
import { ConfirmDialogComponent } from './shared-components/confirm-dialog/confirm-dialog.component';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './navigation/route-reuse.service';
import { SelectEditorComponent } from './ag-grid-components/select-editor/select-editor.component';
import { CustomDatePickerComponent } from './ag-grid-components/custom-date-picker/custom-date-picker.component';
import { DatePickerEditorComponent } from './ag-grid-components/date-picker-editor/date-picker-editor.component';
import { NumberEditorComponent } from './ag-grid-components/number-editor/number-editor.component';
import { SelectFloatingFilterComponent } from './ag-grid-components/select-floating-filter/select-floating-filter.component';
import { JobSelectEditorComponent } from './ag-grid-components/job-select-editor/job-select-editor.component';
import { ErrorHandlerService } from './services/error-handler.service';
import { ThemePickerComponent } from './theme-picker/theme-picker.component';
import { AttachmentsComponent } from './contracts/attachments/attachments.component';
import { SecuredAttachmentComponent } from './shared-components/secured-attachment/secured-attachment.component';
import { ActionButtonComponent } from './ag-grid-components/action-button/action-button.component';
import { TextareaEditorComponent } from './ag-grid-components/textarea-editor/textarea-editor.component';
import { SchedulingListComponent } from './contracts/scheduling-list/scheduling-list.component';
import { AutocompleteFloatingFilterComponent } from './ag-grid-components/autocomplete-floating-filter/autocomplete-floating-filter.component';
// import { SchedulerComponent } from './scheduler/scheduler.component';
// import { SyncfusionModule } from './syncfusion/syncfusion.module';
import { EditScheduleComponent } from './scheduler/edit-schedule/edit-schedule.component';
import { ScheduleStatusFloatingFilterComponent } from './ag-grid-components/schedule-status-floating-filter/schedule-status-floating-filter.component';
import { ScheduleStatusFilterComponent } from './ag-grid-components/schedule-status-floating-filter/schedule-status-filter/schedule-status-filter.component';
import { ScheduleAttachmentDetailsComponent } from './contracts/scheduling-list/schedule-attachment-details/schedule-attachment-details.component';
import { CustomerListComponent } from './projects/customer-list/customer-list.component';
import { ProjectNavigationComponent } from './projects/project-navigation/project-navigation.component';
import { CustomerEditComponent } from './projects/customer-edit/customer-edit.component';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { ProjectCustomerComponent } from './projects/project-customer/project-customer.component';
import { ProposalCustomerComponent } from './projects/proposal-customer/proposal-customer.component';
import { ProposalEditComponent } from './projects/proposal-edit/proposal-edit.component';
import { ProjectEditComponent } from './projects/project-edit/project-edit.component';
import { ProposalListComponent } from './projects/proposal-list/proposal-list.component';
import { DatesFloatingFilterComponent } from './ag-grid-components/dates-floating-filter/dates-floating-filter.component';
import { DatesFilterComponent } from './ag-grid-components/dates-floating-filter/dates-filter.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TabDirective } from './custom-directives/tab.directive';
import { MatInputCurrencyDirective } from './custom-directives/mat-input-currency.directive';
import { SnippetsComponent } from './snippets/snippets/snippets.component';
import { SnippetEditComponent } from './snippets/snippet-edit/snippet-edit.component';
import { SettingsNavigationComponent } from './settings/settings-navigation/settings-navigation.component';
import { ProposalTemplatesComponent } from './projects/proposal-templates/proposal-templates.component';
import { ProposalTemplateEditComponent } from './projects/proposal-template-edit/proposal-template-edit.component';
import { ProposalTermsComponent } from './projects/proposal-terms/proposal-terms.component';
import { RepsComponent } from './reps/reps/reps.component';
import { PrintProposalDialogComponent } from './projects/print-proposal-dialog/print-proposal-dialog.component';
import { CustomerAttachmentsComponent } from './projects/customer-attachments/customer-attachments.component';
import { ProjectAttachmentsComponent } from './projects/project-attachments/project-attachments.component';
import { ChangePasswordComponent } from './reps/change-password/change-password.component';
import { RepEditComponent } from './reps/rep-edit/rep-edit.component';
import { RepEmailSettingsComponent } from './reps/rep-email-settings/rep-email-settings.component';
import { RepEmailTemplateComponent } from './reps/rep-email-template/rep-email-template.component';
import { ProposalEmailDialogComponent } from './projects/proposal-email-dialog/proposal-email-dialog.component';
import { CrewsComponent } from './settings/crews/crews/crews.component';
import { SubcontractorsComponent } from './settings/subcontractors/subcontractors/subcontractors.component';
import { TradesComponent } from './settings/trades/trades.component';
import { StatusesComponent } from './settings/statuses/statuses.component';
import { ContractStatusFloatingFilterComponent } from './ag-grid-components/contract-status-floating-filter/contract-status-floating-filter.component';
import { ContractStatusFilterComponent } from './ag-grid-components/contract-status-floating-filter/contract-status-filter/contract-status-filter.component';
import { PermitStatusFloatingFilterComponent } from './ag-grid-components/permit-status-floating-filter/permit-status-floating-filter.component';
import { PermitStatusFilterComponent } from './ag-grid-components/permit-status-floating-filter/permit-status-filter/permit-status-filter.component';
import { ProjectMapComponent } from './projects/project-map/project-map.component';



@NgModule({
  declarations: [
    AppComponent,
    ContractsListComponent,
    YearFloatingFilterComponent,
    MonthFloatingFilterComponent,
    RemainingFilterComponent,
    RemainingFloatingFilterComponent,
    ContractsComponent,
    ExpensesComponent,
    LaborComponent,
    OrdersComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    SidenavListComponent,
    LogoutComponent,
    UserSettingsComponent,
    SavedLayoutsComponent,
    TotalPinnedRowRendererComponent,
    SaveLayoutDialogComponent,
    ConfirmDialogComponent,
    SelectEditorComponent,
    CustomDatePickerComponent,
    DatePickerEditorComponent,
    NumberEditorComponent,
    SelectFloatingFilterComponent,
    JobSelectEditorComponent,
    ThemePickerComponent,
    AttachmentsComponent,
    SecuredAttachmentComponent,
    ActionButtonComponent,
    TextareaEditorComponent,
    SchedulingListComponent,
    AutocompleteFloatingFilterComponent,
    // SchedulerComponent,
    EditScheduleComponent,
    ScheduleStatusFloatingFilterComponent,
    ScheduleStatusFilterComponent,
    ScheduleAttachmentDetailsComponent,
    CustomerListComponent,
    ProjectNavigationComponent,
    CustomerEditComponent,
    ProjectListComponent,
    ProjectCustomerComponent,
    ProposalCustomerComponent,
    ProposalEditComponent,
    ProjectEditComponent,
    ProposalListComponent,
    DatesFloatingFilterComponent,
    DatesFilterComponent,
    TabDirective,
    MatInputCurrencyDirective,
    SnippetsComponent,
    SnippetEditComponent,
    SettingsNavigationComponent,
    ProposalTemplatesComponent,
    ProposalTemplateEditComponent,
    ProposalTermsComponent,
    RepsComponent,
    PrintProposalDialogComponent,
    CustomerAttachmentsComponent,
    ProjectAttachmentsComponent,
    ChangePasswordComponent,
    RepEditComponent,
    RepEmailSettingsComponent,
    RepEmailTemplateComponent,
    ProposalEmailDialogComponent,
    CrewsComponent,
    SubcontractorsComponent,
    TradesComponent,
    StatusesComponent,
    ContractStatusFloatingFilterComponent,
    ContractStatusFilterComponent,
    PermitStatusFloatingFilterComponent,
    PermitStatusFilterComponent,
    ProjectMapComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,    
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    AngularSplitModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatGridListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatProgressBarModule,
    ScrollingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    DragDropModule,
    MatTooltipModule,
    MatSnackBarModule,
    TextFieldModule,
    NgxMatSelectSearchModule,
    GoogleMapsModule,


    AgGridModule.withComponents([
      YearFloatingFilterComponent,
      YearFilterComponent,
      DatesFilterComponent,
      DatesFloatingFilterComponent,
      MonthFloatingFilterComponent,
      MonthFilterComponent,
      RemainingFilterComponent,
      RemainingFloatingFilterComponent,
      TotalPinnedRowRendererComponent,
      SelectEditorComponent,
      DatePickerEditorComponent,
      CustomDatePickerComponent,
      NumberEditorComponent,
      SelectFloatingFilterComponent,
      JobSelectEditorComponent,
      ActionButtonComponent,
      TextareaEditorComponent,
      CommonModule,
      AutocompleteFloatingFilterComponent,
      NgxMatSelectSearchModule,
      ScheduleStatusFloatingFilterComponent,
      ScheduleStatusFilterComponent,
      ScheduleAttachmentDetailsComponent
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  exports: [CommonModule],
  providers: [DatePipe, CurrencyPipe, PercentPipe,// SyncfusionModule,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true, },
    { provide: RouteReuseStrategy, useClass: RouteReuseService },
    { provide: ErrorHandler, useClass: ErrorHandlerService }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function appInitializer(authenticationService: AuthenticationService) {
  return () => new Promise(resolve => {
    // attempt to refresh token on app start up to auto authenticate
    authenticationService.refreshToken().subscribe().add(resolve);
  });
}
