import { Component, OnInit } from '@angular/core';
import { FilterChangedEvent, GridOptions, IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-dates-floating-filter',
  templateUrl: './dates-floating-filter.component.html',
  styleUrls: ['./dates-floating-filter.component.scss']
})
export class DatesFloatingFilterComponent implements IFloatingFilter {

  params: IFloatingFilterParams | undefined;
  gridOptions: GridOptions = {};

  selectedValue = "All";
  options: string[] = [
    'Past Week',
    'Past Month',
    'Past Year',
    'This Week',
    'This Month',
    'This Year',
    'Last Week',
    'Last Month',
    'Last Year'];

  constructor() { }


  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.selectedValue = 'All';
    }
    else {
      this.selectedValue = parentModel;
    }
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged() {
    this.params?.parentFilterInstance((instance) => {
      instance.setModel(this.selectedValue);
    });
  }
}
