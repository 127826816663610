import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ActionButtonComponent } from 'src/app/ag-grid-components/action-button/action-button.component';
import { getBaseUrl } from 'src/app/api-authorization/authorize.interceptor';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-components/confirm-dialog/confirm-dialog.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { IScheduleItem, IScheduleItemAttachment, ScheduleItemsService } from '../../schedule-items.service';

@Component({
  selector: 'app-schedule-attachment-details',
  templateUrl: './schedule-attachment-details.component.html',
  styleUrls: ['./schedule-attachment-details.component.scss']
})
export class ScheduleAttachmentDetailsComponent implements OnInit {

  subscriptions = new Subscription();
  public params: any;
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  cols: any = {};
  rowHeight() { return this.gridOptions.rowHeight ? this.gridOptions.rowHeight : 20; }

  defaultColDef = {
    resizable: true,
    sortable: true,
    cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
    floatingFilter: false,
    filterParams: {
      debounceMs: 1,
      newRowAction: 'keep'
    },
  };

  gridActivated = false;
  attachments: IScheduleItemAttachment[] = [];
  selectedAttachment: IScheduleItemAttachment | undefined;
  scheduleItem: IScheduleItem | undefined;

  constructor(private scheduleItemsService: ScheduleItemsService, userSettings: UserSettingsService, private dialog: MatDialog, private datePipe: DatePipe) {
    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => this.initGrid(data)));
  }

  agInit(params: any): void {
    this.params = params; //.node.data;
    this.attachments = params.data.Attachments;
    this.scheduleItem = params.data;
  }

  ngOnInit(): void {

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows();
    if (selectedRows) {
      this.selectedAttachment = selectedRows[0];
    }
  }

  openAttachment(params: any) {
    window.open(`${getBaseUrl()}/SubcontractorSchedules/Attachments/${this.selectedAttachment?.Id}/${this.selectedAttachment?.FileName}`, "_blank")
  }

  addAttachment(event: any) {
    this.scheduleItemsService.saveAttachments(this.scheduleItem?.ID ?? 0, event.target.files)?.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * event.loaded / Number(event.total));
        // console.log(progress);
      }
    });
  }

  deleteAttachment() {
    const message = `Are you sure you want to delete ${this.selectedAttachment?.FileName} attachment?`;
    const dialogData = new ConfirmDialogModel("Confirm Delete", message);

    if (!this.selectedAttachment) { return }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (this.scheduleItem && this.selectedAttachment) {
          this.scheduleItemsService.deleteAttachment(this.scheduleItem.ID, this.selectedAttachment.Id).subscribe();
        }
      }
    });
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.getRowNodeId = (params: any) => params.Id;
    this.gridOptions.immutableData = true;
    this.gridOptions.onRowDoubleClicked = this.openAttachment.bind(this);
    this.gridOptions.frameworkComponents = { 'btnCellRenderer': ActionButtonComponent }


    this.defaultColDef = {
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
      floatingFilter: false,
      filterParams: {
        debounceMs: 1,
        newRowAction: 'keep'
      },
    };

    this.cols = [
      { field: 'FileName', headerName: 'Name' },
      {
        field: 'DateSaved', headerName: 'Date Added', width: 130, valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
      },
      {
        headerName: 'Action', cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          clicked: (params: any) => {
            const data = params.data;
            window.open(`${getBaseUrl()}/SubcontractorSchedules/Attachments/${data.Id}/${data.FileName}`, "_blank")
          }
        },
        minWidth: 100,
        width: 100,
        filterParams: null,
        filter: null,
      }
    ];
    this.gridActivated = true;
  }

}
