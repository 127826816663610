import { AfterViewInit, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-print-proposal-dialog',
  templateUrl: './print-proposal-dialog.component.html',
  styleUrls: ['./print-proposal-dialog.component.scss']
})
export class PrintProposalDialogComponent implements OnChanges, AfterViewInit {

  src: string = ""
  private src$ = new BehaviorSubject(this.src);
  documentId = "pdfProposalDocument"

  constructor(private domSanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) public data: { url: string }, private dialogRef: MatDialogRef<PrintProposalDialogComponent>) {
    this.src$.next(data.url);
  }


  // this stream will contain the actual url that our img tag will load
  // every time the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  loading = true;

  private loadImage(url: string): Observable<any> {
    if (url === null) { return of('') }
    if (url === undefined || url === null || url?.length < 1) { return of(''); }
    return of(this.domSanitizer.bypassSecurityTrustResourceUrl(url));
  }

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  ngAfterViewInit(): void {
    //this.printDocument('pdfProposalDocument');
  }

  pdfLoaded() {
    this.loading = false;
    this.printDocument();
  }

  printDocument() {
    var doc = document.getElementById(this.documentId) as HTMLIFrameElement;
    if (doc == null) { console.error("Embedded Proposal PDF is null"); return; }

    if (typeof doc.contentWindow?.print === 'undefined') {
      setTimeout(() => {
        this.printDocument();
      }, (500));
    }
    else {
      doc.contentWindow.print();
    }

    // //Wait until PDF is ready to print    
    // if (typeof doc.print === 'undefined') {    
    //     setTimeout(function(){printDocument(documentId);}, 1000);
    // } else {
    //     doc.print();
    // }
  }

  close() {
    this.dialogRef.close();
  }



}
