import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { User } from 'src/app/api-authorization/authentication.service';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { StringExt } from 'src/app/utils/string';
import { ProjectAttachmentsComponent } from '../project-attachments/project-attachments.component';
import { CustomerProjectEditService } from '../services/customer-project-edit.service';
import { CustomersService, ICustomer } from '../services/customers.service';
import { ProjectAttachmentsService } from '../services/project-attachments.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { IOption, ProposalAutocompleteOptionsService } from '../services/proposal-autocomplete-options.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) projectTypeAutoComplete: MatAutocompleteTrigger | undefined;

  subscriptions = new Subscription();
  projects: IProject[] = [];
  customers: ICustomer[] = [];
  selectedCustomer: ICustomer | null = null;
  selectedProject: IProject | null = null;
  reps: IRep[] = [];
  attachmentCount: number = 0;

  projectTypeOptions: IOption[] = [];
  filteredProjectTypeOptions: IOption[] = [];
  cityOptions: IOption[] = [];
  filteredCityOptions: IOption[] = [];

  statusOptions: IOption[] = [];
  filteredStatusOptions: IOption[] = [];
  saveProjectClicked = false;

  user: User | null = null;
  isInitializing = true;

  public get projectTypeAutoCompleteHeight() : number {
    if (this.filteredProjectTypeOptions.length === 0) return 0;
    const optionHeight = 35;
    return Math.min(256, this.filteredProjectTypeOptions.length * optionHeight)
  }


  projectForm = this.fb.group({
    Id: [0],
    CustomerId: [0],
    Description: [''],
    ProjectType: ['', this.checkProjectType()],
    Address: [''],
    City: [''],
    Notes: [''],
    Status: [''],
    DateCalled: [''],
    DateCompleted: [''],
    RepId: [0],
  });


  get projectTypeError(): string {
    return this.projectForm.get('ProjectType')?.getError('required') ?? '';
  }

  get geocodeMessage(): string | undefined {
    return this.selectedProject?.GeocodeMessage;
  }

  constructor(private projectsService: ProjectsService, private customersService: CustomersService, private userSettingsService: UserSettingsService, private repsService: RepsService,
    private customerProjectEditService: CustomerProjectEditService, private fb: FormBuilder, private autoCompleteOptionsService: ProposalAutocompleteOptionsService,
    private dialog: MatDialog, private attachmentsService: ProjectAttachmentsService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.projectsService.selectedProject.subscribe(data => {
      this.selectedProject = data;
      if (data !== null) {
        this.projectForm.patchValue(data);
      }
      else {
        this.newProject();
      }
    }));

    this.subscriptions.add(this.customersService.selectedCustomer.subscribe(data => {
      this.selectedCustomer = data;
      if (this.selectedCustomer == null) {
        this.newProject();
      }
    }));

    this.subscriptions.add(this.projectsService.projects.subscribe(data => {
      this.projects = data;
      if (this.selectedProject !== null) {
        const id = this.selectedProject.Id;
        this.selectedProject = this.projects.find(p => p.Id === id) ?? null;
        if (this.selectedProject) {
          this.projectForm.patchValue(this.selectedProject);
        }
      }
    }));
    this.subscriptions.add(this.attachmentsService.attachments.subscribe(data => this.attachmentCount = data.length));
    this.subscriptions.add(this.customersService.customers.subscribe(data => { this.customers = data; }))
    this.subscriptions.add(this.autoCompleteOptionsService.city.subscribe(data => { this.cityOptions = data; this.filteredCityOptions = [...data] }));
    this.subscriptions.add(this.autoCompleteOptionsService.projectType.subscribe(data => { this.projectTypeOptions = data; this.filteredProjectTypeOptions = [...data] }));
    this.subscriptions.add(this.repsService.reps.subscribe(data => this.reps = data));
    this.subscriptions.add(this.autoCompleteOptionsService.statusOptions.subscribe(data => { this.statusOptions = data; }));


    this.subscriptions.add(this.projectForm.controls['ProjectType'].valueChanges.pipe(startWith(''),).subscribe(data => {
      const filter = data.toLowerCase();
      if (StringExt.isNullOrWhiteSpace(filter) || filter === "<unknown>") {
        this.filteredProjectTypeOptions = this.projectTypeOptions;
        return;
      }
      this.filteredProjectTypeOptions = this.projectTypeOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
    }));

    this.subscriptions.add(this.projectForm.controls['City'].valueChanges.pipe(startWith(''),).subscribe(data => {
      if (StringExt.isNullOrWhiteSpace(data)) {
        if (this.cityOptions !== undefined) {
          this.filteredCityOptions = [...this.cityOptions];
          return;
        }
      }
      const filter = data.toLowerCase();
      this.filteredCityOptions = this.cityOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
    }));

    this.subscriptions.add(this.projectForm.controls['Status'].valueChanges.pipe(startWith(''),).subscribe(data => {
      if (StringExt.isNullOrWhiteSpace(data)) {
        if (this.statusOptions !== undefined) {
          this.filteredStatusOptions = this.statusOptions.map(m => m); return;
        }
      }
      const filter = data.toLowerCase();
      this.filteredStatusOptions = this.statusOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
    }));


    this.subscriptions.add(this.customerProjectEditService.address$.subscribe(data => this.projectForm.controls['Address'].setValue(data)));
    this.subscriptions.add(this.customerProjectEditService.city$.subscribe(data => this.projectForm.controls['City'].setValue(data)));
    this.subscriptions.add(this.customerProjectEditService.rep$.subscribe(data => this.projectForm.controls['RepId'].setValue(data)));
    this.subscriptions.add(this.customerProjectEditService.customerSuccessfullySaved$.subscribe(data => {
      if (this.selectedCustomer === null && this.saveProjectClicked) {
        this.selectedCustomer = data;
        this.saveProjectClicked = false;
        this.projectForm.controls['CustomerId'].setValue(data.Id);
        this.saveProject();
      }
      else {
        this.selectedCustomer = data;
        this.saveProjectClicked = false;
      }
    }));

    this.subscriptions.add(this.userSettingsService.user.subscribe(data => {
      this.user = data;
      if (this.isInitializing) {
        this.isInitializing = false;
        this.newProject();
      }
    }));
  }

  saveProject() {
    this.projectForm.markAllAsTouched();
    Object.keys(this.projectForm.controls).forEach(key => {
      this.projectForm.get(key)?.updateValueAndValidity();
    });

    if (this.projectForm.valid) {
      if (this.selectedCustomer === null) {
        this.saveProjectClicked = true;
        this.customerProjectEditService.saveEvent$.next();
        return;
      }
      this.saveProjectClicked = false;
      const project = this.projectForm.value as IProject;
      if (project.Id > 0) {
        this.projectsService.update(project).subscribe();
      }
      else {
        this.projectsService.add(project).subscribe(data => {
          this.projectForm.patchValue(data);
          setTimeout(() => { this.projectsService.selectProject(data); }, 10);
        });
      }
    }
    else {
      this.projectForm.markAllAsTouched();
    }
  }

  newProject() {
    const newProject: IProject = {
      Id: 0,
      CustomerId: this.selectedCustomer?.Id ?? 0,
      Description: '',
      Address: this.selectedCustomer?.Address ?? '',
      City: this.selectedCustomer?.City ?? '',
      ProjectType: '<unknown>',
      Status: 'Called',
      DateCalled: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      RepId: this.user?.RepId ?? this.selectedCustomer?.RepId ?? null,
      DateCompleted: null,
      Notes: '',
      Latitude: undefined,
      Longitude: undefined,
      GeocodeMessage: ''
    }
    this.projectForm.patchValue(newProject);
    this.projectForm.markAsUntouched();
    if (this.selectedProject !== null) {
      this.selectedProject = null;
      this.projectsService.selectProject(null);
    }
    this.projectForm.markAsPristine();
    this.projectForm.markAsUntouched();

    Object.keys(this.projectForm.controls).forEach(key => {
      this.projectForm.get(key)?.updateValueAndValidity();
    });

  }

  deleteProject() {
    if (this.selectedProject !== null) {
      this.projectsService.delete(this.selectedProject).subscribe();
    }
  }

  checkProjectType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const required = control.value === "<unknown>" || StringExt.isNullOrWhiteSpace(control.value);
      if (control.touched || control.dirty) {
        const v = required ? { required: true } : null;
        return v;
      }
      return null;
    };
  }

  selectInputText(event: any) {
    event.target.select();
  }

  addressClicked() {
    if (this.selectedProject) {
      const baseUrl = `https://www.google.com/maps/search/?api=1&query=`;
      let address = `${this.selectedProject.Address},${this.selectedProject.City}`;
      address = address.replace(/\r|\n/g, ' ');
      let url = encodeURI(baseUrl + address);
      window.open(url, "_blank")
    }
  }

  openAttachmentDialog() {
    const dialogRef = this.dialog.open(ProjectAttachmentsComponent, {
      data: { customerId: this.selectedCustomer?.Id ?? null },
      maxWidth: '1000px',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: ['full-screen-modal']
    });
  }

  drop(e: any): void {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.uploadFiles(files);
  }

  dragover(e: any): void {
    e.preventDefault();
  }

  uploadFiles(files: any) {
    if (this.selectedCustomer) {
      this.attachmentsService.uploadFiles(this.selectedCustomer.Id, files)?.subscribe();
    }
  }
}
