import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { ProposalTemplatesComponent } from 'src/app/projects/proposal-templates/proposal-templates.component';
import { ProposalTermsComponent } from 'src/app/projects/proposal-terms/proposal-terms.component';
import { RepsComponent } from 'src/app/reps/reps/reps.component';
import { SnippetsComponent } from 'src/app/snippets/snippets/snippets.component';
import { CrewsComponent } from '../crews/crews/crews.component';
import { StatusesComponent } from '../statuses/statuses.component';
import { SubcontractorsComponent } from '../subcontractors/subcontractors/subcontractors.component';
import { TradesComponent } from '../trades/trades.component';

@Component({
  selector: 'app-settings-navigation',
  templateUrl: './settings-navigation.component.html',
  styleUrls: ['./settings-navigation.component.scss']
})
export class SettingsNavigationComponent implements OnInit {


  @ViewChild('pageHost', { read: ViewContainerRef }) pageHost: ViewContainerRef | undefined;


  pageItems: SettingsPageItem[] = [];
  selectedPageItem: SettingsPageItem | null = null;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { this.initSettingsPageItems(); }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadComponent();
    }, 100);
  }


  pageItemListChanged(event: MatSelectionListChange) {
    const options = event.options;
    const pageItem = options[0].value as SettingsPageItem;
    this.selectedPageItem = pageItem;
    this.loadComponent();
  }

  renderedComponents = new Map<string, ComponentRef<any>>();
  loadComponent() {
    if (this.selectedPageItem === null || this.pageHost === undefined) { return; }

    this.pageHost.detach();

    if (this.renderedComponents.has(this.selectedPageItem.displayText)) {

      const component = this.renderedComponents.get(this.selectedPageItem.displayText)
      component && this.pageHost.insert(component.hostView);
    } else {
      // const componentFactory = this.componentFactoryResolver.resolveComponentFactory();
      const componentRef = this.pageHost.createComponent(this.selectedPageItem.component);
      this.renderedComponents.set(this.selectedPageItem.displayText, componentRef);
    }
  }

  initSettingsPageItems() {
    const items: SettingsPageItem[] = [
      new SettingsPageItem(SnippetsComponent, 'Snippets', {}),
      new SettingsPageItem(ProposalTemplatesComponent, 'Templates', {}),
      new SettingsPageItem(ProposalTermsComponent, 'Terms', {}),
      new SettingsPageItem(RepsComponent, 'Reps', {}),
      new SettingsPageItem(CrewsComponent, 'Crews', {}),
      new SettingsPageItem(SubcontractorsComponent, 'Subcontractors', {}),
      new SettingsPageItem(TradesComponent, 'Trades', {}),
      new SettingsPageItem(StatusesComponent, 'Status', {}),
    ];

    this.pageItems = items;
    this.selectedPageItem = this.pageItems[0];
  }
}

class SettingsPageItem {
  constructor(public component: Type<any>, public displayText: string, public data: any) { }
}
