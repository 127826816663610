<as-split>
    <as-split-area [size]="30">
        <ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 5px); margin-top: 4px;"
            [rowData]="reps" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [columnDefs]="cols"
            [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)"
            (selectionChanged)="onSelectionChanged()">
        </ag-grid-angular>
    </as-split-area>
    <as-split-area [size]="70">
        <app-rep-edit></app-rep-edit>
    </as-split-area>
</as-split>