import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import * as math from 'mathjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { Snippet, SnippetsService } from 'src/app/snippets/snippets.service';
import { NumberExt } from 'src/app/utils/number-ext';
import { StringExt } from 'src/app/utils/string';
import { IOption, ProposalAutocompleteOptionsService } from '../services/proposal-autocomplete-options.service';
import { IProposalTemplate, ProposalTemplatesService } from '../services/proposal-templates.service';
import { IProposalTerms, ProposalTermsService } from '../services/proposal-terms.service';
import { ILine, IPayment } from '../services/proposals.service';

@Component({
  selector: 'app-proposal-template-edit',
  templateUrl: './proposal-template-edit.component.html',
  styleUrls: ['./proposal-template-edit.component.scss']
})
export class ProposalTemplateEditComponent implements OnInit, OnDestroy {
  @ViewChildren('textInput') textInputs: QueryList<ElementRef> | undefined;

  subscriptions: Subscription = new Subscription();
  selectedTemplate: IProposalTemplate | null = null;
  proposalTerms: IProposalTerms[] = [];
  reps: IRep[] = [];

  conditionOptions: IOption[] = [];
  filteredConditionOptions: IOption[] = [];

  proposalSnippets = new Map<string, Snippet>();

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['Amount', 'Condition'];
  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'Payments': this.rows });

  templateForm = this.fb.group({
    Id: [0],
    Description: ['', Validators.required],
    RepId: [null],
    // Rep: [''],
    DaysValid: [21],
    NumberOfPayments: [1],
    TermsId: [null, Validators.required],
    Lines: this.fb.array([]),
    Payments: this.fb.array([])
  });

  get Lines() { return this.templateForm.get('Lines') as FormArray };
  get Payments() { return this.templateForm.get('Payments') as FormArray };
  get Amount() { return this.templateForm.get('Amount')?.value };
  get NumberOfPayments() { return this.templateForm.get('NumberOfPayments')?.value };

  getLine(index: number) {
    return this.Lines.get(`${index}`) as FormGroup ?? new FormControl();
  };

  constructor(private templateService: ProposalTemplatesService, private autoCompleteOptionsService: ProposalAutocompleteOptionsService,
    private snippetsService: SnippetsService, private termsService: ProposalTermsService, private repsService: RepsService, private fb: FormBuilder) {
    this.createLines();
  }


  ngOnInit(): void {

    this.subscriptions.add(this.repsService.reps.subscribe(data => { this.reps = data; }));
    this.subscriptions.add(this.autoCompleteOptionsService.conditionOptions.subscribe(data => { this.conditionOptions = data; this.filteredConditionOptions = data; }));
    this.subscriptions.add(this.termsService.terms.subscribe(data => this.proposalTerms = data));

    this.subscriptions.add(this.snippetsService.snippets.subscribe(data => {
      this.proposalSnippets.clear();
      for (const snippet of data) {
        this.proposalSnippets.set(snippet.Keyword, snippet);
      }
    }));

    this.subscriptions.add(this.templateService.selectedTemplate.subscribe(data => {
      const selectedTemplateIsNull = this.selectedTemplate === null;
      this.selectedTemplate = data;

      if (data !== null) {
        data.Payments.length = data.NumberOfPayments;
        this.addPaymentsControls(data.NumberOfPayments, true);
        this.templateForm.patchValue(data);
      }
      else {
        if (!selectedTemplateIsNull) {
          this.newTemplate();
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  filterPaymentConditionOptions(event: any) {
    const value = event.target.value;
    if (StringExt.isNullOrWhiteSpace(value)) {
      this.filteredConditionOptions = [...this.conditionOptions];
      return;
    }
    const filter = value.toLowerCase();
    this.filteredConditionOptions = this.conditionOptions.filter(option => StringExt.wordContainsFilterString(option.FilterValue, filter));
  }

  onKeyDownEvent(i: number, event: KeyboardEvent) {
    switch (event.key) {
      case "Enter":
        this.checkSnippets(i, event);
        this.setFocusToNextRow(i);
        this.preventDefaultKeyEvent(event);
        break;
      case "ArrowUp":
        {
          if (event.ctrlKey) {
            this.setFocusToPreviousRow(i);
            this.preventDefaultKeyEvent(event);
          }
          const el = event.target as HTMLInputElement;
          const pos = el.selectionStart;
          if (pos === 0) {
            this.setFocusToPreviousRow(i);
            this.preventDefaultKeyEvent(event);
          }
        }
        break;
      case "ArrowDown":
        {
          if (event.ctrlKey) {
            this.setFocusToNextRow(i);
            this.preventDefaultKeyEvent(event);
          }
          else {
            const el = event.target as HTMLInputElement;
            const pos = el.selectionStart;
            if (pos === el.value.length) {
              this.setFocusToNextRow(i);
              this.preventDefaultKeyEvent(event);
            }
          }
        }
        break;
      default:
        break;
    }
  }

  checkSnippets(lineNumber: number, event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (StringExt.isNullOrWhiteSpace(input.value)) { return; }
    const value = input.value.toLocaleLowerCase();

    let snippet: Snippet | undefined = this.proposalSnippets.get(value.trim());
    if (snippet === undefined) {
      const snippetId = value.substring(0, value.search(/\d/)).trim(); // ?? value.split(' ')[0];
      snippet = this.proposalSnippets.get(snippetId);
    }

    if (snippet === undefined) {
      const snippetId = value.split(' ')[0];
      snippet = this.proposalSnippets.get(snippetId);
    }

    if (snippet === undefined) { return; }
    if (snippet.Parameter.includes('${') && value === snippet.Keyword) {
      return;
    }
    const parameterSplit = this.splitString(snippet.Parameter);
    const parameterDelimiters = parameterSplit.filter(x => !x.includes('${'));
    const parameters = parameterSplit.filter(f => f.includes('${'));
    const regex = new RegExp(`[${parameterDelimiters.join('')}]+`, 'g');  // /[psxx]+/g
    const valueSplits = input.value.split(regex).map(m => m.trim()).filter(f => !StringExt.isNullOrWhiteSpace(f));

    let textParameters = this.splitString(snippet.Text).filter(f => f.includes('${'));
    const snippetTextPlaceHoldersCleaned = textParameters.map(m => m.replace(new RegExp('[${}]+', 'g'), ''));

    const parameterMap = new Map<string, string>();
    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i].replace(new RegExp('[${}]+', 'g'), '');
      let newValue = valueSplits[i] ?? '';

      if (!isNaN(Number(valueSplits[i]))) {
        newValue = NumberExt.numberWithCommas(Number(valueSplits[i]), 0);
      }
      parameterMap.set(parameter, newValue);
    }

    let newString = snippet.Text;
    for (let i = 0; i < textParameters.length; i++) {
      const textParam = textParameters[i];
      const cleanedTextParam = textParam.replace(new RegExp('[${}]+', 'g'), '');
      const cleanedTextParamSplit = cleanedTextParam.split(' ').map(m => m.trim()).filter(f => !StringExt.isNullOrWhiteSpace(f));

      const parser = math.parser();
      for (const textParam of cleanedTextParamSplit) {
        const parameter = parameterMap.get(textParam);
        if (parameter !== undefined) {
          const n = NumberExt.stringToNumber(parameter);
          if (n === 0) {
            parser.set(textParam, parameter);
          }
          else {
            parser.set(textParam, n);
          }
        }
      }
      const newValue = parser.evaluate(cleanedTextParam);
      newString = newString.replace(textParam, newValue);
    }

    const newStringSplit = newString.split(/\r?\n/);
    for (let i = 0; i < newStringSplit.length; i++) {
      const lineText = newStringSplit[i];
      const currentLine = Math.min((lineNumber + i), ((this.textInputs?.length ?? 0) - 1));
      const text: ILine = { Text: lineText };
      this.getLine(currentLine).patchValue(text);
    }
  }

  splitString(value: string) {
    // "ps${width}x${length}x${height}": "Build a ${width} x ${length} x ${height} per the attached specs." };
    const v = value.split(/(\${.*?})/g);
    return v;
  }

  preventDefaultKeyEvent(event: KeyboardEvent) {
    event.cancelBubble = true;
    event.preventDefault();
  }

  setFocusToNextRow(i: number) {
    if (this.textInputs) {
      if (this.textInputs.length > (i + 1)) {
        const el = this.textInputs.get(i + 1);
        el?.nativeElement.focus();
      }
    }
  }

  setFocusToPreviousRow(i: number) {
    if (this.textInputs) {
      if (i > 0) {
        const el = this.textInputs.get(i - 1);
        el?.nativeElement.focus();
      }
    }
  }

  newTemplate() {
    const newTemplate: IProposalTemplate = {
      Id: 0,
      Description: '',
      RepId: null,
      DaysValid: 21,
      NumberOfPayments: 1,
      TermsId: 0,
      Lines: new Array<ILine>(20),
      Payments: new Array<IPayment>(4)
    }

    this.createLines();

    this.templateForm.patchValue(newTemplate);
    this.templateForm.patchValue({ TermsId: null });
    this.templateForm.markAsUntouched();
    if (this.templateForm !== null) {
      this.selectedTemplate = null;
      this.templateService.selectTemplate(null);
    }
  }

  clone() {
    if (this.selectedTemplate === null) {
      return;
    }
    const newTemplate: IProposalTemplate = { ...this.selectedTemplate };
    newTemplate.Id = 0;
    newTemplate.Description = `${newTemplate.Description} - COPY`;
    this.templateService.add(newTemplate).subscribe(data => {
      this.templateService.selectTemplate(data);
    });

  }

  deleteTemplate() {
    if (this.selectedTemplate === null) { return; }
    this.templateService.delete(this.selectedTemplate).subscribe(data => this.templateService.selectTemplate(null));
  }

  saveTemplate() {
    if (this.templateForm.valid) {
      const template = this.templateForm.value as IProposalTemplate;
      if (template.Id > 0) {
        this.templateService.update(template).subscribe();
      }
      else {
        this.templateService.add(template).subscribe(data => {
          this.selectedTemplate = data;
          this.templateForm.patchValue(data);
          setTimeout(() => { this.templateService.selectTemplate(data); }, 10);
        });
      }
    }
    else {
      this.templateForm.markAllAsTouched();
    }
  }

  createLines(): void {
    this.Lines.clear();
    for (let i = 0; i < 19; i++) {
      this.Lines.push(this.fb.group({ Text: '' }));
    }
    this.addPaymentsControls(1, true);
  }

  addPaymentsControls(numberOfPayments: number, clearExisting: boolean) {
    if (clearExisting) {
      this.Payments.clear();
      for (let i = 0; i < numberOfPayments; i++) {
        this.Payments.push(this.fb.group({ Condition: '', Amount: 0 }));
      }
    }
    else {
      if (this.Payments.length < numberOfPayments) {
        const count = this.Payments.length;
        for (let i = count; i < numberOfPayments; i++) {
          this.Payments.push(this.fb.group({ Condition: '', Amount: 0 }));
        }
      }
      else {
        for (let i = this.Payments.length; i > numberOfPayments; i--) {
          this.Payments.removeAt(i - 1);
        }
      }
    }
  }

  numberOfPaymentsSelected(event: MatSelectChange) {
    this.addPaymentsControls(event.value, false);
  }

  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.selectedProposal?.Lines ?? [], event.previousIndex, event.currentIndex);
    this.moveItemInFormArray(this.Lines, event.previousIndex, event.currentIndex);
  }

  moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }

}
