import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  baseUrl = 'Orders';
  private orders$: ReplaySubject<IOrder[]> = new ReplaySubject(1);
  public get Orders(): Observable<IOrder[]> { return this.orders$.asObservable(); }

  constructor(private httpClient: HttpClient) { }

  loadOrders(jobId: number | null): Observable<IOrder[]> {
    const url = `${this.baseUrl}/${jobId ?? 0}`;
    this.httpClient.get<IOrder[]>(url).subscribe(data => this.orders$.next(data));
    return this.orders$.asObservable();
  }
}


export interface IOrder {
  OrderId: number;
  Document: string;
  OrderTotal: number;
  EntryDate: Date;
  DocType: number;
  OrderLineItems: IOrderLineItem[]
}

export interface IOrderLineItem {
  OrderDetailsId: number;
  OrderId: number;
  Item: string;
  Description: string;
  Qty: number;
  Price: number;
  Amount: number;
  DateOrdered: Date | null;
  PODoc: string;
  Ordered: number;
  DateReceived: Date | null;
  ReceiptDoc: string;
  ReceivedQty: number;
  Sequence: number;
}
