import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { getBaseUrl } from 'src/app/api-authorization/authorize.interceptor';

@Component({
  selector: 'app-secured-attachment',
  templateUrl: './secured-attachment.component.html',
  styleUrls: ['./secured-attachment.component.scss']
})
export class SecuredAttachmentComponent implements OnChanges {
  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  @Input() public src: string = '';
  private src$ = new BehaviorSubject(this.src);

  // this stream will contain the actual url that our img tag will load
  // every time the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  get IsPdf() { return this.src?.match(/.(pdf)$/i) !== null }
  get IsImage() { return (this.src?.match(/.(jpg|jpeg|png|gif)$/i) !== null) }

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {
  }

  private loadImage(url: string | null): Observable<any> {
    if (url === null) { return of('') }
    if (url === undefined || url === null || url?.length < 1) { return of(''); }

    if (url.toLowerCase().startsWith('api/')) {
      return of(this.domSanitizer.bypassSecurityTrustResourceUrl(url));
    }
    return of(this.domSanitizer.bypassSecurityTrustResourceUrl(`${getBaseUrl()}${url}`));
  }

  ngOnChanges(): void {
    this.src$.next(this.src);
  }
}
