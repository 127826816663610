<mat-nav-list>
    <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a>
    <a mat-list-item routerLink="/contracts" (click)="onSidenavClose()">
        <span class="nav-caption">Contracts</span>
    </a>
    <a mat-list-item routerLink="/scheduler" (click)="onSidenavClose()">
        <span class="nav-caption">Scheduler</span>
    </a>
    <a mat-list-item routerLink="/proposals" (click)="onSidenavClose()">
        <span class="nav-caption">Proposals</span>
    </a>
    <a mat-list-item routerLink="/logout" (click)="onSidenavClose()">
        <span class="nav-caption">Logout</span>
    </a>
</mat-nav-list>