<h1 mat-dialog-title>User Settings</h1>
<div mat-dialog-content style="padding: 5px;">
    <!-- <mat-card style="padding: 5px; margin-bottom: 15px;">
        <mat-card-content>
            <app-theme-picker (themeChanged)="themeChanged($event)"></app-theme-picker>
        </mat-card-content>
    </mat-card> -->


    <mat-tab-group>
        <mat-tab label="Theme">
            <mat-card>
                <mat-card-content style="width: 20em; padding: 5px;">
                    <mat-slide-toggle matInput class="switch" color="primary" [(ngModel)]="darkTheme"
                        (change)="darkThemeChanged($event)">
                        Dark Theme
                    </mat-slide-toggle>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Grid">
            <mat-card>
                <!-- <mat-card-title>Grid Settings</mat-card-title> -->
                <mat-card-content style="width: 20em; padding: 5px;">
                    <form [formGroup]="form" (ngSubmit)="submit()" class="example-form">
                        <mat-slide-toggle class="switch" color="primary" formControlName="singleClickEdit"> Single Click
                            Edit
                        </mat-slide-toggle>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Row Height</mat-label>
                            <input matInput formControlName="rowHeight" type="number" min="20" max="35" step="1">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Header Height</mat-label>
                            <input matInput formControlName="headerHeight" type="number" min="20" max="35" step="1">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Floating Filter Height</mat-label>
                            <input matInput formControlName="floatingFiltersHeight" type="number" min="20" max="35"
                                step="1">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Theme</mat-label>
                            <mat-select formControlName="theme">
                                <ng-container *ngIf="darkTheme">
                                    <mat-option *ngIf="darkTheme" value="ag-theme-alpine-dark">Alpine Dark</mat-option>
                                    <mat-option value="ag-theme-balham-dark">Balham Dark</mat-option>
                                </ng-container>
                                <ng-container *ngIf="!darkTheme">
                                    <mat-option value="ag-theme-alpine">Alpine</mat-option>
                                    <mat-option value="ag-theme-balham">Balham</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <div class="button">
                            <button type="button" mat-raised-button color="primary" (click)="apply()">Apply</button>
                            <button type="submit" mat-raised-button color="primary">Save & Close</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-tab>
    </mat-tab-group>
</div>