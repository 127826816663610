import { Component, OnInit } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { AgPromise, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IScheduleItem } from 'src/app/contracts/schedule-items.service';

@Component({
  selector: 'app-permit-status-filter',
  template: ``,
  styles: [ ]
})
export class PermitStatusFilterComponent implements AgFilterComponent {
  params: IFilterParams | undefined;
  selectedOption = "All"

  constructor() { }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.selectedOption !== 'All';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const scheduleItem: IScheduleItem = params.data;
    if (this.selectedOption === "All") { return true; }
        
    return this.selectedOption === scheduleItem.Status
  }

  getModel() {
    return this.selectedOption;
  }

  setModel(model: any): void | AgPromise<void> {
    this.selectedOption = model ?? 'All';
    this.params?.filterChangedCallback();
  }
}
