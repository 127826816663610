<as-split>
  <as-split-area [size]="30">
      <ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 5px); margin-top: 4px;"
          [rowData]="reps" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [columnDefs]="cols"
          [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged()">
      </ag-grid-angular>
  </as-split-area>
  <as-split-area [size]="70">

    <form class="edit-form" [formGroup]="form" autocomplete="off" style="margin: 5px ;" >
      <div style="display: grid; grid-template-columns: 300px 400px;">
          <div style=" grid-row: 1; grid-column: 1 / -1; width: 100%;">
              <button type="button" mat-icon-button matTooltip="Add New Proposal" color="accent"
                  class="mat-raised-button-sm" (click)="newCrew()">
                  <mat-icon>add</mat-icon>
              </button>
              <button type="button" matTooltip="Save Changes" (click)="saveCrew()" mat-icon-button color="accent"
                  class="mat-raised-button-sm">
                  <mat-icon>save</mat-icon>
              </button>
              <button type="button" matTooltip="Delete" mat-icon-button color="warn"
                  [disabled]="selectedSubcontractor === null" class="mat-raised-button-sm" (click)="deleteCrew()">
                  <mat-icon class="md-18">delete</mat-icon>
              </button>

          </div>
          <div style="grid-column: 1; width: 100%; margin-top: 10px;">
              <div class="left-label-inputs" style="padding: 3px; margin: 3px; max-width: 672px;">
                  <label>Name</label>
                  <div>
                      <mat-form-field appearance="fill" color="accent">
                          <input matInput formControlName="ContractorName">
                          <mat-error>Name is required.</mat-error>
                      </mat-form-field>
                  </div>
                  <label>Trade</label>
                  <div>
                      <mat-form-field appearance="fill" color="accent">
                        <mat-select formControlName="Trade">
                          <mat-option *ngFor="let trade of trades | async" [value]="trade.TradeName">
                            {{trade.TradeName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
              </div>
          </div>
      </div>
  </form>
  </as-split-area>
</as-split>

