import { ErrorHandler, Injectable } from '@angular/core';
import { MyMonitoringService } from './my-monitoring.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private myMonitoringService: MyMonitoringService) {
    super();
  }

  handleError(error: Error): void {
    console.error(error);
    this.myMonitoringService.logException(error); // Manually log exception
  }

}
