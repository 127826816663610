import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private jobs$: ReplaySubject<IJob[]> = new ReplaySubject(1);
  public get jobs(): Observable<IJob[]> { return this.jobs$.asObservable() }

  baseUrl = 'jobs';
  isLoaded = false;

  constructor(private httpClient: HttpClient) {
  }

  loadJobs(): Observable<IJob[]> {
    const url = `${this.baseUrl}`;
    this.httpClient.get<IJob[]>(url).subscribe(data => { this.jobs$.next(data); });
    return this.jobs$.asObservable();
  }
}


export interface IJob {
  JobNumber: number;
  Name: string;
}