import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { IOutputData } from 'angular-split';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { ContractLaborService, IContractLabor } from '../contract-labor.service';
import { Contract, ContractsService } from '../contracts.service';

@Component({
  selector: 'app-labor',
  templateUrl: './labor.component.html',
  styleUrls: ['./labor.component.scss']
})
export class LaborComponent implements OnInit, OnDestroy {
  @Input() parentResized: Observable<IOutputData> = new Observable<IOutputData>();
  subscriptions = new Subscription();
  selectedContract: Contract | null = null;
  contractLabor: IContractLabor[] = [];
  groupedContractLabor: IContractLabor[] = [];
  totalByWorker = false;

  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  rowHeight() { return this.gridOptions.rowHeight ? this.gridOptions.rowHeight : 20; }
  gridActivated = false;

  get SumLaborDiff() { return (this.selectedContract?.ActLabor ?? 0) - (this.selectedContract?.EstLabor ?? 0) }
  get SumLaborDiffPercentage() {
    const estimateLabor = this.selectedContract?.EstLabor ?? 0;
    const diffSum = this.SumLaborDiff;
    if (diffSum == 0 || estimateLabor == 0) { return 0; }
    return diffSum / estimateLabor;
  }

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      var cellDate = new Date(cellValue); // new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  defaultColDef = {
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
  };

  cols = [
    { field: 'Name', headerName: 'Name' },
    {
      field: 'DatePaid', headerName: 'Date Paid', filterParams: this.filterParams,
      valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? ''
    },
    { field: 'TotalPay', headerName: 'Total Pay', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
    { field: 'RegHours', headerName: 'Reg Hours', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
    { field: 'OTHours', headerName: 'OT Hours', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
    { field: 'TotalHours', headerName: 'Total Hours', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
  ];


  constructor(private contractLaborService: ContractLaborService, private contractsService: ContractsService, userSettings: UserSettingsService,
    private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private percentPipe: PercentPipe, private ngZone: NgZone) {

    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => {
      this.gridActivated = false;
      const { theme, ...gridOptions } = data;
      this.gridOptions = gridOptions;
      this.agGridTheme = theme;

      this.defaultColDef = {
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
      };
      setTimeout(() => { this.gridActivated = true; });
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.parentResized?.subscribe(x => this.ngZone.run(() => this.gridApi?.sizeColumnsToFit())));
    this.subscriptions.add(this.contractsService.selectedContract.pipe(switchMap(contract => {
      this.contractLabor = []; this.groupedContractLabor = [];
      this.selectedContract = contract;
      if (contract) {
        return this.contractLaborService.loadContractLabor(contract.ContractID);
      }
      return EMPTY;
    })).subscribe(data => { this.contractLabor = data; this.groupContractLabor() }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  groupContractLabor() {
    const arr = this.contractLabor;
    const result = [...arr.reduce((r, o) => {
      const key = this.totalByWorker ? o.Name : o.DatePaid;
      const item = r.get(key) || Object.assign({}, o, {
        TotalPay: 0,
        TotalHours: 0,
        RegHours: 0,
        OTHours: 0,
      });

      item.TotalPay += o.TotalPay;
      item.TotalHours += o.TotalHours;
      item.RegHours += o.RegHours;
      item.OTHours += o.OTHours;

      return r.set(key, item);
    }, new Map).values()];
    this.groupedContractLabor = result;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setGridColumns();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows();
    if (selectedRows) {
      // this.selectedLinkedAccount = selectedRows[0];
    }
  }

  setGridColumns() {
    this.gridColumnApi.setColumnVisible('Name', this.totalByWorker);
    this.gridColumnApi.setColumnVisible('DatePaid', !this.totalByWorker);
    this.groupContractLabor();
    this.gridApi?.sizeColumnsToFit();
  }
}
