import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { CellClickedEvent, CellValueChangedEvent, GridApi, GridOptions, ICellEditorParams } from 'ag-grid-community';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { IScheduleItem, ScheduleItemsService } from '../schedule-items.service';
import { ISelectEditorOption, SelectEditorComponent } from 'src/app/ag-grid-components/select-editor/select-editor.component';
import { DatePickerEditorComponent } from 'src/app/ag-grid-components/date-picker-editor/date-picker-editor.component';
import { SelectFloatingFilterComponent } from 'src/app/ag-grid-components/select-floating-filter/select-floating-filter.component';
import { TextareaEditorComponent } from 'src/app/ag-grid-components/textarea-editor/textarea-editor.component';
import { ISubcontractor, SubcontractorsService } from '../subcontractors.service';
import { AutocompleteFloatingFilterComponent } from 'src/app/ag-grid-components/autocomplete-floating-filter/autocomplete-floating-filter.component';
import { Contract, ContractsService } from '../contracts.service';
import { DatePipe } from '@angular/common';
import { ScheduleStatusFilterComponent } from 'src/app/ag-grid-components/schedule-status-floating-filter/schedule-status-filter/schedule-status-filter.component';
import { ScheduleStatusFloatingFilterComponent } from 'src/app/ag-grid-components/schedule-status-floating-filter/schedule-status-floating-filter.component';
 import { ScheduleAttachmentDetailsComponent } from './schedule-attachment-details/schedule-attachment-details.component';
import { HttpEventType } from '@angular/common/http';
import { TradesService } from '../trades.service';
import { AppSettingsService, IStatus, Settings } from 'src/app/settings/app-settings.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-scheduling-list',
  templateUrl: './scheduling-list.component.html',
  styleUrls: ['./scheduling-list.component.scss']
})
export class SchedulingListComponent implements OnInit, OnDestroy {
  @Output() JobFilterChangedEvent = new EventEmitter();
  subscriptions = new Subscription();
  scheduleItems: IScheduleItem[] = [];
  filteredScheduleItems: IScheduleItem[] = [];
  selectedScheduleItem: IScheduleItem | null = null;
  filter: string[] = [];
  contractorFilterList: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  tradesFilterList: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  jobsFilter: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  isEmittingFilterEvent = false;
  contracts: Contract[] = [];
  selectedContract: Contract | null = null;
  subcontractors: ISubcontractor[] = [];

  scheduleStatusList: IStatus[] = [];
  scheduleStatusList$: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());

  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  cols: any = {};
  rowHeight() { return this.gridOptions.rowHeight ? this.gridOptions.rowHeight : 20; }
  gridActivated = false;

  defaultColDef = {
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
    floatingFilter: true,
    filterParams: {
      debounceMs: 1,
      newRowAction: 'keep'
    },
  };

  frameworkComponents = {
    selectEditor: SelectEditorComponent,
    datePickerEditor: DatePickerEditorComponent,
    textareaEditor: TextareaEditorComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    autoCompleteFloatingFilter: AutocompleteFloatingFilterComponent,
    scheduleStatusFilter: ScheduleStatusFilterComponent,
    scheduleStatusFloatingFilter: ScheduleStatusFloatingFilterComponent,
    scheduleAttachmentDetailsRenderer: ScheduleAttachmentDetailsComponent
  };

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue); // new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  fullWidthCellRenderer = 'scheduleAttachmentDetailsRenderer';
  isFullWidthCell = (rowNode: any) => rowNode.data.isFullWidth;
  getRowHeight = (params: any) => {
    if (params.data.isFullWidth) {
      return 50 + (this.rowHeight() * params.data.Attachments.length);
    }
    return;
  }

  constructor(private scheduleItemsService: ScheduleItemsService, private subcontractorService: SubcontractorsService, private tradesService: TradesService, private contractService: ContractsService,
    userSettings: UserSettingsService, private datePipe: DatePipe, private settingsService: AppSettingsService) {
    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => { this.initGrid(data); }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.contractService.contracts.subscribe(data => this.contracts = data));
    this.subscriptions.add(this.scheduleItemsService.scheduleItems.subscribe(data => {
      this.scheduleItems = data;
      this.filterScheduleItems();
    }));
    this.subscriptions.add(this.contractService.selectedContract.subscribe(data => {
      this.selectedContract = data;
      this.filterScheduleItems();
    }));
    this.subscriptions.add(this.contractService.filteredContracts$.subscribe(data => { this.filter = data.map(m => m.ContractID); this.filterScheduleItems(); }));
    this.subscriptions.add(this.subcontractorService.subcontractors.subscribe(data => { this.subcontractors = data; this.contractorFilterList.next(data.map(m => ({ Value: m.ContractorName, Text: m.ContractorName }))); }));
    this.subscriptions.add(this.tradesService.trades.subscribe(data => { this.tradesFilterList.next(data.map(m => ({ Value: m.TradeName, Text: m.TradeName }))); }));

    this.subscriptions.add(this.settingsService.settings.pipe(map(items => items.find(x => x.Key === Settings.ScheduleStatus))).subscribe(data => {
      if (data === undefined) { return; }
      this.scheduleStatusList = data.Data as IStatus[];
      this.scheduleStatusList$.next(this.scheduleStatusList.map(r => ({ Value: r.Name, Text: r.Name })));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.filterScheduleItems();
    this.gridApi?.sizeColumnsToFit();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows();
    if (selectedRows?.length) {
      this.selectedScheduleItem = selectedRows[0];
    }
    else { this.selectedScheduleItem = null }
  }

  filterScheduleItems() {
    let distinctJobList = [];
    let filteredItems: IScheduleItem[] = [];
    if (this.isEmittingFilterEvent) { console.error('Is Emitting filter event.'); return; }

    const expandedRow = this.filteredScheduleItems.find((x: any) => x.expanded && (!x.isFullWidth ?? false));
    if (this.filter.length > 1) {
      filteredItems = this.scheduleItems.filter(s => this.filter.includes(s.ContractID));
      distinctJobList = [...new Set(this.contracts.filter(f => this.filter.includes(f.ContractID)).map(s => s.JobName))];
    }
    else {
      filteredItems = [...this.scheduleItems];
      distinctJobList = [...new Set(this.contracts.map(s => s.JobName))];
    }

    if (expandedRow) {
      const isInList = filteredItems.find(x => x.ID === expandedRow.ID);
      if (isInList) {
        const index = filteredItems.findIndex(x => x.ID === expandedRow.ID);
        isInList.expanded = true;
        const newData = { ...isInList } as any;
        newData.isFullWidth = true;
        filteredItems.splice(index + 1, 0, newData);
      }
    }
    this.filteredScheduleItems = filteredItems;
    setTimeout(() => { this.gridApi?.resetRowHeights(); }, 0);

    const sorted = distinctJobList.sort((a, b) => {
      const aName = a.toLowerCase();
      const bName = b.toLowerCase();
      if (aName < bName) { return -1; }
      if (aName > bName) { return 1; }
      return 0;
    });
    const options = sorted.map(m => ({ Value: m, Text: m }));
    this.jobsFilter.next(options);

    setTimeout(() => {
      if (this.selectedContract) {
        const filterComponent = this.gridApi?.getFilterInstance('JobName');
        filterComponent?.setModel({
          type: 'equals',
          filter: this.selectedContract?.JobName
        });
        this.gridApi?.onFilterChanged();
      }
    }, 5);
  }

  clearFilters() {
    this.gridApi?.setFilterModel(null);
    this.JobFilterChangedEvent.emit(null);
  }

  cellValueChanged(params: CellValueChangedEvent) {
    const colId = params.column.getId();
    const scheduleItem: IScheduleItem = params.data;
    switch (colId) {
      case 'Contractor':
        const sub = this.subcontractors.find(s => s.ContractorName === scheduleItem.Contractor);
        if (sub) {
          scheduleItem.Trade = sub.Trade;
          params.node.setDataValue('Trade', sub.Trade);
        }
        break;
      case 'Trade':
        break;
    }
    this.scheduleItemsService.saveScheduleItem(scheduleItem).subscribe();
  }

  deleteScheduledItem() {
    if (this.selectedScheduleItem) {
      this.scheduleItemsService.deleteScheduleItem(this.selectedScheduleItem).subscribe();
    }
  }

  addScheduleItem() {
    if (this.selectedContract) {
      const newItem: IScheduleItem = { ID: 0, ContractID: this.selectedContract.ContractID, Contractor: '', JobName: this.selectedContract.JobName, Trade: '', Status: '', Notes: '', StartTime: null, EndTime: null, Attachments: [], expanded: undefined }
      this.scheduleItemsService.saveScheduleItem(newItem).subscribe();
    }
  }

  rowClicked(params: any) {
    // this.toggleDetailsRow(params.data);
    // this.gridApi?.refreshCells({ force: true });
  }

  toggleDetailsRow(data: any) {
    if (data.isFullWidth) { return; }
    if (data.expanded && !(data.isFullWidth ?? false)) {
      data.expanded = false;
      let index = this.filteredScheduleItems.findIndex((x: any) => x.ID === data.ID && x.isFullWidth);
      if (index > -1) { // remove details row if parent row is clicked again.
        const rowToRemove = this.filteredScheduleItems.find((x: any) => x.ID === data.ID && x.isFullWidth);
        this.filteredScheduleItems.splice(index, 1);
        this.gridApi?.applyTransaction({ remove: [rowToRemove] });
        return;
      }
    }

    this.collapseAll();

    const index = this.filteredScheduleItems.findIndex(x => x.ID === data.ID);
    data.expanded = true;
    const newData = { ...data };
    newData.isFullWidth = true;
    this.filteredScheduleItems.splice(index + 1, 0, newData);
    this.gridApi?.applyTransaction({ add: [newData], addIndex: index + 1 });
  }

  collapseAll() {
    const rowsToRemove: Object[] = [];
    for (let i = 0; i < this.filteredScheduleItems.length; i++) {
      const item = this.filteredScheduleItems[i] as any;
      if (item.isFullWidth) {
        rowsToRemove.push(item);
        this.filteredScheduleItems.splice(i, 1);
      }
    }

    this.gridApi?.applyTransaction({ remove: rowsToRemove });
    for (const item of this.filteredScheduleItems) {
      item.expanded = false;
    }
  }

  cellClicked(event: CellClickedEvent) {
    const column = event.column.getColId();

    if (event.data.ID !== this.selectedScheduleItem?.ID && column !== 'Attachments.length') {
      this.collapseAll();
      this.gridApi?.refreshCells({ force: true });
      return;
    }

    if (column === 'Attachments.length') {
      event.event?.stopPropagation();
      event.event?.preventDefault();
      this.toggleDetailsRow(event.data);
      this.gridApi?.refreshCells({ force: true });
    }
  }

  dragover(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (rowId) {
      this.addHoverClass(rowId);
    }
  }

  dragEnd(event: any) {
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
  }

  drop(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (!rowId) { return }
    this.saveFiles(rowId, e.dataTransfer.files);
  }

  saveFiles(rowId: string, files: any) {
    let scheduleItem: IScheduleItem | undefined;
    if (rowId.includes('FullWidth')) {
      const parsedId = parseInt(rowId.substring(0, rowId.indexOf('FullWidth')));
      scheduleItem = this.scheduleItems.find(x => x.ID === parsedId);
    }
    else {
      scheduleItem = this.scheduleItems.find(x => x.ID === parseInt(rowId))
    }

    if (scheduleItem === undefined) {
      console.error('Could not find schedule item with rowId ' + rowId);
      return
    }

    this.scheduleItemsService.saveAttachments(scheduleItem.ID, files)?.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * event.loaded / Number(event.total));
      }
    });
  }

  getRowId(element: HTMLElement | null) {
    if (!element) { return null }
    let rowId = element.getAttribute('row-id');
    if (rowId) { return rowId; }

    while (!rowId) {
      element = element.parentElement;
      if (element === null) { return null; }
      if (element.classList.contains('drop-attachment')) {
        rowId = element.getAttribute('row-id');
      }
    }
    return rowId;
  }

  addHoverClass(rowId: string) {
    if (rowId === 'b-0') { return; }
    document.querySelectorAll(`div[row-id="${rowId}"`).forEach(element => element.classList.add('drag-drop-attachment-hover'));
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.onCellValueChanged = this.cellValueChanged.bind(this);
    this.gridOptions.getRowNodeId = (params: any) => params.isFullWidth ? (params.ID + 'FullWidth') : params.ID;
    this.gridOptions.immutableData = true;
    this.gridOptions.onCellClicked = this.cellClicked.bind(this);
    this.gridOptions.isRowSelectable = rowNode => rowNode.data ? (!rowNode.data.isFullWidth) : false;
    this.gridOptions.rowClass = "drop-attachment";
    this.gridOptions.onGridSizeChanged = (event) => this.gridApi?.sizeColumnsToFit();

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1,
        newRowAction: 'keep'
      },
    };

    this.cols = [
      {
        field: 'JobName', headerName: 'Job Name', floatingFilterComponent: 'autoCompleteFloatingFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true, options: this.jobsFilter, filterChangedEvent: (event: any) => {
            this.JobFilterChangedEvent.emit(event);
            this.isEmittingFilterEvent = true;
            setTimeout(() => {
              this.isEmittingFilterEvent = false;
            }, 200);
          }
        },
      },
      { field: 'Attachments.length', headerName: 'Attachments', editable: false, width: 90 },
      {
        field: 'Trade', headerName: 'Trade',
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.tradesFilterList },
        editable: (params: any) => true, cellEditor: 'selectEditor',
        cellEditorParams: {
          options: this.tradesFilterList.asObservable(),
          clear: false,
        }
      },
      {
        field: 'Contractor', headerName: 'Contractor',
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.contractorFilterList },
        editable: (params: any) => true, cellEditor: 'selectEditor',
        cellEditorParams: {
          options: this.contractorFilterList.asObservable(),
          clear: true,
        }
      },
      {
        field: 'Status', headerName: 'Status', width: 120, cellStyle: (params: any) => {
          const status = this.scheduleStatusList.find(x => x.Name === params.value);
          const cellStyle = status !== undefined ? { color: status.FontColor, backgroundColor: status.BackgroundColor, fontWeight: status.FontWeight } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        },
        filter: 'scheduleStatusFilter', floatingFilterComponent: 'scheduleStatusFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true },
        editable: (params: any) => true,
        cellEditor: 'selectEditor',
        cellEditorParams: {
          options: this.scheduleStatusList$.asObservable(),
          clear: false,
        }
      },
      {
        field: 'StartTime', headerName: 'Start Time', width: 130, filter: 'agDateColumnFilter', filterParams: this.filterParams,
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        editable: (params: any) => true, cellEditor: 'datePickerEditor'
      },
      {
        field: 'EndTime', headerName: 'End Time', width: 130, filter: 'agDateColumnFilter', filterParams: this.filterParams,
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        editable: (params: any) => true, cellEditor: 'datePickerEditor'
      },
      {
        field: 'Notes', headerName: 'Notes', flex: 1, minWidth: 130, width: 250, editable: (params: any) => true,
        cellEditor: 'textareaEditor'
      },
    ];
    setTimeout(() => { this.gridActivated = true; });
  }
}
