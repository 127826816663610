import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService, User } from './authentication.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {

  baseUrl = getBaseUrl();
  isRefreshingToken = false;

  constructor(private authenticationService: AuthenticationService, private snackBar: MatSnackBar) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    // const user = this.authenticationService.userValue;
    // const isLoggedIn = user && user.JwtToken;
    const isLoggedIn = this.authenticationService.isAuthenticated;
    const isApiUrl = this.isSameOriginUrl(request);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        // setHeaders: { Authorization: `Bearer ${user?.JwtToken}` },
        withCredentials: true,
        url: `${this.baseUrl}/${request.url}`
      });
    }
    else {
      const apiReq = request.clone({ url: `${this.baseUrl}/${request.url}` });
      return next.handle(apiReq);
    }
    return next.handle(request).pipe(catchError(err => {
      return throwError(err);
      // if (request.url.includes('login')) {
      //   return throwError(err);
      // }

      // if (err.status === 401) {
      //   //return this.handleUnauthorized(request, next);
      //   this.authenticationService.navigateToLogin();

      //   if (request.url.includes('check-auth')) {
      //     return throwError(err);
      //   }

      // }
      // if (request.method == 'POST' || request.method == 'PUT') {
      //   if (err.error?.title) {
      //     this.snackBar.open(`Save Failed: ${err?.error?.title}`, "Dismiss", { horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-error-message', duration: 4000 });
      //   }
      //   else if (err.status === 0) {
      //     this.snackBar.open(`Save Failed:  No internet connection.`, "Dismiss", { horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-error-message', duration: 4000 });
      //   }
      //   else {
      //     this.snackBar.open(`Save Failed: ${err?.error}`, "Dismiss", { horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-error-message', duration: 4000 });
      //   }
      // }
      // else if (request.method === 'DELETE') {
      //   if (err.error?.title) {
      //     this.snackBar.open(`Delete Failed: ${err?.error?.title}`, "Dismiss", { horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-error-message', duration: 4000 });
      //   }
      //   else {
      //     this.snackBar.open(`Delete Failed: ${err?.error}`, "Dismiss", { horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-error-message', duration: 4000 });
      //   }
      // }
      // return throwError(err);
    }));

  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
  }

  private isSameOriginUrl(req: any): boolean {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(`/${req.url}`)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }


  tokenSubject = new Subject<string>();
  handleUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(undefined);
      // get a new token via userService.refreshToken
      return this.authenticationService.refreshToken()
        .pipe(switchMap((user: User) => {
          // did we get a new token retry previous request
          // const newToken = user ?? "";
          // if (user?.JwtToken) {
          //   this.tokenSubject.next(newToken);
          //   return next.handle(this.addToken(req, newToken));
          // }

          // If we don't get a new token, we are in trouble so logout.
          this.authenticationService.logout();
          return throwError('');

        })
          , catchError(error => {
            // If there is an exception calling 'refreshToken', bad news so logout.
            this.authenticationService.logout();
            return throwError(error);
          })
          , finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      return this.tokenSubject
        .pipe(
          filter(token => token != null)
          , take(1)
          , switchMap(token => {
            return next.handle(this.addToken(req, token));
          })
        );
    }
  }
}



export function getBaseUrl(): string {
  const baseRef = document.getElementsByTagName('base')[0].href;

  if (environment.production || !baseRef.includes('localhost:4200')) {
    return document.getElementsByTagName('base')[0].href + 'api';
  }
  return 'https://localhost:5001/api';  // DevURL.. Might need to change it...
}
