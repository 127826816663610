import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SplitComponent } from 'angular-split';
import { Subscription } from 'rxjs';
import { AttachmentsService } from '../attachments.service';
import { ContractViewSettingsService, IContractViewLayout } from '../contract-view-settings.service';
import { ContractsService, IContract } from '../contracts.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {
  @ViewChild('contractListSplitter') contractListSplitter: SplitComponent | undefined;
  @ViewChild('expensesSplitter') expensesSplitter: SplitComponent | undefined;

  orderCount = 0;
  attachmentCount = 0;
  subscriptions: Subscription = new Subscription();
  tabIndex = 0;
  private isSettingView = false;
  selectedContract: IContract | null = null;

  constructor(contractsService: ContractsService, private contractViewService: ContractViewSettingsService, private attachmentService: AttachmentsService) {
    this.subscriptions.add(contractsService.selectedContract.subscribe(data => {
      this.selectedContract = data;
      this.orderCount = data == null ? 0 : data.Orders;
      this.attachmentCount = data == null ? 0 : data.AttachmentCount;
    }));

    this.subscriptions.add(this.contractViewService.contractLayoutView.subscribe(data => {
      this.isSettingView = true;
      this.tabIndex = data?.DetailTabSelected ?? 0;

      if (data?.Splitters.ContractsExpenses) {
        this.contractListSplitter?.setVisibleAreaSizes(data.Splitters.ContractsList)
      }
      if (data?.Splitters.ContractsExpenses) {
        this.expensesSplitter?.setVisibleAreaSizes(data.Splitters.ContractsExpenses);
        this.expensesSplitter?.notify("progress", 1);
      }
      setTimeout(() => {
        this.isSettingView = false;
      }, 10);
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
  }

  tabChanged(tabIndex: number) {
    this.tabIndex = tabIndex;
    this.layoutUpdated();
  }

  layoutUpdated() {
    if (this.isSettingView) { return; }
    const contractListSplitterSize = this.contractListSplitter!.getVisibleAreaSizes();
    const expenseSplitterSize = this.expensesSplitter!.getVisibleAreaSizes();

    const contractViewLayout: IContractViewLayout = {
      DetailTabSelected: this.tabIndex,
      Splitters: { ContractsList: contractListSplitterSize, ContractsExpenses: expenseSplitterSize }
    }

    this.contractViewService.saveLayoutViewOnClient(contractViewLayout);
  }

  dragover(e: any): void {
    e.preventDefault();
  }

  drop(e: any): void {
    e.preventDefault();

    if (!this.selectedContract) { return; }

    const files = e.dataTransfer.files;
    this.attachmentService.uploadFiles(this.selectedContract.DocId, files)?.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * event.loaded / Number(event.total));
        //console.log(progress);
      }
    });
  }
}
