import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Snippet, SnippetsService } from '../snippets.service';

@Component({
  selector: 'app-snippet-edit',
  templateUrl: './snippet-edit.component.html',
  styleUrls: ['./snippet-edit.component.scss']
})
export class SnippetEditComponent implements OnInit {

  subscriptions = new Subscription();
  selectedSnippet: Snippet | null = null;
  form = this.formBuilder.group({
    Id: [0],
    Keyword: ['', Validators.required],
    Parameter: [''],
    Text: ['', Validators.required]
  });

  constructor(private snippetService: SnippetsService, private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.subscriptions.add(this.snippetService.selectedSnippet.subscribe(data => {
      if (data !== null) {
        this.selectedSnippet = data
        this.form.patchValue(data);
      }
      else {
        if (data == null && this.selectedSnippet !== null) {
          this.newSnippet();
        }
      }
    }));

  }

  newSnippet() {
    const newSnippet: Snippet = {
      Id: 0,
      Keyword: '',
      Parameter: '',
      Text: '',
    }
    this.form.patchValue(newSnippet);
    this.selectedSnippet = null;
    this.snippetService.selectSnippet(null);
  }

  submit(): void {

    if (this.form.valid) {
      const snippet = this.form.value as Snippet;
      if (snippet.Id > 0) {
        this.snippetService.update(snippet).subscribe();
      }
      else {
        this.snippetService.add(snippet).subscribe(data => { this.form.patchValue(data); this.selectedSnippet = data });
      }
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  deleteSnippet() {
    if (this.selectedSnippet === null) { return; }
    this.snippetService.delete(this.selectedSnippet).subscribe();
  }

}
