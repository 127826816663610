import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IProposalTemplate, ProposalTemplatesService } from 'src/app/projects/services/proposal-templates.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { IRep, IRepCreateDto, RepsService } from '../reps.service';

@Component({
  selector: 'app-rep-edit',
  templateUrl: './rep-edit.component.html',
  styleUrls: ['./rep-edit.component.scss']
})
export class RepEditComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  selectedRep: IRep | null = null;
  signatureFile: File | null = null;
  hide = true;
  templates: IProposalTemplate[] = [];

  public signatureImageUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  repForm = this.fb.group({
    Id: [0],
    Name: ['', Validators.required],
    PhoneNumber: [''],
    Email: ['', [Validators.email, Validators.required]],
    DefaultProposalTemplateId: [null],
    Password: ['', [Validators.required, Validators.minLength(6)]],
    ConfirmPassword: ['', [Validators.required, Validators.min(6)]]
  }, { validators: this.checkPasswords });


  constructor(private repsService: RepsService, private proposalTemplatesService: ProposalTemplatesService, private fb: FormBuilder, public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.subscriptions.add(this.proposalTemplatesService.templates.subscribe(data => this.templates = data));
    this.repsService.selectedRep.subscribe(data => {
      if (data === null) {
        this.selectedRep = null;
        this.signatureImageUrl$.next('');
        this.newRep();
      } else {
        this.selectedRep = data;
        this.resetForm();
        this.repForm.setValue(this.selectedRep);
        this.signatureImageUrl$.next(this.repsService.signatureLink(data.Id));
      }
    });
  }

  resetForm() {
    if (this.selectedRep === null) {
      this.repForm = this.fb.group({
        Id: [0],
        Name: ['', Validators.required],
        Email: ['', [Validators.email, Validators.required]],
        PhoneNumber: [''],
        DefaultProposalTemplateId: [null],
        Password: ['', Validators.required],
        ConfirmPassword: ['', Validators.required]
      });
    }
    else {
      this.repForm = this.fb.group({
        Id: [0],
        Name: ['', Validators.required],
        PhoneNumber: [''],
        Email: ['', Validators.email],
        DefaultProposalTemplateId: [null],
      });
    }
  }

  newRep() {
    this.selectedRep = null;
    // const newRep: IRep = { Id: 0, Name: '', PhoneNumber: '', Email: '' };
    // this.repForm.setValue(newRep);
    this.resetForm();
    this.signatureImageUrl$.next('');
    if (this.selectedRep !== null) {
      this.selectedRep == null;
      this.repsService.selectRep(null);
    }
  }

  saveRep() {
    if (this.selectedRep === null) {
      this.saveNewRep();
    }
    else {
      this.updateRep();
    }
  }

  saveNewRep() {
    if (this.repForm.valid) {
      const newRep = this.repForm.value as IRepCreateDto;
      this.repsService.add(newRep).subscribe(data => {
        this.selectedRep = data;
        this.resetForm();
        this.repForm.setValue(data);
        this.repsService.selectRep(data);
      });
    }
    else {
      this.repForm.markAllAsTouched();
    }
  }

  updateRep() {
    if (this.repForm.valid) {
      const rep = this.repForm.value as IRep;
      this.repsService.update(rep).subscribe(data => { this.repsService.selectRep(data); this.repForm.setValue(data); });
    }
    else {
      this.repForm.markAllAsTouched();
    }
  }

  deleteRep() {
    if (this.selectedRep) {
      this.repsService.delete(this.selectedRep).subscribe(data => { this.repsService.selectRep(null); this.repForm.reset(); });
    }
  }

  updateSignature() {
    if (this.selectedRep !== null && this.signatureFile !== null) {
      const formData = new FormData();
      formData.append('Signature', this.signatureFile);
      this.repsService.updateSignature(this.selectedRep, formData).subscribe(data => { this.signatureImageUrl$.next(''); this.signatureFile = null; this.repsService.selectRep(data); });
    }
  }

  attachSignature(event: any) {
    this.signatureFile = <File>event.target.files[0];
    this.updateSignature();
  }

  checkPasswords(group: FormGroup) {
    const password = group.get('Password')?.value;
    const confirmPassword = group.get('ConfirmPassword')?.value;
    return password === confirmPassword ? null : { notSame: true }
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      // width: '250px',
      data: { selectedRep: this.selectedRep }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) { return; }

      console.log(result);

    });
  }

}
