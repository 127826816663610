import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionButtonComponent } from 'src/app/ag-grid-components/action-button/action-button.component';
import { getBaseUrl } from 'src/app/api-authorization/authorize.interceptor';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-components/confirm-dialog/confirm-dialog.component';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { AttachmentsService, IAttachmentDto } from '../attachments.service';
import { ContractsService, IContract } from '../contracts.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  attachments: IAttachmentDto[] = [];
  selectedAttachment: IAttachmentDto | null = null;
  selectedContract: IContract | null = null;
  selectedAttachmentUrl: string = "";

  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  rowHeight() { return this.gridOptions.rowHeight ? this.gridOptions.rowHeight : 20; }
  gridActivated = false;
  defaultColDef: any;
  cols: any;
  groupedCols: any;

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      var cellDate = new Date(cellValue); // new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private attachmentsService: AttachmentsService, private contractsService: ContractsService, userSettings: UserSettingsService, private dialog: MatDialog) {
    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.contractsService.selectedContract.pipe(switchMap(contract => {
      this.attachments = [];
      this.selectedAttachment = null;
      this.selectedContract = null;
      if (contract) {
        this.selectedContract = contract;
        return this.attachmentsService.getAttachments(contract.DocId);
      }
      return EMPTY;
    })).subscribe(data => this.attachments = data));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows();
    if (selectedRows) {
      this.selectedAttachment = selectedRows[0];
      this.selectedAttachmentUrl = `/Attachments/${this.selectedAttachment?.Id}/${encodeURIComponent(this.selectedAttachment?.FileName ?? "")}`
    }
  }

  layoutUpdated() { }

  openAttachment(params: any) {
    window.open(`${getBaseUrl()}${this.selectedAttachmentUrl}`, "_blank")
  }

  addAttachment(event: any) {
    if (this.selectedContract) {
      this.attachmentsService.uploadFiles(this.selectedContract.DocId, event.target.files)?.subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / Number(event.total));
          // console.log(progress);
        }
      });
    }
  }

  deleteAttachment() {
    const message = `Are you sure you want to delete ${this.selectedAttachment?.FileName} attachment?`;
    const dialogData = new ConfirmDialogModel("Confirm Delete", message);

    if (!this.selectedAttachment) { return }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.attachmentsService.deleteAttachment(this.selectedAttachment!.Id).subscribe();
        this.attachments = this.attachments.filter(a => a.Id !== this.selectedAttachment?.Id);
        this.selectedAttachment = null;
      }
    });
  }



  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;

    this.gridOptions.onRowDoubleClicked = this.openAttachment.bind(this);
    this.gridOptions.frameworkComponents = { 'btnCellRenderer': ActionButtonComponent };

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'FileName', headerName: 'Name', flex: 1 },
      {
        headerName: 'Action', cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          clicked: function (params: any) {
            const data = params.data;
            window.open(`${getBaseUrl()}/Attachments/${data?.Id}/${encodeURIComponent(data?.FileName)}`, "_blank")
          }
        },
        minWidth: 100,
        width: 100,
        filterParams: null,
        filter: null,
      }
    ];
    this.gridOptions.columnDefs = this.cols;
    setTimeout(() => { this.gridActivated = true; });
  }
}
