import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    submitted = false;
  returnUrl = '';
  error = '';
  hide = true;
  isServerUrl = false;
  saving = false;
  form: FormGroup = this.formBuilder.group({
    Username: ['', [Validators.required, Validators.email]],
    Password: ['', [Validators.required]]
  });

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {
    // redirect to home if already logged in
    if (this.authenticationService.userValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    if (this.returnUrl === null) {
      returnUrl = this.route.snapshot.queryParamMap.get('ReturnUrl');
      this.isServerUrl = true;
    }
    this.returnUrl = returnUrl ?? '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  submit(): void {
    this.submitted = true;
    this.error = "";
    // stop here if form is invalid
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.saving = true;
    this.authenticationService.login(this.f.Username.value, this.f.Password.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.saving = false;
          if (this.isServerUrl) {
            window.location.href = this.returnUrl;
          }
          else {
            this.router.navigate([this.returnUrl]);
          }
        },
        error: error => {
          console.error(error);
          this.error = error.error.message
          this.saving = false;
        }
      });
  }
}
