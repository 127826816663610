import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IRep, RepChangePassword, RepsService } from '../reps.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  selectedRep: IRep | null = null;
  hide = true;

  changePasswordForm = this.fb.group({
    Password: ['', [Validators.required, Validators.minLength(6)]],
    ConfirmPassword: ['', [Validators.required, Validators.min(6)]]
  }, { validators: this.checkPasswords });

  constructor(private fb: FormBuilder, private repsService: RepsService, private dialogRef: MatDialogRef<ChangePasswordComponent>) { }


  ngOnInit(): void {
    this.subscriptions.add(this.repsService.selectedRep.subscribe(data => this.selectedRep = data));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit() {
    if (!this.selectedRep) { return; }
    if (this.changePasswordForm.valid) {
      const changePassword: RepChangePassword = {
        UserName: this.selectedRep.Email,
        Password: this.changePasswordForm.value.Password,
        ConfirmPassword: this.changePasswordForm.value.ConfirmPassword,
        OldPassword: ''
      };
      this.repsService.changeUserPassword(changePassword).subscribe(data => this.dialogRef.close());
    }
    else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  checkPasswords(group: FormGroup) {
    const password = group.get('Password')?.value;
    const confirmPassword = group.get('ConfirmPassword')?.value;
    return password === confirmPassword ? null : { notSame: true }
  }
}
