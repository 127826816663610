import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EmailTemplate, IRep, RepsService } from '../reps.service';

@Component({
  selector: 'app-rep-email-template',
  templateUrl: './rep-email-template.component.html',
  styleUrls: ['./rep-email-template.component.scss']
})
export class RepEmailTemplateComponent implements OnInit {


  subscriptions: Subscription = new Subscription();
  selectedRep: IRep | null = null;

  emailTemplateForm = this.fb.group({
    Subject: [''],
    Body: [''],
  });



  constructor(private repsService: RepsService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.subscriptions.add(this.repsService.selectedRep.subscribe(data => {
      this.selectedRep = data;
      if (data) {
        this.repsService.getEmailTemplate(data.Id).subscribe(d => {
          this.emailTemplateForm.setValue(d);
          this.emailTemplateForm.markAsUntouched();
        });
      }
      else {
        this.resetForm();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resetForm() {
    this.emailTemplateForm = this.fb.group({
      Subject: [''],
      Body: [''],
    });
  }

  save() {
    console.log("save");
    if (this.selectedRep == null) {
      return;
    }
    const emailTemplate = this.emailTemplateForm.value as EmailTemplate
    this.repsService.postEmailTemplate(this.selectedRep.Id, emailTemplate).subscribe();
  }
}
