import { Component } from '@angular/core';
import { FilterChangedEvent, GridOptions, IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-remaining-floating-filter',
  templateUrl: './remaining-floating-filter.component.html',
  styleUrls: ['./remaining-floating-filter.component.scss']
})
export class RemainingFloatingFilterComponent implements IFloatingFilter {

  params: IFloatingFilterParams | undefined;
  gridOptions: GridOptions = {};

  selectedRemaining = "All"

  constructor() {
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.selectedRemaining = 'All';
    }
    else {
      this.selectedRemaining = parentModel;
    }
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged() {
    this.params?.parentFilterInstance((instance) => {
      instance.setModel(this.selectedRemaining);
    });
  }
}

