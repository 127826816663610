import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SnippetsService {

  private snippets$: ReplaySubject<Snippet[]> = new ReplaySubject(1);
  public get snippets(): Observable<Snippet[]> { if (!this.isSnippetsLoaded) { this.loadSnippets(); } return this.snippets$.asObservable(); }

  private selectedSnippet$: ReplaySubject<Snippet | null> = new ReplaySubject(1);
  public get selectedSnippet(): Observable<Snippet | null> { return this.selectedSnippet$.asObservable(); }

  baseUrl = 'snippets';
  isSnippetsLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadSnippets(): void {
    this.isSnippetsLoaded = true;
    const url = `${this.baseUrl}`;
    this.httpClient.get<Snippet[]>(url).subscribe(data => { this.snippets$.next(data); });
  }

  selectSnippet(snippet: Snippet | null | number) {
    setTimeout(() => {
      if (typeof (snippet) === 'number') {
        this.snippets.pipe(take(1)).subscribe(data => {
          const p = data.find(p => p.Id === snippet);
          this.selectedSnippet$.next(p ?? null);
          if (p) {
            this.getSnippet(p.Id);
          }
        });
      }
      else {
        this.selectedSnippet$.next(snippet);
        if (snippet) {
          this.getSnippet(snippet.Id);
        }
      }
    }, 1);
  }

  getSnippet(id: number): void {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<Snippet>(url).subscribe(data => { this.updateSnippets(1, [data]); this.selectedSnippet$.next(data) });
  }

  add(snippet: Snippet) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<Snippet>(url, snippet).pipe(catchError(err => this.handleError(err)), tap((data => this.updateSnippets(0, [data]))));
  }

  update(snippet: Snippet) {
    const url = `${this.baseUrl}/${snippet.Id}`;
    return this.httpClient.put<Snippet>(url, snippet).pipe(catchError(err => this.handleError(err)), tap((data => this.updateSnippets(1, [data]))));
  }

  delete(snippet: Snippet) {
    const url = `${this.baseUrl}/${snippet.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete snippet.")), tap((data => this.updateSnippets(2, [snippet]))));
  }

  private handleError(err: any, message: string = "Failed to save snippet. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateSnippets(crudType: number, snippets: Snippet[]) {
    this.snippets.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const snippet of snippets) {
          const index = data.findIndex(c => c.Id === snippet.Id);
          if (index === -1) {
            data.push(snippet);
          }
          else {
            data[index] = snippet;
          }
        }
      }
      else if (crudType === 2) {
        for (const snippet of snippets) {
          const index = data.findIndex(c => c.Id === snippet.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.snippets$.next([...data]);
    });
  }
}

export interface Snippet {
  Id: number,
  Keyword: string,
  Parameter: string,
  Text: string
}