import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import { CellPosition, ColumnApi, EditableCallbackParams, GridApi, GridOptions, GridReadyEvent, ICellEditorParams, NavigateToNextCellParams, RowNode } from 'ag-grid-community';
import { MonthFloatingFilterComponent } from 'src/app/ag-grid-components/month-floating-filter/month-floating-filter.component';
import { RemainingFilterComponent } from 'src/app/ag-grid-components/remaining-filter/remaining-filter.component';
import { RemainingFloatingFilterComponent } from 'src/app/ag-grid-components/remaining-floating-filter/remaining-floating-filter.component';
import { YearFloatingFilterComponent } from 'src/app/ag-grid-components/year-floating-filter/year-floating-filter.component';
import { StringExt } from 'src/app/utils/string';
import { Contract, ContractsService, IContract } from '../contracts.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { IContractGridState, ContractViewSettingsService } from '../contract-view-settings.service';
import { YearFilterComponent } from 'src/app/ag-grid-components/year-floating-filter/year-filter.component';
import { MonthFilterComponent } from 'src/app/ag-grid-components/month-floating-filter/month-filter.component';
import { ViewType } from 'src/app/saved-layouts/saved-layouts.service';
import { TotalPinnedRowRendererComponent } from 'src/app/ag-grid-components/total-pinned-row-renderer/total-pinned-row-renderer.component';
import { ISelectEditorOption, SelectEditorComponent } from 'src/app/ag-grid-components/select-editor/select-editor.component';
import { DatePickerEditorComponent } from 'src/app/ag-grid-components/date-picker-editor/date-picker-editor.component';
import { NumberEditorComponent } from 'src/app/ag-grid-components/number-editor/number-editor.component';
import { SelectFloatingFilterComponent } from 'src/app/ag-grid-components/select-floating-filter/select-floating-filter.component';

import { JobSelectEditorComponent } from 'src/app/ag-grid-components/job-select-editor/job-select-editor.component';

import { debounceTime, map } from 'rxjs/operators';
import { TextareaEditorComponent } from 'src/app/ag-grid-components/textarea-editor/textarea-editor.component';
import { AttachmentsService } from '../attachments.service';
import { HttpEventType } from '@angular/common/http';
import { IJob, JobsService } from '../jobs.service';
import { CrewsService } from '../crews.service';
import { AppSettingsService, IStatus, Settings } from 'src/app/settings/app-settings.service';
import { ContractStatusFilterComponent } from 'src/app/ag-grid-components/contract-status-floating-filter/contract-status-filter/contract-status-filter.component';
import { ContractStatusFloatingFilterComponent } from 'src/app/ag-grid-components/contract-status-floating-filter/contract-status-floating-filter.component';
import { PermitStatusFilterComponent } from 'src/app/ag-grid-components/permit-status-floating-filter/permit-status-filter/permit-status-filter.component';
import { PermitStatusFloatingFilterComponent } from 'src/app/ag-grid-components/permit-status-floating-filter/permit-status-floating-filter.component';


@Component({
  selector: 'app-contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrls: ['./contracts-list.component.scss']
})
export class ContractsListComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptions = new Subscription();
  contracts: Contract[] = new Array<Contract>();
  selectedContract: Contract | null = null;
  reps: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  crews: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  jobs: IJob[] = [];
  filteredJobs: BehaviorSubject<IJob[]> = new BehaviorSubject(new Array<IJob>());
  contractsCount: number = 0;
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  selectableColumns: SelectableColumn[] = [];
  selectedColumns = new FormControl();
  statusFilter = 'All';
  viewType: ViewType = ViewType.Contracts;
  contractStatusList: IStatus[] = [];
  contractStatusList$: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  permitStatusList: IStatus[] = [];
  permitStatusList$: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());

  frameworkComponents = {
    yearFilterComponent: YearFloatingFilterComponent,
    yearFilter: YearFilterComponent,
    monthFilterComponent: MonthFloatingFilterComponent,
    monthFilter: MonthFilterComponent,
    remainingFilter: RemainingFilterComponent,
    remainingFloatingFilterComponent: RemainingFloatingFilterComponent,
    totalPinnedRow: TotalPinnedRowRendererComponent,
    selectEditor: SelectEditorComponent,
    datePickerEditor: DatePickerEditorComponent,
    numberEditor: NumberEditorComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    jobSelectEditor: JobSelectEditorComponent,
    textareaEditor: TextareaEditorComponent,
    contractStatusFilter: ContractStatusFilterComponent,
    contractStatusFloatingFilter: ContractStatusFloatingFilterComponent,
    permitStatusFilter: PermitStatusFilterComponent,
    permitStatusFloatingFilter: PermitStatusFloatingFilterComponent,
  };

  pinnedBottomRowData = [];
  rowClassRules = { 'contract-isClosed': (params: any) => params.data.IsClosed }
  defaultColDef: any;
  cols: any;

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };



  private scrollTopBeforeSelection: number | undefined;
  @ViewChild(MatSelect, { static: true }) mySelect: MatSelect | undefined;
  gridState: IContractGridState | null = null;
  private isSettingSelectedColumns = false;
  private isSettingSavedGridState = false;
  private returnUrl: string | null = null;
  private gridFilterApplied = false;
  sendFilterChange$: Subject<string> = new Subject();

  constructor(private contractsService: ContractsService, userSettings: UserSettingsService, private contractViewSettings: ContractViewSettingsService, private jobsService: JobsService, private crewsService: CrewsService,
    private attachmentService: AttachmentsService, private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private route: ActivatedRoute, private router: Router, private location: Location,
    private locationStrategy: LocationStrategy, private settingsService: AppSettingsService) {

    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    this.subscriptions.add(this.contractViewSettings.contractGridViewSettings.subscribe(data => {
      this.gridState = data;
      if (this.gridApi && this.gridActivated) {
        setTimeout(() => { this.setGridState(); }, 0);
      }
    }));

    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.jobsService.loadJobs().subscribe(data => this.jobs = data));
    let contractId = this.route.snapshot.paramMap.get('id');
    this.subscriptions.add(this.contractsService.loadContracts().subscribe(data => {
      this.contracts = data;
      setTimeout(() => { this.countRows(); this.sendFilterChanged(); }, 25);
      const reps = this.contracts.map(c => c.Rep).filter((v, i, a) => a.indexOf(v) === i && !StringExt.isNullOrWhiteSpace(v));
      this.reps.next(reps.map(r => ({ Value: r, Text: r })));
      const crews = this.contracts.map(c => c.Crew).filter((v, i, a) => a.indexOf(v) === i && !StringExt.isNullOrWhiteSpace(v));
      this.crews.next(crews.map(r => ({ Value: r, Text: r })));
      if (!StringExt.isNullOrWhiteSpace(contractId ?? '')) {
        const contract = this.contracts.find(f => f.ContractID === contractId);
        contractId = ''; // Set to empty string so we don't select row when updating data later.
        if (contract) {
          this.contractsService.selectContract(contract);
          setTimeout(() => {
            this.gridApi?.forEachNodeAfterFilter(node => { if (node.data.ContractID === contract.ContractID) { node.setSelected(true); this.gridApi?.ensureIndexVisible(node.rowIndex) } })
          }, 40);
        }
      }
    }));

    this.subscriptions.add(this.contractsService.updatedContracts.subscribe(data => this.gridApi?.applyTransaction(data)));
    this.subscriptions.add(this.settingsService.settings.pipe(map(items => items.find(x => x.Key === Settings.ContractStatus))).subscribe(data => {
      if (data === undefined) { return; }
      this.contractStatusList = data.Data as IStatus[];
      this.contractStatusList$.next(this.contractStatusList.map(r => ({ Value: r.Name, Text: r.Name })));
    }));
    this.subscriptions.add(this.settingsService.settings.pipe(map(items => items.find(x => x.Key === Settings.PermitStatus))).subscribe(data => {
      if (data === undefined) { return; }
      this.permitStatusList = data.Data as IStatus[];
      this.permitStatusList$.next(this.permitStatusList.map(r => ({ Value: r.Name, Text: r.Name })));
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.sendFilterChange$.pipe(debounceTime(300)).subscribe(data => {
      const filteredContracts: Contract[] = [];
      this.gridApi?.forEachNodeAfterFilter(r => {
        filteredContracts.push(r.data);
      });
      this.contractsService.filteredContracts$.next(filteredContracts);
      if (this.returnUrl !== null) {
        this.router.navigateByUrl(this.returnUrl);
        this.returnUrl = null;
      }
    }));

    this.mySelect?.openedChange.subscribe((open) => {
      if (open) {
        this.mySelect?.panel.nativeElement.addEventListener(
          'scroll',
          (event: any) => (this.scrollTopBeforeSelection = event.target.scrollTop)
        );
      }
    });

    setTimeout(() => {
      this.mySelect?.optionSelectionChanges.subscribe(() => {
        if (this.mySelect?.panel) {
          this.mySelect.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
        }
      });
    }, 10);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.gridApi?.destroy();
    this.gridApi = undefined;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => { this.setGridState(); }, 200);
  }

  setGridState() {
    this.gridFilterApplied = true;
    if (!this.gridState) {
      this.isSettingSavedGridState = true;
      this.setSelectedColumns(this.selectableColumns.filter(f => f.Visible).map(m => m.Field));
      this.gridApi?.onFilterChanged();
      setTimeout(() => { this.isSettingSavedGridState = false; }, 100);
      return;
    }

    this.isSettingSavedGridState = true;
    const c = this.gridColumnApi?.applyColumnState({ state: this.gridState.ColumnState, applyOrder: true });
    if (!c) { console.error('Could not set column state.'); }

    this.statusFilter = this.gridState.ExternalFilterState.StatusFilter;
    this.gridApi?.setFilterModel(this.gridState.ColumnFilterState); // Needs to be last to trigger filter changed.

    if (Object.keys(this.gridState.ColumnFilterState).length === 0) {
      // If filter state is empty we need to trigger filter changed to count the rows in the grid.
      this.gridApi?.onFilterChanged();
    }

    setTimeout(() => { this.isSettingSavedGridState = false; }, 400);

    if (this.gridState.ColumnState.length > 0) {
      this.setSelectedColumns(this.gridState.ColumnState.filter(f => !f.hide).map(m => m.colId ?? ''));
    }
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows();
    let queryString = '';

    if (this.locationStrategy.path().split('?').length > 0) {
      queryString = this.locationStrategy.path().split('?')[1]
    }

    if (selectedRows && selectedRows!.length > 0) {
      const contract: Contract = Object.assign(new Contract(), selectedRows[0]);
      this.selectedContract = contract;
      if (!this.selectingRow) this.contractsService.selectContract(contract)
      this.location.replaceState(`contracts/${contract.ContractID}`, queryString);
    }
    else {
      this.contractsService.selectContract(null);
      this.selectedContract = null;
      this.location.replaceState(`contracts`, queryString);
    }
    this.filterJobs();
    // this.sendFilterChanged();
  }

  selectingRow = false;
  selectRow(jobName: string) {
    if (StringExt.isNullOrWhiteSpace(jobName)) {
      this.gridApi?.deselectAll();
      return;
    }
    const contract = this.contracts.find(f => f.JobName === jobName);
    jobName = ''; // Set to empty string so we don't select row when updating data later.
    if (contract) {
      this.selectingRow = true;
      this.contractsService.selectContract(contract);
      setTimeout(() => {
        this.gridApi?.deselectAll();
        this.gridApi?.forEachNodeAfterFilter(node => { if (node.data.ContractID === contract.ContractID) { node.setSelected(true); this.gridApi?.ensureIndexVisible(node.rowIndex) } })
      }, 1);
      setTimeout(() => {
        this.selectingRow = false;
      }, 100);
    }
  }

  selectedColumnsUpdated(event: any[]) {
    if (this.isSettingSelectedColumns) { return; }
    this.selectableColumns.forEach(c => c.Visible = event.includes(c.Field));
    const visibleColumns = this.selectableColumns.filter(c => c.Visible).map(m => m.Field);
    const hiddenColumns = this.selectableColumns.filter(c => !c.Visible).map(m => m.Field);
    this.gridColumnApi?.setColumnsVisible(visibleColumns, true)
    this.gridColumnApi?.setColumnsVisible(hiddenColumns, false);
    this.gridApi?.sizeColumnsToFit();
    this.saveViewState();
    this.gridApi?.resetRowHeights();
  }

  statusFilterUpdated(event: any) {
    this.statusFilter = event;
    this.gridApi?.onFilterChanged();
    this.saveViewState();
  }

  autoSizeAll(skipHeader: boolean = false) {
    var allColumnIds: any[] = [];
    this.gridColumnApi?.getAllColumns()?.forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    setTimeout(() => {
      this.gridColumnApi?.autoSizeColumns(allColumnIds, skipHeader);
    }, 0);

    setTimeout(() => {
      this.saveViewState();
    }, 100);
  }

  gridSortChanged() {
    if (!this.isSettingSavedGridState) {
      this.saveViewState();
    }
  }

  onFilterChanged(event: any) {
    const gApi = <any>this.gridApi;
    const rows = gApi.getModel()?.rootNode.childrenAfterFilter;
    this.countRows();
    this.sendFilterChanged();
    if (!this.isSettingSavedGridState) {
      this.saveViewState();
    }
    this.gridApi?.setPinnedBottomRowData(this.createTotalsRowData());
    const selectedRows = this.gridApi?.getSelectedRows();
    if (selectedRows) {
      const selectedRow = selectedRows[0];
      if (!selectedRow) { return; }
      const isRowInList = rows.some((data: any) => data.data.ContractID === selectedRow.ContractID);
      if (!isRowInList) {
        this.gridApi?.deselectAll();
      }
    }
  }

  sendFilterChanged() {
    if (!this.gridFilterApplied) { return; }
    this.sendFilterChange$.next('');
  }

  countRows() {
    this.contractsCount = this.gridApi?.getDisplayedRowCount() ?? 0;
  }

  doesExternalFilterPass(node: RowNode) {
    const data = <IContract>node.data;
    if (this.statusFilter != 'All') {
      switch (this.statusFilter) {
        case 'Open':
          if (data.IsClosed) { return false; }
          break;
        case 'Closed':
          if (!data.IsClosed) { return false; }
          break;
        case 'Incomplete':
          if (!StringExt.isNullOrWhiteSpace(data.Month)) { return false; }
          break;
        case 'Completed':
          if (data.IsClosed) { return false; }
          if (StringExt.isNullOrWhiteSpace(data.Month)) { return false; }
          break;
        default:
          console.error('Unsupported Status.')
      }
    }
    return true;
  }

  clearFilters() {
    this.statusFilter = 'All';
    this.gridApi?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  saveViewState() {
    if (!this.gridColumnApi) { return; }

    const viewSettings: IContractGridState = {
      ColumnState: this.gridColumnApi.getColumnState(),
      ColumnFilterState: this.gridOptions.api?.getFilterModel() ?? {},
      ExternalFilterState: { StatusFilter: this.statusFilter }
    }
    this.contractViewSettings.saveGridStateOnClient(viewSettings);
  }

  onColumnsMoved(params: any) {
    const visibleColumns: string[] = params.columnApi.getAllDisplayedColumns().map((col: any) => col.colId);
    this.saveViewState();
    this.setSelectedColumns(visibleColumns);
  }

  setSelectedColumns(columns: string[] | string) {
    this.isSettingSelectedColumns = true;
    this.selectedColumns.setValue(columns);
    this.gridApi?.resetRowHeights();
    setTimeout(() => { this.isSettingSelectedColumns = false; }, 100);
  }

  navigateToNextRow(params: NavigateToNextCellParams) {
    const nextCell = params.nextCellPosition;
    if (nextCell && nextCell.rowIndex >= 0) {
      this.gridApi?.forEachNode(node => {
        if (node.rowIndex === nextCell.rowIndex) {
          node.setSelected(true);
          this.gridApi?.ensureIndexVisible(node.rowIndex)
        }
      });
    }
    return params.nextCellPosition as CellPosition;
  }

  createTotalsRowData() {
    const gApi = <any>this.gridApi;
    const rows = gApi.getModel()?.rootNode.childrenAfterFilter as any[];

    const data = rows.map(r => r.data) as IContract[];
    const sumTotal = data.reduce((a: number, b: IContract) => a + b.Total, 0);
    const sumEstProfit = data.reduce((a: number, b: IContract) => a + b.EstProfit, 0);
    const sumActProfit = data.reduce((a: number, b: IContract) => a + b.ActProfit, 0);
    const sumEstLabor = data.reduce((a: number, b: IContract) => a + b.EstLabor, 0);
    const sumActLabor = data.reduce((a: number, b: IContract) => a + b.ActLabor, 0);

    const total = [{
      Total: sumTotal.toFixed(2),
      Paid: data.reduce((a: number, b: IContract) => a + b.Paid, 0).toFixed(2),
      Remaining: data.reduce((a: number, b: IContract) => a + b.Remaining, 0).toFixed(2),
      EstLabor: sumEstLabor.toFixed(2),
      ActLabor: sumActLabor.toFixed(2),
      Hours: data.reduce((a: number, b: IContract) => a + b.Hours, 0).toFixed(2),
      Est3rdParty: data.reduce((a: number, b: IContract) => a + b.Est3rdParty, 0).toFixed(2),
      Act3rdParty: data.reduce((a: number, b: IContract) => a + b.Act3rdParty, 0).toFixed(2),
      EstProfit: sumEstProfit.toFixed(2),
      ActProfit: sumActProfit.toFixed(2),
      EstMargin: (sumEstProfit == 0 ? 0 : (sumEstProfit / sumTotal) * 100).toFixed(2),
      ActMargin: (sumActProfit == 0 ? 0 : (sumActProfit / sumTotal) * 100).toFixed(2),
      EstLaborMargin: (sumEstLabor == 0 ? 0 : (sumEstProfit / sumEstLabor) * 100).toFixed(2),
      ActLaborMargin: (sumActLabor == 0 ? 0 : (sumActProfit / sumActLabor) * 100).toFixed(2),
      WorkDays: data.reduce((a: number, b: IContract) => a + (b.WorkDays ?? 0), 0).toFixed(2),
    }];
    return total;
  }

  isCellEditable(params: EditableCallbackParams) {
    if (params.node.rowPinned) {
      return false;
    }
    const contract: IContract = params.data;
    return !contract.IsClosed;
  }

  onCellValueChanged(params: ICellEditorParams) {
    const colId = params.column.getId();
    const contract: IContract = params.data;

    if (colId === 'SpruceNotes') {
      this.contractsService.updateSpruceNotes(contract).subscribe();
    }
    else {
      if (colId === 'Month') {
        if (StringExt.isNullOrWhiteSpace(contract.Year)) {
          contract.Year = new Date().getFullYear().toString();
        }
      }
      
      contract.Year = contract.Year?.toString() ?? "";       
      this.contractsService.updateContract(contract).subscribe();
    }
    this.gridApi?.redrawRows();
    this.gridApi?.setPinnedBottomRowData(this.createTotalsRowData());
    this.gridApi?.resetRowHeights();
  }

  filterJobs() {
    const filteredJobs = this.jobs.filter(j => {
      if (this.selectedContract === null) return false;
      const selectedJobWords = this.selectedContract.JobName.toLowerCase().split(' ');
      for (const v of selectedJobWords) {
        if (v.length > 1 && j.Name.toLowerCase().includes(v)) { return true; }
      }
      return false;
    });
    this.filteredJobs.next(filteredJobs);
  }

  dragover(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (rowId) {
      this.addHoverClass(rowId);
    }
  }

  dragEnd(event: any) {
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
  }

  drop(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (!rowId) { return }
    this.saveFiles(rowId, e.dataTransfer.files);
  }

  saveFiles(rowId: string, files: any) {
    const contract = this.contracts.find(c => c.ContractID === rowId);
    if (!contract) {
      console.error('Could not find contract with rowId ' + rowId);
      return
    }

    this.attachmentService.uploadFiles(contract.DocId, files)?.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * event.loaded / Number(event.total));
        // console.log(progress);
      }
    });
  }

  getRowId(element: HTMLElement | null) {
    if (!element) { return null }
    let rowId = element.getAttribute('row-id');
    if (rowId) { return rowId; }

    while (!rowId) {
      element = element.parentElement;
      if (element === null) { return null; }

      rowId = element.getAttribute('row-id');
    }
    return rowId;
  }

  addHoverClass(rowId: string) {
    if (rowId === 'b-0') { return; }
    document.querySelectorAll(`div[row-id="${rowId}"`).forEach(element => element.classList.add('drag-drop-attachment-hover'));
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.isExternalFilterPresent = () => true;
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this)
    this.gridOptions.onFilterChanged = (event) => this.onFilterChanged(event);
    this.gridOptions.navigateToNextCell = this.navigateToNextRow.bind(this);
    this.gridOptions.onDragStopped = (params) => this.onColumnsMoved(params);
    this.gridOptions.onSortChanged = () => this.gridSortChanged();
    this.gridOptions.getRowNodeId = (data: IContract) => data.ContractID;
    this.gridOptions.getRowHeight = (params: any) => {
      const selectedColumns: string[] = this.selectedColumns.value;
      const isVisible = selectedColumns?.includes('SpruceNotes');
      if (!isVisible) { return this.gridOptions.rowHeight; }
      const notes = params.data.SpruceNotes as string;
      const lines = notes?.split(/\n/g) ?? [];
      return lines.length > 1 ? Math.max((lines.length * 16) + 4, this.gridOptions.rowHeight ?? 22) : this.gridOptions.rowHeight;
    }
    //this.gridOptions.dateComponentFramework = CustomDatePickerComponent;

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
      pinnedRowCellRenderer: 'totalPinnedRow',
      pinnedRowCellRendererParams: { style: { 'font-weight': 'bold' } },
    };

    this.cols = [
      { field: 'JobName', headerName: 'Job Name', flex: 100, pinned: 'left' },
      { field: 'Reference', headerName: 'Reference', flex: 120 },
      { field: 'ContractID', headerName: 'Contract Id', width: 120 },
      {
        field: 'Rep', headerName: 'Rep', width: 120,
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.reps }
      },
      { field: 'Total', headerName: 'Total', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'Paid', headerName: 'Paid', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      {
        field: 'Remaining', headerName: 'Remaining', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', ''), cellStyle: (params: any) => {
          const isDue = params.data.IsDue;
          const isInvoicedInFull = params.data.IsInvoicedInFull;
          let cellStyle = isDue ? { color: 'Red' } : {};
          cellStyle = isInvoicedInFull ? { color: 'Green' } : cellStyle;
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }, filter: 'remainingFilter', floatingFilterComponent: 'remainingFloatingFilterComponent', floatingFilterComponentParams: { suppressFilterButton: true }
      },
      { field: 'EstLabor', headerName: 'Est Labor', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'ActLabor', headerName: 'Act Labor', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'Hours', headerName: 'Hours', width: 120, editable: (params: any) => this.isCellEditable(params), cellEditor: 'numberEditor' },
      { field: 'Est3rdParty', headerName: 'Est 3rd Party', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'Act3rdParty', headerName: 'Act 3rd Party', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'EstProfit', headerName: 'Est Profit', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'ActProfit', headerName: 'Act Profit', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'EstMargin', headerName: 'Est Margin', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'ActMargin', headerName: 'Act Margin', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'EstLaborMargin', headerName: 'Est Labor Margin', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      { field: 'ActLaborMargin', headerName: 'Act Labor Margin', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
      {
        field: 'Month', headerName: 'Month', width: 120, filter: 'monthFilter', floatingFilterComponent: 'monthFilterComponent', floatingFilterComponentParams: { suppressFilterButton: true },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any) => nodeA.data.MonthInt - nodeB.data.MonthInt,
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'selectEditor',
        cellEditorParams: {
          options: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          clear: true,
        }
      },
      {
        field: 'Year', headerName: 'Year', width: 120, filter: 'yearFilter', floatingFilterComponent: 'yearFilterComponent', floatingFilterComponentParams: { suppressFilterButton: true },
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'selectEditor',
        cellEditorParams: {
          options: [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1],
          clear: true,
        }
      },
      {
        field: 'ContractDate', headerName: 'Contract Date', flex: 120, sort: 'desc', filter: 'agDateColumnFilter', filterParams: this.filterParams,
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'datePickerEditor'
      },
      {
        field: 'Crew', headerName: 'Crew', width: 120, floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.crews },
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'selectEditor',
        cellEditorParams: {
          options: this.crewsService.crews.pipe(map(c => c.map(crew => ({ Value: crew.Name, Text: crew.Name })))),
          clear: true,
        }
      },
      {
        field: 'JobNum', headerName: 'Job', width: 120, editable: (params: any) => this.isCellEditable(params), cellEditor: 'jobSelectEditor', cellEditorParams: {
          options: this.filteredJobs.asObservable(),
          clear: true,
        }
      },
      {
        field: 'Notes', headerName: 'Notes', width: 120, cellStyle: (params: any) => {
          const isAsap = params.value?.toLowerCase().includes('interior');
          const cellStyle = isAsap ? { color: 'Black', backgroundColor: 'Pink' } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }, editable: (params: any) => this.isCellEditable(params), cellEditor: 'textareaEditor'
      },
      {
        field: 'ScheduleDate', headerName: 'Schedule Date', width: 120, filter: 'agDateColumnFilter', filterParams: this.filterParams,
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'datePickerEditor'
      },
      {
        field: 'ScheduleNotes', headerName: 'Schedule Notes', width: 120, cellStyle: (params: any) => {
          const isAsap = params.value?.toLowerCase().includes('asap')
          const cellStyle = isAsap ? { color: 'Black', backgroundColor: 'Yellow' } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }, editable: (params: any) => this.isCellEditable(params), cellEditor: 'textareaEditor'
      },
      {
        field: 'Size', headerName: 'Size', width: 120, editable: (params: any) => this.isCellEditable(params), cellEditor: 'agTextCellEditor',
      },
      {
        field: 'Status', headerName: 'Status', width: 120,
        filter: 'contractStatusFilter', floatingFilterComponent: 'contractStatusFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true },
        cellStyle: (params: any) => {
          const status = this.contractStatusList.find(x => x.Name === params.value);
          const cellStyle = status !== undefined ? { color: status.FontColor, backgroundColor: status.BackgroundColor, fontWeight: status.FontWeight } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        },
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'selectEditor',
        cellEditorParams: {
          options:  this.contractStatusList$.asObservable(),
          clear: true,
        }
      },
      {
        field: 'PermitStatus', headerName: 'Permit Status', width: 120,
        filter: 'permitStatusFilter', floatingFilterComponent: 'permitStatusFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true },
        cellStyle: (params: any) => {
          const status = this.permitStatusList.find(x => x.Name === params.value);
          const cellStyle = status !== undefined ? { color: status.FontColor, backgroundColor: status.BackgroundColor, fontWeight: status.FontWeight } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        },
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'selectEditor',
        cellEditorParams: {
          options:  this.permitStatusList$.asObservable(),
          clear: true,
        }
      },
      { field: 'WorkDays', headerName: 'Days', width: 120, editable: (params: any) => this.isCellEditable(params), cellEditor: 'numberEditor' },
      { field: 'Orders', headerName: 'Orders', width: 120 },
      { field: 'City', headerName: 'City', width: 120 },
      { field: 'SpruceHours', headerName: 'Spruce Hours', width: 120, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') },
      {
        field: 'SpruceNotes', headerName: 'SpruceNotes', width: 120, cellStyle: (params: any) => {
          const notes = params.value as string;
          const lines = notes?.split(/\n/g) ?? [];
          const cellStyle = { ...this.defaultColDef.cellStyle };
          if (lines.length > 1) {
            cellStyle['white-space'] = 'pre';
            cellStyle['line-height'] = '16px';
          }
          return cellStyle
        },
        editable: (params: any) => this.isCellEditable(params), cellEditor: 'textareaEditor',
        cellEditorParams: {
          minimumRows: 4,
          maximumRows: 6,
          suppressEnterKey: true
        }
      }
    ];

    // Setting grid state before the displaying the grid.
    if (this.gridState) {
      this.gridOptions.columnApi?.applyColumnState({ state: this.gridState.ColumnState, applyOrder: true });
      this.gridOptions.api?.setFilterModel(this.gridState.ColumnFilterState);
    }

    this.selectableColumns = (this.cols as any).filter((f: any) => f.field !== 'JobName')
      .map((value: any) => { return { Field: value.field, Name: value.headerName, Visible: true } })
      .sort((a: any, b: any) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    setTimeout(() => { this.gridActivated = true; }, 1);
  }
}


interface SelectableColumn {
  Field: string;
  Name: string;
  Visible: boolean;
}
