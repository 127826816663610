import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CellValueChangedEvent, FilterChangedEvent, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISelectEditorOption } from 'src/app/ag-grid-components/select-editor/select-editor.component';
import { SelectFloatingFilterComponent } from 'src/app/ag-grid-components/select-floating-filter/select-floating-filter.component';
import { TextareaEditorComponent } from 'src/app/ag-grid-components/textarea-editor/textarea-editor.component';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { StringExt } from 'src/app/utils/string';
import { CustomerMatchService } from '../customer-match.service';
import { Grids, IProposalGridState, ProposalLayoutService } from '../proposal-layout.service';
import { CustomerAttachmentsService } from '../services/customer-attachments.service';
import { CustomersService, ICustomer } from '../services/customers.service';
import { ProjectsService } from '../services/projects.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, OnDestroy {
  @Input() clearFilter: Observable<void> = new Observable<void>();
  @Input() sizeColumnsToFit: Observable<void> = new Observable<void>();
  @Input() resetGrid: Observable<void> = new Observable<void>();
  @Output() customerCount = new EventEmitter<number>();

  subscriptions = new Subscription();
  customers: ICustomer[] = [];
  selectedCustomer: ICustomer | null = null;
  reps: IRep[] = [];
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;
  gridState: IProposalGridState | null = null;
  frameworkComponents = { textareaEditor: TextareaEditorComponent, selectFloatingFilter: SelectFloatingFilterComponent, };
  filterCustomers: ICustomer[] = [];
  reps$: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());



  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private projectsService: ProjectsService, private userSettings: UserSettingsService, private customerService: CustomersService,
    private customerMatchService: CustomerMatchService, private repsService: RepsService, private layoutService: ProposalLayoutService, private customerAttachmentsService: CustomerAttachmentsService) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.repsService.reps.subscribe(data => { this.reps = data; this.reps$.next(data.map(r => ({ Value: r.Id, Text: r.Name }))) }));
    this.subscriptions.add(this.layoutService.proposalViewLayout.subscribe(data => { this.gridState = data.Grids.CustomerList; this.setSavedGridSate(); }));
    this.subscriptions.add(this.customerService.customers.subscribe(data => {
      this.customers = data;

      // const reps = this.customers.map(c => c.Rep).filter((v, i, a) => a.indexOf(v) === i && !StringExt.isNullOrWhiteSpace(v));
      // this.reps.next(reps.map(r => ({ Value: r, Text: r })));
      setTimeout(() => { this.countRows(); }, 2);
    }));
    this.subscriptions.add(this.customerMatchService.customerMatch.subscribe(data => { this.filterCustomers = data; this.gridApi?.onFilterChanged() }));
    this.subscriptions.add(this.customerService.selectedCustomer.subscribe(data => {
      if (data === null) {
        const selectedRows = this.gridApi?.getSelectedRows() ?? [];
        if (selectedRows.length > 0) {
          this.gridApi?.deselectAll();
        }
      }
      else {
        if (this.selectedCustomer?.Id !== data.Id) {
          this.gridApi?.forEachNodeAfterFilter(node => {
            if (node.data.Id === data.Id) {
              node.setSelected(true);
              this.gridApi?.ensureIndexVisible(node.rowIndex, 'middle');
            }
          });
        }
      }
      this.selectedCustomer = data;
    }));
    this.subscriptions.add(this.clearFilter.subscribe(event => this.clearFilters()));
    this.subscriptions.add(this.sizeColumnsToFit.subscribe(event => { this.gridApi?.sizeColumnsToFit(); this.saveGridState(); }));
    this.subscriptions.add(this.resetGrid.subscribe(event => { this.gridOptions.columnApi?.resetColumnState(); this.gridApi?.sizeColumnsToFit(); }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setSavedGridSate();
    this.countRows();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedCustomer = selectedRows[0] as ICustomer;

      if (this.filterCustomers.length > 0) {
        setTimeout(() => {
          const rowIndex = this.gridApi?.getSelectedNodes()[0].rowIndex;
          this.gridApi?.ensureIndexVisible(rowIndex, 'middle');
        }, 1);
      }
      this.selectedCustomer = selectedCustomer;
      this.customerService.selectCustomer(selectedCustomer);
      this.projectsService.selectProject(null);
    }
    else {
      if (this.selectedCustomer !== null) {
        this.selectedCustomer = null;
        this.customerService.selectCustomer(null);
      }
      this.projectsService.selectProject(null);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  onFilterChanged(params: FilterChangedEvent) {
    this.countRows();
    setTimeout(() => { this.saveGridState(); }, 5);
  }

  countRows() {
    const count = this.gridApi?.getDisplayedRowCount() ?? 0;
    this.customerCount.emit(count);
  }

  onCellValueChanged(params: CellValueChangedEvent) {
    const colId = params.column.getId();
    const customer: ICustomer = params.data;
    this.customerService.update(customer).subscribe();
    this.gridApi?.redrawRows();
  }

  doesExternalFilterPass(node: RowNode): boolean {
    if (this.filterCustomers.length > 0) {
      const data = node.data as ICustomer;
      return this.filterCustomers.some(c => c.Id === data.Id);
    }
    return true;
  }

  saveGridState() {
    const gridState = {
      ColumnState: this.gridColumnApi.getColumnState(),
      ColumnFilterState: this.gridOptions.api?.getFilterModel() ?? {},
    }
    this.layoutService.gridUpdate(Grids.CustomerList, gridState);
  }

  setSavedGridSate() {
    if (this.gridApi && this.gridState) {
      this.gridOptions?.columnApi?.applyColumnState({ state: this.gridState.ColumnState, applyOrder: true });
      this.gridApi?.setFilterModel(this.gridState.ColumnFilterState);
    }
    else if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.isExternalFilterPresent = () => true;
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this)
    this.gridOptions.onFilterChanged = (event) => this.onFilterChanged(event);
    this.gridOptions.onDragStopped = () => this.saveGridState();
    this.gridOptions.onSortChanged = () => this.saveGridState();
    this.gridOptions.onColumnPinned = () => this.saveGridState();
    this.gridOptions.onColumnMoved = () => this.saveGridState();
    this.gridOptions.onColumnResized = () => this.saveGridState();
    this.gridOptions.onColumnVisible = () => this.saveGridState();
    this.gridOptions.onColumnValueChanged = () => this.saveGridState();

    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: ICustomer) => data.Id.toString();
    this.gridOptions.onCellValueChanged = this.onCellValueChanged.bind(this);
    // this.gridOptions.onFirstDataRendered = () => this.gridApi?.sizeColumnsToFit;

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'Name', headerName: 'Name', width: 100 },
      { field: 'Address', headerName: 'Address', width: 100 },
      { field: 'City', headerName: 'City', width: 100 },
      { field: 'Phone1', headerName: 'Phone 1', width: 70 },
      { field: 'Phone2', headerName: 'Phone 2', width: 70 },
      { field: 'Email', headerName: 'Email', width: 100 },
      { field: 'Note', headerName: 'Note', minWidth: 50, width: 130, editable: (params: any) => true, cellEditor: 'textareaEditor' },
      {
        field: 'RepId', headerName: 'Rep', width: 70, valueFormatter: (params: any) => {
          return this.reps.find(x => x.Id == params.value)?.Name ?? '';
        },
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.reps$ }
      },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }

  dragover(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (rowId) {
      this.addHoverClass(rowId);
    }
  }

  dragEnd(event: any) {
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
  }

  drop(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (!rowId) { return }
    this.saveFiles(rowId, e.dataTransfer.files);
  }

  saveFiles(rowId: string, files: any) {
    const customerId = parseInt(rowId);
    const customer = this.customers.find(c => c.Id === customerId);
    if (!customer) {
      console.error('Could not find customer with rowId ' + rowId);
      return
    }

    this.customerAttachmentsService.uploadFiles(customer.Id, files)?.subscribe();
  }

  getRowId(element: HTMLElement | null) {
    if (!element) { return null }
    let rowId = element.getAttribute('row-id');
    if (rowId) { return rowId; }

    while (!rowId) {
      element = element.parentElement;
      if (element === null) { return null; }

      rowId = element.getAttribute('row-id');
    }
    return rowId;
  }

  addHoverClass(rowId: string) {
    if (rowId === 'b-0') { return; }
    document.querySelectorAll(`div[row-id="${rowId}"`).forEach(element => element.classList.add('drag-drop-attachment-hover'));
  }
}

