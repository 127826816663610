import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouterModule,
  Routes,
  UrlSegment
} from '@angular/router';



export class RouteReuseService implements RouteReuseStrategy {


  handlers: { [key: string]: DetachedRouteHandle } = {};

  calcKey(route: ActivatedRouteSnapshot) {
    let next: ActivatedRouteSnapshot | null = route;
    let url = '';
    while (next) {
      if (next.url) {
        url = next.url.join('/');
      }
      next = next.firstChild;
    }
    return url;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // return true;
    return route.data.reuse === true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[this.calcKey(route)] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[this.calcKey(route)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) { return null; }
    return this.handlers[this.calcKey(route)];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return this.calcKey(curr) === this.calcKey(future);
  }

  // private handlers: { [key: string]: DetachedRouteHandle } = {};

  // shouldDetach(route: ActivatedRouteSnapshot): boolean {
  //   if (!route.routeConfig || route.routeConfig.loadChildren) {
  //     return false;
  //   }
  //   let shouldReuse = false;
  //   console.log('checking if this route should be re used or not ', route);
  //   if (route.routeConfig.data) {
  //     route.routeConfig.data.reuse ? shouldReuse = true : shouldReuse = false;
  //   }
  //   return shouldReuse;
  // }

  // store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
  //   console.log('storing handler');
  //   if (handler) {
  //     this.handlers[this.getUrl(route)] = handler;
  //   }
  // }

  // shouldAttach(route: ActivatedRouteSnapshot): boolean {
  //   console.log('checking if it should be re attached');
  //   return !!this.handlers[this.getUrl(route)];
  // }

  // retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {

  //   console.log('Retrieve', route)

  //   if (!route.routeConfig || route.routeConfig.loadChildren) {
  //     return null;
  //   };
  //   return this.handlers[this.getUrl(route)];
  // }

  // shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {

  //   console.log('Should Reuse Route', future, current);

  //   let reUseUrl = false;
  //   if (future.routeConfig) {
  //     if (future.routeConfig.data) {
  //       reUseUrl = future.routeConfig.data.reuse;
  //     }
  //   }

  //   const defaultReuse = (future.routeConfig === current.routeConfig);
  //   console.log('Default reuse', defaultReuse, reUseUrl)
  //   return reUseUrl || defaultReuse;
  // }

  // getUrl(route: ActivatedRouteSnapshot): string {
  //   if (route.routeConfig) {
  //     const url = route.routeConfig.path;
  //     console.log('returning url', url);
  //     return url ?? '/';
  //   }
  //   return '/'
  // }
}