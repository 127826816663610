<div style=" display: flex; align-items: center; justify-content: center; height: 100%;">
    <mat-form-field class="mat-select-sm" style="width: 12em; margin-right: .5rem;" appearance="fill">
        <mat-select [(value)]="selectedLayoutId" (selectionChange)="valueChanged()">
            <mat-option class="mat-option-sm" [value]="-1">Custom</mat-option>
            <mat-option class="mat-option-sm" *ngFor="let option of savedLayouts" [value]="option.Id">
                {{option.Name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" class="mat-raised-button-sm" *ngIf="selectedLayoutId === -1" (click)="openSaveDialog()">Save
        View</button>
    <button mat-raised-button color="primary" class="mat-raised-button-sm" *ngIf="selectedLayoutId !== -1" (click)="deleteSavedLayout()">
        Delete View</button>
</div>