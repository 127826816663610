import { Component, OnInit } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams, GridOptions, FilterChangedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IStatus, AppSettingsService, Settings } from 'src/app/settings/app-settings.service';

@Component({
  selector: 'app-permit-status-floating-filter',
  templateUrl: './permit-status-floating-filter.component.html',
  styleUrls: ['./permit-status-floating-filter.component.scss']
})
export class PermitStatusFloatingFilterComponent implements IFloatingFilter {

  params: IFloatingFilterParams | undefined;
  gridOptions: GridOptions = {};
  options: Observable<IStatus[]> | undefined;
  selectedStatus = "All";

  constructor(private settingsService: AppSettingsService) {
    this.options = this.settingsService.settings.pipe(map(items => items.find(x => x.Key === Settings.PermitStatus)?.Data))
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.selectedStatus = "All";
    }
    else {
      this.selectedStatus = parentModel;
    }
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged() {
    this.params?.parentFilterInstance((instance) => {
      instance.setModel(this.selectedStatus);
    });
  }
}
