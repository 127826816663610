import { Component, OnInit } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { FilterChangedEvent, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IContract } from 'src/app/contracts/contracts.service';

@Component({
  selector: '',
  template: '',
  styles: ['']
})
export class YearFilterComponent implements AgFilterComponent {
  params: IFilterParams | undefined;
  selectedOption = "All";
  readonly thisYear = new Date().getFullYear().toString();
  readonly lastYear = (new Date().getFullYear() - 1).toString();
  constructor() { }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.selectedOption !== 'All';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const contract: IContract = params.data;
    switch (this.selectedOption) {
      case 'All':
        return true;
      case 'This Year':
        return this.thisYear === contract.Year;
      case 'Last Year':
        return this.lastYear === contract.Year;
      default:
        return this.selectedOption === contract.Year;
    }
  }

  getModel() {
    return this.selectedOption;
  }

  setModel(model: any) {
    this.selectedOption = model ?? 'All';
    this.params?.filterChangedCallback();
  }
}