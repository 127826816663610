import { Component, OnInit } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { IDoesFilterPassParams, AgPromise, IFilterParams } from 'ag-grid-community';
import { DateHelper } from 'src/app/services/date-helper';

@Component({
  selector: 'app-dates-filter',
  template: ``,
  styles: []
})
export class DatesFilterComponent implements AgFilterComponent {

  params: IFilterParams | undefined;
  filterValue = "All";
  field: string | null = null;

  startDate = new Date();
  endDate = new Date();

  constructor(private dateHelper: DateHelper) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.field = params.colDef.field ?? null;
  }

  isFilterActive(): boolean {
    return this.filterValue !== 'All';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.field == null) { return true; }
    if (this.filterValue === "All") { return true; }

    const valueToCheck = params.data[this.field];
    if (!valueToCheck) { return false; }

    const dateToCheck: Date = new Date(valueToCheck);
    return dateToCheck.getTime() >= this.startDate.getTime() && dateToCheck.getTime() <= this.endDate.getTime();
  }

  getDates() {
    this.startDate = new Date();
    this.endDate = new Date();

    switch (this.filterValue) {
      case 'All':
        break;
      case 'Past Week':
        this.startDate = this.dateHelper.add('day', -6, this.startDate);
        break;
      case 'Past Month':
        this.startDate = this.dateHelper.add('month', -1, this.startDate);
        break;
      case 'Past Year':
        this.startDate = this.dateHelper.add('year', -1, this.startDate);
        break;
      case 'This Week':
        this.startDate = this.dateHelper.add('day', 1 - (this.endDate.getDay()), this.startDate);
        break;
      case 'This Month':
        this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1);
        break;
      case 'This Year':
        this.startDate = new Date(this.endDate.getFullYear(), 1, 1);
        break;
      case 'Last Week':
        this.startDate = this.dateHelper.add('day', (0 - (7 + (this.endDate.getDay()))), this.startDate);
        this.endDate = this.dateHelper.add('day', 6, this.startDate);
        break;
      case 'Last Month':
        this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() - 1, 1);
        this.endDate = this.dateHelper.add('day', -1, this.dateHelper.add('month', 1, this.startDate));
        break;
      case 'Last Year':
        this.startDate = new Date(this.endDate.getFullYear() - 1, 1, 1);
        this.endDate = this.dateHelper.add('day', -1, this.dateHelper.add('year', 1, this.startDate));
        break;
      default:
        console.error("Unsupported Date.");
        break;
    }
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(0, 0, 0, 0);
  }

  getModel() {
    return this.filterValue;
  }

  setModel(model: any): void | AgPromise<void> {
    this.filterValue = model ?? 'All';
    this.getDates();
    this.params?.filterChangedCallback();
  }
}

