import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { IJob } from 'src/app/contracts/jobs.service';

@Component({
  selector: 'app-job-select-editor',
  templateUrl: './job-select-editor.component.html',
  styleUrls: ['./job-select-editor.component.scss']
})
export class JobSelectEditorComponent implements OnInit, AgEditorComponent, AfterViewInit, OnDestroy {

  params: ICellEditorParams | undefined;
  value: IJob | null = null;
  @ViewChild('select') matSelect: MatSelect | undefined;
  subscriptions: Subscription = new Subscription();
  options: IJob[] = [];
  clearOption = true;

  constructor() { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  ngOnInit(): void {

  }

  getValue() {
    return this.value?.JobNumber;
  }

  isPopup(): boolean {
    return true;
  }


  agInit(params: ISelectCellEditorParams): void {
    this.params = params;
    this.clearOption = params.clear ?? true;
    let paramOptions = (params as any).options as Observable<IJob[]>;
    if (!paramOptions) { console.error("Options is required."); return; }

    this.subscriptions.add(paramOptions.subscribe(data => {
      this.options = data;

      if (params.value) {
        this.value = data.find(job => job.JobNumber === Number(params.value)) ?? null

      }
      else {
        this.value = null;
      }
    }));
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.matSelect?.open();
      this.matSelect?.focus();
    });
  }

  selectionChanged(state: boolean) {
    if (!state) {
      this.params?.api?.stopEditing();
    }
  }
}


export interface ISelectCellEditorParams extends ICellEditorParams {
  options: IJob[];
  clear: boolean | undefined;
}
