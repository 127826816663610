<div style="display: grid; grid-template-columns: 1fr; height: 100%; margin-top: 5px;">
    <div style="width: 100%;">
        <form class="edit-form" [formGroup]="repForm">
            <div style="display: grid; grid-template-columns: 300px 400px;">
                <div style=" grid-row: 1; grid-column: 1 / -1; width: 100%;">
                    <button type="button" mat-icon-button matTooltip="Add New Proposal" color="accent"
                        class="mat-raised-button-sm" (click)="newRep()">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button type="button" matTooltip="Save Changes" (click)="saveRep()" mat-icon-button color="accent"
                        class="mat-raised-button-sm">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button type="button" matTooltip="Delete" mat-icon-button color="warn"
                        [disabled]="selectedRep === null" class="mat-raised-button-sm" (click)="deleteRep()">
                        <mat-icon class="md-18">delete</mat-icon>
                    </button>
                    <button type="button" matTooltip="Changed Password" mat-raised-button color="primary"
                        (click)="changePassword()" class="mat-raised-button-sm" [disabled]=" selectedRep===null">
                        Change Password
                    </button>
                </div>
                <div style="grid-column: 1; width: 100%; margin-top: 10px;">
                    <div class="left-label-inputs" style="padding: 3px; margin: 3px; max-width: 672px;">
                        <label>Name</label>
                        <div>
                            <mat-form-field appearance="fill" color="accent">
                                <input matInput formControlName="Name">
                                <mat-error>Name is required.</mat-error>
                            </mat-form-field>
                        </div>
                        <label>Email</label>
                        <div>
                            <mat-form-field appearance="fill" color="accent">
                                <input matInput formControlName="Email">
                                <mat-error>Invalid email address.</mat-error>
                            </mat-form-field>
                        </div>
                        <label>Phone</label>
                        <div>
                            <mat-form-field appearance="fill" color="accent">
                                <input matInput formControlName="PhoneNumber">
                            </mat-form-field>
                        </div>
                        <label>Template</label>
                        <div>
                            <mat-form-field appearance="fill" color="accent">
                                <mat-select formControlName="DefaultProposalTemplateId">
                                    <mat-option>-- None --</mat-option>
                                    <mat-option *ngFor="let template of templates" [value]="template.Id">
                                        {{template.Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                    </div>
                </div>
                <div class="left-label-inputs" style="grid-column: 2; margin-top: 50px;" *ngIf="selectedRep === null">
                    <label>Password</label>
                    <div>
                        <mat-form-field appearance="fill" color="accent">
                            <input matInput formControlName="Password" [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error>Password is required.</mat-error>
                        </mat-form-field>
                    </div>
                    <label>Confirm Password</label>
                    <div>
                        <mat-form-field appearance="fill" color="accent">
                            <input matInput formControlName="ConfirmPassword" [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error>Confirm Password is required.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>

        <mat-tab-group dynamicHeight>
            <mat-tab [disabled]="selectedRep === null" label="Signature">
                <ng-container *ngIf="selectedRep !== null">
                    <div style="padding: 5px;;">
                        <div style="display: flex; flex-direction: row;">
                            <label style="margin-top: 11px;">Change Signature</label>
                            <div style="margin-left: 5px">
                                <input #addFile hidden="true" type="file" multiple onclick="this.value=null" type="file"
                                    (change)="attachSignature($event)" />
                                <button mat-icon-button color="accent" matTooltip="Attach Signature"
                                    (click)="addFile.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style="padding: 5px;">
                        <img [src]="signatureImageUrl$ | async" style="max-height: 400px; max-width: 600px;" />
                    </div>
                </ng-container>
            </mat-tab>
            <mat-tab [disabled]="selectedRep === null" label="Email Template">
                <ng-container *ngIf="selectedRep !== null">
                    <app-rep-email-template></app-rep-email-template>
                </ng-container>
            </mat-tab>
            <mat-tab [disabled]="selectedRep === null" label="Email Settings">
                <ng-container *ngIf="selectedRep !== null">
                    <app-rep-email-settings></app-rep-email-settings>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>