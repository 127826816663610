import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FilterChangedEvent, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { DatesFilterComponent } from 'src/app/ag-grid-components/dates-floating-filter/dates-filter.component';
import { DatesFloatingFilterComponent } from 'src/app/ag-grid-components/dates-floating-filter/dates-floating-filter.component';
import { ISelectEditorOption } from 'src/app/ag-grid-components/select-editor/select-editor.component';
import { SelectFloatingFilterComponent } from 'src/app/ag-grid-components/select-floating-filter/select-floating-filter.component';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { CustomerMatchService } from '../customer-match.service';
import { Grids, IProposalGridState, ProposalLayoutService } from '../proposal-layout.service';
import { CustomersService, ICustomer } from '../services/customers.service';
import { ProjectAttachmentsService } from '../services/project-attachments.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { ProposalAutocompleteOptionsService } from '../services/proposal-autocomplete-options.service';
import { IProposal, ProposalsService } from '../services/proposals.service';

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent implements OnInit {
  @Input() clearFilter: Observable<void> = new Observable<void>();
  @Input() sizeColumnsToFit: Observable<void> = new Observable<void>();
  @Input() resetGrid: Observable<void> = new Observable<void>();
  @Output() proposalCount = new EventEmitter<number>();

  subscriptions = new Subscription();
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;
  gridState: IProposalGridState | null = null;
  frameworkComponents = {
    selectFloatingFilter: SelectFloatingFilterComponent,
    datesFilter: DatesFilterComponent,
    datesFloatingFilter: DatesFloatingFilterComponent,
  };

  proposals: IProposalCustomer[] = [];
  selectedCustomer: ICustomer | null = null;
  selectedProject: IProject | null = null;
  selectedProposal: IProposal | null = null;

  customers: ICustomer[] = [];
  matchingCustomers: Map<number, ICustomer> = new Map();
  reps: IRep[] = [];

  reps$: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  status: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());


  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private proposalsService: ProposalsService, private projectsService: ProjectsService, private customerService: CustomersService, private customerMatchService: CustomerMatchService, private userSettings: UserSettingsService,
    private repsService: RepsService, private autocompleteService: ProposalAutocompleteOptionsService, private layoutService: ProposalLayoutService, private projectAttachmentService: ProjectAttachmentsService, private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private router: Router) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => this.initGrid(data)));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.layoutService.proposalViewLayout.subscribe(data => { this.gridState = data.Grids.ProposalList; this.setSavedGridSate(); }));
    const proposals$ = this.proposalsService.proposals;
    const customers$ = this.customerService.customers;
    const projects$ = this.projectsService.projects;
    this.subscriptions.add(combineLatest(proposals$, customers$, projects$)
      .subscribe(([proposals, customers, projects]) => {
        this.customers = customers;
        const customersMap = new Map(customers.map(c => [c.Id, c]));
        const projectsMap = new Map(projects.map(c => [c.Id, c]));
        const projectsWithCustomer: IProposalCustomer[] = [];
        for (const proposal of proposals) {
          const pcp: IProposalCustomer = { ...proposal, Customer: customersMap.get(proposal.CustomerId) ?? null, Project: projectsMap.get(proposal.ProjectId) ?? null }
          projectsWithCustomer.push(pcp);
        }
        this.proposals = projectsWithCustomer;
        setTimeout(() => { this.countRows(); }, 2);
      }));


    this.subscriptions.add(this.proposalsService.selectedProposal.subscribe(data => {
      this.selectedProposal = data;
      if (!data) { return; }
      const selectedRows = this.gridApi?.getSelectedRows() ?? [];
      if (selectedRows.length > 0) {
        const selectedProposal = selectedRows[0] as IProposal;
        if (selectedProposal.Id !== data?.Id) {
          this.gridApi?.forEachNodeAfterFilter(node => {
            if (node.data.Id === data?.Id) {
              node.setSelected(true);
              this.gridApi?.ensureIndexVisible(node.rowIndex, 'middle');
            }
          });
        }
      }
      else {
        this.gridApi?.forEachNodeAfterFilter(node => {
          if (node.data.Id === data?.Id) {
            node.setSelected(true);
            this.gridApi?.ensureIndexVisible(node.rowIndex, 'middle');
          }
        });
      }
    }));


    this.subscriptions.add(this.projectsService.selectedProject.subscribe(data => this.selectedProject = data));

    this.subscriptions.add(this.customerMatchService.customerMatch.subscribe(data => { this.matchingCustomers = new Map(data.map(c => [c.Id, c])); this.gridApi?.onFilterChanged(); }));
    this.subscriptions.add(this.clearFilter.subscribe(event => this.clearFilters()));
    this.subscriptions.add(this.sizeColumnsToFit.subscribe(event => { this.gridApi?.sizeColumnsToFit(); this.saveGridState(); }));
    this.subscriptions.add(this.resetGrid.subscribe(event => { this.gridOptions.columnApi?.resetColumnState(); this.gridApi?.sizeColumnsToFit(); }));

    this.subscriptions.add(this.autocompleteService.statusOptions.subscribe(data => this.status.next(data.map(r => ({ Value: r.Text, Text: r.Text })))));
    this.subscriptions.add(this.repsService.reps.subscribe(data => { this.reps = data; this.reps$.next(data.map(r => ({ Value: r.Id, Text: r.Name }))); }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setSavedGridSate();
    this.countRows();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedProposal = selectedRows[0] as IProposal;
      if (this.selectedProposal?.Id !== selectedProposal.Id) {
        this.proposalsService.selectProposal(selectedProposal);
        if (this.selectedProject?.Id !== selectedProposal.ProjectId) {
          this.projectsService.selectProject(selectedProposal.ProjectId);
        }
        if (this.selectedCustomer?.Id !== selectedProposal.CustomerId) {
          this.customerService.selectCustomer(selectedProposal.CustomerId);
        }
      }
    }
    else {
      this.proposalsService.selectProposal(null);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  onFilterChanged(params: FilterChangedEvent) {
    this.countRows();
    setTimeout(() => { this.saveGridState(); }, 5);
  }

  countRows() {
    const count = this.gridApi?.getDisplayedRowCount() ?? 0;
    this.proposalCount.emit(count);
  }

  doesExternalFilterPass(node: RowNode): boolean {
    if (this.matchingCustomers.size > 0) {
      const data = node.data as IProposal;
      return this.matchingCustomers.has(data.CustomerId);
    }
    return true;
  }

  saveGridState() {
    const gridState = {
      ColumnState: this.gridColumnApi.getColumnState(),
      ColumnFilterState: this.gridOptions.api?.getFilterModel() ?? {},
    }
    this.layoutService.gridUpdate(Grids.ProposalList, gridState);
  }

  setSavedGridSate() {
    if (this.gridApi && this.gridState) {
      this.gridOptions?.columnApi?.applyColumnState({ state: this.gridState.ColumnState, applyOrder: true });
      this.gridApi?.setFilterModel(this.gridState.ColumnFilterState);
    }
    else if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.isExternalFilterPresent = () => this.matchingCustomers.size > 0;
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this)
    this.gridOptions.onFilterChanged = (event) => this.onFilterChanged(event);
    this.gridOptions.onDragStopped = () => this.saveGridState();
    this.gridOptions.onSortChanged = () => this.saveGridState();
    this.gridOptions.onColumnPinned = () => this.saveGridState();
    this.gridOptions.onColumnMoved = () => this.saveGridState();
    this.gridOptions.onColumnResized = () => this.saveGridState();
    this.gridOptions.onColumnVisible = () => this.saveGridState();
    this.gridOptions.onColumnValueChanged = () => this.saveGridState();

    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: IProject) => data.Id.toString();
    // this.gridOptions.onCellValueChanged = this.onCellValueChanged.bind(this);
    // this.gridOptions.onFirstDataRendered = () => this.gridApi?.sizeColumnsToFit();
    // this.gridOptions.onGridSizeChanged = (event) => this.gridApi?.sizeColumnsToFit();


    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'ProposalNumber', headerName: 'Proposal Number', width: 70 },
      { field: 'Customer.Name', headerName: 'Name', width: 100 },
      { field: 'Project.Description', headerName: 'Description', width: 100 },
      { field: 'Project.Address', headerName: 'Address', width: 100 },
      { field: 'Project.City', headerName: 'City', width: 100 },
      { field: 'Customer.Phone1', headerName: 'Phone 1', width: 80 },
      { field: 'Customer.Phone2', headerName: 'Phone 2', width: 80 },
      { field: 'Project.ProjectType', headerName: 'Project Type', width: 80 },
      {
        field: 'RepId', headerName: 'Rep', width: 80, valueFormatter: (params: any) => {
          return this.reps.find(x => x.Id == params.value)?.Name ?? '';
        },
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.reps$ }
      },
      {
        field: 'Project.Status', headerName: 'Status', width: 80,
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.status }
      },

      {
        field: 'BidDate', headerName: 'Date', width: 60, sort: 'desc',
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        filter: 'datesFilter', floatingFilterComponent: 'datesFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true },
      },
      { field: 'Amount', headerName: 'Amount', width: 60, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
    ];
    setTimeout(() => { this.gridActivated = true; }, 1);
  }

  dragover(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (rowId) {
      this.addHoverClass(rowId);
    }
  }

  dragEnd(event: any) {
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
  }

  drop(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (!rowId) { return }
    this.saveFiles(rowId, e.dataTransfer.files);
  }

  saveFiles(rowId: string, files: any) {
    const proposalId = parseInt(rowId);
    const proposal = this.proposals.find(c => c.Id === proposalId);
    if (!proposal) {
      console.error('Could not find proposal with rowId ' + rowId);
      return
    }

    this.projectAttachmentService.uploadFiles(proposal.ProjectId, files)?.subscribe();
  }

  getRowId(element: HTMLElement | null) {
    if (!element) { return null }
    let rowId = element.getAttribute('row-id');
    if (rowId) { return rowId; }

    while (!rowId) {
      element = element.parentElement;
      if (element === null) { return null; }

      rowId = element.getAttribute('row-id');
    }
    return rowId;
  }

  addHoverClass(rowId: string) {
    if (rowId === 'b-0') { return; }
    document.querySelectorAll(`div[row-id="${rowId}"`).forEach(element => element.classList.add('drag-drop-attachment-hover'));
  }

}

interface IProposalCustomer extends IProposal {
  Customer: ICustomer | null;
  Project: IProject | null;
}
