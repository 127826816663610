<form [formGroup]="form" (ngSubmit)="submit()">

    <div style="margin: 5px 0px;">
        <button type="button" mat-icon-button matTooltip="Add New Snippet" color="accent" class="mat-raised-button-sm"
            (click)="newSnippet()">
            <mat-icon>add</mat-icon>
        </button>
        <button type="submit" matTooltip="Save Changes" mat-icon-button color="primary" class="mat-raised-button-sm">
            <mat-icon>save</mat-icon>
        </button>
        <button type="button" matTooltip="Delete" mat-icon-button color="warn" [disabled]="selectedSnippet === null"
            class="mat-raised-button-sm" (click)="deleteSnippet()">
            <mat-icon class="md-18">delete</mat-icon>
        </button>
    </div>

    <div class="field">
        <label>Keyword</label>
        <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
            <input matInput formControlName="Keyword">
        </mat-form-field>
    </div>

    <div class="field">
        <label>Parameter</label>
        <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
            <input matInput formControlName="Parameter">
        </mat-form-field>
    </div>

    <div class="field">
        <label>Text</label>
        <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
            <textarea [cdkTextareaAutosize]="true" matInput formControlName="Text"></textarea>
        </mat-form-field>
    </div>
</form>
