<form class="edit-customer-form" [formGroup]="editCustomerForm" (ngSubmit)="saveCustomer()" autocomplete="disabled"
    (dragover)="dragover($event)" (drop)="drop($event)">
    <div style="display: grid; grid-gap:1px 3px; height: 100%; ">
        <div class="left-label-inputs" style="grid-column: 1;">
            <label>Name</label>
            <div class="tooltip-container">
                <mat-form-field appearance="fill" color="accent">
                    <input matInput formControlName="Name">
                    <mat-error *ngIf="editCustomerForm.controls['Name'].hasError('required')">
                        Customer name cannot be blank.
                    </mat-error>
                </mat-form-field>
                <div *ngIf="nameMessage" class="mat-drawer-container tooltip-content" style="width: 200px !important;">
                    <mat-error>
                        {{ nameMessage }}
                    </mat-error>
                </div>
                <!-- <div class="mat-error" *ngIf="nameMessage">{{ nameMessage }}</div> -->
            </div>
            <label (click)="addressClicked()">Address</label>
            <mat-form-field appearance="fill" color="accent">
                <input matInput formControlName="Address">
            </mat-form-field>

            <label>City</label>
            <mat-form-field appearance="fill" color="accent">
                <input matInput tab-directive formControlName="City" [matAutocomplete]="cityAutoComplete">
                <mat-autocomplete #cityAutoComplete="matAutocomplete" class="example-viewport" panelWidth="300px">
                    <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" style="height: 256px;"
                        [itemSize]="25">
                        <mat-option *cdkVirtualFor="let option of filteredCityOptions" [value]="option.Value"
                            class="example-item-detail">
                            {{option.Text}}
                        </mat-option>
                    </cdk-virtual-scroll-viewport>
                </mat-autocomplete>
            </mat-form-field>

            <label>Email</label>
            <mat-form-field appearance="fill" color="accent">
                <input matInput formControlName="Email">
            </mat-form-field>

            <label>Reference</label>
            <mat-form-field appearance="fill" color="accent">
                <input matInput tab-directive formControlName="Reference" [matAutocomplete]="referenceAutoComplete">
                <mat-autocomplete #referenceAutoComplete="matAutocomplete" class="example-viewport" panelWidth="300px">
                    <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" style="height: 256px;"
                        [itemSize]="25">
                        <mat-option *cdkVirtualFor="let option of filteredReferenceOptions" [value]="option.Value"
                            class="example-item-detail">
                            {{option.Text}}
                        </mat-option>
                    </cdk-virtual-scroll-viewport>
                </mat-autocomplete>
            </mat-form-field>


            <div style="grid-column: 1 / span 2; justify-content: end; margin-top: 4px;">
                <div style="display: flex; flex-direction: row; justify-content:center;">
                    <button type="button" mat-icon-button matTooltip="Add New Customer" color="accent"
                        class="mat-raised-button-sm" (click)="newCustomer()">
                        <mat-icon>person_add</mat-icon>
                    </button>
                    <button type="submit" matTooltip="Save Changes" mat-icon-button color="primary"
                        class="mat-raised-button-sm">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button type="button" matTooltip="Delete" mat-icon-button color="warn"
                        [disabled]="selectedCustomer === null" class="mat-raised-button-sm" (click)="deleteCustomer()">
                        <mat-icon class="md-18">delete</mat-icon>
                    </button>
                    <button type="button" matTooltip="Attachments" mat-icon-button color="accent"
                        [disabled]="selectedCustomer === null" class="mat-raised-button-sm attachment-count-badge"
                        (click)="openAttachmentDialog()">
                        <mat-icon [matBadge]="attachmentCount" matBadgePosition="after" matBadgeColor="primary"
                            [matBadgeHidden]="attachmentCount  == 0" matBadgeSize="small">
                            attach_file</mat-icon>
                    </button>
                </div>
            </div>

        </div>

        <div class="left-label-inputs" style="grid-column: 2;">
            <label>Phone 1</label>
            <div class="phone tooltip-container">
                <div class="" style="display: block;">
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Phone1">
                    </mat-form-field>
                    <!-- <div class="mat-error" *ngIf="phone1Message">{{ phone1Message }}</div> -->
                </div>
                <mat-form-field appearance="fill" class="phone-note" color="accent">
                    <input matInput tab-directive formControlName="Phone1Note"
                        [matAutocomplete]="phoneNoteAutoComplete">
                    <mat-autocomplete #phoneNoteAutoComplete="matAutocomplete" class="example-viewport"
                        panelWidth="300px" (opened)="phoneNoteAutoCompleteOpen()">
                        <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" style="height: 256px;"
                            [itemSize]="25">
                            <mat-option *cdkVirtualFor="let option of filteredPhoneNotesOptions" [value]="option.Value"
                                class="example-item-detail">
                                {{option.Text}}
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="mat-drawer-container tooltip-content" *ngIf="phone1Message">
                    <mat-error>
                        {{phone1Message}}
                    </mat-error>
                </div>
            </div>
            <label>Phone 2</label>
            <div class="phone tooltip-container">
                <div style="display: block;">
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Phone2">
                    </mat-form-field>
                    <!-- <div class="mat-error" *ngIf="phone2Message">{{ phone2Message }}</div> -->
                </div>
                <mat-form-field appearance="fill" class="phone-note" color="accent">
                    <input matInput tab-directive formControlName="Phone2Note"
                        [matAutocomplete]="phoneNote2AutoComplete">
                    <mat-autocomplete #phoneNote2AutoComplete="matAutocomplete" class="example-viewport"
                        panelWidth="300px" (opened)="phoneNoteAutoCompleteOpen()">
                        <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" style="height: 256px;"
                            [itemSize]="25">
                            <mat-option *cdkVirtualFor="let option of filteredPhoneNotesOptions" [value]="option.Value"
                                class="example-item-detail">
                                {{option.Text}}
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="mat-drawer-container tooltip-content" *ngIf="phone2Message">
                    <mat-error>
                        {{phone2Message}}
                    </mat-error>
                </div>
            </div>
            <label>Phone 3</label>
            <div class="phone tooltip-container">
                <div style="display: block;">
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Phone3">
                    </mat-form-field>
                    <!-- <div class="mat-error" *ngIf="phone3Message">{{ phone3Message }}</div> -->
                </div>
                <mat-form-field appearance="fill" class="phone-note" color="accent">
                    <input matInput tab-directive formControlName="Phone3Note"
                        [matAutocomplete]="phoneNote3AutoComplete">
                    <mat-autocomplete #phoneNote3AutoComplete="matAutocomplete" class="example-viewport"
                        panelWidth="300px" (opened)="phoneNoteAutoCompleteOpen()">
                        <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" style="height: 256px;"
                            [itemSize]="25">
                            <mat-option *cdkVirtualFor="let option of filteredPhoneNotesOptions" [value]="option.Value"
                                class="example-item-detail">
                                {{option.Text}}
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="mat-drawer-container tooltip-content" *ngIf="phone3Message">
                    <mat-error>
                        {{phone3Message}}
                    </mat-error>
                </div>
            </div>

            <label>Fax</label>
            <div style="display: flex; flex-direction: row;">
                <mat-form-field appearance="fill" style="width: 50%;" color="accent">
                    <input matInput formControlName="Fax">
                </mat-form-field>

                <div style="display: flex; flex-direction: row; margin-left: 3px; width: 50%;">
                    <label>Rep</label>
                    <mat-form-field appearance="fill" color="accent" style="margin-left: 3px;">
                        <mat-select formControlName="RepId" required>
                            <mat-option *ngFor="let rep of reps" [value]="rep.Id">
                                {{ rep.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <div style="grid-column: 1 / span 2 ;">
                <mat-form-field appearance="fill" color="accent" style="height: 100%; ">
                    <textarea matInput formControlName="Note" style="min-height: 41px;"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>