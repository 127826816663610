import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle/slide-toggle';
import { Subscription } from 'rxjs';
import { ThemeStorage } from 'src/app/theme-picker/theme-storage.service';
import { AgGridSettings, UserSettingsService } from '../user-settings.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();

  darkTheme = false;
  form = this.formBuilder.group({
    rowHeight: [20, Validators.required],
    headerHeight: [20, Validators.required],
    floatingFiltersHeight: [20, Validators.required],
    theme: ['ag-theme-balham', Validators.required],
    singleClickEdit: [false]
  });

  constructor(private userSettingsService: UserSettingsService, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<UserSettingsComponent>, private themeStorage: ThemeStorage) { }


  ngOnInit(): void {
    this.subscriptions.add(this.userSettingsService.agGridSettings.subscribe(data => {
      this.form.patchValue(data);
      this.darkTheme = data.theme.includes("-dark");
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(): void {
    if (!this.form.dirty) {
      this.dialogRef.close();
      return;
    }

    if (this.form.valid) {
      this.userSettingsService.saveAgGridSettings(this.form.value);
      this.setAppTheme();
      this.dialogRef.close();
      return;
    }
    this.form.markAllAsTouched();
  }

  apply() {
    if (this.form.valid) {
      this.userSettingsService.saveAgGridSettings(this.form.value);
      this.setAppTheme();
      return;
    }
    this.form.markAllAsTouched();
  }

  setAppTheme() {
    const gridSettings = this.form.value as AgGridSettings;
    const darkTheme = gridSettings.theme.includes('dark');
    this.themeStorage.saveAppTheme(darkTheme);
  }

  themeChanged(isDarkTheme: boolean) {
    const gridTheme = this.form.value as AgGridSettings;
    if (isDarkTheme) {
      gridTheme.theme.toLowerCase().includes('dark');
    }
  }

  darkThemeChanged(event: MatSlideToggleChange) {
    const gridSettings = this.form.value as AgGridSettings;
    if (event.checked) {
      if (!(gridSettings.theme.includes("-dark"))) {
        gridSettings.theme = `${gridSettings.theme}-dark`;
      }
    }
    else {
      if (gridSettings.theme.includes("alpine")) {
        gridSettings.theme = "ag-theme-alpine";
      }
      else if (gridSettings.theme.includes("balham")) {
        gridSettings.theme = "ag-theme-balham";
      }
    }
    this.form.patchValue(gridSettings);
    setTimeout(() => { this.apply(); }, 1);
  }
}
