import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements ICellRendererAngularComp, OnDestroy {
  params: any | undefined;
  buttonHeight = 20;
  text = "Open"

  ngOnDestroy(): void {
  }
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.buttonHeight = (params.node.rowHeight ?? 22) - 2;
    if (this.params.text) {
      this.text = this.params.text;
    }
  }

  btnClickedHandler() {
    this.params?.clicked(this.params);
  }
}
