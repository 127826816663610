import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ColumnState } from 'ag-grid-community';
import { IOutputAreaSizes } from 'angular-split';
import { ReplaySubject } from 'rxjs';
import { ISavedViewLayout } from '../saved-layouts/saved-layouts.service';
import { ILayoutService } from '../services/LayoutService';

@Injectable({
  providedIn: 'root'
})
export class ContractViewSettingsService implements ILayoutService {

  private gridStateKey = 'contractsGridState';
  private layoutViewKey = 'contractsLayOutView';

  private contractGridViewSettings$: ReplaySubject<IContractGridState | null> = new ReplaySubject(1);
  public get contractGridViewSettings() {
    return this.contractGridViewSettings$.asObservable();
  }

  private contractLayoutView$: ReplaySubject<IContractViewLayout | null> = new ReplaySubject(1);
  public get contractLayoutView() {
    return this.contractLayoutView$.asObservable();
  }

  private layoutChanged$: ReplaySubject<IContractViewState> = new ReplaySubject();
  get stateChanged() { return this.layoutChanged$.asObservable(); }


  currentState: IContractViewState = { ContractViewLayout: null, GridState: null };
  private isSettingPreset = false;


  constructor(private storage: StorageMap) {
    const state: IContractViewState = { ContractViewLayout: null, GridState: null };
    this.layoutChanged$.next(state)
  }
  loadAndSetStateFromBrowser(): void {
    this.loadGridState();
    this.loadLayoutView();
  }


  loadGridState(): void {
    this.storage.get(this.gridStateKey).subscribe((data: any) => {
      if (data) {
        this.contractGridViewSettings$.next(data);
        this.currentState.GridState = data;
      }
      else {
        this.contractGridViewSettings$.next(null);
        this.currentState.GridState = null;
      }
    });
  }

  saveGridStateOnClient(gridState: IContractGridState) {
    this.storage.set(this.gridStateKey, gridState).subscribe();
    this.currentState.GridState = gridState;
    this.layoutChanged();
  }

  saveLayoutViewOnClient(layout: IContractViewLayout) {
    this.storage.set(this.layoutViewKey, layout).subscribe();
    this.currentState.ContractViewLayout = layout;
    this.layoutChanged();
  }

  loadLayoutView() {
    this.storage.get(this.layoutViewKey).subscribe((data: any) => {
      if (data) {
        this.contractLayoutView$.next(data);
        this.currentState.ContractViewLayout = data;
      }
      else {
        this.contractLayoutView$.next(null);
        this.currentState.ContractViewLayout = null;
      }
    });
  }


  setSavedView(savedView: any): void {
    this.isSettingPreset = true;
    const gridState: IContractGridState = savedView.GridState;
    const layout: IContractViewLayout = savedView.ContractViewLayout;
    this.contractGridViewSettings$.next(gridState);
    this.contractLayoutView$.next(layout);

    this.saveGridStateOnClient(gridState);
    this.saveLayoutViewOnClient(layout);
    this.isSettingPreset = false;
  }

  layoutChanged() {
    if (this.isSettingPreset) { return }
    this.layoutChanged$.next(this.currentState);
  }
}

const enum ExternalFilters { StatusFilter = 'StatusFilter' };
const enum Splitters { ContractsList = 'ContractsList', ContractsExpenses = "ContractsExpenses" };

export interface IContractGridState {
  ColumnState: ColumnState[];
  ColumnFilterState: { [key: string]: any }
  ExternalFilterState: Record<ExternalFilters, any>
}

export interface IContractViewLayout {
  Splitters: Record<Splitters, IOutputAreaSizes>;
  DetailTabSelected: number;
}

export interface IContractViewState {
  GridState: IContractGridState | null;
  ContractViewLayout: IContractViewLayout | null;
}


