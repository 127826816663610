import { Component, OnInit } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { AgPromise, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IScheduleItem } from 'src/app/contracts/schedule-items.service';

@Component({
  selector: 'app-contract-status-filter',
  templateUrl: './contract-status-filter.component.html',
  styleUrls: ['./contract-status-filter.component.scss']
})
export class ContractStatusFilterComponent implements AgFilterComponent {
  params: IFilterParams | undefined;
  selectedOption = "All"

  constructor() { }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.selectedOption !== 'All';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const scheduleItem: IScheduleItem = params.data;
    if (this.selectedOption === "All") { return true; }

    if (this.selectedOption === "All Incomplete") {
      return scheduleItem.Status !== "Completed"
    }
    return this.selectedOption === scheduleItem.Status
  }

  getModel() {
    return this.selectedOption;
  }

  setModel(model: any): void | AgPromise<void> {
    this.selectedOption = model ?? 'All';
    this.params?.filterChangedCallback();
  }
}
