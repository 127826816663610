import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class RepsService {

  private reps$: ReplaySubject<IRep[]> = new ReplaySubject(1);
  public get reps(): Observable<IRep[]> { if (!this.isProjectsLoaded) { this.loadReps(); } return this.reps$.asObservable(); }

  private selectedRep$: ReplaySubject<IRep | null> = new ReplaySubject(1);
  public get selectedRep(): Observable<IRep | null> { return this.selectedRep$.asObservable(); }

  baseUrl = 'reps';
  isProjectsLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadReps(): void {
    const url = `${this.baseUrl}`;
    this.isProjectsLoaded = true;
    this.httpClient.get<IRep[]>(url).subscribe(data => { this.reps$.next(data); });
  }

  selectRep(rep: IRep | number | null) {
    setTimeout(() => {
      if (typeof (rep) === 'number') {
        this.reps.pipe(take(1)).subscribe(data => {
          const p = data.find(p => p.Id === rep);
          this.selectedRep$.next(p ?? null);
          if (p) {
            this.getRep(p.Id);
          }
        });
      }
      else {
        this.selectedRep$.next(rep);
        if (rep) {
          this.getRep(rep.Id);
        }
      }
    }, 1);
  }

  getRep(id: number): void {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<IRep>(url).subscribe(data => { this.updateReps(1, [data]); this.selectedRep$.next(data); });
  }
  public signatureLink(repId: number): string { return `api/${this.baseUrl}/${repId}/signature`; }

  add(rep: IRepCreateDto) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<IRep>(url, rep).pipe(catchError(err => this.handleError(err)), tap((data => this.updateReps(0, [data]))));
  }

  update(rep: IRep) {
    const url = `${this.baseUrl}/${rep.Id}`;
    return this.httpClient.put<IRep>(url, rep).pipe(catchError(err => this.handleError(err)), tap(data => this.updateReps(1, [data])));
  }

  updateSignature(rep: IRep, formData: FormData) {
    const url = `${this.baseUrl}/${rep.Id}/signature`;
    return this.httpClient.put<IRep>(url, formData).pipe(catchError(err => this.handleError(err, "Failed to save attachment.")), tap(data => this.updateReps(1, [data])));
  }

  delete(rep: IRep) {
    const url = `${this.baseUrl}/${rep.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete Rep.")), tap(data => this.updateReps(2, [rep])));
  }

  changeUserPassword(user: RepChangePassword): Observable<any> {
    const url = `${this.baseUrl}/ChangePassword`;
    return this.httpClient.post(url, user).pipe(catchError(err => this.handleError(err, "Failed to changed Reps Password.")),);
  }

  updateReps(crudType: number, reps: IRep[]) {
    this.reps.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const rep of reps) {
          const index = data.findIndex(c => c.Id === rep.Id);
          if (index === -1) {
            data.push(rep);
          }
          else {
            data[index] = rep;
          }
        }
      }
      else if (crudType === 2) {
        for (const rep of reps) {
          const index = data.findIndex(c => c.Id === rep.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.reps$.next([...data]);
    });
  }

  getEmailSettings(repId: number) {
    const url = `${this.baseUrl}/${repId}/EmailSettings`;
    return this.httpClient.get<EmailSettings>(url);
  }

  postEmailSettings(repId: number, emailSettings: EmailSettings) {
    const url = `${this.baseUrl}/${repId}/EmailSettings`;
    return this.httpClient.post(url, emailSettings).pipe(catchError(err => this.handleError(err, "Failed to save Email Settings.")),);
  }
  testEmailSettings(repId: number) {
    const url = `${this.baseUrl}/${repId}/TestEmailSettings`;
    return this.httpClient.post(url, {});
  }

  getEmailTemplate(repId: number) {
    const url = `${this.baseUrl}/${repId}/EmailTemplate`;
    return this.httpClient.get<EmailTemplate>(url);
  }

  postEmailTemplate(repId: number, emailSettings: EmailTemplate) {
    const url = `${this.baseUrl}/${repId}/EmailTemplate`;
    return this.httpClient.post(url, emailSettings).pipe(catchError(err => this.handleError(err, "Failed to save email template.")),);
  }

  private handleError(err: any, message: string = "Failed to save Rep. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }
}

export interface IRep {
  Id: number;
  Name: string;
  PhoneNumber: string;
  Email: string;
  DefaultProposalTemplate: number | null;
}

export interface IRepCreateDto {
  Id: number;
  Name: string;
  PhoneNumber: string;
  Email: string;
  Password: string;
  ConfirmPassword: string
}

export interface RepChangePassword {
  UserName: string;
  OldPassword: string;
  Password: string;
  ConfirmPassword: string;
}

export interface EmailSettings {
  Server: string;
  Port: string;
  UserName: string;
  Password: string
}

export interface EmailTemplate {
  Subject: string;
  Body: string;
}