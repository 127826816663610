<div style="width: 100%; height: calc(100vh - 32px);">
    <as-split #contractListSplitter direction="vertical" (dragEnd)="layoutUpdated()">
        <as-split-area [size]="60">
            <app-contracts-list #contractsList style="height: 100%;"></app-contracts-list>
        </as-split-area>
        <as-split-area [size]="40" (dragover)="dragover($event)" (drop)="drop($event)">
            <mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="tabChanged($event)" class="mat-tab-sm" color="primary" backgroundColor="primary" style="height: 100%;">
                <mat-tab label="Expenses">
                    <div style="width: 100%; height: 100%">
                        <as-split #expensesSplitter direction="horizontal" (dragEnd)="layoutUpdated()">
                            <as-split-area [size]="50">
                                <app-expenses [parentResized]="expensesSplitter.dragProgress$"></app-expenses>
                            </as-split-area>
                            <as-split-area [size]="50">
                                <app-labor [parentResized]="expensesSplitter.dragProgress$"></app-labor>
                            </as-split-area>
                        </as-split>
                    </div>
                </mat-tab>
                <mat-tab label="Orders ({{ orderCount }})">
                    <app-orders></app-orders>
                </mat-tab>
                <mat-tab label="Attachments ({{ attachmentCount }})">
                    <app-attachments></app-attachments>
                </mat-tab>
                <mat-tab label="Scheduling">
                    <app-scheduling-list (JobFilterChangedEvent)="contractsList.selectRow($event)"></app-scheduling-list>
                </mat-tab>
            </mat-tab-group>
        </as-split-area>
    </as-split>
</div>
