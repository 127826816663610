<div style="margin-left: 10px; margin-top: 2px; display: flex; width: 200px; ">
    <mat-label style="margin-top: 4px;">Orders:</mat-label>
    <div style="border: 1px darkgray solid; border-radius: 4px; width: 100%; margin-left: 3px;">
        <mat-form-field class="mat-select-sm" appearance="legacy">
            <mat-select #s [(ngModel)]="selectedOrder" (ngModelChange)="selectedOrderUpdated($event) ">
                <mat-select-trigger>
                    {{ selectedOrder?.Document }}
                </mat-select-trigger>
                <mat-option class="mat-option-sm" *ngFor="let option of orders" [value]="option">
                    <div style="display: flex;">
                        <div style="width: 14em;">{{option.Document}}</div>
                        <div style="width: 10em;">{{option.OrderTotal | currency}}</div>
                        <div style="width: 10em; text-align: right;">{{option.EntryDate | date}}</div>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 30px); margin-top: 2px;" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [columnDefs]="cols" [rowData]="selectedOrder?.OrderLineItems" [defaultColDef]="defaultColDef" rowSelection="single"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>
