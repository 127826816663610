import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { IOutputData, SplitComponent } from 'angular-split';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { ContractsService } from '../contracts.service';
import { ThirdPartyExpenses, ThirdPartyExpensesService } from '../third-party-expenses.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit, OnDestroy {
  @Input() parentResized: Observable<IOutputData> = new Observable<IOutputData>();
  subscriptions: Subscription = new Subscription();
  originalThirdPartyExpenses: ThirdPartyExpenses[] = [];
  thirdPartyExpenses: ThirdPartyExpenses[] = [];
  totalByVendor = true;
  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  rowHeight() { return this.gridOptions.rowHeight ? this.gridOptions.rowHeight : 20; }
  gridActivated = false;

  get SumDiff() { return this.originalThirdPartyExpenses.reduce((acc, cur) => acc + cur.Diff, 0) }
  get SumDiffPercentage() {
    const estimateSum = this.originalThirdPartyExpenses.reduce((acc, cur) => acc + cur.EstAmount, 0);
    const diffSum = this.SumDiff;
    if (diffSum == 0 || this.SumDiff == 0) { return 0; }
    return diffSum / estimateSum;
  }

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      var cellDate = new Date(cellValue); // new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  defaultColDef: any;
  cols: any;
  groupedCols: any;

  constructor(private thirdPartyExpensesService: ThirdPartyExpensesService, private contractsService: ContractsService, userSettings: UserSettingsService,
    private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private percentPipe: PercentPipe, private ngZone: NgZone) {
    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => {
      this.initGrid(data);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.parentResized?.subscribe(x => this.ngZone.run(() => this.gridApi?.sizeColumnsToFit())));
    this.subscriptions.add(this.contractsService.selectedContract.pipe(switchMap(contract => {
      if (contract) {
        return this.thirdPartyExpensesService.loadThirdPartyExpenses(contract.DocId);
      }
      this.thirdPartyExpenses = [];
      this.originalThirdPartyExpenses = [];
      return EMPTY;
    })).subscribe(data => { this.originalThirdPartyExpenses = data; this.groupExpenses() }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setGridColumns() {
    if (this.totalByVendor) {
      this.gridApi?.setColumnDefs(this.groupedCols);
    }
    else {
      this.gridApi?.setColumnDefs(this.cols);
    }
    this.groupExpenses();
    this.gridApi?.sizeColumnsToFit();
  }

  groupExpenses() {
    if (!this.totalByVendor) { this.thirdPartyExpenses = this.originalThirdPartyExpenses; return; }

    if (this.originalThirdPartyExpenses.length === 0) { return; }
    const arr = this.originalThirdPartyExpenses;

    const result = [...arr.reduce((r, o) => {
      const key = o.VendorCode;

      const item = r.get(key) || Object.assign({}, o, {
        EstAmount: 0,
        ActAmount: 0
      });

      item.EstAmount += o.EstAmount;
      item.ActAmount += o.ActAmount;

      return r.set(key, item);
    }, new Map).values()];
    this.thirdPartyExpenses = result.map(m => Object.assign(new ThirdPartyExpenses(), m));
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.setGridColumns();
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows();
    if (selectedRows) {
      // this.selectedLinkedAccount = selectedRows[0];
    }
  }

  openAttachment(params: any) {
    const columnClicked = params.colDef.field;
    if (columnClicked === "APDoc") {
      this.thirdPartyExpensesService.getAttachmentLink(params.value).subscribe(
        data => window.open(data, "_blank")
      );
    }
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.onCellDoubleClicked = this.openAttachment.bind(this);

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      {
        field: 'Vendor', headerName: 'Vendor',
        cellStyle: (params: any) => {
          const value = params.data.ActAmount;
          let cellStyle = {};
          if (value === 0) { cellStyle = { color: 'Red' } }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
      {
        field: 'EstAmount', headerName: 'Est Amount', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '',
        cellStyle: (params: any) => {
          const value = params.value;
          let cellStyle = {};
          if (value === 0) { cellStyle = { color: 'Red', backgroundColor: 'LightPink' } }
          else if (value > 0) { cellStyle = {} }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
      { field: 'APDoc', headerName: 'AP Document' },
      {
        field: 'Date', headerName: 'Date', filter: 'agDateColumnFilter', filterParams: this.filterParams,
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? ''
      },
      {
        field: 'ActAmount', headerName: 'Act Amount', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '',
        cellStyle: (params: any) => {
          const value = params.value;
          let cellStyle = {};
          if (value === 0) { cellStyle = { color: 'Red', backgroundColor: 'LightPink' } }
          else if (value > 0) { cellStyle = {} }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
    ];

    this.groupedCols = [
      {
        field: 'Vendor', headerName: 'Vendor', cellStyle: (params: any) => {
          const value = params.data.ActAmount;
          let cellStyle = {};
          if (value === 0) { cellStyle = { color: 'Red' } }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
      {
        field: 'EstAmount', headerName: 'Est Amount', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '',
        cellStyle: (params: any) => {
          const value = params.value;
          let cellStyle = {};
          if (value === 0) { cellStyle = { color: 'Red', backgroundColor: 'LightPink' } }
          else if (value > 0) { cellStyle = {} }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
      {
        field: 'ActAmount', headerName: 'Act Amount', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '',
        cellStyle: (params: any) => {
          const value = params.value;
          let cellStyle = {};
          if (value === 0) { cellStyle = { color: 'Red', backgroundColor: 'LightPink' } }
          else if (value > 0) { cellStyle = {} }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
      {
        field: 'Diff', headerName: 'Difference', valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '',
        cellStyle: (params: any) => {
          const value = params.value;
          let cellStyle = {};
          if (value === 0) { cellStyle = {} }
          else if (value > 0) { cellStyle = { color: 'Red' } }
          else if (value < 0) { cellStyle = { color: 'Green' } }
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        }
      },
      {
        field: 'DiffPercent', headerName: 'Diff Pct', valueFormatter: (params: any) => this.percentPipe.transform(params.value, '1.1-1') ?? '',
      }
    ];

    setTimeout(() => { this.gridActivated = true; });
  }
}
