import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-date-picker-editor',
  templateUrl: './date-picker-editor.component.html',
  styleUrls: ['./date-picker-editor.component.scss']
})
export class DatePickerEditorComponent implements OnInit, AfterViewInit, ICellEditorAngularComp {
  @ViewChild('datepicker') matDatePicker: MatDatepicker<Date> | undefined;
  @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef | undefined;

  params: ICellEditorParams | undefined;
  value: Date | null = null;

  lineHeight = '22px';
  width = 30;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.matDatePicker?.open();
    }, 0);

  }

  onOpen() {
    this.appendFooter();
  }

  getValue(): Date | null {
    return this.value ?? null;
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = this.params.value ?? null;

    this.width = (params.column.getActualWidth());
    const lineH = params.eGridCell.style.lineHeight;
    const ln = parseFloat(lineH);
    this.lineHeight = (ln) + 'px';
  }

  isPopup() {
    return true;
  }

  closed() {
    this.params?.api?.stopEditing();
  }

  clear() {
    this.value = null;
    this.matDatePicker?.close();
  }

  private appendFooter() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter?.nativeElement);
  }
}
