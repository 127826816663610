<!-- <mat-form-field *ngIf="maximumRows > 1 " class="form-field mat-field-sm" style="padding: 0px !important;" [style.height]="rowHeight" color="accent">
    <textarea class="multi-row-editor" matInput cdkTextareaAutosize #input [formControl]="value" [cdkAutosizeMinRows]="minimumRows" [cdkAutosizeMaxRows]="maximumRows" [style.width.ch]="textareaWidth" (keydown)="onKeypressEvent($event)" (focusout)="onFocusOut()"></textarea>
</mat-form-field> -->

<mat-form-field *ngIf="maximumRows" class="form-field mat-field-sm" style="padding: 0px !important;" [style.height]="rowHeight" color="accent">
    <textarea matInput cdkTextareaAutosize #input [formControl]="value" [cdkAutosizeMinRows]="minimumRows" [cdkAutosizeMaxRows]="maximumRows" [style.width.ch]="textareaWidth" [style.minWidth.px]="minimumWidth" [style.lineHeight]='lineHeight' (keydown)="onKeypressEvent($event)"
        (focusout)="onFocusOut()"></textarea>
</mat-form-field>

<!-- <mat-form-field *ngIf="maximumRows === 1" class="form-field mat-field-sm" style="padding: 0px !important;" [style.width.ch]="textareaWidth" [style.height]="rowHeight" color="accent">
    <textarea matInput [formControl]="value" style="width: 100%;" (keydown)="onKeypressEvent($event)" (focusout)="onFocusOut()"></textarea>
</mat-form-field> -->