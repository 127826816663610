import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyExpensesService {

  baseUrl = 'ThirdPartyExpenses';

  constructor(private httpClient: HttpClient) {
  }

  private expenses$: ReplaySubject<ThirdPartyExpenses[]> = new ReplaySubject(1);
  public get thirdPartyExpenses(): Observable<ThirdPartyExpenses[]> { return this.expenses$.asObservable() }

  loadThirdPartyExpenses(docId: number): Observable<ThirdPartyExpenses[]> {
    const url = `${this.baseUrl}/${docId}`;
    return this.httpClient.get<IThirdPartyExpenses[]>(url).pipe(map(response => {
      return response.map(value => Object.assign(new ThirdPartyExpenses(), value))
    })).pipe(tap(data => { this.expenses$.next(data); }));
  }

  getAttachmentLink(apDocId: string): Observable<string> {
    const url = `${this.baseUrl}/attachmentLink/${apDocId}`;
    return this.httpClient.get<string>(url);
  }

}

export interface IThirdPartyExpenses {
  Vendor: string;
  EstAmount: number;
  ActAmount: number;
  APDoc: string;
  VendorCode: string;
}

export class ThirdPartyExpenses implements IThirdPartyExpenses {
  Vendor: string = '';
  EstAmount: number = 0;
  ActAmount: number = 0;
  APDoc: string = '';
  VendorCode: string = '';

  get Diff() { return this.ActAmount - this.EstAmount }
  get DiffPercent() {
    if (this.Diff == 0 || this.EstAmount == 0) { return 0 }
    return this.Diff / this.EstAmount
  }
}
