<form class="edit-form" [formGroup]="emailTemplateForm">
    <div style="display: grid; grid-template-columns: 50px 1fr;">
        <label style="grid-column: 1;">To</label>
        <mat-form-field appearance="fill" color="accent" style="grid-column: 2;">
            <input matInput formControlName="To">
            <mat-error>To is required.</mat-error>
        </mat-form-field>

        <label style="grid-column: 1;">CC</label>
        <mat-form-field appearance="fill" color="accent" style="grid-column: 2;">
            <input matInput formControlName="CC">
        </mat-form-field>

        <label style="grid-column: 1;">Subject</label>
        <mat-form-field appearance="fill" color="accent" style="grid-column: 2;">
            <input matInput formControlName="Subject">
            <mat-error>Subject is required.</mat-error>
        </mat-form-field>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 5px; margin-bottom: 5px;">
        <div style="width: 375px; ">
            Attachments:
            <a [href]="pdfLink" target="_blank" class="not-link">Proposal {{ proposal?.ProposalNumber}}</a>
        </div>


        <div class="button">
            <button type="button" matTooltip="Send" (click)="send()" mat-raised-button color="primary"
                [class.spinner]="sending" [disabled]="sending" class="mat-raised-button-sm"
                style="width: 100px; margin-left: 15px;">
                Send
            </button>
        </div>
    </div>
    <mat-error class="border" style="max-height: 120px; overflow: auto;" *ngIf="message?.length">{{ message }}
    </mat-error>
    <mat-form-field class="stretch-height" appearance="fill" color="accent">
        <textarea matInput formControlName="Body"></textarea>
        <mat-error>Body is required.</mat-error>
    </mat-form-field>
</form>