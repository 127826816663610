import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private settings$: ReplaySubject<ISetting[]> = new ReplaySubject(1);
  public get settings(): Observable<ISetting[]> { if (!this.isSettingsLoaded) { this.loadSettings(); } return this.settings$.asObservable() }

  baseUrl = 'settings';
  isSettingsLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadSettings(): void {
    this.isSettingsLoaded = true;
    const url = `${this.baseUrl}`;
    this.httpClient.get<ISetting[]>(url).subscribe(data => { this.settings$.next(data); this.isSettingsLoaded = true; });
  }

  saveSettings(key: Settings, settings: ISetting) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<ISetting>(url,settings).pipe(catchError(err => this.handleError(err)), tap((data => this.updateSettings(0, [data]))));
  }

  deleteSettings(settings: ISetting) {
    const url = `${this.baseUrl}/${settings.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete settings.")), tap(data => this.updateSettings(2, [settings])));
  }

  private handleError(err: any, message: string = "Failed to save Settings. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateSettings(crudType: number, subcontractors: ISetting[]) {
    this.settings.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const subcontractor of subcontractors) {
          const index = data.findIndex(c => c.Id === subcontractor.Id);
          if (index === -1) {
            data.push(subcontractor);
          }
          else {
            data[index] = subcontractor;
          }
        }
      }
      else if (crudType === 2) {
        for (const subcontractor of subcontractors) {
          const index = data.findIndex(c => c.Id === subcontractor.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.settings$.next([...data]);
    });
  }
}

export enum Settings {
  ContractStatus = "ContractStatus",
  ScheduleStatus = "ScheduleStatus",
  PermitStatus = "PermitStatus",
}

export interface ISetting {
  Id: number;
  Key: string;
  Data: any;
}

export interface IStatus{
    Name: string;
    Idx: number,
    FontColor: string;
    BackgroundColor: string;
    FontWeight: string;
}
