import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SplitComponent } from 'angular-split';
import { ViewType } from 'src/app/saved-layouts/saved-layouts.service';
import { ProposalLayoutService, Splitters } from '../proposal-layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-project-navigation',
  templateUrl: './project-navigation.component.html',
  styleUrls: ['./project-navigation.component.scss']
})
export class ProjectNavigationComponent implements OnInit, AfterViewInit {
  @ViewChild('Proposals.ProposalList.EditProposalSplitter') ProposalListEditProposalSplitter: SplitComponent | undefined;
  @ViewChild('Proposals.ProposalsListSplitter') ProposalsListSplitter: SplitComponent | undefined;
  @ViewChild('CustomerEditProjectEditSplitter') CustomerEditProjectEditSplitter: SplitComponent | undefined;
  @ViewChild('ProjectCustomerProposalCustomerSplitter') ProjectCustomerProposalCustomerSplitter: SplitComponent | undefined;

  viewType: ViewType = ViewType.Proposals;

  count = 0;
  private clearFilterSubject: Subject<void> = new Subject<void>();
  public get clearFilterEvent() { return this.clearFilterSubject.asObservable(); }

  private sizeColumnsToFitSubject: Subject<void> = new Subject<void>();
  public get sizeColumnsToFitEvent() { return this.sizeColumnsToFitSubject.asObservable(); }

  private resetGridSubject: Subject<void> = new Subject<void>();
  public get resetGridEvent() { return this.resetGridSubject.asObservable(); }

  subscriptions: Subscription = new Subscription();
  selectedView = "Customers";
  mainSplit = 65;
  editProposalSplit = 35;
  smallScreen = false;
  editExpansionPanelOpen = false;

  constructor(private cdr: ChangeDetectorRef, breakpointObserver: BreakpointObserver, private proposalLayoutService: ProposalLayoutService) {
    breakpointObserver.observe([
      '(max-width: 1280px)'
    ]).subscribe(result => {
      if (result.matches) {
        //To small to show proposals on the side.
        // this.editProposalSplit = 0;
        // this.mainSplit = 100;
        this.smallScreen = true;
      } else {
        // this.mainSplit = 75;
        // this.editProposalSplit = 25;
        this.smallScreen = false;
      }
      this.proposalLayoutService.proposalViewLayout.pipe(take(1)).subscribe(data => {
        const splitters = data.Splitters;
        if (splitters == null) { return; }

        if (splitters.ProposalListEditProposal) {
          this.ProposalListEditProposalSplitter?.setVisibleAreaSizes(splitters.ProposalListEditProposal);
        }
      });
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(this.proposalLayoutService.proposalViewLayout.subscribe(data => {
      this.selectedView = data.SelectedGrid ?? 'Customers';
      this.editExpansionPanelOpen = data.EditExpansionPanelExpanded ?? false;
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.proposalLayoutService.proposalViewLayout.subscribe(data => {
      const splitters = data.Splitters;
      if (splitters == null) { return; }

      if (splitters.ProposalListEditProposal) {
        this.ProposalListEditProposalSplitter?.setVisibleAreaSizes(splitters.ProposalListEditProposal);
      }

      if (splitters.ProposalsList) {
        this.ProposalsListSplitter?.setVisibleAreaSizes(splitters.ProposalsList);
      }

      if (splitters.CustomerEditProjectEdit) {
        this.CustomerEditProjectEditSplitter?.setVisibleAreaSizes(splitters.CustomerEditProjectEdit);
      }

      if (splitters.ProjectCustomerProposalCustomer) {
        this.ProjectCustomerProposalCustomerSplitter?.setVisibleAreaSizes(splitters.ProjectCustomerProposalCustomer);
      }
    }));

    this.ProposalListEditProposalSplitter?.dragEnd.subscribe(data => {
      const size = this.ProposalListEditProposalSplitter!.getVisibleAreaSizes();
      this.proposalLayoutService.splitterUpdated(Splitters.ProposalsListEditProposal, size);
    });

    this.ProposalsListSplitter?.dragEnd.subscribe(data => {
      const size = this.ProposalsListSplitter!.getVisibleAreaSizes();
      this.proposalLayoutService.splitterUpdated(Splitters.ProposalsList, size);
    });

    this.CustomerEditProjectEditSplitter?.dragEnd.subscribe(data => {
      const size = this.CustomerEditProjectEditSplitter!.getVisibleAreaSizes();
      this.proposalLayoutService.splitterUpdated(Splitters.CustomerEditProjectEdit, size);
    });

    this.ProjectCustomerProposalCustomerSplitter?.dragEnd.subscribe(data => {
      const size = this.ProjectCustomerProposalCustomerSplitter!.getVisibleAreaSizes();
      this.proposalLayoutService.splitterUpdated(Splitters.ProjectCustomerProposalCustomer, size);
    });
  }

  editExpansionPanelStateChanged(open: boolean) {
    this.editExpansionPanelOpen = open;
    this.proposalLayoutService.expansionPanelStateChanged(open);
  }

  countChanged(count: number) {
    this.count = count;
  }

  clearFilter() {
    this.clearFilterSubject.next();
  }

  selectedViewUpdated(event: any) {
    this.count = 0;
    this.cdr.detectChanges();
    this.proposalLayoutService.selectedGridUpdated(this.selectedView);
  }

  sizeGridColumnsToFit() {
    this.sizeColumnsToFitSubject.next();
  }

  resetGrid() {
    this.resetGridSubject.next();
  }
}
