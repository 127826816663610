import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private customers$: BehaviorSubject<ICustomer[]> = new BehaviorSubject<ICustomer[]>([]);
  public get customers(): Observable<ICustomer[]> { if (!this.isCustomersLoaded) { this.loadCustomers(); } return this.customers$.asObservable(); }

  private selectedCustomer$: BehaviorSubject<ICustomer | null> = new BehaviorSubject<ICustomer | null>(null);
  public get selectedCustomer(): Observable<ICustomer | null> { return this.selectedCustomer$.asObservable(); }


  baseUrl = 'customers';
  isCustomersLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadCustomers(): void {
    this.isCustomersLoaded = true;
    const url = `${this.baseUrl}`;
    this.httpClient.get<ICustomer[]>(url).subscribe(data => { this.customers$.next(data); });
  }

  selectCustomer(customer: ICustomer | number | null) {
    if (typeof (customer) === 'number') {
      customer = this.customers$.value.find(p => p.Id === customer) ?? null;
    }

    customer && this.updateSelectedCustomer(customer);
    this.selectedCustomer$.next(customer);
  }

  private updateSelectedCustomer(customer: ICustomer) {
    if (this.selectedCustomer$.value?.Id !== customer.Id) {
      this.getCustomer(customer.Id);
    }
  }

  getCustomer(id: number) {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<ICustomer>(url).subscribe(data => { this.updateCustomers(1, [data]); this.selectedCustomer$.next(data); });
  }

  add(customer: ICustomer) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<ICustomer>(url, customer).pipe(catchError(err => this.handleError(err)), tap((data => this.updateCustomers(0, [data]))));
  }

  update(customer: ICustomer) {
    const url = `${this.baseUrl}/${customer.Id}`;
    return this.httpClient.put<ICustomer>(url, customer).pipe(catchError(err => this.handleError(err)), tap((data => this.updateCustomers(1, [data]))));
  }

  delete(customer: ICustomer) {
    const url = `${this.baseUrl}/${customer.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete customer. ")), tap((d => this.updateCustomers(2, [customer]))));;
  }

  private handleError(err: any, message: string = "Failed to save customer. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateCustomers(crudType: number, customers: ICustomer[]) {
    this.customers.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const customer of customers) {
          const index = data.findIndex(c => c.Id === customer.Id);
          if (index === -1) {
            data.push(customer);
          }
          else {
            data[index] = customer;
          }
        }
      }
      else if (crudType === 2) {
        for (const customer of customers) {
          const index = data.findIndex(c => c.Id === customer.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.customers$.next([...data]);
    });
  }
}


export interface ICustomer {
  Id: number;
  Name: string;
  Address: string;
  City: string;
  Phone1: string;
  Phone2: string;
  Phone3: string;
  Phone1Note: string;
  Phone2Note: string;
  Phone3Note: string;
  Note: string;
  Reference: string;
  Email: string;
  Fax: string;
  RepId: number | null;
}
