import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-total-pinned-row-renderer',
  templateUrl: './total-pinned-row-renderer.component.html',
  styleUrls: ['./total-pinned-row-renderer.component.scss']
})
export class TotalPinnedRowRendererComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams | undefined;
  style: any;

  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.style = (<any>this.params).style;
  }

  ngOnInit(): void {
  }

}
