import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, IHttpConnectionOptions, ILogger } from '@microsoft/signalr'
import { AuthenticationService } from '../api-authorization/authentication.service';
import { getBaseUrl } from '../api-authorization/authorize.interceptor';
import { ContractsService, IContract } from '../contracts/contracts.service';
import { IScheduleItem, ScheduleItemsService } from '../contracts/schedule-items.service';
import { CustomersService } from '../projects/services/customers.service';
import { ProjectsService } from '../projects/services/projects.service';
import { ProposalTemplatesService } from '../projects/services/proposal-templates.service';
import { ProposalTermsService } from '../projects/services/proposal-terms.service';
import { ProposalsService } from '../projects/services/proposals.service';
import { RepsService } from '../reps/reps.service';
import { SnippetsService } from '../snippets/snippets.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private hubConnection: HubConnection | undefined;

  constructor(private authenticationService: AuthenticationService, private contractsService: ContractsService,
    private scheduleItemsService: ScheduleItemsService, private customersService: CustomersService, private projectsService: ProjectsService,
    private proposalsService: ProposalsService, private snippetService: SnippetsService, private proposalTermsService: ProposalTermsService, private proposalTemplateService: ProposalTemplatesService,
    private repsService: RepsService) { }

  public connect = () => {
    this.startConnection();
  }

  public async init() {

    if (!this.hubConnection) {
      await this.startConnection();
    }

    this.hubConnection?.on('contracts', (data: IContract[]) => this.contractsService.updateContractsFromSignalR(data));
    this.hubConnection?.on('scheduleItems', (data: IScheduleItem[]) => this.scheduleItemsService.updateScheduleItems(data));
    this.hubConnection?.on('customers', (data: any) => this.customersService.updateCustomers(data.crudType, data.customers));
    this.hubConnection?.on('projects', (data) => this.projectsService.updateProjects(data.crudType, data.projects));
    this.hubConnection?.on('proposals', (data) => this.proposalsService.updateProposals(data.crudType, data.proposals));
    this.hubConnection?.on('snippets', (data) => this.snippetService.updateSnippets(data.crudType, data.proposals));
    this.hubConnection?.on('ProposalTerms', (data) => this.proposalTermsService.updateTerms(data.crudType, data.proposalTerms));
    this.hubConnection?.on('ProposalTemplates', (data) => this.proposalTemplateService.updateTemplates(data.crudType, data.templates));
    this.hubConnection?.on('Reps', (data) => this.repsService.updateReps(data.crudType, data.reps));
  }

  private getConnection(): HubConnection {
    // const options: IHttpConnectionOptions = {
    //   accessTokenFactory: () => {
    //     return this.authenticationService.userValue?.JwtToken ?? '';
    //   }
    // };
    return new HubConnectionBuilder()
      .withUrl(`${getBaseUrl()}/notifications`).withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();
  }

  private startConnection() {

    if (!this.authenticationService.isAuthenticated) {
      console.error("Authentication Failed.");
      return;
    }

    console.log("Starting SignalR connection");
    this.hubConnection = this.getConnection();
    return this.hubConnection.start();
  }

}


export class MyLogger implements ILogger {
  log(logLevel: LogLevel, message: string) {
    console.log(logLevel, message)
    // Use `message` and `logLevel` to record the log message to your own system
  }
}
