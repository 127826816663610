import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }


  open(message: string, action?: string, config?: MatSnackBarConfig<any>) {
    let defaultConfig: MatSnackBarConfig = { horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-error-message', duration: 4000 }
    let myConfig = { ...defaultConfig }
    myConfig = { ...myConfig, ...defaultConfig }
    return this.snackBar.open(message, action, myConfig);
  }

  showError(message: string, err: any, action?: string, config?: MatSnackBarConfig<any>) {
    let errorMessage = "";
    if (err.error?.title) {
      errorMessage = err?.error?.title;
    }
    else if (err.status === 0) {
      errorMessage = `No internet connection or server is not responding.`;
    }
    else {
      errorMessage = err?.error ?? "";
    }

    message = `${message} ${errorMessage}`;
    return this.open(message, action, config);
  }

}
