<as-split #Proposals.ProposalList.EditProposalSplitter>
    <as-split-area [size]="mainSplit">
        <as-split #Proposals.ProposalsListSplitter direction="vertical">
            <as-split-area [size]="40">
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div style="margin-left: 5px; display: flex; width: 100px;">
                        <mat-form-field class="mat-select-sm" appearance="fill">
                            <mat-select [(ngModel)]="selectedView" (ngModelChange)="selectedViewUpdated($event)">
                                <mat-option value="Customers">Customers</mat-option>
                                <mat-option value="Projects">Projects</mat-option>
                                <mat-option value="Proposals">Proposals</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="font-weight: 500; display: flex; align-items: center; width: 50px; margin-left: 10px;">
                        (<span class="count"> {{ count }}</span>)
                    </div>
                    <button (click)="clearFilter()" mat-raised-button color="primary" class="mat-raised-button-sm"
                        style="margin-left: 15px !important;">Clear
                        Filters</button>
                    <button (click)="resetGrid()" mat-raised-button color="primary" class="mat-raised-button-sm"
                        style=" margin-left: 15px !important;">Reset Grid
                    </button>
                    <button (click)="sizeGridColumnsToFit()" mat-raised-button color="primary"
                        class="mat-raised-button-sm" style=" margin-left: 15px !important;">Size Columns to Fit
                    </button>

                    <app-saved-layouts [viewType]="viewType" style="width: 300px;"></app-saved-layouts>
                </div>

                <app-customer-list *ngIf="selectedView === 'Customers'" [sizeColumnsToFit]="sizeColumnsToFitEvent"
                    [clearFilter]="clearFilterEvent" [resetGrid]="resetGridEvent" (customerCount)="countChanged($event)"
                    style="height: 100%;">
                </app-customer-list>
                <app-project-list *ngIf="selectedView === 'Projects'" [sizeColumnsToFit]="sizeColumnsToFitEvent"
                    [clearFilter]="clearFilterEvent" [resetGrid]="resetGridEvent" (projectCount)="countChanged($event)"
                    style="height: 100%;">
                </app-project-list>
                <app-proposal-list *ngIf="selectedView === 'Proposals'" [sizeColumnsToFit]="sizeColumnsToFitEvent"
                    [clearFilter]="clearFilterEvent" [resetGrid]="resetGridEvent" (proposalCount)="countChanged($event)"
                    style="height: 100%;">
                </app-proposal-list>
            </as-split-area>
            <as-split-area [size]="60">
                <mat-tab-group dynamicHeight style="height: 100%;">
                    <mat-tab label="Edit">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="editExpansionPanelOpen"
                                (opened)="editExpansionPanelStateChanged(true)"
                                (closed)="editExpansionPanelStateChanged(false)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Edit </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="height: 175px; width: 100%;" class="edit-panel">
                                    <as-split #CustomerEditProjectEditSplitter direction="horizontal"
                                        style="min-width: 875px;">
                                        <as-split-area [size]="50">
                                            <app-customer-edit></app-customer-edit>
                                        </as-split-area>
                                        <as-split-area>
                                            <app-project-edit></app-project-edit>
                                        </as-split-area>
                                    </as-split>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <as-split #ProjectCustomerProposalCustomerSplitter direction="horizontal"
                            style="height: calc(100% - 25px);">
                            <as-split-area [size]="50">
                                <app-project-customer></app-project-customer>
                            </as-split-area>
                            <as-split-area>
                                <app-proposal-customer></app-proposal-customer>
                            </as-split-area>
                        </as-split>
                    </mat-tab>
                    <mat-tab label="Map">
                        <app-project-map></app-project-map>
                    </mat-tab>
                </mat-tab-group>
            </as-split-area>
        </as-split>
    </as-split-area>
    <as-split-area [visible]="!smallScreen" [size]="editProposalSplit">
        <app-proposal-edit></app-proposal-edit>
    </as-split-area>
</as-split>