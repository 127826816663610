<mat-form-field class="mat-select-sm" appearance="fill">
    <mat-select [(ngModel)]="selectedStatus" (ngModelChange)="valueChanged()">
        <mat-option class="mat-option-sm" selected value="All">All</mat-option>
        <mat-option class="mat-option-sm"  value="All Incomplete">All Incomplete</mat-option>

        <mat-option *ngFor="let option of options | async" [value]="option.Name">
          {{option.Name}}
        </mat-option>

        <!-- <mat-option class="mat-option-sm" selected value="Not Scheduled">Not Scheduled</mat-option>
        <mat-option class="mat-option-sm" selected value="Ordered">Ordered</mat-option>
        <mat-option class="mat-option-sm" selected value="Scheduled">Scheduled</mat-option>
        <mat-option class="mat-option-sm" selected value="In Progress">In Progress</mat-option>
        <mat-option class="mat-option-sm" selected value="Completed">Completed</mat-option> -->
    </mat-select>
</mat-form-field>
