<div *ngIf="!apiLoaded">
  Google Maps API not loaded. 
</div>

<div *ngIf="apiLoaded" style="height: 100%;">
    <google-map [options]="options"  id="gMaps" #googleMaps width="100%" height="100%" >
      <map-info-window>
        <div [innerHTML]="infoWindow"></div>
    </map-info-window>
    </google-map>
  </div>
