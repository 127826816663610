<form class="edit-form" (ngSubmit)="save()" [formGroup]="emailTemplateForm">
    <div style="width: 100%;">
        <div style=" width: 100%; margin-top: 10px;">
            <div style="padding: 3px; margin: 3px; max-width: 700px;">
                <div class="field">
                    <div>Subject</div>
                    <mat-form-field appearance="fill" color="accent">
                        <input matInput formControlName="Subject">
                    </mat-form-field>
                </div>
                <div class="field">
                    <label>Body</label>
                    <mat-form-field class="mat-field-sm" appearance="fill" color="accent">
                        <textarea matInput  [cdkTextareaAutosize]="true" [cdkAutosizeMinRows]="15" matInput
                            formControlName="Body"></textarea>
                    </mat-form-field>
                </div>
                <label></label>
                <div style="margin: 10px;">
                    <button type="submit" matTooltip="Save Changes" mat-raised-button color="primary"
                        style="width: 100px;">
                        Save
                    </button>
                </div>

                <div style="margin: 10px;">
                    <div>Placeholders</div>
                    <div>Customer: &lt;customer&gt; </div>
                    <div>Proposal Number: &lt;proposalNumber&gt; </div>
                    <div>Amount: &lt;amount&gt; </div>
                    <div>Days Valid: &lt;daysValid&gt; </div>
                    <div>Project Description: &lt;projectDescription&gt; </div>
                </div>

            </div>
        </div>
    </div>
</form>
