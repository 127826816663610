import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SavedLayoutsService {

  baseUrl = 'ViewLayouts';
  private savedViewLayouts$: ReplaySubject<ISavedViewLayout[]> = new ReplaySubject(1);
  public get savedViewLayouts(): Observable<ISavedViewLayout[]> { return this.savedViewLayouts$.asObservable() }

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
    this.loadSavedViews();
  }

  loadSavedViews(): Observable<ISavedViewLayout[]> {
    const url = `${this.baseUrl}`;
    this.httpClient.get<ISavedViewLayout[]>(url).subscribe(data => { this.savedViewLayouts$.next(data); });
    return this.savedViewLayouts$.asObservable();
  }

  saveNewViewLayout(view: ISavedViewLayout): Observable<ISavedViewLayout> {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<ISavedViewLayout>(url, view).pipe(catchError(err => this.handleError(err)), tap(data => this.updateSavedViews(data)));
  }

  updateView(view: ISavedViewLayout): Observable<ISavedViewLayout> {
    const url = `${this.baseUrl}/${view.Id}`;
    return this.httpClient.put<ISavedViewLayout>(url, view).pipe(catchError(err => this.handleError(err)), tap(data => {
      // this.machineUpdated$.next(data);
      this.updateSavedViews(data);
    }));
  }

  deleteView(layoutViewId: number): Observable<object> {
    const url = `${this.baseUrl}/${layoutViewId}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete saved layout.")), tap(data => {
      this.loadSavedViews();
    }));
  }

  private handleError(err: any, message: string = "Failed to save layout. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  private updateSavedViews(savedView: ISavedViewLayout): void {
    this.savedViewLayouts$.asObservable().subscribe(data => {
      const index = data.findIndex(m => m.Id === savedView.Id);
      if (index === -1) {
        data.push(savedView);
      }
      else {
        data[index] = savedView;
      }
      setTimeout(() => {
        this.savedViewLayouts$.next(data);
      }, 50);
    }).unsubscribe();
  }
}

export enum ViewType {
  Contracts = 'Contracts',
  Proposals = 'Proposals'
}
export interface ISavedViewLayout {
  Id: number;
  Name: string;
  ViewType: ViewType;
  Properties: any;
}