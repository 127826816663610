import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-number-editor',
  templateUrl: './number-editor.component.html',
  styleUrls: ['./number-editor.component.scss']
})
export class NumberEditorComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('input', { read: ViewContainerRef }) public input: ViewContainerRef | undefined;
  private params: ICellEditorParams | undefined;
  public value: number = 0;

  cellWidth = '';   // [style.width] = "cellWidth"
  rowHeight = '20px';
  lineHeight = '22px';
  width = 30;

  constructor() { }

  getValue() {
    const v = this.value ?? 0;
    return v;
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = parseFloat(params.value);

    this.width = (params.column.getActualWidth())
    this.rowHeight = params.node.rowHeight ? (params.node.rowHeight - 2) + 'px !Important' : '20px'
    const lineH = params.eGridCell.style.lineHeight;
    const ln = parseFloat(lineH);
    this.lineHeight = (ln) + 'px';
  }

  ngAfterViewInit(): void {
    // focus on the input 
    setTimeout(() => this.input?.element.nativeElement.focus());
  }
}
