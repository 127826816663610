<mat-form-field class="mat-select-sm" appearance="fill">
    <mat-select [(ngModel)]="month" (ngModelChange)="valueChanged()">
        <mat-option class="mat-option-sm" selected value="All">All</mat-option>
        <mat-option class="mat-option-sm" selected value="LastMonth">Last Month</mat-option>
        <mat-option class="mat-option-sm" selected value="ThisMonth">This Month</mat-option>

        <mat-option class="mat-option-sm" *ngFor="let m of months" [value]="m">
            {{m}}
        </mat-option>
    </mat-select>
</mat-form-field>
