import { Component, OnInit } from '@angular/core';
import { FilterChangedEvent, GridOptions, IFloatingFilter, IFloatingFilterParams, TextFilter } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-floating-filter',
  templateUrl: './select-floating-filter.component.html',
  styleUrls: ['./select-floating-filter.component.scss']
})
export class SelectFloatingFilterComponent implements IFloatingFilter {

  params: ISelectFloatingFilterParams | undefined;
  gridOptions: GridOptions = {};

  options: Observable<ISelectFloatingFilterOption[]> = new Observable<ISelectFloatingFilterOption[]>();
  allOption = true;
  selectedValue: string | number = '';

  constructor() {
  }

  agInit(params: ISelectFloatingFilterParams): void {
    this.params = params;
    this.selectedValue = 'All';

    this.allOption = params.allOption ?? true;

    let paramOptions = (params as any).options;
    if (!paramOptions) { console.error("Options is required."); return; }
    this.options = paramOptions;
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    if (!parentModel) {
      this.selectedValue = 'All';
    }
    else {
      this.selectedValue = parentModel.filter;
    }
  }

  valueChanged() {
    let valueToUse = this.selectedValue === 'All' ? null : this.selectedValue?.toString();
    this.params?.parentFilterInstance((instance) => (<TextFilter>instance).onFloatingFilterChanged('equals', valueToUse));
  }
}

export interface ISelectFloatingFilterParams extends IFloatingFilterParams {
  options: ISelectFloatingFilterOption[] | number[] | string[];
  allOption: boolean | undefined;
}

export interface ISelectFloatingFilterOption {
  Value: string | number;
  Text: string;
}
