import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class CrewsService {

  private crews$: ReplaySubject<ICrew[]> = new ReplaySubject(1);
  public get crews(): Observable<ICrew[]> { return this.crews$.asObservable() }

  baseUrl = 'crews';
  isLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
    this.loadCrews();
  }

  loadCrews(): Observable<ICrew[]> {
    const url = `${this.baseUrl}`;
    this.httpClient.get<ICrew[]>(url).subscribe(data => { this.crews$.next(data); });
    return this.crews$.asObservable();
  }

  saveCrew(crew: ICrew) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<ICrew>(url, crew).pipe(catchError(err => this.handleError(err)), tap((data => this.updateCrews(0, [data]))));
  }

  delete(crew: ICrew) {
    const url = `${this.baseUrl}/${crew.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete Crew.")), tap(data => this.updateCrews(2, [crew])));
  }

  private handleError(err: any, message: string = "Failed to save crew. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }


  updateCrews(crudType: number, crews: ICrew[]) {
    this.crews.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const crew of crews) {
          const index = data.findIndex(c => c.Id === crew.Id);
          if (index === -1) {
            data.push(crew);
          }
          else {
            data[index] = crew;
          }
        }
      }
      else if (crudType === 2) {
        for (const crew of crews) {
          const index = data.findIndex(c => c.Id === crew.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.crews$.next([...data]);
    });
  }
}


export interface ICrew {
  Id: number;
  Name: string;
}
