<mat-toolbar color="primary">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <!-- <div class="home-link">
        <a routerLink="/">Project Manager</a>
    </div> -->
    <div fxFlex fxLayout fxLayoutAlign="start" fxHide.xs style="margin-left: 1em;">
        <ul *ngIf="isAuthenticated | async" fxLayout fxLayoutGap="15px" class="navigation-items">
            <li> <a [routerLink]="['/contracts']" routerLinkActive="active-link">Contracts</a></li>
            <li> <a [routerLink]="['/scheduler']" routerLinkActive="active-link">Scheduler</a></li>
            <li> <a [routerLink]="['/proposals']" routerLinkActive="active-link">Proposals</a></li>
            <li> <a [routerLink]="['/settings']" routerLinkActive="active-link">Settings</a></li>
        </ul>
    </div>
    <div *ngIf="isAuthenticated | async" fxFlex fxLayout fxLayoutAlign="end" fxHide.xs
        style="margin-left: 2em; margin-right: 3px;">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item color="primary" class="not-link" [routerLink]='["/settings"]' [state]='{ local: true }'
                title="Settings">{{ userName | async }} </a>

            <button mat-menu-item (click)="openSettings()">Grid Settings</button>
            <a mat-menu-item color="primary" class="not-link" [routerLink]='["/logout"]' [state]='{ local: true }'
                title="Logout">Logout</a>
        </mat-menu>
    </div>
    <ul *ngIf="!(isAuthenticated | async)" fxLayout fxLayoutGap="15px" class="navigation-items">
        <li> <a [routerLink]='["/authentication/login"]'>Login</a></li>
    </ul>
</mat-toolbar>