import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;
    const user = this.authService.userValue;
    if (user) {
      let url: string = state.url;
      return this.checkUserLogin(route, url);
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }


  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    // const userRole = this.authService.userType;
    // if (route.data.role && route.data.role.indexOf(userRole) === -1) {
    //   this.router.navigate(['']);
    //   return false;
    // }
    return true;
  }
}
