import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellValueChangedEvent, FilterChangedEvent, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { DatesFilterComponent } from 'src/app/ag-grid-components/dates-floating-filter/dates-filter.component';
import { DatesFloatingFilterComponent } from 'src/app/ag-grid-components/dates-floating-filter/dates-floating-filter.component';
import { ISelectEditorOption } from 'src/app/ag-grid-components/select-editor/select-editor.component';
import { SelectFloatingFilterComponent } from 'src/app/ag-grid-components/select-floating-filter/select-floating-filter.component';
import { TextareaEditorComponent } from 'src/app/ag-grid-components/textarea-editor/textarea-editor.component';
import { IRep, RepsService } from 'src/app/reps/reps.service';
import { AgGridSettings, UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { CustomerMatchService } from '../customer-match.service';
import { Grids, IProposalGridState, ProposalLayoutService } from '../proposal-layout.service';
import { CustomersService, ICustomer } from '../services/customers.service';
import { ProjectAttachmentsService } from '../services/project-attachments.service';
import { IProject, ProjectsService } from '../services/projects.service';
import { ProposalAutocompleteOptionsService } from '../services/proposal-autocomplete-options.service';
import { ProposalsService } from '../services/proposals.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() clearFilter: Observable<void> = new Observable<void>();
  @Input() sizeColumnsToFit: Observable<void> = new Observable<void>();
  @Input() resetGrid: Observable<void> = new Observable<void>();
  @Output() projectCount = new EventEmitter<number>();

  subscriptions = new Subscription();
  projects: IProjectCustomer[] = [];
  reps: IRep[] = [];

  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  agGridTheme = 'ag-theme-balham';
  gridActivated = false;
  defaultColDef: any;
  cols: any;
  gridState: IProposalGridState | null = null;
  frameworkComponents = {
    textareaEditor: TextareaEditorComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    datesFilter: DatesFilterComponent,
    datesFloatingFilter: DatesFloatingFilterComponent,
  };

  matchingCustomers: ICustomer[] = [];
  customers: ICustomer[] = [];
  selectedProject: IProject | null = null;
  reps$: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  status: BehaviorSubject<ISelectEditorOption[]> = new BehaviorSubject(new Array<ISelectEditorOption>());
  sendFilterChange$: Subject<string> = new Subject();

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  constructor(private projectsService: ProjectsService, private customerService: CustomersService, private customerMatchService: CustomerMatchService,
    private userSettings: UserSettingsService, private proposalsService: ProposalsService, private repsService: RepsService, private layoutService: ProposalLayoutService,
    private autocompleteService: ProposalAutocompleteOptionsService, private projectAttachmentService: ProjectAttachmentsService, private datePipe: DatePipe) {
    this.subscriptions.add(this.userSettings.agGridSettings.subscribe(data => this.initGrid(data)));
  }
  ngAfterViewInit(): void {
    this.subscriptions.add(this.sendFilterChange$.pipe(debounceTime(300)).subscribe(data => {
      const filteredProjects: IProject[] = [];
      this.gridApi?.forEachNodeAfterFilter(r => {
        filteredProjects.push(r.data);
      });
      this.projectsService.filteredProjects$.next(filteredProjects);
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.layoutService.proposalViewLayout.subscribe(data => { this.gridState = data.Grids.ProjectList; this.setSavedGridSate(); }));
    const projects$ = this.projectsService.projects;
    const customers$ = this.customerService.customers;
    this.subscriptions.add(combineLatest(projects$, customers$)
      .subscribe(([projects, customers]) => {
        this.customers = customers;
        const customersMap = new Map(customers.map(c => [c.Id, c]));
        const projectsWithCustomer: IProjectCustomer[] = [];
        for (const project of projects) {
          const pc: IProjectCustomer = { ...project, Customer: customersMap.get(project.CustomerId) ?? null }
          projectsWithCustomer.push(pc);
        }
        this.projects = projectsWithCustomer;
        setTimeout(() => { this.countRows(); }, 5);
      }));
    this.subscriptions.add(this.autocompleteService.statusOptions.subscribe(data => this.status.next(data.map(r => ({ Value: r.Text, Text: r.Text })))));
    this.subscriptions.add(this.repsService.reps.subscribe(data => { this.reps = data; this.reps$.next(data.map(r => ({ Value: r.Id, Text: r.Name }))) }));
    this.subscriptions.add(this.projectsService.selectedProject.subscribe(data => {
      this.selectedProject = data;
      if (!data) { return; }
      const selectedRows = this.gridApi?.getSelectedRows() ?? [];
      if (selectedRows.length > 0) {
        const selectedProject = selectedRows[0] as IProject;
        if (selectedProject.Id !== data?.Id) {
          this.gridApi?.forEachNodeAfterFilter(node => {
            if (node.data.Id === data?.Id) {
              node.setSelected(true);
              this.gridApi?.ensureIndexVisible(node.rowIndex, 'middle');
            }
          });
        }
      }
      else {
        this.gridApi?.forEachNodeAfterFilter(node => {
          if (node.data.Id === data?.Id) {
            node.setSelected(true);
            this.gridApi?.ensureIndexVisible(node.rowIndex, 'middle');
          }
        });
      }
    }));

    this.subscriptions.add(this.customerMatchService.customerMatch.subscribe(data => { this.matchingCustomers = data; this.gridApi?.onFilterChanged(); }));
    this.subscriptions.add(this.clearFilter.subscribe(event => this.clearFilters()));
    this.subscriptions.add(this.sizeColumnsToFit.subscribe(event => { this.gridApi?.sizeColumnsToFit(); this.saveGridState(); }));
    this.subscriptions.add(this.resetGrid.subscribe(event => { this.gridOptions.columnApi?.resetColumnState(); this.gridApi?.sizeColumnsToFit(); }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setSavedGridSate();
    this.countRows();
    this.sendFilterChange$.next('');
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi?.getSelectedRows() ?? [];
    if (selectedRows?.length > 0) {
      const selectedProject = selectedRows[0] as IProject;
      if (this.selectedProject?.Id !== selectedProject.Id) {
        this.projectsService.selectProject(selectedProject);
        this.proposalsService.selectProposal(null);
      }
      const customer = this.customers.find(c => c.Id === selectedProject.CustomerId) ?? null;
      this.customerService.selectCustomer(customer);
    }
    else {
      this.projectsService.selectProject(null);
      this.customerService.selectCustomer(null);
    }
  }

  clearFilters() {
    this.gridOptions.api?.setFilterModel(null);
    this.gridApi?.onFilterChanged();
  }

  onFilterChanged(params: FilterChangedEvent) {
    this.countRows();
    setTimeout(() => { this.saveGridState(); }, 2);
    this.sendFilterChange$.next('');
  }

  countRows() {
    const count = this.gridApi?.getDisplayedRowCount() ?? 0;
    this.projectCount.emit(count);
  }

  onCellValueChanged(params: CellValueChangedEvent) {
    const colId = params.column.getId();
    const project: IProject = params.data;
    this.projectsService.update(project).subscribe();
    this.gridApi?.redrawRows();
  }

  doesExternalFilterPass(node: RowNode): boolean {
    if (this.matchingCustomers.length > 0) {
      const data = node.data as IProject;
      return this.matchingCustomers.some(c => c.Id === data.CustomerId);
    }
    return true;
  }

  saveGridState() {
    const gridState: IProposalGridState = {
      ColumnState: this.gridColumnApi.getColumnState(),
      ColumnFilterState: this.gridOptions.api?.getFilterModel() ?? {},
    }
    this.layoutService.gridUpdate(Grids.ProjectList, gridState);
  }

  setSavedGridSate() {
    if (this.gridApi && this.gridState) {
      this.gridOptions?.columnApi?.applyColumnState({ state: this.gridState.ColumnState, applyOrder: true });
      this.gridApi?.setFilterModel(this.gridState.ColumnFilterState);
    }
    else if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  initGrid(data: AgGridSettings) {
    this.gridActivated = false;
    const { theme, ...gridOptions } = data;
    this.gridOptions = gridOptions;
    this.agGridTheme = theme;
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.isExternalFilterPresent = () => true;
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this)
    this.gridOptions.onFilterChanged = (event) => this.onFilterChanged(event);
    this.gridOptions.onDragStopped = () => this.saveGridState();
    this.gridOptions.onSortChanged = () => this.saveGridState();
    this.gridOptions.onColumnPinned = () => this.saveGridState();
    this.gridOptions.onColumnMoved = () => this.saveGridState();
    this.gridOptions.onColumnResized = () => this.saveGridState();
    this.gridOptions.onColumnVisible = () => this.saveGridState();
    this.gridOptions.onColumnValueChanged = () => this.saveGridState();

    this.gridOptions.immutableData = true;
    this.gridOptions.getRowNodeId = (data: IProject) => data.Id.toString();
    this.gridOptions.onCellValueChanged = this.onCellValueChanged.bind(this);
    // this.gridOptions.onFirstDataRendered = () => this.gridApi?.sizeColumnsToFit();
    // this.gridOptions.onGridSizeChanged = (event) => this.gridApi?.sizeColumnsToFit();

    this.defaultColDef = {
      filter: 'agTextColumnFilter',
      resizable: true,
      sortable: true,
      cellStyle: { 'line-height': `${(data.rowHeight ?? 20) - 4}px` },
      floatingFilter: true,
      filterParams: {
        debounceMs: 1
      },
    };

    this.cols = [
      { field: 'Customer.Name', headerName: 'Name', width: 100 },
      { field: 'Address', headerName: 'Address', width: 100 },
      { field: 'City', headerName: 'City', width: 100 },
      { field: 'Customer.Phone1', headerName: 'Phone 1', width: 70 },
      { field: 'Customer.Phone2', headerName: 'Phone 2', width: 70 },
      { field: 'ProjectType', headerName: 'Project Type', width: 70 },
      { field: 'Description', headerName: 'Description', width: 120, editable: (params: any) => true, cellEditor: 'textareaEditor' },
      {
        field: 'Notes', headerName: 'Notes', width: 120, cellStyle: (params: any) => {
          const sch = params.value?.toLowerCase().trim().startsWith('sch')
          const cellStyle = sch ? { color: 'Black', backgroundColor: 'Yellow' } : {};
          return Object.assign(cellStyle, this.defaultColDef.cellStyle);
        },
        editable: (params: any) => true, cellEditor: 'textareaEditor',
        cellEditorParams: {
          minimumRows: 1,
          maximumRows: 6,
          suppressEnterKey: true
        }
      },
      {
        field: 'RepId', headerName: 'Rep', width: 80, valueFormatter: (params: any) => {
          return this.reps.find(x => x.Id == params.value)?.Name ?? '';
        },
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.reps$ }
      },
      {
        field: 'DateCalled', headerName: 'Date Called', width: 80,
        valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? '',
        filter: 'datesFilter', floatingFilterComponent: 'datesFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        field: 'Status', headerName: 'Status', width: 80,
        floatingFilterComponent: 'selectFloatingFilter', floatingFilterComponentParams: { suppressFilterButton: true, options: this.status }
      }
    ];
    setTimeout(() => {

      this.gridActivated = true;
    }, 1);
  }

  dragover(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (rowId) {
      this.addHoverClass(rowId);
    }
  }

  dragEnd(event: any) {
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
  }

  drop(e: any): void {
    e.preventDefault();
    document.querySelectorAll('.drag-drop-attachment-hover').forEach(el => el.classList.remove('drag-drop-attachment-hover'));
    const rowId = this.getRowId(e.target);
    if (!rowId) { return }
    this.saveFiles(rowId, e.dataTransfer.files);
  }

  saveFiles(rowId: string, files: any) {
    const projectId = parseInt(rowId);
    const project = this.projects.find(c => c.Id === projectId);
    if (!project) {
      console.error('Could not find project with rowId ' + rowId);
      return
    }

    this.projectAttachmentService.uploadFiles(project.Id, files)?.subscribe();
  }

  getRowId(element: HTMLElement | null) {
    if (!element) { return null }
    let rowId = element.getAttribute('row-id');
    if (rowId) { return rowId; }

    while (!rowId) {
      element = element.parentElement;
      if (element === null) { return null; }

      rowId = element.getAttribute('row-id');
    }
    return rowId;
  }

  addHoverClass(rowId: string) {
    if (rowId === 'b-0') { return; }
    document.querySelectorAll(`div[row-id="${rowId}"`).forEach(element => element.classList.add('drag-drop-attachment-hover'));
  }

}

interface IProjectCustomer extends IProject {
  Customer: ICustomer | null;
}
