import { Component, OnInit } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { FilterChangedEvent, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IContract } from 'src/app/contracts/contracts.service';

@Component({
    selector: '',
    template: '',
    styles: ['']
})
export class MonthFilterComponent implements AgFilterComponent {
    params: IFilterParams | undefined;
    selectedOption = "All"
    readonly thisMonth = new Date().getMonth();
    readonly lastMonth = (new Date().getMonth() - 1);
    readonly months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    constructor() { }

    agInit(params: IFilterParams): void {
        this.params = params;
    }

    isFilterActive(): boolean {
        return this.selectedOption !== 'All';
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        const contract: IContract = params.data;
        switch (this.selectedOption) {
            case 'All':
                return true;
            case 'ThisMonth':
                return this.months[this.thisMonth] === contract.Month;
            case 'LastMonth':
                return this.months[this.lastMonth] === contract.Month;
            default:
                return this.selectedOption === contract.Month;
        }
    }

    getModel() {
        return this.selectedOption;
    }

    setModel(model: any) {
        this.selectedOption = model ?? 'All';
        this.params?.filterChangedCallback();
    }
}