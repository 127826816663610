import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FilterChangedEvent, GridOptions, IFloatingFilter, IFloatingFilterParams, TextFilter } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-floating-filter',
  templateUrl: './autocomplete-floating-filter.component.html',
  styleUrls: ['./autocomplete-floating-filter.component.scss']
})
export class AutocompleteFloatingFilterComponent implements OnDestroy, IFloatingFilter {
  subscriptions = new Subscription();
  params: IAutocompleteFloatingFilterParams | undefined;
  gridOptions: GridOptions = {};

  options: IAutoCompleteFloatingFilterOption[] = [];
  filteredOptions: Observable<IAutoCompleteFloatingFilterOption[]> = new Observable<IAutoCompleteFloatingFilterOption[]>();
  allOption = true;
  selectedValue: string | number = '';
  filterControl = new FormControl();
  showAllOptions = true;

  constructor() { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  agInit(params: IAutocompleteFloatingFilterParams): void {
    this.params = params;
    this.selectedValue = 'All';
    this.allOption = params.allOption ?? true;
    let paramOptions = (params as any).options;
    if (!paramOptions) { console.error("Options is required."); return; }

    this.subscriptions.add(paramOptions.subscribe((data: any) => { this.options = data; this.filterControl.updateValueAndValidity({ onlySelf: false, emitEvent: true }); }));
    this.filteredOptions = this.filterControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  private _filter(name: string): IAutoCompleteFloatingFilterOption[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.Text.toLowerCase().indexOf(filterValue) === 0);
  }


  onParentModelChanged(parentModel: any, filterChangedEvent: FilterChangedEvent): void {
    if ((filterChangedEvent as any).source?.type === 'rowDataChanged') {
      return;
    }

    if (!parentModel) {
      this.selectedValue = 'All';
    }
    else {
      this.selectedValue = parentModel.filter;
    }
  }

  valueChanged() {
    let valueToUse = this.selectedValue === 'All' ? null : this.selectedValue.toString();
    this.params?.parentFilterInstance((instance) => (<TextFilter>instance).onFloatingFilterChanged('equals', valueToUse));
    this.params?.filterChangedEvent(valueToUse);
  }
}

export interface IAutocompleteFloatingFilterParams extends IFloatingFilterParams {
  options: IAutoCompleteFloatingFilterOption[] | number[] | string[];
  allOption: boolean | undefined;
  filterChangedEvent: CallableFunction
}

export interface IAutoCompleteFloatingFilterOption {
  Value: string | number;
  Text: string;
}
