import { AfterViewInit, ChangeDetectorRef, Directive, HostListener, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { merge, Subscription } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[tab-directive]' })
export class TabDirective implements AfterViewInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  private panelOpen = false;

  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger, @Optional() private control: NgControl, private cdRef: ChangeDetectorRef) {
  }

  @HostListener('keydown.tab', ['$event.target']) onBlur() {
    if (this.panelOpen && this.autoTrigger.activeOption) {
      const value = this.autoTrigger.activeOption.value;
      this.control.control?.setValue(value, { emit: false });
      this.autoTrigger.writeValue(value);
    }
  }

  ngAfterViewInit() {
    const autocomplete = this.autoTrigger.autocomplete;
    this.subscriptions.add(merge(
      autocomplete.opened.pipe(map(() => true)),
      autocomplete.closed.pipe(map(() => false))
    ).pipe(delay(0)).subscribe(value => this.panelOpen = value));


    // this.subscriptions.add(this.autoTrigger.panelClosingActions.subscribe(x => {
    //   if (this.autoTrigger.activeOption) {
    //     const value = this.autoTrigger.activeOption.value;
    //     if (this.control)
    //       this.control.control?.setValue(value, { emit: false });
    //     this.autoTrigger.writeValue(this.autoTrigger.activeOption.value)
    //   }
    // }));

    this.subscriptions.add(this.autoTrigger.autocomplete.options.changes.subscribe(d => {
      const data = this.control.value ?? '';
      const filter = data.trim().toLowerCase();

      if (filter === '') {
        setTimeout(() => { this.autoTrigger.autocomplete._keyManager.setFirstItemActive(); }, 1);
        return;
      }

      setTimeout(() => {
        if (this.autoTrigger) {
          const matOptions = this.autoTrigger.autocomplete.options;
          for (let i = 0; i < matOptions.length; i++) {
            const matOption = matOptions.get(i);
            if (matOption) {
              const mValue = matOption.value as string;
              if (mValue.trim().toLowerCase().startsWith(filter)) {

                setTimeout(() => {
                  this.autoTrigger?.autocomplete._keyManager.setActiveItem(i);
                }, 1);
                return;
              }
            }
          }
          setTimeout(() => {
            this.autoTrigger.autocomplete._keyManager.setFirstItemActive();
          }, 1);
        }
      }, 5);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}