import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SignalrService } from './services/signalr.service';
import { ThemeStorage } from './theme-picker/theme-storage.service';
import { UserSettingsService } from './user-settings/user-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();

  constructor(themeStorageService: ThemeStorage, userSettingsService: UserSettingsService, renderer: Renderer2, private signalrService: SignalrService,
    private router: Router, private storage: StorageMap, public swUpdate: SwUpdate) {

    this.storage.get('route').subscribe(data => {

      if (data === undefined && this.router.url === '/') {
        this.router.navigateByUrl('/contracts');
        return;
      }
      if (!data) { return; }
      if (this.router.url !== "/") { return; }

      const url = (data as string).toLowerCase();
      if (url.startsWith('/proposals')) {
        this.router.navigateByUrl(url);
      }
      else if (url.startsWith('/contracts')) {
        this.router.navigateByUrl(url);
      }
    }, err => console.log(err));

    this.subscriptions.add(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.storage.set('route', event.url).subscribe();
      }
    }));


    this.subscriptions.add(themeStorageService.appTheme.subscribe(data => {
      if (data === null) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          themeStorageService.saveAppTheme(true);
          data = true;
          userSettingsService.agGridSettings.pipe(take(1)).subscribe(data => {
            data.theme = 'ag-theme-balham-dark';
            userSettingsService.saveAgGridSettings(data);
          });
        }
      }

      if (data === true) {
        renderer.addClass(document.body, 'dark-theme');
        renderer.removeClass(document.body, 'light-theme');
      } else {
        renderer.addClass(document.body, 'light-theme');
        renderer.removeClass(document.body, 'dark-theme');
      }
      this.setStyleSheet(data ?? false);
    }));
  }

  setStyleSheet(darkMode: boolean) {
    for (let i = 0; i < document.styleSheets.length; i++) {
      const styleSheet = document.styleSheets[i];
      if (styleSheet.href?.includes('syncfusion-material-light')) {
        styleSheet.disabled = darkMode;
      }
      if (styleSheet.href?.includes('syncfusion-material-dark')) {
        styleSheet.disabled = !darkMode;
      }
    }
  }

  ngOnInit(): void {
    this.signalrService.init();
    if (this.swUpdate.isEnabled) {
      this.subscriptions.add(this.swUpdate.available.subscribe(() => {
        if (confirm("You're using an old version. Want to update?")) {
          window.location.reload();
        }
      }));
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
