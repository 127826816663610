import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalsService {

  private proposals$: BehaviorSubject<IProposal[]> = new BehaviorSubject<IProposal[]>([]);
  public get proposals(): Observable<IProposal[]> { if (!this.isProposalLoaded) { this.loadProposals(); } return this.proposals$.asObservable(); }

  private selectedProposal$: BehaviorSubject<IProposal | null> = new BehaviorSubject<IProposal | null>(null);
  public get selectedProposal(): Observable<IProposal | null> { return this.selectedProposal$.asObservable(); }

  baseUrl = 'proposals';
  isProposalLoaded = false;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  loadProposals(): void {
    const url = `${this.baseUrl}`;
    this.isProposalLoaded = true;
    this.httpClient.get<IProposal[]>(url).subscribe(data => { this.proposals$.next(data); });
  }

  selectProposal(proposal: IProposal | null | number) {
    setTimeout(() => {
      if (typeof (proposal) === 'number') {
        proposal = this.proposals$.value.find(p => p.Id === proposal) ?? null;
      }
      proposal && this.refreshSelectedProposal(proposal);
      this.selectedProposal$.next(proposal);
    }, 1);
  }

  private refreshSelectedProposal(proposal: IProposal) {
    if (this.selectedProposal$.value?.Id !== proposal.Id) {
      this.getProposal(proposal.Id);
    }
  }

  getProposal(id: number): void {
    const url = `${this.baseUrl}/${id}`;
    this.httpClient.get<IProposal>(url).subscribe(data => { this.updateProposals(1, [data]); this.selectedProposal$.next(data) });
  }

  add(proposal: IProposal) {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<IProposal>(url, proposal).pipe(catchError(err => this.handleError(err)), tap((data => this.updateProposals(0, [data]))));
  }

  update(proposal: IProposal) {
    const url = `${this.baseUrl}/${proposal.Id}`;
    return this.httpClient.put<IProposal>(url, proposal).pipe(catchError(err => this.handleError(err)), tap((data => this.updateProposals(1, [data]))));
  }

  delete(proposal: IProposal) {
    const url = `${this.baseUrl}/${proposal.Id}`;
    return this.httpClient.delete(url).pipe(catchError(err => this.handleError(err, "Failed to delete proposal. ")), tap((data => this.updateProposals(2, [proposal]))));
  }

  getProposalHtmlToPrint(id: number) {
    const url = `${this.baseUrl}/${id}/print`;
    return this.httpClient.get(url, { responseType: 'text' });
  }

  emailProposal(proposalId: number, emailProposalDto: EmailProposalDto) {
    const url = `${this.baseUrl}/${proposalId}/email`;
    return this.httpClient.post(url, emailProposalDto).pipe(catchError(err => this.handleError(err, "Failed to send email.")));
  }

  getProposalPdfURL(proposal: IProposal) {
    const url = `api/${this.baseUrl}/${proposal.Id}/pdf/Proposal ${proposal.ProposalNumber}?ngsw-bypass=true`;
    return url;
  }

  private handleError(err: any, message: string = "Failed to save customer. ") {
    this.notificationService.showError(message, err);
    return throwError(err);
  }

  updateProposals(crudType: number, proposals: IProposal[]) {
    this.proposals.pipe(take(1)).subscribe(data => {
      if (crudType === 0 || crudType === 1) {
        for (const proposal of proposals) {
          const index = data.findIndex(c => c.Id === proposal.Id);
          if (index === -1) {
            data.push(proposal);
          }
          else {
            data[index] = proposal;
          }
        }
      }
      else if (crudType === 2) {
        for (const proposal of proposals) {
          const index = data.findIndex(c => c.Id === proposal.Id);
          if (index !== -1) { data.splice(index, 1); }
        }
      }
      this.proposals$.next([...data]);
    });
  }
}


export interface IProposal {
  Id: number;
  ProjectId: number;
  CustomerId: number;
  RepId: number | null;
  ProposalNumber: string;
  BidDate: Date;
  NumberOfPayments: number;
  DaysValid: number;
  Status: string;
  Amount: number;
  Lines: ILine[];
  Payments: IPayment[];
  Terms: string;
}

export interface ILine {
  Text: string;
}

export interface IPayment {
  Condition: string;
  Amount: number;
}

export interface EmailProposalDto {
  Subject: string;
  Body: string;
}