import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { IDateAngularComp } from 'ag-grid-angular';
import { IDateParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss']
})
export class CustomDatePickerComponent implements OnInit, IDateAngularComp {
  @ViewChild('input') matDatePicker: MatDatepicker<Date> | undefined;

  params: IDateParams | undefined;
  value: Date | null = null;
  popup = false;


  constructor() { }


  getDate(): Date | null {
    return this.value;
  }
  setDate(date: Date | null): void {
    this.value = date;
  }
  agInit(params: IDateParams): void {
    this.params = params;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.matDatePicker?.open();
    });
  }

  // getValue() {
  //   this.value;
  // }

  // agInit(params: ICellEditorParams): void {
  //   this.params = params;
  //   this.value = this.params.value ?? null;
  // }

  // isPopup() {
  //   return true;
  // }

  closed(event: any) {
    this.params?.onDateChanged();
  }

}
