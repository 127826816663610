import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/user-settings/user-settings.service';
import { ContractsService } from '../contracts.service';
import { IOrder, OrdersService } from './orders.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  orders: IOrder[] = new Array<IOrder>();
  selectedOrder: IOrder | null = null;

  gridApi: GridApi | undefined;
  gridColumnApi: any;
  gridOptions: GridOptions = {}; // { theme: 'ag-theme-balham' };
  agGridTheme = 'ag-theme-balham';
  rowHeight() { return this.gridOptions.rowHeight ? this.gridOptions.rowHeight : 20; }
  gridActivated = false;

  defaultColDef = {
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    cellStyle: { 'line-height': `${this.rowHeight() - 2}px` },
  };

  filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      if (cellValue === null) return -1;
      const cellDate = new Date(cellValue); // new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
      if (cellDate < filterLocalDateAtMidnight) { return -1; }
      if (cellDate > filterLocalDateAtMidnight) { return 1; }
      return -1
    },
    browserDatePicker: true,
    buttons: ['reset', 'apply']
  };

  cols = [
    { field: 'Item', headerName: 'Item', width: 120 },
    { field: 'Description', headerName: 'Description', width: 300 },
    { field: 'Qty', headerName: 'Qty', width: 100 },
    { field: 'Price', headerName: 'Price', width: 100, valueFormatter: (params: any) => this.currencyPipe.transform(params.value, 'USD', '') ?? '' },
    { field: 'Amount', headerName: 'Amount', width: 120 },
    {
      field: 'DateOrdered', headerName: 'Date Ordered', width: 150, filterParams: this.filterParams,
      valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? ''
    },
    { field: 'PODoc', headerName: 'PO Doc', width: 150, },
    { field: 'Ordered', headerName: 'Ordered Qty', width: 120 },
    {
      field: 'DateReceived', headerName: 'Date Received', width: 150, filterParams: this.filterParams,
      valueFormatter: (params: any) => params.value === '0001-01-01T00:00:00' ? '' : this.datePipe.transform(params.value, 'M/d/yyyy') ?? ''
    },
    { field: 'ReceiptDoc', headerName: 'Rec Doc', width: 120 },
    { field: 'ReceivedQty', headerName: 'Rec Qty', width: 120 },
  ];

  rowClassRules = (params: any) => {
    const Ordered = params.data.Ordered;
    const Qty = params.data.Qty;
    const ReceivedQty = params.data.ReceivedQty;
    let status = 0;
    if (Ordered > 0 && Ordered < Qty) status = 1; // return 'status-partial-order'  //partial order  
    if (Ordered > 0 && Ordered == Qty) status = 2; // return 'status-ordered' // Status = 2; //ordered
    if (ReceivedQty > 0 && ReceivedQty < Ordered) status = 3 //return 'status-partial-received' // Status = 3; //partial receipt
    if (ReceivedQty > 0 && ReceivedQty == Qty) status = 4 //return 'status-fully-received' //  Status = 4; //fully received

    switch (status) {
      case 0:
        return '';
      case 1:
        return 'status-partial-order'
      case 2:
        return 'status-ordered'
      case 3:
        return 'status-partial-received'
      case 4:
        return 'status-fully-received'
    }
    return '';
  }

  constructor(private ordersService: OrdersService, private contractsService: ContractsService, userSettings: UserSettingsService, private datePipe: DatePipe, private currencyPipe: CurrencyPipe) {
    this.subscriptions.add(userSettings.agGridSettings.subscribe(data => {
      this.gridActivated = false;
      const { theme, ...gridOptions } = data;
      this.gridOptions = gridOptions;
      this.gridOptions.getRowClass = this.rowClassRules;
      this.agGridTheme = theme;
      this.defaultColDef = {
        filter: 'agTextColumnFilter',
        resizable: true,
        sortable: true,
        cellStyle: { 'line-height': `${this.rowHeight() - 2}px` }
      }
      setTimeout(() => { this.gridActivated = true; });
    }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.contractsService.selectedContract.pipe(switchMap(contract => {
      this.orders = [];
      this.selectedOrder = null;
      if (contract) {
        return this.ordersService.loadOrders(contract.JobNum);
      }
      return EMPTY;
    })).subscribe(data => {
      this.orders = data;
      this.selectedOrder = this.orders.length > 0 ? this.orders[0] : null;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  selectedOrderUpdated(event: any) {
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi?.showNoRowsOverlay();
  }

}
