<div style="display: grid; grid-template-columns: 190px 1fr; height: 100%">

  <mat-selection-list [multiple]="false" (selectionChange)="selectedStatusTypeChanged($event)" class="border"
    style="grid-column: 1; margin: 5px;">
    <mat-list-option *ngFor="let statusType of statusTypes, let i = index" [value]="statusType.Value"
      [selected]="statusType == selectedStatusType">
      {{ statusType.Text }}
    </mat-list-option>
  </mat-selection-list>

  <as-split>
    <as-split-area [size]="30">
      <ag-grid-angular *ngIf="gridActivated" style="width: 100%; height: calc(100% - 5px); margin-top: 4px;"
        [rowData]="statusList" [gridOptions]="gridOptions" [ngClass]="agGridTheme" [columnDefs]="cols"
        [defaultColDef]="defaultColDef" rowSelection="single" (gridReady)="onGridReady($event)"
        (selectionChanged)="onSelectionChanged()">
      </ag-grid-angular>
    </as-split-area>
    <as-split-area [size]="70">

      <form class="edit-form" [formGroup]="form" autocomplete="off">
        <div style="display: grid; grid-template-columns: 300px 400px;">
          <div style=" grid-row: 1; grid-column: 1 / -1; width: 100%;">
            <button type="button" mat-icon-button matTooltip="Add New Proposal" color="accent"
              class="mat-raised-button-sm" (click)="newStatus()">
              <mat-icon>add</mat-icon>
            </button>
            <button type="button" matTooltip="Save Changes" (click)="save()" mat-icon-button color="accent"
              class="mat-raised-button-sm">
              <mat-icon>save</mat-icon>
            </button>
            <button type="button" matTooltip="Delete" mat-icon-button color="warn" [disabled]="selectedStatus === null"
              class="mat-raised-button-sm" (click)="deleteStatus()">
              <mat-icon class="md-18">delete</mat-icon>
            </button>

          </div>
          <div style="grid-column: 1; width: 100%; margin-top: 10px;">
            <div class="left-label-inputs" style="padding: 3px; margin: 3px; max-width: 672px;">
              <mat-label>Status</mat-label>
              <div>
                <mat-form-field appearance="fill" color="accent">
                  <input matInput formControlName="Name">
                  <mat-error>Status is required.</mat-error>
                </mat-form-field>
              </div>
              <label>Font Color</label>
              <div>
                <mat-form-field appearance="fill" color="accent">
                  <div class="my-color-picker">
                    <input matInput formControlName="FontColor" [value]="form.value.FontColor">
                    <input matInput type="color" formControlName="FontColor" [value]="form.value.FontColor">
                  </div>
                </mat-form-field>
                <button *ngIf="form.value.FontColor !== 'Initial'" matSuffix mat-icon-button aria-label="Clear"
                  type="button" (click)="form.value.FontColor = 'Initial'">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <label>Background Color</label>
              <div>
                <mat-form-field appearance="fill" color="accent">
                  <div class="my-color-picker">
                    <input matInput formControlName="BackgroundColor" [value]="form.value.BackgroundColor">
                    <input matInput type="color" formControlName="BackgroundColor" [value]="form.value.BackgroundColor">
                  </div>
                </mat-form-field>
                <button *ngIf="form.value.FontColor !== 'Initial'" matSuffix mat-icon-button aria-label="Clear"
                  type="button" (click)="form.value.FontColor = 'Initial'">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <label>Font Weight</label>
              <div>
                <mat-form-field appearance="fill" color="accent">
                  <mat-select formControlName="FontWeight" [(value)]="form.value.FontWeight">
                    <mat-option value="Normal">Normal</mat-option>
                    <mat-option value="Bold">Bold</mat-option>
                    <mat-option value="Bolder">Bolder</mat-option>
                    <mat-option value="Lighter">Lighter</mat-option>
                    <mat-option value="100">100</mat-option>
                    <mat-option value="200">200</mat-option>
                    <mat-option value="300">300</mat-option>
                    <mat-option value="400">400</mat-option>
                    <mat-option value="500">500</mat-option>
                    <mat-option value="600">600</mat-option>
                    <mat-option value="700">700</mat-option>
                    <mat-option value="800">800</mat-option>
                    <mat-option value="900">900</mat-option>
                    <mat-option value="Initial">Initial</mat-option>
                    <mat-option value="Inherit">Inherit</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </as-split-area>
  </as-split>
</div>
