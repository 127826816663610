import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractViewSettingsService } from 'src/app/contracts/contract-view-settings.service';
import { ISavedViewLayout as ISavedLayouts, ISavedViewLayout, SavedLayoutsService, ViewType } from '../saved-layouts.service';
import { StringExt } from 'src/app/utils/string';
import { StorageMap } from '@ngx-pwa/local-storage';
import { MatDialog } from '@angular/material/dialog';
import { SaveLayoutDialogComponent } from '../save-layout-dialog/save-layout-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-components/confirm-dialog/confirm-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import { ProposalLayoutService } from 'src/app/projects/proposal-layout.service';
import { ILayoutService } from 'src/app/services/LayoutService';

@Component({
  selector: 'app-saved-layouts',
  templateUrl: './saved-layouts.component.html',
  styleUrls: ['./saved-layouts.component.scss']
})
export class SavedLayoutsComponent implements OnInit, OnDestroy {
  @Input() viewType: ViewType = ViewType.Contracts;

  subscriptions: Subscription = new Subscription();
  savedLayouts: ISavedLayouts[] = []
  selectedLayoutId: number = -1;
  isSavingLayout = false;
  currentLayout: ISavedLayouts = { Id: 0, Name: '', ViewType: this.viewType, Properties: null };
  newLayoutName = '';
  isSettingSavedState = false;
  selectedLayoutKey = "Contracts.SelectedLayout";
  layoutService: ILayoutService | undefined;

  constructor(private savedLayoutsService: SavedLayoutsService, private contractLayoutService: ContractViewSettingsService,
    private proposalLayoutService: ProposalLayoutService, private storage: StorageMap, public dialog: MatDialog, private route: ActivatedRoute,
    private router: Router, private location: Location, private locationStrategy: LocationStrategy) {
  }

  ngOnInit(): void {
    this.currentLayout = { Id: 0, Name: '', ViewType: this.viewType, Properties: null };
    this.selectedLayoutKey = `${this.viewType}.SelectedLayout`;
    let layoutId = Number(this.route.snapshot.queryParamMap.get('selectedViewLayout'));
    this.getSavedLayouts(layoutId);

    switch (this.viewType) {
      case ViewType.Contracts:
        this.layoutService = this.contractLayoutService;
        break;
      case ViewType.Proposals:
        this.layoutService = this.proposalLayoutService;
        break;
      default:
        console.error('View type not supported');
        break;
    }

    if (this.layoutService === undefined) {
      throw new Error('LayoutService is undefined.');
    }

    this.subscriptions.add(this.layoutService.stateChanged.subscribe(data => {
      if (this.isSettingSavedState) { return; }
      this.currentLayout.Properties = data;
      this.selectedLayoutId = -1;
      this.updateUrl();
    }));

    this.storage.get(this.selectedLayoutKey, { type: 'number' }).subscribe(data => {
      if (data && data > 0) {
        if (this.selectedLayoutId < 1) {
          this.selectedLayoutId = data;
          this.isSettingSavedState = true;
          this.valueChanged();
          setTimeout(() => {
            this.isSettingSavedState = false;
          }, 1500);
          return;
        }
      }
      if (this.selectedLayoutId < 1) {
        this.layoutService?.loadAndSetStateFromBrowser();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  valueChanged() {
    const selectedLayout = this.savedLayouts.find(s => s.Id === this.selectedLayoutId);
    if ((this.selectedLayoutId !== -1) && selectedLayout) {
      this.setPresetLayout(selectedLayout);
    }
  }

  getSavedLayouts(layoutId: number) {
    this.subscriptions.add(this.savedLayoutsService.savedViewLayouts.pipe(map(savedLayouts =>
      savedLayouts.filter(savedLayouts => savedLayouts.ViewType === this.viewType))).subscribe(data => {
        this.savedLayouts = data;
        if (layoutId > 0) {
          this.selectedLayoutId = layoutId;
          this.valueChanged();
        }
      }));
  }

  setPresetLayout(savedView: ISavedViewLayout) {
    this.isSettingSavedState = true;
    this.layoutService?.setSavedView(savedView.Properties);
    this.updateUrl();
    setTimeout(() => {
      this.isSettingSavedState = false;
    }, 100);
  }

  openSaveDialog() {
    const dialogRef = this.dialog.open(SaveLayoutDialogComponent, {
      // width: '250px',
      data: { savedLayouts: this.savedLayouts.map(m => m.Name) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!StringExt.isNullOrWhiteSpace(result)) {
        this.newLayoutName = result;
        this.saveLayout();
      }
      else {
        this.newLayoutName = '';
      }
    });
  }

  saveLayout() {
    if (StringExt.isNullOrWhiteSpace(this.newLayoutName)) { return; }

    this.isSavingLayout = false;
    const newLayout = this.getLayout();
    if (newLayout == null) {
      console.error('Cannot save null layout.');
      return;
    }

    if (newLayout.Id === 0) {
      this.savedLayoutsService.saveNewViewLayout(newLayout).subscribe(data => this.layoutSaved(data));
    }
    else {
      this.savedLayoutsService.updateView(newLayout).subscribe(data => this.layoutSaved(data));
    }
  }

  layoutSaved(layout: ISavedViewLayout) {
    this.selectedLayoutId = layout.Id;
    this.newLayoutName = '';
    this.updateUrl();
  }

  getLayout(): ISavedViewLayout | null {
    if (!this.viewType) { return null; }
    const name = this.newLayoutName.toLowerCase().trim();
    const getLayout = this.savedLayouts.find(l => l.Name.toLowerCase().trim() === name);

    if (getLayout) {
      const newLayout: ISavedViewLayout = {
        Id: getLayout.Id,
        Name: getLayout.Name,
        ViewType: this.viewType,
        Properties: this.currentLayout.Properties
      };
      return newLayout;
    }

    const newLayout: ISavedViewLayout = {
      Id: 0,
      Name: this.newLayoutName,
      ViewType: this.viewType,
      Properties: this.currentLayout.Properties
    };
    return newLayout;
  }

  deleteSavedLayout() {
    const message = `Are you sure you want to delete ${this.savedLayouts.find(l => l.Id === this.selectedLayoutId)?.Name ?? 'layout'}?`;
    const dialogData = new ConfirmDialogModel("Confirm Delete", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.savedLayoutsService.deleteView(this.selectedLayoutId).subscribe();
        this.selectedLayoutId = -1;
      }
    });
  }


  updateUrl() {
    this.storage.set(this.selectedLayoutKey, this.selectedLayoutId).subscribe();
    // const queryParamsObj = { selectedViewLayout: this.selectedLayoutId };
    // this.location.replaceState(this.router.createUrlTree([this.locationStrategy.path().split('?')[0]], // Get uri
    // { queryParams: queryParamsObj } // Pass all parameters inside queryParamsObj
    // ).toString());

  }
}

